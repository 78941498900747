import React, { useState } from "react";
import Indicadores from "./Indicadores";
import Sobre from "./Sobre";
import "./auxilio.css";

export default function Ciencia() {
    const [page, setPage] = useState("sobre");

    return (
        <div>
            {page === "sobre" ?
                <div>
                    <div className="backgroundPainelCienciaAuxilio">
                        <div className="divSelectContentCienciaAuxilio">
                            <div className="selectedContentCienciaAuxilio" onClick={() => setPage("sobre")}>
                                <div className="textMainCienciaAuxilio" style={{ marginRight: "16px" }}>Sobre</div>
                            </div>
                            <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainCienciaAuxilio">Indicadores</div>
                            </div>
                        </div>
                        <div className="textDescricaoCienciaAuxilio">
                            <div>Dados gerais e resultados das ações de apoio a projetos de produção e difusão do conhecimento científico e tecnológico.</div>
                        </div>
                    </div>
                    <Sobre />
                </div>
                : ""}
            {page === "indicadores" ?
                <div className="backgroundPainelCienciaAuxilio">
                    <div className="divSelectContentCienciaAuxilio">
                        <div className="selectedContentCienciaAuxilio" onClick={() => setPage("sobre")}>
                            <div className="textOffMainCienciaAuxilio" style={{ marginRight: "16px" }}>Sobre</div>
                        </div>
                        <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                            <div className="textMainCienciaAuxilio">Indicadores</div>
                        </div>
                    </div>
                    <div className="textDescricaoCienciaAuxilio">
                        <div>Seleção de indicadores pertinentes às ações de apoio a projetos de produção e difusão do conhecimento científico e tecnológico.</div>
                    </div>
                    <Indicadores />
                </div>
                : ""}
        </div>
    )
}