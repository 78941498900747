import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Logo } from '../components/icons/logoFuncap.svg';

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
    	<div>
	      <SvgIcon style={{fontSize: "150px", color: "#20764B", display: "block", marginLeft: "auto", marginRight: "auto"}}>
            <Logo/>
          </SvgIcon>
	      <h4 style={{textAlign: "center"}}>Investimento nos programas de apoio a inovação empresarial</h4>
	      <div style={{display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", justifyItems: "center"}}>
		      <table border="1"  >
		        <thead>
		          <th style={{textAlign: "center"}}>Região</th>
		          <th style={{textAlign: "center"}}>Área</th>
		          <th style={{textAlign: "center"}}>Ano</th>
		          <th style={{textAlign: "center"}}>Valor (R$)</th>
		        </thead>
		        <tbody>
		        {this.props.data.map((item) => (
		          <tr>
		            <td style={{paddingRight: "15px", paddingLeft: "15px"}}>{item.regiao}</td>
		            <td style={{paddingRight: "15px", paddingLeft: "15px"}}>{item.area}</td>
		            <td style={{paddingRight: "15px", paddingLeft: "15px"}}>{item.ano}</td>
		            <td style={{paddingRight: "15px", paddingLeft: "15px"}}>{item.valor}</td>
		          </tr>
		        ))}
		        </tbody>
		      </table>
		   </div>
      </div>
    );
  }
}