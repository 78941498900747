import React, { useState, useEffect } from "react";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Animacao(props) {

    
    const controls = useAnimation();
    var [ref, inView] = useInView({triggerOnce:true});

    const number = props.data + "";
    const duration = 2;
    const [count, setCount] = useState(0);

    useEffect(() => {

        let start = 0;
        const end = parseInt(number.substring(0, 3));

        if (start === end) {
            return;
        }

        let totalMillSecDur = duration;
        let IncrementTime = (totalMillSecDur / end) * 1000;

        let timer = setInterval(() => {
            start += 1;
            setCount(String(start) + number.substring(3));
            if (start === end) {
                clearInterval(timer);
            }
        }, IncrementTime);

    }, [controls, inView, number, duration, ref])
    
    if(props.data === 0){
        return ("-")
    }


    return (
        <motion ref={ref}>
            {count}
        </motion>
    )
}
