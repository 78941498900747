import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Animacao from "../../../components/animacoes/IncrementNumbers";
import Donut from "../../../components/graficos/donutPaineis/Donut";
import SimpleModal from "../../blog/Modal";
import Areas from "./areasCientistaChefe/Areas";

import BlogsSection from "../../../components/blogsSection/BlogsSection";
import { ReactComponent as Bolsistas } from '../../../components/icons/paineis/caneta.svg';
import { ReactComponent as InstituicoesAfiliadas } from '../../../components/icons/paineis/casinha.svg';
import { ReactComponent as ProjetosAtivos } from '../../../components/icons/paineis/mala.svg';
import { ReactComponent as Coordenadores } from '../../../components/icons/paineis/pessoas.svg';
import { ReactComponent as ProjetosFinalizados } from '../../../components/icons/paineis/quadrados.svg';
import "./cientistaChefe.css";


const useStyles = makeStyles({
  selectRoot: {
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
  select: {
    "&:before": {
      borderColor: "#20764B",
    },
    "&:after": {
      borderColor: "#20764B",
    },
    color: "#405965",
    fontWeight: "bold",
  },
  icon: {
    fill: "#20764B",
  },
});
export default function Sobre() {


  var blogs = [
    {
      tipo: 1
    },
    {
      tipo: 1
    },
  ];

  const [data, setData] = useState(
    [
      {
        type: 'Capital',
        value: 7219851,
      },
      {
        type: 'Custeio',
        value: 21164073,
      },
      {
        type: 'Bolsas',
        value: 73959060,
      },
    ]
  )

  const location = useLocation();
  var param = parseInt(location.pathname.replace(/\D/g, ''));
  var bornOpened = false;

  if (param > 0) {
    bornOpened = true
  }

  const [open, setOpen] = useState(bornOpened);
  const [open2, setOpen2] = useState(false);
  const classes = useStyles();
  const [anos, setAnos] = useState([2018, 2019, 2020, 2021, 2022]);
  const [minYear, setMinYear] = useState(2022);
  const [maxYear, setMaxYear] = useState(2022);
  const [dados, setDados] = useState([
    { ano: 2018, type: "Capital", value: 975400.00 },
    { ano: 2018, type: "Custeio", value: 2648369.00 },
    { ano: 2018, type: "Bolsas", value: 2216500.00 },
    { ano: 2019, type: "Capital", value: 2062401.00 },
    { ano: 2019, type: "Custeio", value: 4604832.00 },
    { ano: 2019, type: "Bolsas", value: 5208550.00 },
    { ano: 2020, type: "Capital", value: 1581259.00 },
    { ano: 2020, type: "Custeio", value: 5616474.00 },
    { ano: 2020, type: "Bolsas", value: 10250500.00 },
    { ano: 2021, type: "Capital", value: 1265482.00 },
    { ano: 2021, type: "Custeio", value: 4944671.00 },
    { ano: 2021, type: "Bolsas", value: 18611958.00 },
    { ano: 2022, type: "Capital", value: 1894699.00 },
    { ano: 2022, type: "Custeio", value: 3561219.00 },
    { ano: 2022, type: "Bolsas", value: 14941788.00 },

  ]);
  const [filterData, setFilterData] = useState([
    { type: "Capital", value: 1894699.00 },
    { type: "Custeio", value: 3561219.00 },
    { type: "Bolsas", value: 14941788.00 },
  ]);

  function updateFilterData(minYear, maxYear) {
    let array = [...filterData]
    array[0].value = 0
    array[1].value = 0
    array[2].value = 0
    for (var i = 0; i < dados.length; i++) {
      if (dados[i].ano >= minYear && dados[i].ano <= maxYear) {
        if (dados[i].type === "Capital") {
          array[0].value += dados[i].value
        }
        if (dados[i].type === "Custeio") {
          array[1].value += dados[i].value
        }
        if (dados[i].type === "Bolsas") {
          array[2].value += dados[i].value
        }
      }
    }
    setFilterData(array)
  }

  const handleChangeMinYear = (event) => {
    var min;
    var max;

    if (event.target.value > maxYear) {
      min = maxYear;
      max = event.target.value;
      setMinYear(maxYear);
      setMaxYear(event.target.value);
      updateFilterData(min, max);
    } else {
      min = event.target.value;
      max = maxYear;
      setMinYear(event.target.value);
      updateFilterData(min, max);
    }
  };

  const handleChangeMaxYear = (event) => {
    var min;
    var max;

    if (event.target.value < minYear) {
      min = event.target.value;
      max = minYear;
      setMaxYear(minYear);
      setMinYear(event.target.value);
      updateFilterData(min, max);
    } else {
      min = minYear;
      max = event.target.value;
      setMaxYear(event.target.value);
      updateFilterData(min, max);
    }
  };

  function getIndicadorNumber(indi) {
    var data = [
      { ano: 2018, ind: "Áreas Implementadas", valor: 8 },
      { ano: 2018, ind: "Bolsas Ativas", valor: 116 },
      { ano: 2018, ind: "Projetos Implementados", valor: 16 },
      { ano: 2018, ind: "Instituições Beneficiadas", valor: 7 },
      { ano: 2018, ind: "Projetos Finalizados", valor: 0 },
      { ano: 2019, ind: "Áreas Implementadas", valor: 2 },
      { ano: 2019, ind: "Bolsas Ativas", valor: 251 },
      { ano: 2019, ind: "Projetos Implementados", valor: 15 },
      { ano: 2019, ind: "Instituições Beneficiadas", valor: 5 },
      { ano: 2019, ind: "Projetos Finalizados", valor: 0 },
      { ano: 2020, ind: "Áreas Implementadas", valor: 8 },
      { ano: 2020, ind: "Bolsas Ativas", valor: 556 },
      { ano: 2020, ind: "Projetos Implementados", valor: 24 },
      { ano: 2020, ind: "Instituições Beneficiadas", valor: 7 },
      { ano: 2020, ind: "Projetos Finalizados", valor: 10 },
      { ano: 2021, ind: "Áreas Implementadas", valor: 1 },
      { ano: 2021, ind: "Bolsas Ativas", valor: 128 },
      { ano: 2021, ind: "Projetos Implementados", valor: 37 },
      { ano: 2021, ind: "Instituições Beneficiadas", valor: 3 },
      { ano: 2021, ind: "Projetos Finalizados", valor: 15 },
      { ano: 2022, ind: "Áreas Implementadas", valor: 3 },
      { ano: 2022, ind: "Bolsas Ativas", valor: 904 },
      { ano: 2022, ind: "Projetos Implementados", valor: 30 },
      { ano: 2022, ind: "Instituições Beneficiadas", valor: 16 },
      { ano: 2022, ind: "Projetos Finalizados", valor: 26 }

    ]

    var count = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].ano >= minYear && data[i].ano <= maxYear) {
        if (data[i].ind === indi) {
          count += data[i].valor
        }
      }
    }

    return count
  }


  return (
    <div style={{ backgroundColor: "white" }}>
      {open
        ?
        <SimpleModal open={open} setOpen={() => setOpen(!open)}></SimpleModal>
        :
        <></>
      }
      <Areas />
      <div className="contentInovacaoPublicaAcaoInduzidaSobre">
        {/*
        <div className="painelInovacaoPublicaAcaoInduzidaSobreI">
          <div className="textPainelInovacaoPublicaAcaoInduzidaSobre">
            INVESTIMENTOS EM INOVAÇÃO PÚBLICA
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <div className="text2PainelInovacaoPublicaAcaoInduzidaSobre">
              Investimentos realizados de
            </div>
            <div className="divDropdownPeriodoInovacaoPublicaAcaoInduzidaSobre">
              <FormControl className="dropdownPeriodoInovacaoPublicaAcaoInduzidaSobre">
                <Select
                  value={minYear}
                  onChange={handleChangeMinYear}
                  displayEmpty
                  disableUnderline
                  classes={{ root: classes.selectRoot }}
                  className={classes.select}
                  MenuProps={{ disablePortal: true }}
                  inputProps={{
                    "aria-label": "Without label",
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                >
                  {anos.map((ano) => (
                    <MenuItem value={ano}>{ano}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="text2PainelInovacaoPublicaAcaoInduzidaSobre">a</div>
            <div className="divDropdownPeriodoInovacaoPublicaAcaoInduzidaSobre">
              <FormControl className="dropdownPeriodoInovacaoPublicaAcaoInduzidaSobre">
                <Select
                  value={maxYear}
                  onChange={handleChangeMaxYear}
                  displayEmpty
                  disableUnderline
                  classes={{ root: classes.selectRoot }}
                  className={classes.select}
                  MenuProps={{ disablePortal: true }}
                  inputProps={{
                    "aria-label": "Without label",
                    classes: {
                      icon: classes.icon,
                      root: classes.root,
                    },
                  }}
                >
                  {anos.map((ano) => (
                    <MenuItem value={ano}>{ano}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          
          <div
            style={{
              marginBottom: "32px",
              paddingBottom: "32px",
              paddingTop: "42px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="donutInovacaoPublicaAcaoInduzidaSobre">
              <Donut data={filterData} cor={"amarelo"}></Donut>
            </div>
            <div className="divMiniIndicadoresInovacaoPublicaAcaoInduzida">
              <div className="divIconesInovacaoPublicaAcaoInduzida">
                <div className="divIconeInovacaoPublicaAcaoInduzida">
                  <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                    <Coordenadores />
                  </SvgIcon>
                  <p className="iconeTituloInovacaoPublicaAcaoInduzida"><b className="iconeNumeroInovacaoPublicaAcaoInduzida"><Animacao data={getIndicadorNumber("Áreas Implementadas")} /></b><br></br>Áreas Implementadas<br></br></p>
                </div>
                <div className="divIconeInovacaoPublicaAcaoInduzidaEnd">
                  <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                    <InstituicoesAfiliadas />
                  </SvgIcon>
                  <p className="iconeTituloInovacaoPublicaAcaoInduzida"><b className="iconeNumeroInovacaoPublicaAcaoInduzida"><Animacao data={getIndicadorNumber("Instituições Beneficiadas")} /></b><br></br>Instituições Beneficiadas<br></br></p>
                </div>
              </div>
              <div className="divIconesInovacaoPublicaAcaoInduzida" >
                <div className="divIconeInovacaoPublicaAcaoInduzida">
                  <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                    <Bolsistas />
                  </SvgIcon>
                  <p className="iconeTituloInovacaoPublicaAcaoInduzida"><b className="iconeNumeroInovacaoPublicaAcaoInduzida"><Animacao data={getIndicadorNumber("Bolsas Ativas")} /></b><br></br>Bolsas Ativas<br></br></p>
                </div>
                <div className="divIconeInovacaoPublicaAcaoInduzidaEnd">
                  <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                    <ProjetosFinalizados />
                  </SvgIcon>
                  <p className="iconeTituloInovacaoPublicaAcaoInduzida"><b className="iconeNumeroInovacaoPublicaAcaoInduzida"><Animacao data={getIndicadorNumber("Projetos Finalizados")} /></b><br></br>Projetos Finalizados<br></br></p>
                </div>
              </div>
              <div className="divIconesInovacaoPublicaAcaoInduzidaEnd">
                <div className="divIconeInovacaoPublicaAcaoInduzida">
                  <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                    <ProjetosAtivos />
                  </SvgIcon>
                  <p className="iconeTituloInovacaoPublicaAcaoInduzida"><b className="iconeNumeroInovacaoPublicaAcaoInduzida"><Animacao data={getIndicadorNumber("Projetos Implementados")} /></b><br></br>Projetos Implementados<br></br></p>
                </div>
                
                <div className="divIconeInovacaoPublicaAcaoInduzidaEnd">
                  <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                  </SvgIcon>
                  <p className="iconeTituloInovacaoPublicaAcaoInduzida" style={{ color: "transparent" }}>
                    <b style={{ color: "transparent" }} className="iconeNumeroInovacaoPublicaAcaoInduzida"><Animacao data={getIndicadorNumber("Bolsistas")} /></b><br></br>Projetos Ativos<br></br></p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
          */}
        <BlogsSection page={"Cientista-Chefe"} title={"Realizações"} />
      </div>
    </div>
  );
}
