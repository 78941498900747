import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import StoreContext from '../../../components/Store/Context';

const RoutesPrivate = ({ component: Component, ...rest }) => {
  const { token, setToken, timeout, setTimeout } = useContext(StoreContext);
  const today = new Date().getDate();


  /*se token expirou
  if (today != timeout) {
    
    setToken(null)
    setTimeout(null)
    window.location = "/"
  }
  */

  return (
    <Route
      {...rest}
      render={() => token
        ? <Component {...rest} />
        : <Redirect to="/" />
      }
    />
  )
}

export default RoutesPrivate;