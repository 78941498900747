import React, { useState } from "react";
import CientistaChefe from "./CientistaChefe";
import "./publica.css";

export default function Internacionalização({ ind = false }) {
    var selectedRoute = "cientistaChefe";

    if (ind) {
        selectedRoute = "indicadores";
    }
    const [page, setPage] = useState(selectedRoute);

    return (
        <div>
            {page === "cientistaChefe" ?
                <div>
                    <div className="backgroundPainelInovacaoPublica">
                        <div className="divSelectContentInovacaoPublica">
                            <div /*className="selectedContentInovacaoPublica"*/ onClick={() => setPage("cientistaChefe")}>
                                <div className="textMainInovacaoPublica" style={{ marginRight: "16px" }}>Cientista Chefe</div>
                            </div>
                            {/*
                            <div className="selectedContentInovacaoPublica" onClick={() => setPage("outros")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainInovacaoPublica" style={{ marginRight: "16px" }}>Outros</div>
                            </div>
                            <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainInovacaoPublica">Indicadores</div>
                            </div>
    */}
                        </div>
                        <div className="textDescricaoInovacaoPublica">
                            <div>Dados gerais e resultados das ações de apoio ao desenvolvimento de projetos de pesquisa e inovação para o setor público, em parceria com órgãos públicos estaduais e municipais.</div>
                        </div>
                    </div>
                    <CientistaChefe />
                </div>
                : ""}
            {/*
            {page === "outros" ?
                <div>
                    <div className="backgroundPainelInovacaoPublica">
                        <div className="divSelectContentInovacaoPublica">
                            <div className="selectedContentInovacaoPublica" onClick={() => setPage("cientistaChefe")}>
                                <div className="textOffMainInovacaoPublica" style={{ marginRight: "16px" }}>Cientista Chefe</div>
                            </div>
                            <div className="selectedContentInovacaoPublica" onClick={() => setPage("outros")} style={{ marginLeft: "16px" }}>
                                <div className="textMainInovacaoPublica" style={{ marginRight: "16px" }}>Outros</div>
                            </div>
                            <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainInovacaoPublica">Indicadores</div>
                            </div>
                        </div>
                        <div className="textDescricaoInovacaoPublica">
                            <div>Dados gerais e resultados das ações de apoio a projetos de pesquisa, desenvolvimento e inovação de interesse público.</div>
                        </div>
                    </div>
                    <Outros />
                </div>
                : ""}
            {page === "indicadores" ?
                <div>
                    <div className="backgroundPainelInovacaoPublica">
                        <div className="divSelectContentInovacaoPublica">
                            <div className="selectedContentInovacaoPublica" onClick={() => setPage("cientistaChefe")}>
                                <div className="textOffMainInovacaoPublica" style={{ marginRight: "16px" }}>Cientista Chefe</div>
                            </div>
                            <div className="selectedContentInovacaoPublica" onClick={() => setPage("outros")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainInovacaoPublica" style={{ marginRight: "16px" }}>Outros</div>
                            </div>
                            <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                                <div className="textMainInovacaoPublica">Indicadores</div>
                            </div>
                        </div>
                        <div className="textDescricaoInovacaoPublica">
                            <div>Seleção de indicadores pertinentes às ações de apoio a projetos de pesquisa, desenvolvimento e inovação voltadas ao interesse público. Filtrar por programa, Cientista Chefe ou outros.</div>
                        </div>
                    </div>
                    <Indicadores />
                </div>
                : ""}
    */}
        </div>
    )
}