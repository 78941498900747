import parse from 'html-react-parser';
import "./substringColor.css";

function isLetter(c) {
    return c.toLowerCase() != c.toUpperCase();
}

export default function substringColor(texto, subTexto = "", colorNumber = 1) {

    if (texto === null || texto == undefined) {
        return ""
    }
    if (texto.length < 1) {
        return ""
    }

    var color = "color" + colorNumber;
    var textoOriginal = texto;
    texto = texto.toLowerCase();
    subTexto = subTexto.toLowerCase();

    texto = texto.replaceAll(subTexto, "<span className=" + color + ">" + subTexto + "</span>");

    //Retransforma o texto para sua forma original
    var noHTML = true //variavel que determina se a parte do texto não é html
    var posicao = 0 // index do texto original

    texto = texto.split('');
    textoOriginal = textoOriginal.split('')

    try {
        for (var i = 0; i < texto.length; i++) {
            if (texto[i] === '<') {
                noHTML = false
            }
            if (texto[i] === '>') {
                noHTML = true
            }
            if (noHTML === true && texto[i] !== '>' && posicao < textoOriginal.length) {
                //console.log(texto.length)
                //console.log(textoOriginal.length)
                //console.log(texto[i] + textoOriginal[posicao])
                texto[i] = textoOriginal[posicao]
                posicao++;
            }
        }
    }
    catch (err) {
        console.log(err)
    }

    //Re-capitalize first letter
    //texto = texto.split('');
    if (!isLetter(texto[0])) {
        for (var i = 0; i < texto.length - 1; i++) {
            if (texto[i] === '>' && isLetter(texto[i + 1])) {
                texto[i + 1] = texto[i + 1].toUpperCase();
                break;
            }
        }
    }
    else {
        texto[0] = texto[0].toUpperCase()
    }

    //console.log(texto)

    texto = texto.join('');

    texto = "<div>" + texto + "</div>"

    return parse(texto)
}