import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from "react";
import Animacao from "../../../components/animacoes/IncrementNumbers";
import BlogsSection from "../../../components/blogsSection/BlogsSection";
import Donut from "../../../components/graficos/donutPaineis/Donut";
import { ReactComponent as InstituicoesAtendidas } from '../../../components/icons/paineis/casinha.svg';
import { ReactComponent as Bolsistas } from '../../../components/icons/paineis/pessoas.svg';
import "./sobre.css";

const useStyles = makeStyles({
    selectRoot: {
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
    select: {
      "&:before": {
        borderColor: "#20764B",
      },
      "&:after": {
        borderColor: "#20764B",
      },
      color: "#405965",
      fontWeight: "bold",
    },
    icon: {
      fill: "#20764B",
    },
  });

export default function Sobre() {

    var blogs = [
        {
            tipo: 1
        },
        {
            tipo: 1
        },
    ];

    const [data, setData] = useState(
        [
            { ano: 2021, type: "Bsocial", value: 9847350.00 },
            { ano: 2021, type: "Mestrado/Doutorado", value: 21413900.00 },
            { ano: 2021, type: "BICT", value: 3264000.00 },
            { ano: 2021, type: "PDPG", value: 236526.00 },
            { ano: 2021, type: "AVANCE", value: 0 },
            { ano: 2022, type: "Bsocial", value: 11195550.00 },
            { ano: 2022, type: "Mestrado/Doutorado", value: 23643740.00 },
            { ano: 2022, type: "BICT", value: 3979650.00 },
            { ano: 2022, type: "PDPG", value: 64980.00 },
            { ano: 2022, type: "AVANCE", value: 3290744.00 },
           
        ]
    )

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [anos, setAnos] = useState([2021,2022]);
    const [minYear, setMinYear] = useState(2022);
    const [maxYear, setMaxYear] = useState(2022);
    const [titulo, setTitulo] = useState("Investimentos em Formação de Recursos Humanos");
    
    const [filterData, setFilterData] = useState([
        { type: "Bsocial", value: 11195550.00 },
        { type: "Mestrado/Doutorado", value: 23643740.00 },
        { type: "BICT", value: 3979650.00 },
        { type: "PDPG", value: 64980.00 },
        { type: "AVANCE", value: 3290744.00 },
      ]);
      
    const handleChangeMinYear = (event) => {
        var min;
        var max;
    
        if (event.target.value > maxYear) {
          min = maxYear;
          max = event.target.value;
          setMinYear(maxYear);
          setMaxYear(event.target.value);
          updateFilterData(min, max);
        } else {
          min = event.target.value;
          max = maxYear;
          setMinYear(event.target.value);
          updateFilterData(min, max);
        }
      };
    
      const handleChangeMaxYear = (event) => {
        var min;
        var max;
    
        if (event.target.value < minYear) {
          min = event.target.value;
          max = minYear;
          setMaxYear(minYear);
          setMinYear(event.target.value);
          updateFilterData(min, max);
        } else {
          min = minYear;
          max = event.target.value;
          setMaxYear(event.target.value);
          updateFilterData(min, max);
        }
      };
    
      function updateFilterData(minYear, maxYear) {
        let array = [...filterData]
        for (var o = 0; o < array.length; o++) {
            array[o].value = 0
          }
        for (var i = 0; i < data.length; i++) {
          if (data[i].ano >= minYear && data[i].ano <= maxYear) {
            if (data[i].type === "Bsocial") {
              array[0].value += data[i].value
            }
            if (data[i].type === "Mestrado/Doutorado") {
              array[1].value += data[i].value
            }
            if (data[i].type === "BICT") {
              array[2].value += data[i].value
            }
            if (data[i].type === "PDPG") {
              array[3].value += data[i].value
            }
            if (data[i].type === "AVANCE") {
                array[4].value += data[i].value
            }
          }
        }
        setFilterData(array)
      }
    
      function getIndicadorNumber(indi) {
        var data = [
          { ano: 2021, ind: "Bolsas de Pós Graduação", valor: 1060 },
          { ano: 2021, ind: "Bolsas Bsocial", valor: 2363 },
          { ano: 2021, ind: "Instituições Atendidas", valor: 17},
          { ano: 2021, ind: "Bolsas de Iniciação Científica", valor: 726},
          { ano: 2021, ind: "Cidades Contempladas", valor: 27},
          { ano: 2022, ind: "Bolsas de Pós Graduação", valor: 1047 },
          { ano: 2022, ind: "Bolsas Bsocial", valor: 2933 },
          { ano: 2022, ind: "Instituições Atendidas", valor: 80},
          { ano: 2022, ind: "Bolsas de Iniciação Científica", valor:873},
          { ano: 2022, ind: "Cidades Contempladas", valor: 43},
          
        ]
    
        var count = 0
        for (var i = 0; i < data.length; i++) {
          if (data[i].ano >= minYear && data[i].ano <= maxYear) {
            if (data[i].ind === indi) {
              count += data[i].valor
            }
          }
        }
    
        return count
      }
      
    return (
        <div className="contentCienciaAuxilioSobre">
            <div className="painelCienciaAuxilioSobre">
            <div className="textTituloPainelSobre">{titulo}</div>
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    }}
                >
                    <div className="text2TituloPainelSobre">
                    Investimentos realizados de
                    </div>
                    <div className="divDropdownPeriodoTituloBanner">
                    <FormControl className="dropdownPeriodoTituloBanner">
                        <Select
                        value={minYear}
                        onChange={handleChangeMinYear}
                        displayEmpty
                        disableUnderline
                        classes={{ root: classes.selectRoot }}
                        className={classes.select}
                        MenuProps={{ disablePortal: true }}
                        inputProps={{
                            "aria-label": "Without label",
                            classes: {
                            icon: classes.icon,
                            root: classes.root,
                            },
                        }}
                        >
                        {anos.map((ano) => (
                            <MenuItem value={ano}>{ano}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </div>
                    <div className="text2TituloPainelSobre">a</div>
                    <div className="divDropdownPeriodoTituloBanner">
                    <FormControl className="dropdownPeriodoTituloBanner">
                        <Select
                        value={maxYear}
                        onChange={handleChangeMaxYear}
                        displayEmpty
                        disableUnderline
                        classes={{ root: classes.selectRoot }}
                        className={classes.select}
                        MenuProps={{ disablePortal: true }}
                        inputProps={{
                            "aria-label": "Without label",
                            classes: {
                            icon: classes.icon,
                            root: classes.root,
                            },
                        }}
                        >
                        {anos.map((ano) => (
                            <MenuItem value={ano}>{ano}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </div>
                </div>
            <div
          style={{
            marginBottom: "32px",
            paddingBottom: "32px",
            paddingTop: "42px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
                <div className="donutCienciaAuxilioSobre">
                    <Donut data={filterData} cor={"verde"}></Donut>
                </div>
                <div className="divMiniIndicadoresCienciaAuxilio">
                    <div className="divIconesCienciaAuxilio">
                        <div className="divIconeCienciaAuxilio">
                            <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                <Bolsistas />
                            </SvgIcon>
                            <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Bolsas de Pós Graduação")} /></b><br></br>Bolsas de Pós Graduação<br></br></p>
                        </div>
                        <div className="divIconeCienciaAuxilioEnd">
                            <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                <Bolsistas/>
                            </SvgIcon>
                            <p className="iconeTituloCienciaAuxilio" ><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Bolsas de Iniciação Científica")} /></b><br></br>Bolsas de Iniciação Científica<br></br></p>
                        </div>
                    </div>
                    <div className="divIconesCienciaAuxilio" >
                        <div className="divIconeCienciaAuxilio">
                            <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                <Bolsistas />
                            </SvgIcon>
                            <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Bolsas Bsocial")} /></b><br></br>Bolsas Bsocial<br></br></p>
                        </div>
                        <div className="divIconeCienciaAuxilioEnd" style={{ userSelect: "none" }}>
                            <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                <InstituicoesAtendidas/>
                            </SvgIcon>
                            <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Cidades Contempladas")}/></b><br></br>Cidades Contempladas<br></br></p>
                        </div>
                    </div>
                    <div className="divIconesCienciaAuxilioEnd">
                        <div className="divIconeCienciaAuxilio">
                            <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                <InstituicoesAtendidas/>
                            </SvgIcon>
                            <p className="iconeTituloCienciaAuxilio" ><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Instituições Atendidas")} /></b><br></br>Instituições Atendidas<br></br></p>
                        </div>
                        <div className="divIconeCienciaAuxilioEnd" style={{ userSelect: "none" }}>
                            <SvgIcon style={{ fontSize: "50px", color: "transparent" }}>
                            </SvgIcon>
                            <p className="iconeTituloCienciaAuxilio" style={{ color: "transparent" }}><b className="iconeNumeroCienciaAuxilio" style={{ color: "transparent" }}></b><br></br><br></br></p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <BlogsSection page={"Ciência (Formação de RH)"} title={"Realizações"}/>
        </div>
    );
}
