import React from "react";
import { ReactComponent as Facebook } from './icons/facebook.svg';
import { ReactComponent as Governo } from './icons/governo.svg';
import { ReactComponent as Instagram } from './icons/instagram.svg';
import Ondas from './icons/ondas.svg';
import { ReactComponent as Segitece } from './icons/segitece.svg';
import "./rodape.css";

export default function Rodape() {

	const openInNewTab = url => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<div>
			<div className="rodapeMargin"></div>
			<div className="rodape">
				<div style={{ backgroundColor: "white", backgroundImage: `url(${Ondas})`, backgroundRepeat: "no-repeat", backgroundSize: "300px 335px" }}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div className="rodapeInfo" >
							<div className="rodapeTitulo">SEDE DA FUNCAP:</div>
							<div className="rodapeTexto">AV. OLIVEIRA PAIVA, 941 - CIDADE DOS FUNCIONÁRIOS <br />FORTALEZA, CE<br />CEP: 60.822-130</div>
						</div>
						<div className="rodapeInfo">
							<div className="rodapeTitulo">HORÁRIO DE ATENDIMENTO:</div>
							<div className="rodapeTexto">SEGUNDAS ÀS SEXTAS, DE 08 ÀS 17 HORAS</div>
						</div>
						<div className="rodapeInfo">
							<div className="rodapeTitulo">CANAL DE ATENDIMENTO:</div>
							<div className="rodapeTexto">projeto.avaliacao@funcap.ce.gov.br</div>
						</div>
						<div className="rodapeInfo">
							<div className="rodapeTitulo">APOIO:</div>
							<div style={{ display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
								<div style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => openInNewTab("https://www.ceara.gov.br/", "_blank")}>
									<Governo />
								</div>
								<div className="rodapeIconeSeparador"></div>
								<div style={{ marginTop: "8px", cursor: "pointer" }} onClick={() => openInNewTab("https://www.sct.ce.gov.br/", "_blank")}>
									<Segitece />
								</div>
							</div>
						</div>
						<div>
							<div className="rodapeTitulo">REDES SOCIAIS:</div>
							<div style={{ display: "flex", marginTop: "8px" }} >
								<div style={{ marginRight: "32px", cursor: "pointer" }} onClick={() => openInNewTab("https://pt-br.facebook.com/Funcap", "_blank")}>
									<Facebook />
								</div>
								<div style={{ marginRight: "32px", cursor: "pointer" }} onClick={() => openInNewTab("https://www.instagram.com/funcapce/", "_blank")}>
									<Instagram />
								</div>
							</div>
						</div>
					</div>
					<div className="rodapeDireitos">© 2017 - {new Date().getFullYear()} – GOVERNO DO ESTADO DO CEARÁ <br />TODOS OS DIREITOS RESERVADOS</div>
				</div>
			</div>
		</div>

	)
}