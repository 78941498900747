import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { CKEditor } from "ckeditor4-react";
import React, { useContext, useState } from "react";
import StoreContext from "../../../../components/Store/Context";
import { ReactComponent as Upload } from "../../../../components/icons/cadastro/upload.svg";
import world from "../../../../components/worldMap/World";
import Portas from "../../../../portas";
import "./cadastroCooperacao.css";


import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";

import { ReactComponent as AddLink } from "../../../../components/icons/cadastro/addLink.svg";
import { ReactComponent as Icone1 } from "../../../../components/icons/cadastro/bolsistas.svg";
import { ReactComponent as Icone3 } from "../../../../components/icons/cadastro/editais.svg";
import { ReactComponent as Icone4 } from "../../../../components/icons/cadastro/empresasFomentadas.svg";
import { ReactComponent as Icone2, ReactComponent as Icone5 } from "../../../../components/icons/cadastro/projetos.svg";
import { ReactComponent as Icone6 } from "../../../../components/icons/cadastro/setoresAtingidos.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {},
  },
  button: {
    margin: theme.spacing(1),
  },
  btnCadastrar: {
    backgroundColor: "#20764B",
    borderRadius: "10px",
    width: "140px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  formWidth: {
    width: "300px",
  },
  formWidthText: {
    width: "100%",
  },
  divFormRedesSociais: {
    margin: "24px",
  },
  formControl: {
    minWidth: 680,
  },
  formIcone: {
    marginRight: "16px",
    minWidth: 84,
  },
  importImage: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 965,
    height: "54px",
    left: "1px",
    top: "1px",
    marginLeft: "25px",
    marginRight: "25px",
    borderRadius: "3.5px",
    marginTop: "32px",

  },
  margin: {
    margin: theme.spacing(1),
  },
}));

function App({ setOpen }) {
  const classes = useStyles();

  const [nomeInstituicao, setNomeInstituicao] = useState("");
  const [editorData, setEditorData] = React.useState("");
  const [selectedImagePerfilCooperacao, setSelectedImagePerfilCooperacao] = useState(null);
  const [localizacao, setLocalizacao] = useState("");
  const [siteOficial, setSiteOficial] = useState("");
  const [linkedinPessoal, setLinkedindPessoal] = useState("");
  const [gitHubPessoal, setGitHubPessoal] = useState("");
  const { token } = useContext(StoreContext);
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [indicadores, setIndicadores] = useState([
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
  ]);

  const handleChange = (item, index) => {
    let aux = [...indicadores];
    aux[index].indicador = item;
    setIndicadores(aux);
  };

  const handleChangeIcone = (item, index) => {
    let aux = [...indicadores];
    aux[index].icone = item;
    setIndicadores(aux);
  };

  const handleChangeValor = (item, index) => {
    let aux = [...indicadores];
    aux[index].valor = item;
    setIndicadores(aux);
  };

  const getIcone = (index) => {
    index -= 1;

    if (index === 0) {
      return <Icone1 />;
    } else if (index === 1) {
      return <Icone2 />;
    } else if (index === 2) {
      return <Icone3 />;
    } else if (index === 3) {
      return <Icone4 />;
    } else if (index === 4) {
      return <Icone5 />;
    } else if (index === 5) {
      return <Icone6 />;
    }
  };

  const publicar = async () => {

    if (nomeInstituicao.length < 1) {
      alert("Insira o nome da instituição.")
      return;
    }

    if (localizacao.length < 1) {
      alert("Insira a localização.")
      return;
    }

    if (editorData.length < 1) {
      alert("Insira a descrição.")
      return;
    }

    if (selectedImagePerfilCooperacao === null) {
      alert("Insira a imagem do perfil.")
      return;
    }

    var indicadoresAux = []
    for (var i = 0; i < indicadores.length; i++) {
      if (indicadores[i].indicador.length > 0 && indicadores[i].valor > 0) {
        indicadoresAux.push(indicadores[i])
      }
    }

    if (indicadoresAux.length < 1) {
      alert("Cadastre pelo menos 1 indicador")
      return
    }

    const formData = new FormData()
    formData.append('nome_instituicao', nomeInstituicao)
    formData.append('capa', selectedImagePerfilCooperacao)
    formData.append('descricao', editorData)
    formData.append('indicadores', JSON.stringify(indicadoresAux))
    formData.append('localizacao', localizacao)
    if (siteOficial.length > 0) {
      formData.append('site_oficial', siteOficial)
    }
    if (linkedinPessoal.length > 0) {
      formData.append('linkedin', linkedinPessoal)
    }
    if (gitHubPessoal.length > 0) {
      formData.append('github', gitHubPessoal)
    }
    if (facebook.length > 0) {
      formData.append('facebook', facebook)
    }
    if (youtube.length > 0) {
      formData.append('youtube', youtube)
    }

    try {
      const response = await fetch(Portas().serverHost + "/cooperacoes",
        {
          method: "POST",
          headers: {
            'Authorization': "Bearer " + token
          },
          body: formData
        }
      );
      const resJSON = await response.json();

      if (typeof resJSON.id !== "undefined") {
        alert("Cooperação criada!")
        return;
      }
      else if (typeof resJSON.message !== "undefined") {
        alert(resJSON.message)
        return;
      }
      else {
        alert("Um erro ocorreu, tente novamente...")
      }

    }
    catch (err) {
      
    }


  }

  return (
    <div className="pageCadastroCooperacao">
      <div className="tituloPageCooperacao">Criar Cooperação</div>
      <div>
        <div className={classes.divFormRedesSociais}>
          <form className={classes.formWidthText} noValidate autoComplete="off">
            <TextField
              label="Nome da Instituição Internacional"
              value={nomeInstituicao}
              onChange={(event) => setNomeInstituicao(event.target.value)}
              fullWidth
              required
            />
          </form>
        </div>

        <div className="subtitulosCadastroCooperacao"
          style={{ marginTop: "51px" }}>Descrição do Perfil</div>
        <CKEditor
          initData={editorData}
          onChange={(event) => setEditorData(event.editor.getData())}
          config={{
            language: "pt-br",
            uiColor: "#FFFFFF",
            toolbarCanCollapse: true,
            toolbarGroups: [
              { name: "clipboard", groups: ["clipboard", "undo"] },
              // { name: "styles", groups: ["styles"] },
              { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
              {
                name: "paragraph",
                groups: [
                  "align",
                  "list",
                  "indent",
                  "blocks",
                  "bidi",
                  "paragraph",
                ],
              },
              {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"],
              },
              { name: "links", groups: ["links"] },
              { name: "insert", groups: ["insert"] },
              { name: "forms", groups: ["forms"] },
              {
                name: "document",
                groups: ["mode", "document", "doctools"],
              },
              { name: "others", groups: ["others"] },
              // { name: "colors", groups: ["colors"] },
              { name: "about", groups: ["about"] },
              { name: "tools", groups: ["tools"] },
            ],
            extraPlugins: "justify, font",
            removeButtons:
              "FontSize,Subscript,Superscript,Scayt,PasteText,PasteFromWord,Anchor,Strike,RemoveFormat,Source,About,Styles, Font, Link",
          }}
          onInstanceReady={() => { }}
        />

        {selectedImagePerfilCooperacao === null ? (
          <div>
            <Paper className={classes.importImage} variant="outlined">
              <InputBase
                fullWidth
                disabled
                placeholder="Imagem de perfil"
              />
              <label htmlFor={"upload-button"}>
                <Upload />
              </label>
              <form encType="multipart/form-data" action="">
                <input
                  type="file"
                  accept="image/*"
                  name="upload-button"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={(event) => {

                    setSelectedImagePerfilCooperacao(event.target.files[0]);
                  }}
                />
              </form>
            </Paper>
          </div>
        ) : (
          <div>
            <Paper className={classes.importImage} variant="outlined">
              <InputBase
                fullWidth
                value={selectedImagePerfilCooperacao.name}
                disabled
                placeholder="Imagem da Capa"
              />
              <label htmlFor={"upload-button1"}>
                <div>
                  <Upload />
                </div>
              </label>
              <form encType="multipart/form-data" action="">
                <input
                  type="file"
                  accept="image/*"
                  name="upload-button1"
                  id="upload-button1"
                  style={{ display: "none" }}
                  onChange={(event) => {

                    setSelectedImagePerfilCooperacao(event.target.files[0]);
                  }}
                />
              </form>
            </Paper>
          </div>
        )}
        <div className={classes.divFormRedesSociais}>
          <FormControl className={classes.formWidthText}>
            <InputLabel required>Localização Geográfica</InputLabel>
            <Select
              value={localizacao}
              onChange={(event) => setLocalizacao(event.target.value)}>
              {world.objects.ne_110m_admin_0_countries.geometries.map((option) => (
                <MenuItem key={option.properties.NAME_PTBR} value={option.properties.NAME_PTBR}>
                  {option.properties.NAME_PTBR}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              A localização será usada para exibir e catalogar a cooperação no mapa mundi da página de internacionalização
            </FormHelperText>
          </FormControl>
        </div>

        <div>
          <div className="subtitulosCadastroCooperacao" >Redes Sociais</div>
          <div className={classes.divFormRedesSociais}>
            <div>
              <div>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Link: Site Oficial </InputLabel>
                  <Input
                    value={siteOficial}
                    onChange={(event) => setSiteOficial(event.target.value)}
                    endAdornment={
                      <InputAdornment>
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Link: LinkedIn</InputLabel>
                  <Input
                    value={linkedinPessoal}
                    onChange={(event) => setLinkedindPessoal(event.target.value)}
                    endAdornment={
                      <InputAdornment>
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Link: Github </InputLabel>
                  <Input
                    value={gitHubPessoal}
                    onChange={(event) => setGitHubPessoal(event.target.value)}
                    endAdornment={
                      <InputAdornment>
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Link:Facebook</InputLabel>
                  <Input
                    value={facebook}
                    onChange={(event) => setFacebook(event.target.value)}
                    endAdornment={
                      <InputAdornment>
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Link: Instagram </InputLabel>
                  <Input
                    value={instagram}
                    onChange={(event) => setInstagram(event.target.value)}
                    endAdornment={
                      <InputAdornment>
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Link: Youtube </InputLabel>
                  <Input
                    value={youtube}
                    onChange={(event) => setYoutube(event.target.value)}
                    endAdornment={
                      <InputAdornment>
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="subtitulosCadastroCooperacao"> Indicadores </div>

          {indicadores.map((item, index) => (
            <div className="indicadoresCadastroCooperacao">
              <div className="divItemCooperacao">{index + 1 + "º"}</div>
              <FormControl className={classes.formControl} style={{ marginTop: "16px" }}>
                <TextField
                  value={item.indicador}
                  onChange={(event) =>
                    handleChange(event.target.value, index)
                  }
                ></TextField>
                <FormHelperText>Descreva um indicador</FormHelperText>
              </FormControl>

              <FormControl style={{ marginLeft: "16px" }}>
                <InputLabel className={classes.formIcone}></InputLabel>
                <Select
                  value={item.icone}
                  onChange={(event) =>
                    handleChangeIcone(event.target.value, index)
                  }
                >
                  {indicadores.map((item, index) => (
                    <MenuItem key={index} value={index}>
                      {
                        <SvgIcon
                          style={{ fontSize: "20px", color: "#FFFFFF" }}
                        >
                          {getIcone(index)}
                        </SvgIcon>
                      }
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Icone</FormHelperText>
              </FormControl>

              <FormControl style={{ marginTop: "16px", marginLeft: "16px" }}>
                <TextField
                  id="quantHoras"
                  type="number"
                  defaultValue={0}
                  value={item.valor}
                  onChange={(event) =>
                    handleChangeValor(event.target.value, index)
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                />
                <FormHelperText>Valor Agregado</FormHelperText>
              </FormControl>
            </div>
          ))}
        </div>
      </div>

      <div className="botaoCadastroCooperacao">
        <Button
          className={classes.btnCadastrar}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => publicar()}
        >
          Adicionar
        </Button>
      </div>
    </div>
  );
}

export default App;
