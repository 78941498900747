import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

//pages import
import AuthTeste from './pages/authTeste/AuthTeste';
import Ciencia from "./pages/ciencia/Ciencia";
import Gerencimanto from "./pages/gerenciamento/Gerenciamento";
import Home from "./pages/home/Home";
import Inovacao from "./pages/inovacao/Inovacao";

//auth
import PrivateRoute from "./components/Routes/Private/private";
import StoreProvider from './components/Store/Provider';

export default function Routes() {
    return (
        <BrowserRouter>
            <StoreProvider>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/inovacao" component={Inovacao} />
                    <Route path="/Ciencia" component={Ciencia} />
                    <Route path="/authTeste" component={AuthTeste} />
                    <Route path="/ciencia" component={Ciencia} />
                    <PrivateRoute path="/gerenciamento" component={Gerencimanto}/>
                </Switch>
            </StoreProvider>
        </BrowserRouter>
    )
}