import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import BtnCompartilhar from "../../components/btnCompartilhar/BtnCompartilhar";
import Portas from "../../portas";
import SimpleModal from "./ModalRealizacao";
import "./blogProjeto.css";
import { ReactComponent as Artigo } from "./icons/artigo.svg";
import VejaMais from "./vejaMais/VejaMais";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#FDCC09",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  btnCompartilhar: {
    borderRadius: "4px",
    width: "173px",
    height: "36px",
    color: "#405965",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

const ColorButtonTipo2 = withStyles((theme) => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#008240",
  },
}))(Button);

export default function App({ setOpen, id }) {

  const classes = useStyles();
  const [open2, setOpen2] = useState(false);
  const [projeto, setProjeto] = useState();
  const [realizacoes, setRealizacoes] = useState([]);
  const [selectedRealizacao, setSelectedRealizacao] = useState();

  function clicarNoLink(link) {
    //window.location="http://www.google.com.br"
    window.open(link, "_blank");
  }

  function handleClickRealizacao(id) {
    setSelectedRealizacao(id);
    setOpen2(true);

  }

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }

  const [selectedBlog, setSelectedBlog] = useState("");

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }

  function handleClick(titulo) {
    setSelectedBlog(titulo);
    setOpen2(true);
  }

  const getInfo = async () => {
    try {
      const response1 = await fetch(Portas().serverHost + "/projetos/" + id + "?area=true&image=true&anexo=true&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON1 = await response1.json();
      setProjeto(resJSON1)

      const response = await fetch(Portas().serverHost + "/realizacoes?area=true&image=true&pagina=0&tamanho=5&anexo=true&projeto=true&paginacao=false&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON = await response.json();

      var auxre = []
      for (var i = 0; i < resJSON.length; i++) {

        if (resJSON[i].projeto_realizacao !=null && resJSON[i].projeto_realizacao.id === resJSON1.id) {
          auxre.push(resJSON[i])
        }
      }
      setRealizacoes(auxre)

    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getInfo();
  }, [id]);

  return (
    <div className="blogProjeto">
      {open2 ? (
        <SimpleModal
          open={open2}
          setOpen={() => setOpen2(!open2)}
          id={selectedRealizacao}
        ></SimpleModal>
      ) : (
        <></>
      )}
      {typeof projeto !== "undefined" ?
        <div className="divBlogProjetoConteudo">
          <div className="blogCabecalhoProjetol">
            <div className="divVoltarCompartilharProjeto">
              <Button
                className={classes.btnVoltar}
                variant="contained"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => setOpen(false)}
              >
                Voltar
              </Button>

              <Breadcrumbs>
                <Link className="blogCabecalhoTextProjeto" href="/">
                  CIENTISTA CHEFE
                </Link>
                <Link
                  className="blogCabecalhoTextProjeto"
                  href="/getting-started/installation/"
                >
                  PROJETO
                </Link>
              </Breadcrumbs>
            </div>
            {<BtnCompartilhar tipo={"projeto"} blogid={projeto.id} blogarea={projeto.area_projeto.area}></BtnCompartilhar>}
          </div>

          <div className="conteudoRealizacao">
            <div className="conteudoPrincipalProjeto">
              <div className="blogTituloProjeto">
                <h1>
                  {projeto.titulo}
                </h1>
              </div>
              <div className="autorProjeto">
                <div className="nomeAutorProjeto">
                  {projeto.autor_projeto.nome}
                </div>
                <div style={{ color: "#405965" }}> - De: {getData(projeto.data_inicio, "mes")}/{getData(projeto.data_inicio, "ano")} até {getData(projeto.data_fim, "mes")}/{getData(projeto.data_fim, "ano")}</div>
              </div>

              <div className="conteudoProjeto">

                <img src={projeto.capa} alt="" className="imagemProjeto"></img>

              </div>
              <div className="conteudoProjeto">
                <div>
                  {parse(projeto.conteudo)}
                </div>
              </div>
              {projeto.equipe.length > 0 ?
                <div className="divTopicos">
                  <h2
                    className="divTopicoBlogProjeto"
                    style={{ marginTop: "32px" }}
                  >
                    Equipe Envolvida{" "}
                  </h2>

                  <div className="equipeProjeto">
                    {projeto.equipe.map((membro) => (
                      <div className="linkcitacaoProjetoNoticias" onClick={() => clicarNoLink(membro.lattes)}>• {membro.nome}</div>
                    ))}
                  </div>
                </div>
                : <></>
              }
              {projeto.citacoes.length > 0 ?
                <div className="divTopicos">
                  <h2 className="divTopicoBlogProjeto">Links Úteis </h2>
                  <div className="linkcitacaoProjeto">
                    {projeto.citacoes.map((citacao, index) => (
                      <div className="linkcitacaoProjetoNoticias" onClick={() => clicarNoLink(citacao.link)}>
                        • {citacao.label}
                      </div>
                    ))}
                  </div>
                </div>
                : <></>
              }
              {projeto.exibir_anexo
                ?
                <div className="divTopicos">
                  <h2 className="citacao">Anexo</h2>
                  <div className="anexosProjeto" style={{ marginLeft: "12px" }} onClick={() => clicarNoLink(projeto.anexo)}>
                    <SvgIcon style={{ fontSize: "16px", color: "#008240" }} >
                      <Artigo />
                    </SvgIcon>
                    <div className="artigoTituloProjeto">Artigo</div>
                  </div>
                </div>
                : <></>}
            </div>
            <VejaMais id={projeto.id} tipo="projeto" />
          </div>
        </div>
        : <></>
      }
      <div>
        {realizacoes.length > 0 ?
          <div className="realizacoes">
            <div style={{ width: "100%" }}>
              <p className="realizacoesProjeto">REALIZAÇÕES DO PROJETO</p>
            </div>
          </div>
          : <></>
        }
        {realizacoes.length > 0 ?
          <div className="postRealizacoes" style={{ marginTop: "0px" }}>
            <div style={{ width: "100%" }}>
              <div className="linearGradBlogProjeto"></div>
            </div>
          </div>
          : <></>
        }
        {realizacoes.length > 0 ?
          <div className="blogListProjeto">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {realizacoes.map((item) =>
                <div style={{ display: "flex" }}>
                  <div className="divContentBlogProjeto">
                    <img
                      className="imgBlogProjeto"
                      alt=""
                      src={item.capa}
                    />
                    <div className="divTextBottomImgBlogTipo1Projeto">
                      <div className="textBottomImgBlogTipo1Projeto">REALIZAÇÃO</div>
                    </div>
                  </div>
                  <div className="divTextBlogProjeto">
                    <div className="divTextTituloBlogTipo1Projeto">
                      {item.titulo}
                    </div>
                    <div className="divTextDataBlogTipo1Projeto">
                      <div>Postado em {getData(item.createdAt, "dia")}/{getData(item.createdAt, "mes")}/{getData(item.createdAt, "ano")}</div>
                    </div>
                    <div className="divTextResumoBlogProjeto">
                      <div>
                        {parse(item.conteudo)}
                      </div>
                    </div>
                    <div className="divButtonLerMaisProjetoRealizacoes">
                      <button
                        className="buttonLerMaisProjetoRealizacoes"
                        onClick={() => handleClickRealizacao(item.id)}
                      >
                        Ler Mais
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          : <></>}
      </div>
    </div>
  );
}
