import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { useState } from "react";
import BtnCompartilhar from "../../components/btnCompartilhar/BtnCompartilhar";
import SimpleModal from "./ModalBlogVejaMais";
import "./blogRealizacao.css";
import { ReactComponent as Artigo } from "./icons/artigo.svg";
import Img1 from "./img/img1.png";
import Img2 from "./img/img2.png";
import Img3 from "./img/img3.png";
import Img4 from "./img/img4.png";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#008240",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  btnCompartilhar: {
    borderRadius: "4px",
    width: "173px",
    height: "36px",
    color: "#405965",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

function App({ setOpen }) {
  const classes = useStyles();

  function clicarNoLink() {
    //window.location="http://www.google.com.br"
    window.open("http://www.google.com.br", "_blank");
  }
  const [selectedBlogVejaMais, setSelectedBlogVejaMais] = useState("");
  const [open2, setOpen2] = useState(false);

  function handleClick(titulo) {
    setSelectedBlogVejaMais(titulo);
    setOpen2(true);
  }

  return (
    <div className="blogRealizacaol">
      {open2 ? (
        <SimpleModal
          open={open2}
          setOpen={() => setOpen2(!open2)}
          selectedBlogVejaMais={selectedBlogVejaMais}
        ></SimpleModal>
      ) : (
        <></>
      )}
      <div className="blogCabecalhoRealizacoesl">
        <div className="divVoltarCompartilharRealizacao">
          <Button
            className={classes.btnVoltar}
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
         
          <Breadcrumbs>
            <Link className="blogCabecalhoTextRealizacoesl" href="/">
            CIÊNCIA
            </Link>
            <Link
              className="blogCabecalhoTextRealizacoesl"
              href="/getting-started/installation/"
            >
             BOLSAS
            </Link>
            <Link
              className="blogCabecalhoTextRealizacoesl"
              color="textPrimary"
              href="/getting-started/installation/"
            >
               Artigo com participação de pesquisadores apoiados pela Funcap tem repercussão mundial
            </Link>
          </Breadcrumbs>
        </div>
        <BtnCompartilhar
          link={"https://vitrinefuncap.com/inovacao/1"}
        ></BtnCompartilhar>
      </div>

      <div className="divblogRealizacaolVejaMais">
        <div>
          <div className="blogTituloRealizacoesl">
            <div>
              <h1>Artigo com participação de pesquisadores apoiados pela Funcap tem repercussão mundial</h1>{" "}
            </div>
          </div>
          <div className="autorRealizacoesl">
            <div style={{ color: "#20764B" }}>
            FUNCAP -  postado em 25 de novembro de 2021{" "}
            </div>
            <p style={{ color: "#405965" }}>- postado em: 05 outubro de 2020</p>
          </div>

          <div className="conteudoRealizacoesl">
            <div>
              <img
              className="imgBlogRealizacao" 
               src="https://t1.uc.ltmcdn.com/pt/posts/9/3/9/qual_a_diferenca_entre_ciencia_e_tecnologia_29939_600.jpg" alt=""></img>
            </div>
            <div>
              <p>
                Pesquisa publicada por um grupo de pesquisadores do Brasil – dos
                quais, três são apoiados pela Funcap – na revista Polar Research
                teve repercussão mundial por seu ineditismo e pela importância
                da descoberta. Com o título “Wildfires in the Campanian of James
                Ross Island: a new macro-charcoal record for the Antarctic
                Peninsula (Incêndios no período do Cretáceo Inferior na Ilha
                James Ross: um novo registro de carvão vegetal macroscópico na
                Península Antártica), o trabalho detectou, pela primeira vez,
                fósseis de carvão vegetal que confirmam incêndios
              </p>
            </div>
          </div>
          <div style={{ textAlign: "justify" }}>
            <p>
              na Ilha James Ross, no Pólo Sul, uma região que hoje registra
              temperaturas quase sempre negativas é a quase toda coberta de
              gelo. Os pesquisadores apoiados pela Funcap que participaram do
              estudo são Flaviana Lima, Flaviana, ex-bolsista do Programa de
              Desenvolvimento Científico e Tecnológico Regional (PDCTR), Renan
              Machado e Antônio Álamo Feitosa. Estes dois últimos foram
              beneficiados com o programa Bolsas de Produtividade em Pesquisa e
              Estímulo à Interiorização (BPI) e o – Programa de Pós-Doutorado
              para Jovens Doutores. De acordo com os cientistas, já haviam sido
              encontradas evidências fósseis de vegetais por toda a Antártica, o
              que indicava uma composição florestal e um clima mais quente que o
              atual na região durante o período Cretáceo, há aproximadamente 75
              milhões de anos. A novidade publicada no artigo é que esta
              vegetação também era acometida por incêndios espontâneos. Flaviana
              Lima afirma que “essa descoberta amplia o conhecimento sobre a
              ocorrência de incêndios vegetacionais durante o Cretáceo,
              mostrando que episódios assim eram mais comuns do que se
              imaginava” e isso representa uma contribuição significativa para
              os estudos paleobotânicos em todo o mundo. Ela acrescenta que a
              extensa atividade de incêndios florestais durante o Cretáceo
              afetou diretamente a composição das comunidades de plantas,
              influenciando significativamente as mudanças paleoecológicas em
              diferentes ambientes do planeta. Por toda essa relevância, o
              trabalho realizado pelos pesquisadores teve repercussão mundial,
              gerando matéria em publicações como o New York Times e o
              Washington Post, nos Estados, o Navbharat Times, (um dos jornais
              mais lidos diariamente em Delhi e Mumbai, na Índia) e o National
              Geographic da Rússia (veja os links no fim do texto). Inúmeros
              registros de incêndios florestais intensos no mundo inteiro têm
              sido feitos, sobretudo em camadas formadas na parte superior do
              período Cretáceo (85 a 70 milhões de anos atrás). A maior parte
              desses registros, porém, são do Hemisfério Norte. Além disso,
              apesar de o Cretáceo ser considerado um período no qual
              paleoincêndios foram comuns em escala global, ainda há poucos
              registros de macrocharcoal – que são as principais evidências
              desse fenômeno natural – descritos no Hemisfério Sul. “Os dados
              inéditos apresentados no trabalho reforçam que as florestas, que
              ocupavam o que hoje conhecemos como Península Antártica, também
              foram afetadas pelo fogo ao longo do Campaniano, ampliando a
              abrangência paleogeográfica desses eventos e ajudando a esclarecer
              o seu impacto sobre a paleobiodiversidade”, explica André Jasper,
              professor da Universidade do Vale do Taquari (Univates) e
              pesquisador que também participou do estudo. A íntegra do artigo
              está disponível (em inglês) no endereço
              polarresearch.net/index.php/polar/article/view/5487.
            </p>
            <div style={{ color: "#405965" }}>
              <b>Pesquisadores participantes do artigo: </b>
            </div>
            <di>
              Flaviana Jorge de Lima – Laboratório de Paleobiologia e
              Microestruturas (Universidade Federal de Pernambuco)
            </di>
            <div>
              {" "}
              Juliana Manso Sayão – Museu Nacional (Universidade Federal do Rio
              de Janeiro)
            </div>
            <div>
              Luiza de Oliveira Ponciano – Laboratório de Tafonomia e
              Paleoecologia Aplicadas ((Universidade Federal do Rio de Janeiro)
            </div>
            <div>
              Luiz Weinschütz Centro Paleontológico (Universidade de Contestado,
              Santa Catarina)
            </div>
            <div>
              {" "}
              Rodrigo Figueiredo – Departamento de Biologia (Universidade
              Federal do Espírito Santo)
            </div>{" "}
            <div>
              {" "}
              Taissa Marques Rodrigues Departamento de Ciências Biologicas
              (Universidade Federal do Espírito Santo)
            </div>{" "}
            <div>
              {" "}
              Renan Alfredo Machado – Universidade Regional do Cariri Antonio
              Álamo Feitosa – Laboratório de Paleontologia (Universidade
              Regional do Cariri)
            </div>{" "}
            <div>
              {" "}
              André Jasper – Programa de Pós-graduação em Meio Ambiente e
              Desenvolvimento (Universidade de Vale do Taquari, Rio Grande do
              Sul)
            </div>{" "}
            <div>
              {" "}
              Dieter Uhl – Programa de Pós-graduação em Meio Ambiente e
              Desenvolvimento (Universidade de Vale do Taquari, Rio Grande do
              Sul)
            </div>{" "}
            <div>
              {" "}
              Alexander Kellner – Museu Nacional (Universidade Federal do Rio de
              Janeiro)
            </div>
          </div>

          <div>
            <h2 className="citacaoRealizacoesl">Citação na Mídia </h2>

            <div className="linkcitacaoRealizacoesl">
              • www.funcap.ce.gov.br
            </div>
            <div className="linkcitacaoRealizacoesl">• www.nytimes.com</div>
            <div className="linkcitacaoRealizacoesl">• Washington Post</div>
            <div className="linkcitacaoRealizacoesl">• Navbharat Times</div>
            <div className="linkcitacaoRealizacoesl">
              • National Geographic Russia
            </div>
          </div>
          <div>
            <h2 className="citacaoRealizacoesl" style={{ marginTop: "32px" }}>
              Anexo{" "}
            </h2>
            <div className="anexosRealizacoesl" style={{ marginLeft: "12px" }}>
              <SvgIcon style={{ fontSize: "16px", color: "#008240" }}>
                <Artigo />
              </SvgIcon>
              <div className="artigoTituloRealizacoesl">
                {" "}
                Artigo - Wildfires in the Campanian of James Ross Island: a new
                macro-charcoal record for the Antarctic Peninsula
              </div>
            </div>
          </div>
        </div>

        <div className="divVejaMaisRealizacoes">
          <div className="vejaMaisRealizacoesl">Veja Mais</div>
          <div
            className="postvermaisRealizacoesl"
            onClick={() => handleClick(1)}
          >
            <div className="divimgRealizacoes">
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img1 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                <div>Produtividade da água </div>
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>

          <div className="postvermaisRealizacoesl">
            <div className="divimgRealizacoes" onClick={() => handleClick(2)}>
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img2 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                Produtividade da água e inovação tecnológica no setor
                agropecuário do Estado do Ceará
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>

          <div className="postvermaisRealizacoesl">
            <div className="divimgRealizacoes">
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img3 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                Ações inovadoras para agregar valor à agricultura cearense
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>

          <div className="postvermaisRealizacoesl">
            <div className="divimgRealizacoes">
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img4 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                Eficiência do uso da água na agricultura
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
