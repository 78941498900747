import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import toBRL from "../funcoes/toBRL";
import transEmpty from "../funcoes/transEmpty";
import { ReactComponent as Logo } from './components/icons/logoFuncap.svg';


export class ComponentToPrint extends React.PureComponent {
	render() {
		return (
			<div>
				<SvgIcon style={{ fontSize: "150px", color: "#20764B", display: "block", marginLeft: "auto", marginRight: "auto" }}>
					<Logo />
				</SvgIcon>
				<h4 style={{ textAlign: "center" }}>Dados do Indicador</h4>
				<div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", justifyItems: "center" }}>
					<table border="1"  >
						<thead>
							{this.props.dataset.map((item) => (
								<th style={{ textAlign: "center" }}>{item.titulo}</th>
							))}
						</thead>
						<tbody>
							{this.props.data.map((item) => (
								<tr>
									{this.props.dataset.map((itemDataset) => (
										<td style={{ paddingRight: "15px", paddingLeft: "15px" }}>{item.tipo === "reais" ? transEmpty(toBRL(item[itemDataset.campo], false)):transEmpty(item[itemDataset.campo])}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}