import { Paper } from "@material-ui/core";
import React, { useState } from "react";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import cores from "../../../cores/cores";
import toBRL from '../../../funcoes/toBRL';
import MicroRegioes from "../../../heatmap/microregioes.json";
import UseStyles from "./HeatmapStyle";
import "./heatmapSVG.css";

function getIntervalos(props, cores) {

  //Busca menor e maior valor do array
  var menor = props.data[0].value;
  var maior = props.data[0].value;

  for (var i = 0; i < props.data.length; i++) {
    if (props.data[i].value > maior) {
      maior = props.data[i].value;
    }
    if (props.data[i].value < menor) {
      menor = props.data[i].value;
    }
  }

  maior = maior.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  maior = parseInt(maior.replace(/[^0-9]/g, ''))
  menor = menor.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  menor = parseInt(menor.replace(/[^0-9]/g, ''))

  //Gera N intervalos numéricos entre 1 o valor maximo do dado
  var intervalos = []
  var quantInter = 10

  for (var i = 0; i < quantInter; i++) {

    //Variavel de controle para formatar o corretamente o numero
    var auxMax = parseInt(maior / quantInter)
    auxMax = auxMax.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    auxMax = parseInt(auxMax.replace(/[^0-9]/g, ''))

    //Laço de controle para identificar se o intervalo foi usado 
    var used = false

    for (var j = 0; j < props.data.length; j++) {
      var value = props.data[j].value;
      value = value.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      value = parseInt(value.replace(/[^0-9]/g, ''))

      if (i === 0) {
        if (value >= 1 && value <= auxMax) {
          used = true
        }
      }
      else {
        if (value >= (intervalos[i - 1].max + 1) && value <= auxMax) {
          used = true
        }
      }
    }

    if (i === 0) {
      intervalos.push({ color: cores[i], min: 1, max: auxMax, used: used })
    }
    else if (i === quantInter - 1) {
      intervalos.push({ color: cores[i], min: intervalos[i - 1].max + 1, max: maior, used: used })
    }
    else {
      intervalos.push({ color: cores[i], min: intervalos[i - 1].max + 1, max: intervalos[i - 1].max + auxMax, used: used })
    }
  }

  return intervalos
}

export default function HeatmapSVG(props) {

  const [legenda, serLegenda] = useState(props.legenda)
  const [stateName, setStateName] = useState("");
  var valores = props.data;
  const [isMouseTooltipVisible, setIsMouseTooltipVisible] = useState(false);
  const [intervalos, setIntervalos] = useState(getIntervalos(props, props.cor === 'verde' ? cores().verdes : cores().amarelos))

  function getViewName(name) {
    const regioes = MicroRegioes.locations;
    for (var i = 0; i < regioes.length; i++) {
      if (regioes[i].name === name) {
        return regioes[i].viewName;
      }
    }
    return "";
  }

  function onLocationMouseOver(event) {
    setIsMouseTooltipVisible(true);
    setStateName(event.target.getAttribute("name"));
  }

  function getValor() {
    for (var i = 0; i < valores.length; i++) {
      if (valores[i].type === stateName) {
        return valores[i].value;
      }
    }
    return 0;
  }

  function findInVet(featureName, valores) {
    for (var i = 0; i < valores.length; i++) {
      if (valores[i].type === featureName) {
        return valores[i].value;
      }
    }
  }

  function onLocationMouseOut(location, index) {
    setIsMouseTooltipVisible(false);
  }

  function getLocationClassName(location, index) {

    var color = '#FFFFFF' //Branco default
    var value = findInVet(MicroRegioes.locations[index].name, valores);
    value = value.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
    value = parseInt(value.replace(/[^0-9]/g, ''))


    for (var i = 0; i < intervalos.length; i++) {
      if (value >= intervalos[i].min && value <= intervalos[i].max) {

        color = intervalos[i].color
        break
      }
    }



    const classes = UseStyles({ fill: color })
    return classes.SVGMaplocation;
  }


  return (
    <div>
      <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
        {intervalos.length > 0 && legenda ?
          <Paper style={{ position: "absolute", width: "150px", marginRight: "900px" }}>
            <div className="divTitleLegend">
              Legenda
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[0] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '< ' + toBRL(intervalos[0].max)
                  : '< ' + intervalos[0].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[1] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[1].min) + " e < " + toBRL(intervalos[1].max)
                  : '>= ' + intervalos[1].min + " e < " + intervalos[1].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[2] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[2].min) + " e < " + toBRL(intervalos[2].max)
                  : '>= ' + intervalos[2].min + " e < " + intervalos[2].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[3] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[3].min) + " e < " + toBRL(intervalos[3].max)
                  : '>= ' + intervalos[3].min + " e < " + intervalos[3].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[4] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[4].min) + " e < " + toBRL(intervalos[4].max)
                  : '>= ' + intervalos[4].min + " e < " + intervalos[4].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[5] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[5].min) + " e < " + toBRL(intervalos[5].max)
                  : '>= ' + intervalos[5].min + " e < " + intervalos[5].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[6] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[6].min) + " e < " + toBRL(intervalos[6].max)
                  : '>= ' + intervalos[6].min + " e < " + intervalos[6].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[7] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[7].min) + " e < " + toBRL(intervalos[7].max)
                  : '>= ' + intervalos[7].min + " e < " + intervalos[7].max}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[8] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[8].min) + " e < " + toBRL(intervalos[8].max)
                  : '>= ' + intervalos[8].min + " e < " + intervalos[8].ma}
              </div>
            </div>
            <div className="divPositionLegend">
              <div className="cubeColorLegend" style={{ background: cores().verdes[9] }} />
              <div className="textLegend">
                {props.tipo === "reais" ?
                  '>= ' + toBRL(intervalos[9].min) + " e <= " + toBRL(intervalos[9].max)
                  : '>= ' + intervalos[9].min + " e < " + intervalos[9].max}
              </div>
            </div>
            {/*
            {intervalos.map((intervalo, index) => (
              <div style={{ marginLeft: "10px", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                <div className="circuloLegenda" style={{ background: intervalo.color }}></div>
                <div style={{ marginLeft: "5px" }} className="valorRegiao">{"[" + toBRL(intervalo.min) + " , " + toBRL(intervalo.max) + "]"}</div>
              </div>
            ))}
            */}
          </Paper>
          :
          <></>
        }
        <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
          {valores.slice(0, 7).map((valor) => (
            <div style={{ textAlign: "right", marginBottom: "8px" }} >
              <div className="nomeRegiao">{getViewName(valor.type)}</div>
              <div className="valorRegiao">{props.tipo === "reais"? toBRL(valor.value) : valor.value}</div>
            </div>
          ))}
        </div>
        <div style={{ width: "258px", heigth: "304px", marginLeft: "63.34px", marginRight: "63.34px", display: "flex", justifyContent: "center", alignContent: "center" }}>
          <MouseTooltip
            visible={isMouseTooltipVisible}
            offsetX={-14}
            offsetY={-70}
            style={{ pointerEvents: "none" }}
          >
            <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
              <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                <div className="nomeRegiao" >{getViewName(stateName)}</div>
                <div className="valorRegiao" style={{ left: 0 }}>{props.tipo === "reais"? toBRL(getValor()): getValor()}</div>
              </div>
              <div style={{ marginTop: "-1px", marginLeft: "5px", width: 0, height: 0, borderLeft: "10px solid transparent", borderRight: "10px solid transparent", borderTop: "10px solid white" }}></div>
            </div>
          </MouseTooltip>
          <SVGMap map={MicroRegioes} locationClassName={getLocationClassName} onLocationMouseOver={onLocationMouseOver} onLocationMouseOut={onLocationMouseOut} />
        </div>
        <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
          {valores.slice(7, 14).map((valor) => (
            <div style={{ textAlign: "left", marginBottom: "8px" }} >
              <div className="nomeRegiao" style={{ textAlign: "left" }}>{getViewName(valor.type)}</div>
              <div className="valorRegiao" style={{ textAlign: "left" }}>{props.tipo === "reais"? toBRL(valor.value): valor.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div >
  );
}