import React, { useState } from "react";
import Indicadores from "./Indicadores";
import Sobre from "./Sobre";
import "./internacionalizacao.css";

export default function Internacionalização() {
    const [page, setPage] = useState("sobre");

    return (
        <div>
            {page === "sobre" ?
                <div>
                    <div className="backgroundPainelCienciaInternacionalizacao">
                        <div className="divSelectContentCienciaInternacionalizacao">
                            <div className="selectedContentCienciaInternacionalizacaoDELLLLL" onClick={() => setPage("sobre")}>
                                <div className="textMainCienciaInternacionalizacao" style={{ marginRight: "16px" }}>Sobre</div>
                            </div>
                            {/*
                            <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainCienciaInternacionalizacao">Indicadores</div>
                            </div>
    */}
                        </div>
                        <div className="textDescricaoCienciaInternacionalizacao">
                            <div>Dados gerais e resultados das ações de apoio a projetos e concessão de bolsas para estímulo à internacionalização do capital intelectual.</div>
                        </div>
                    </div>
                    <Sobre />
                </div>
                : ""}
            {page === "indicadores" ?
                <div className="backgroundPainelCienciaInternacionalizacao">
                    <div className="divSelectContentCienciaInternacionalizacao">
                        <div className="selectedContentCienciaInternacionalizacao" onClick={() => setPage("sobre")}>
                            <div className="textOffMainCienciaInternacionalizacao" style={{ marginRight: "16px" }}>Sobre</div>
                        </div>
                        <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                            <div className="textMainCienciaInternacionalizacao">Indicadores</div>
                        </div>
                    </div>
                    <div className="textDescricaoCienciaInternacionalizacao">
                        <div>Seleção de indicadores pertinentes às ações de apoio a projetos e concessão de bolsas para estímulo à internacionalização.</div>
                    </div>
                    <Indicadores />
                </div>
                : ""}
        </div>
    )
}