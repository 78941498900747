import InputAdornment from "@material-ui/core/InputAdornment";
import TablePagination from '@material-ui/core/TablePagination';
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import substringColor from "../../components/funcoes/substringColor";
import SimpleModal from "../../pages/blog/ModalProjeto";
import SimpleModal1 from "../../pages/blog/ModalRealizacao";
import Portas from "../../portas";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B",
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965",
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green",
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "#405965",
        },
        "&:hover .MuiInput-underline:before": {
            borderBottomColor: "#20764B",
        },
        "& .Mui-focused:after": {
            borderBottomColor: "#20764B",
        },
    },
})(TextField);

const useStyles = makeStyles({
    selectRoot: {
        "&:focus": {
            backgroundColor: "inherit",
        },
    },
    select: {
        "&:before": {
            borderColor: "#20764B",
        },
        "&:after": {
            borderColor: "#20764B",
        },
        color: "#405965",
        fontWeight: "bold",
    },
    icon: {
        fill: "#20764B",
    },
});


export default function BlogsSection(props) {

    const [content, setContent] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [page, setPage] = useState(0);
    const [selectedProjeto, setSelectedProjeto] = useState("");
    const [selectedRealizacao, setSelectedRealizacao] = useState("");
    const [tipos, setTipos] = useState("");
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [blogParam, setBlogParam] = useState(window.location.href.substring(window.location.href.lastIndexOf("/") + 1))
    const [bornOpened, setBornOpened] = useState(false)
    const [openedProjeto, setOpenedProjeto] = useState("")
    const [openedRealizacao, setOpenedRealizacao] = useState("")

    var allBlogs = [
        {
            tipo: 1,
        }
    ];

    function getData(timestamp, tipo) {
        var data = new Date(timestamp)
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()

        if (tipo === "dia") {
            return dia
        }

        if (tipo === "mes") {
            return mes
        }

        if (tipo === "ano") {
            return ano
        }
    }

    function filterContent(content) {

        let arr = [];

        for (var i = 0; i < content.length; i++) {
            if (content[i].content.titulo.toUpperCase().includes(searchField.toUpperCase())) {
                arr.push(content[i])
            }
        }

        return arr;

    }

    function filterContentList(content) {

        let arr = [];

        for (var i = 0; i < content.length; i++) {
            if (i >= 5 * (page) && i < 5 * (page + 1)) {
                arr.push(content[i])
            }
        }

        return arr;

    }


    const handleChange = (event) => {
        setTipos(event.target.value);

        if (event.target.value === "") {
            setBlogs(allBlogs);
        } else {
            var filtro = [];
            for (var i = 0; i < allBlogs.length; i++) {
                if (allBlogs[i].tipo === event.target.value) {
                    filtro.push(allBlogs[i]);
                }
            }
            if (event.target.value === 1 || event.target.value === 2)
                setBlogs(filtro);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function handleClickProjeto(id) {
        setSelectedProjeto(id);
        setOpen2(true);
    }

    function handleClickRealizacao(id) {
        setSelectedRealizacao(id);
        setOpen1(true);
    }

    function submit(e) {
        e.preventDefault();
        setSearchField(document.getElementById("searchfield").value);
    }

    //getContent() = obtem todos os projetos e realizacoes
    const getContent = async () => {

        try {

            const response = await fetch(Portas().serverHost + "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();

            const response2 = await fetch(Portas().serverHost + "/realizacoes?image=true&area=true&projeto=true&anexo=true&pagina=0&tamanho=5&paginacao=false&autor=false",
                {
                    method: "GET",
                }
            );

            const resJSON2 = await response2.json();

            let arr = []

            for (var i = 0; i < resJSON.length; i++) {

                if (resJSON[i].id === blogParam) {
                    handleClickProjeto(blogParam)
                }

                if (resJSON[i].area_projeto.area !== "Dúvidas frequentes") {

                    if (props.page === "Cientista-Chefe") {
                        if (resJSON[i].area_projeto.area !== "Ciência (Formação de RH)") {
                            if (resJSON[i].area_projeto.area !== "Inovação Pública (Outros)") {
                                if (resJSON[i].area_projeto.area !== "Inovação Empresarial") {
                                    if (resJSON[i].area_projeto.area !== "Ciência (Auxílio a Projetos)") {
                                        if (resJSON[i].area_projeto.area !== "Ciência (Interiorização)") {
                                            if (resJSON[i].area_projeto.area !== "Ciência (Internacionalização)") {
                                                arr.push(
                                                    {
                                                        type: "projeto",
                                                        content: resJSON[i]
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (props.page === "home") {
                        arr.push(
                            {
                                type: "projeto",
                                content: resJSON[i]
                            }
                        )
                    }
                    if (props.page === "Ciência (Formação de RH)") {
                        if (resJSON[i].area_projeto.area === "Ciência (Formação de RH)") {
                            arr.push(
                                {
                                    type: "projeto",
                                    content: resJSON[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Inovação Pública (Outros)") {
                        if (resJSON[i].area_projeto.area === "Inovação Pública (Outros)") {
                            arr.push(
                                {
                                    type: "projeto",
                                    content: resJSON[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Inovação Empresarial") {
                        if (resJSON[i].area_projeto.area === "Inovação Empresarial") {
                            arr.push(
                                {
                                    type: "projeto",
                                    content: resJSON[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Ciência (Auxílio a Projetos)") {
                        if (resJSON[i].area_projeto.area === "Ciência (Auxílio a Projetos)") {
                            arr.push(
                                {
                                    type: "projeto",
                                    content: resJSON[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Ciência (Interiorização)") {
                        if (resJSON[i].area_projeto.area === "Ciência (Interiorização)") {
                            arr.push(
                                {
                                    type: "projeto",
                                    content: resJSON[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Ciência (Internacionalização)") {
                        if (resJSON[i].area_projeto.area === "Ciência (Internacionalização)") {
                            arr.push(
                                {
                                    type: "projeto",
                                    content: resJSON[i]
                                }
                            )
                        }
                    }
                }
            }

            for (var i = 0; i < resJSON2.length; i++) {

                if (resJSON2[i].id === blogParam) {
                    handleClickRealizacao(blogParam)
                }

                if (resJSON2[i].area_realizacao.area !== "Dúvidas frequentes") {

                    if (props.page === "Cientista-Chefe") {
                        if (resJSON2[i].area_realizacao.area !== "Ciência (Formação de RH)") {
                            if (resJSON2[i].area_realizacao.area !== "Inovação Pública (Outros)") {
                                if (resJSON2[i].area_realizacao.area !== "Inovação Empresarial") {
                                    if (resJSON2[i].area_realizacao.area !== "Ciência (Auxílio a Projetos)") {
                                        if (resJSON2[i].area_realizacao.area !== "Ciência (Interiorização)") {
                                            if (resJSON2[i].area_realizacao.area !== "Ciência (Internacionalização)") {
                                                arr.push(
                                                    {
                                                        type: "realização",
                                                        content: resJSON2[i]
                                                    }
                                                )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (props.page === "home") {
                        arr.push(
                            {
                                type: "realização",
                                content: resJSON2[i]
                            }
                        )
                    }
                    if (props.page === "Ciência (Formação de RH)") {
                        if (resJSON2[i].area_realizacao.area === "Ciência (Formação de RH)") {
                            arr.push(
                                {
                                    type: "realização",
                                    content: resJSON2[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Inovação Pública (Outros)") {
                        if (resJSON2[i].area_realizacao.area === "Inovação Pública (Outros)") {
                            arr.push(
                                {
                                    type: "realização",
                                    content: resJSON2[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Inovação Empresarial") {
                        if (resJSON2[i].area_realizacao.area === "Inovação Empresarial") {
                            arr.push(
                                {
                                    type: "realização",
                                    content: resJSON2[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Ciência (Auxílio a Projetos)") {
                        if (resJSON2[i].area_realizacao.area === "Ciência (Auxílio a Projetos)") {
                            arr.push(
                                {
                                    type: "realização",
                                    content: resJSON2[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Ciência (Interiorização)") {
                        if (resJSON2[i].area_realizacao.area === "Ciência (Interiorização)") {
                            arr.push(
                                {
                                    type: "realização",
                                    content: resJSON2[i]
                                }
                            )
                        }
                    }
                    if (props.page === "Ciência (Internacionalização)") {
                        if (resJSON2[i].area_realizacao.area === "Ciência (Internacionalização)") {
                            arr.push(
                                {
                                    type: "realização",
                                    content: resJSON2[i]
                                }
                            )
                        }
                    }

                }

            }

            setContent(arr.sort(function (a, b) { return a.content.createdAt > b.content.createdAt ? -1 : 1; }))

        }
        catch (err) {

        }
    }

    useEffect(() => {
        getContent();
    }, []);

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
            {content.length > 0 ?
                <div className="divContentAllColumn">
                    {open1 ? (
                        <SimpleModal1
                            open={open1}
                            setOpen={() => setOpen1(!open1)}
                            id={selectedRealizacao}
                        ></SimpleModal1>
                    ) : (
                        <></>
                    )}
                    {open2 ? (
                        <SimpleModal
                            open={open2}
                            setOpen={() => setOpen2(!open2)}
                            id={selectedProjeto}
                        ></SimpleModal>
                    ) : (
                        <></>
                    )}


                    <div className="divContentAllRow" style={{ marginTop: "64px", paddingBottom: "8px", borderBottom: "1px solid #D9D9D9" }}>
                        {props.page !== "Cientista-Chefe"
                            ?
                            <div className="titleSubPageHome">{props.title}</div>
                            :
                            <div className="titleSubPageHome" style={{ color: "#FDCC09" }}>{props.title}</div>
                        }
                    </div>
                    {/*<div className="blogsHome" style={{ marginTop: "0px" }}>
                        <div style={{ width: "65%" }}>
                            <div className="linearGradProjeto"></div>
                        </div>
                    </div>*/}
                    <div
                        className="blogsHome"
                        style={{ marginBottom: "32px" }}
                    >
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center"
                                }}
                            >
                                {props.page === "home" ?
                                    <form
                                        onSubmit={submit}
                                        style={{
                                            marginTop: "17px",
                                            marginRight: "26px"

                                        }}
                                    >
                                        <CssTextField
                                            id="searchfield"
                                            style={{ width: "970px" }}
                                            placeholder={"Buscar Postagem"}
                                            onBlur={(event) => setSearchField(event.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </form>
                                    : <></>
                                }
                                {props.page === "home" ?
                                    <FormControl
                                        style={{
                                            width: "300px",


                                        }}
                                    >
                                        <InputLabel id="demo-simple-select-label">
                                            Filtrar por área
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            disabled
                                            value={tipos}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={""}>Sem filtro</MenuItem>
                                            <MenuItem value={1}>Ciência (Internacionalização)</MenuItem>
                                            <MenuItem value={2}>Ciência (Interiorização)</MenuItem>
                                            <MenuItem value={3}>Ciência (Auxilio a projetos)</MenuItem>
                                            <MenuItem value={4}>Ciência (Formação de RH)</MenuItem>
                                            <MenuItem value={5}>Inovação Empresarial</MenuItem>
                                            <MenuItem value={6}>Cientista Chefe</MenuItem>

                                        </Select>
                                    </FormControl>
                                    : <></>
                                }
                            </div>
                            {content.length > 0 ?
                                <div style={{ marginTop: "32px" }}>
                                    {filterContentList(filterContent(content)).map((item) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginBottom: "30px",
                                            }}
                                        >{item.type === "projeto"
                                            ?
                                            <div
                                                style={{
                                                    display: "flex"
                                                }}
                                            >
                                                <div className="divContentBlogProjetoHome" onClick={() => handleClickProjeto(item.content.id)}>
                                                    <img
                                                        className="imgBlogHome"
                                                        alt=""
                                                        src={item.content.capa}
                                                    />
                                                    <div className="divTextBottomImgBlogTipoProjetoHome">
                                                        <div className="textBottomImgBlogTipoProjetoHome">
                                                            {item.type.toUpperCase()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divTextBlogHome">
                                                    <div className="divTextTituloBlogTipoProjetoHome">
                                                        {substringColor(item.content.titulo.toUpperCase(), searchField.toUpperCase(), 2)}
                                                    </div>
                                                    <div className="divTextDataBlogTipoProjetoHome">
                                                        <div>Postado em {getData(item.content.createdAt, "dia")}/{getData(item.content.createdAt, "mes")}/{getData(item.content.createdAt, "ano")}</div>
                                                    </div>
                                                    <div className="divTextResumoBlogHome">
                                                        <div>
                                                            {parse(item.content.conteudo)}
                                                        </div>
                                                    </div>
                                                    <div className="divButtonLerMaisHome">
                                                        <button
                                                            className="buttonLerMaisHome"
                                                            onClick={() => handleClickProjeto(item.content.id)}
                                                        >
                                                            Ler Mais
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div
                                                style={{
                                                    display: "flex"
                                                }}
                                            >
                                                <div className="divContentBlogRealizacaoHome" onClick={() => handleClickRealizacao(item.content.id)}>
                                                    <img
                                                        className="imgBlogHome"
                                                        alt=""
                                                        src={item.content.capa}
                                                    />
                                                    <div className="divTextBottomImgBlogTipoRealizacaoHome">
                                                        <div className="textBottomImgBlogTipoRealizacaoHome">
                                                            {item.type.toUpperCase()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divTextBlogHome">
                                                    <div className="divTextTituloBlogTipoRealizacaoHome">
                                                        {substringColor(item.content.titulo.toUpperCase(), searchField.toUpperCase(), 1)}
                                                    </div>
                                                    <div className="divTextDataBlogTipoRealizacaoHome">
                                                        <div>Postado em {getData(item.content.createdAt, "dia")}/{getData(item.content.createdAt, "mes")}/{getData(item.content.createdAt, "ano")}</div>
                                                    </div>
                                                    <div className="divTextResumoBlogHome">
                                                        <div>
                                                            {parse(item.content.conteudo)}
                                                        </div>
                                                    </div>
                                                    <div className="divButtonLerMaisHome">
                                                        <button
                                                            className="buttonLerMaisRealizacaoHome"
                                                            onClick={() => handleClickRealizacao(item.content.id)}
                                                        >
                                                            Ler Mais
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                : <></>
                            }
                            {filterContent(content).length > 0 ?
                                <TablePagination
                                    component="div"
                                    count={filterContent(content).length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={5}
                                    rowsPerPageOptions={[]}
                                />
                                : <></>
                            }
                        </div>
                    </div>
                </div>
                : <></>
            }
        </div>
    )
}