import React, { useState } from "react";
import Apoio from "./Apoio";
import SistemaCt from "./SistemaCt";

export default function TodosIndicadores({ subItem = 1 }) {

    var selectecRoute = "apoio" // default

    if (subItem === 2) {
        selectecRoute = "sistemact";
    }

    const [page, setPage] = useState(selectecRoute);

    return (
        <div className="root">
            {page === "apoio" ?
                <div>
                    <div className="backgroundPainelCienciaAuxilio" >
                        <div className="divSelectContentCienciaAuxilio" style={{ userSelect: "none" }}>
                            <div className="selectedContentCienciaAuxilio" onClick={() => setPage("apoio")}>
                                <div className="textMainCienciaAuxilio" style={{ marginRight: "16px"}}>Apoio a Ciência e Inovação</div>
                            </div>
                            <div onClick={() => setPage("sistemact")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainCienciaAuxilio">Sistema de CT&I do Ceará</div>
                            </div>
                        </div>
                        <div className="textDescricaoCienciaAuxilio">
                            <div>Agregado de todos os indicadores inclusos em Ciência e Inovação</div>
                        </div>
                    </div>
                    <Apoio />
                </div>
                :
                ""
            }

            {page === "sistemact" ?
                <div>
                    <div className="backgroundPainelCienciaAuxilio" >
                        <div className="divSelectContentCienciaAuxilio" style={{ userSelect: "none" }}>
                            <div className="selectedContentCienciaAuxilio" onClick={() => setPage("apoio")}>
                                <div className="textOffMainCienciaAuxilio" style={{ marginRight: "16px" }}>Apoio a Ciência e Inovação</div>
                            </div>
                            <div onClick={() => setPage("sistemact")} style={{ marginLeft: "16px" }}>
                                <div className="textMainCienciaAuxilio">Sistema de CT&I do Ceará</div>
                            </div>
                        </div>
                        <div className="textDescricaoCienciaAuxilio">
                            <div>Seleção de indicadores pertinentes às atividades para o desenvolvimento do Sistema de CT&I do Estado do Ceará.</div>
                        </div>
                    </div>
                    <SistemaCt />
                </div>
                :
                ""
            }
        </div>
    )
}