import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { useContext, useState } from "react";
import StoreContext from "../../../../components/Store/Context";
import { ReactComponent as Upload } from "../../../../components/icons/cadastro//upload.svg";
import Portas from "../../../../portas";
import "./cadastroArea.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {},
  },
  button: {
    margin: theme.spacing(1),
  },
  btnCadastrar: {
    backgroundColor: "#20764B",
    borderRadius: "10px",
    width: "140px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },

  importImage: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "54px",
    left: "1px",
    top: "1px",
    margin: "24px",
    borderRadius: "3.5px",
    marginTop: "52px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  formWidthText: {
    width: "100%",
  },
  divFormRedesSociais: {
    margin: "24px",
  },
}));

function CadastroArea() {
  const classes = useStyles();

  const { token } = useContext(StoreContext);
  const [tituloImagemArea, setTituloImagemArea] = useState("");
  const [iconeArea, setIconeArea] = useState(null);
  const [dominio, setDominio] = useState("");

  const currencies = [
    {
      value:"Governo",
      label:"Governo do Ceará"
    },
    {
      value:"Prefeitura",
      label: "Prefeitura de Fortaleza"
    }
  ]

  const cadastrar = async () => {

    if(tituloImagemArea.length < 1) {
      alert("Insira o título da área")
      return;
    }
    if(iconeArea === null) {
      alert("Insira uma imagem svg")
      return;
    }

    const formData = new FormData()
    formData.append('area', tituloImagemArea)
    formData.append('icone', iconeArea)
    formData.append('dominio', dominio)

  

    try {
      const response = await fetch(Portas().serverHost + "/areas", 
      {
        method:"POST",
        headers: {
          'Authorization': "Bearer " + token,
        },
        body: formData
      });
      const resJSON = await response.json();

      if(typeof resJSON.id !== "undefined") {
        alert("Área criada")
        return;
      }
      else if (typeof resJSON.message !== "undefined") {
        alert(resJSON.message)
        return;
      }
      else {
        alert("Um erro ocorreu, tente novamente...")
      }
    }

    catch (err) {
      
    }
  }

  function validationTypeFile(selectFile){
    var selectFile1 = selectFile[0]
    var idxDot = selectFile.name.lastIndexOf(".") +1;
    var extFile = selectFile.name.substr(idxDot, selectFile.name.length).toLowerCase();

    if(extFile ==='svg') {
      setIconeArea(selectFile)
    } else {
      alert("Somente imagem .svg")
    }
  }

  return (
    <div className="pageCadastroArea">
      <div className="tituloPageArea">Criar Area de Conhecimento</div>
      <div style={{marginLeft:"24px", marginRight: "24px"}}>
          <FormControl className={classes.formWidthText}>
            <InputLabel required>Órgão Público Competente</InputLabel>
            <Select
              value={dominio}
              onChange={(event) => setDominio(event.target.value)}
              >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Selecione o orgão público responsável e beneficiado
            </FormHelperText>
          </FormControl>
        </div>
      <div className={classes.divFormRedesSociais}>
        <form className={classes.formWidthText} noValidate autoComplete="off">
          <TextField
            value={tituloImagemArea}
            onChange={(event) => setTituloImagemArea(event.target.value)}
            required
            label="Nome"
            style={{ marginTop: "24px" }}
            fullWidth
          />
        </form>
      </div>
      {iconeArea === null ? (
        <div style={{ marginTop: "32px" }}>
          <div style={{ display: "flex" }}>
          <Paper className={classes.importImage} variant="outlined" style={{width:"100%"}}>
            <InputBase fullWidth disabled required placeholder="Icone" />

            <label htmlFor={"upload-button"}>
              <Upload />
            </label>
            <form encType="multipart/form-data" action="">
              <input
                type="file"
                accept="image/svg"
                name="upload-button"
                id="upload-button"
                style={{ display: "none" }}
                onChange={(event) => {setIconeArea(event.target.files[0]);
                }}
              />
            </form>
          </Paper>
        </div>
        <FormHelperText style={{ marginLeft: "25px" }}>
             Somente formato SVG no tamanho 64x64 é permitido.
          </FormHelperText>
        </div>
        
      ) : (
        <div style={{ marginTop: "32px" }}>
          <div style={{ display: "flex" }}>
          <Paper className={classes.importImage} variant="outlined" style={{width:"100%"}}>
            <InputBase
              fullWidth
              value={iconeArea.name}
              disabled
              placeholder="Icone*"
            />

            <label htmlFor={"upload-button1"}>
              <div>
                <Upload />
              </div>
            </label>
            <form encType="multipart/form-data" action="">
              <input
                type="file"
                accept="image/svg"
                name="upload-button1"
                id="upload-button1"
                style={{ display: "none" }}
                onChange={(event) => {
                  setIconeArea(event.target.files[0]);
                }}
              />
            </form>
          </Paper>
        </div>
        <FormHelperText style={{ marginLeft: "25px" }}>
        Somente formato SVG no tamanho 64x64 é permitido.
            </FormHelperText>
        </div>
      )}

      <div className="botaoCadastro">
        <Button
          className={classes.btnCadastrar}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => cadastrar()}
        >
          Cadastrar
        </Button>
      </div>
    </div>
  );
}

export default CadastroArea;
