import React from 'react';
import { Pie } from '@ant-design/charts';
import toBRL from '../../../../../../components/funcoes/toBRL';
import cores from "../../../../../../components/cores/cores";
import "./donut.css";

const d3 = require("d3");

export default function AntPie(props) {

  var data = props.data;

  function getColor(value) {

    var verdes = cores().amarelos;

    var menor = props.data[0].value;
    var maior = props.data[0].value;

    for (var i = 0; i < props.data.length; i++) {
      if (props.data[i].value > maior) {
        maior = props.data[i].value;
      }
      if (props.data[i].value < menor) {
        menor = props.data[i].value;
      }
    }

    if (menor > 1) {
      menor = Math.log2(menor)
    }
    if (maior > 1) {
      maior = Math.log2(maior)
    }

    var colorScale = d3.scaleQuantize().domain([menor, maior]).range(verdes);

    if (value > 0) {
      return colorScale(Math.log2(value));
    }
    return colorScale(value);

  }

  function findValue(type) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].type === type) {
        return data[i].value;
      }
    }
  }

  var config = {
    data: data,
    angleField: 'value',
    colorField: 'type', // or seriesField in some cases
    color: ({ type }) => {
      return getColor(findValue(type));
    },
    innerRadius: 0.7,
    radius: 1,
    label: {
      type: 'inner',
      autoHide: true,
      offset: '-50%',
      content: '',
    },
    legend: false,
    tooltip: {
      formatter: data => {
        return { name: data.type, value: toBRL(data.value) };
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'auto',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };
  return (
    <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
      <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
        {props.data.slice(0, 7).map((valor) => (
          <div style={{ textAlign: "right", marginBottom: "8px" }} >
            <div className="nomeRegiaoEmpresarial">{valor.type}</div>
            <div className="valorRegiaoEmpresarial">{toBRL(valor.value)}</div>
          </div>
        ))}
      </div>
      <div style={{ width: "258px", height: "258px", marginLeft: "63.34px", marginRight: "63.34px", }}>
        <Pie {...config} />
      </div>
      <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
        {props.data.slice(7, 14).map((valor) => (
          <div style={{ textAlign: "left", marginBottom: "8px" }} >
            <div className="nomeRegiaoEmpresarial" style={{ textAlign: "left" }}>{valor.type}</div>
            <div className="valorRegiaoEmpresarial" style={{ textAlign: "left" }}>{toBRL(valor.value)}</div>
          </div>
        ))}
      </div>
    </div>)
    ;
};
