import React, { useEffect, useState } from "react";
import Portas from "../../../portas";
import "./indicadores.css";
import Indicador1 from "./indicadores/Indicador1";
import Indicador2 from "./indicadores/Indicador2";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Area1 } from '../../../components/icons/areas/area1.svg';
import { ReactComponent as Area2 } from '../../../components/icons/areas/area2.svg';
import { ReactComponent as Area3 } from '../../../components/icons/areas/area3.svg';
import { ReactComponent as Area4 } from '../../../components/icons/areas/area4.svg';
import { ReactComponent as Area5 } from '../../../components/icons/areas/area5.svg';
import { ReactComponent as Area6 } from '../../../components/icons/areas/area6.svg';
import { ReactComponent as Area7 } from '../../../components/icons/areas/area7.svg';
import { ReactComponent as Area8 } from '../../../components/icons/areas/area8.svg';
import { ReactComponent as Area9 } from '../../../components/icons/areas/area9.svg';
import LoadingPainel from "../../../components/painelndicador/loadingIndicador/LoadingPainel";


export default function Empresarial() {

    const [area1, setArea1] = useState(false);
    const [area2, setArea2] = useState(false);
    const [area3, setArea3] = useState(false);
    const [area4, setArea4] = useState(false);
    const [area5, setArea5] = useState(false);
    const [area6, setArea6] = useState(false);
    const [area7, setArea7] = useState(false);
    const [area8, setArea8] = useState(false);
    const [area9, setArea9] = useState(false);
    const [allData, setAllData] = useState([]);
    const [filter, setFilter] = useState([]);

    function getClassname(value) {
        if (value) {
            return "roundSelectedCienciaAuxilio"
        }
        else return "roundCienciaAuxilio"
    }

    function getTextClassname(value) {
        if (value) {
            return "areaSelectedTitleCienciaAuxilio"
        }
        else return "areaTitleCienciaAuxilio"
    }

    async function getData() {

        try {
            const response = await fetch(Portas().serverHost + "/fatos/empresarial",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();

            var aux = []

            for(var i = 0; i < resJSON.length; i++){
                if(!resJSON[i].programa.includes("CEGAS") && !resJSON[i].programa.includes("CAGECE") && !resJSON[i].programa.includes("ENERGIAS RENOVAVEIS")){
                    aux.push(resJSON[i])
                }
            }

            setFilter(aux)
            setAllData(aux)

        }
        catch (err) {
            
        }

    }

    function filterbyArea(area1, area2, area3, area4, area5, area6, area7, area8, area9) {

        let filtered = [];

        if (area1 === false && area2 === false && area3 === false && area4 === false && area5 === false && area6 === false && area7 === false && area8 === false && area9 === false) {
            setArea1(area1);
            setArea2(area2);
            setArea3(area3);
            setArea4(area4);
            setArea5(area5);
            setArea6(area6);
            setArea7(area7);
            setArea8(area8);
            setArea9(area9);
            setFilter(allData)
            return;
        }


        for (var i = 0; i < allData.length; i++) {


            if (allData[i].ano !== null) { //tem ano

                if (allData[i].dominio !== null) { //tem area
                    if (area1) {
                        if (allData[i].dominio.toUpperCase() === "CIENCIAS EXATAS E DA TERRA") {
                            filtered.push(allData[i]);
                        }
                    }
                    if (area2) {
                        if (allData[i].dominio.toUpperCase() === "CIENCIAS BIOLOGICAS") {
                            filtered.push(allData[i]);
                        }
                    }
                    if (area3) {
                        if (allData[i].dominio.toUpperCase() === "ENGENHARIAS") {
                            filtered.push(allData[i]);

                        }
                    }
                    if (area4) {
                        if (allData[i].dominio.toUpperCase() === "CIENCIAS DA SAUDE") {
                            filtered.push(allData[i]);
                        }
                    }
                    if (area5) {
                        if (allData[i].dominio.toUpperCase() === "CIENCIAS AGRARIAS") {
                            filtered.push(allData[i]);
                        }
                    }
                    if (area6) {
                        if (allData[i].dominio.toUpperCase() === "CIENCIAS SOCIAIS APLICADAS") {
                            filtered.push(allData[i]);

                        }
                    }
                    if (area7) {
                        if (allData[i].dominio.toUpperCase() === "CIENCIAS HUMANAS") {
                            filtered.push(allData[i]);
                        }
                    }
                    if (area8) {
                        if (allData[i].dominio.toUpperCase() === "LINGUISTICA, LETRAS E ARTES") {
                            filtered.push(allData[i]);
                        }
                    }
                    if (area9) {  //nn tem dominio, mas a area outros esta ativa
                        if (
                            allData[i].dominio.toUpperCase() !== "CIENCIAS EXATAS E DA TERRA" &&
                            allData[i].dominio.toUpperCase() !== "CIENCIAS BIOLOGICAS" &&
                            allData[i].dominio.toUpperCase() !== "ENGENHARIAS" &&
                            allData[i].dominio.toUpperCase() !== "CIENCIAS DA SAUDE" &&
                            allData[i].dominio.toUpperCase() !== "CIENCIAS AGRARIAS" &&
                            allData[i].dominio.toUpperCase() !== "CIENCIAS SOCIAIS APLICADAS" &&
                            allData[i].dominio.toUpperCase() !== "CIENCIAS HUMANAS" &&
                            allData[i].dominio.toUpperCase() !== "LINGUISTICA, LETRAS E ARTES"
                        ) {
                            filtered.push(allData[i]);
                        }
                    }
                }
                else {
                    if (area9) {
                        filtered.push(allData[i]);
                    }
                }
            }
        }

        setArea1(area1);
        setArea2(area2);
        setArea3(area3);
        setArea4(area4);
        setArea5(area5);
        setArea6(area6);
        setArea7(area7);
        setArea8(area8);
        setArea9(area9);
        setFilter(filtered);

    }

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <div className="filtroAreasCienciaAuxilio">
                <div className="divTextFiltroAreasCienciaAuxilio">
                    <div className="nomeFiltroAreasCienciaAuxilio">ÁREAS DO CONHECIMENTO</div>
                    <div className="legendaFiltroAreasCienciaAuxilio">Marque ou desmarque os campos de interesse abaixo para filtrar os dados</div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", paddingTop: "32px" }}>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area1)} onClick={() => { filterbyArea(!area1, area2, area3, area4, area5, area6, area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area1 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area1)}>Ciências Exatas e da Terra</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area2)} onClick={() => { filterbyArea(area1, !area2, area3, area4, area5, area6, area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area2 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area2)}>Ciências Biológicas</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area3)} onClick={() => { filterbyArea(area1, area2, !area3, area4, area5, area6, area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area3 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area3)}>Engenharias</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area4)} onClick={() => { filterbyArea(area1, area2, area3, !area4, area5, area6, area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area4 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area4)}>Ciências da Saúde</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area5)} onClick={() => { filterbyArea(area1, area2, area3, area4, !area5, area6, area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area5 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area5)}>Ciências Agrárias</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area6)} onClick={() => { filterbyArea(area1, area2, area3, area4, area5, !area6, area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area6 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area6)}>Ciências Sociais Aplicadas</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area7)} onClick={() => { filterbyArea(area1, area2, area3, area4, area5, area6, !area7, area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area7 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area7)}>Ciências Humanas</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area8)} onClick={() => { filterbyArea(area1, area2, area3, area4, area5, area6, area7, !area8, area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area8 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area8)}>Linguística, Letras e Artes</div>
                    </div>
                    <div className="divBorderCienciaAuxilio">
                        <div className={getClassname(area9)} onClick={() => { filterbyArea(area1, area2, area3, area4, area5, area6, area7, area8, !area9) }}>
                            <SvgIcon style={{ fontSize: "50px", color: "white" }}>
                                <Area9 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area9)}>Outros</div>
                    </div>
                </div>
            </div>
            {filter.length > 0 ?
                <div>
                    {<Indicador1 data={filter} />}
                    {<Indicador2 data={filter} />}
                </div>

                :
                <LoadingPainel/>
            }
        </div>
    )
}