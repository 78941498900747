import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Animacao from "../../../components/animacoes/IncrementNumbers";
import Donut from "../../../components/graficos/donutPaineis/Donut";
import SimpleModal from "../../blog/Modal";
import "./sobre.css";


import SvgIcon from "@material-ui/core/SvgIcon";
import BlogsSection from "../../../components/blogsSection/BlogsSection";
import { ReactComponent as Editais } from '../../../components/icons/paineis/caneta.svg';
import { ReactComponent as PropostasRecebidas } from '../../../components/icons/paineis/graficoPizza.svg';
import { ReactComponent as Bolsistas } from '../../../components/icons/paineis/pessoas.svg';
import { ReactComponent as PropostasAprovadas } from '../../../components/icons/paineis/quadrados.svg';

const useStyles = makeStyles({
  selectRoot: {
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
  select: {
    "&:before": {
      borderColor: "#20764B",
    },
    "&:after": {
      borderColor: "#20764B",
    },
    color: "#405965",
    fontWeight: "bold",
  },
  icon: {
    fill: "#20764B",
  },
});

export default function Sobre() {

  var blogs = [
    {
      tipo: 1
    },
    {
      tipo: 1
    },
  ];

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const classes = useStyles();

  const [anos, setAnos] = useState([2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,2022]);
  const [minYear, setMinYear] = useState(2008);
  const [maxYear, setMaxYear] = useState(2022);
  const [data, setData] = useState([
    { ano: 2008, type: "Subvenção", value: 7540497.00 },
    { ano: 2008, type: "Contrapartida", value: 2066256.00 },
    { ano: 2009, type: "Subvenção", value: 4447254.00 },
    { ano: 2009, type: "Contrapartida", value: 2844471.00 },
    { ano: 2010, type: "Subvenção", value: 11421749.00 },
    { ano: 2010, type: "Contrapartida", value: 4022782.00 },
    { ano: 2011, type: "Subvenção", value: 8767579.00 },
    { ano: 2011, type: "Contrapartida", value: 3219733.00 },
    { ano: 2012, type: "Subvenção", value: 0 },
    { ano: 2012, type: "Contrapartida", value: 0 },
    { ano: 2013, type: "Subvenção", value: 7154300.00 },
    { ano: 2013, type: "Contrapartida", value: 1209938.00 },
    { ano: 2014, type: "Subvenção", value: 0 },
    { ano: 2014, type: "Contrapartida", value: 0 },
    { ano: 2015, type: "Subvenção", value: 2998600.00 },
    { ano: 2015, type: "Contrapartida", value: 415587.00 },
    { ano: 2016, type: "Subvenção", value: 8230686.00 },
    { ano: 2016, type: "Contrapartida", value: 1266359.00 },
    { ano: 2017, type: "Subvenção", value: 2427919.00 },
    { ano: 2017, type: "Contrapartida", value: 333279.00 },
    { ano: 2018, type: "Subvenção", value: 9011339.00 },
    { ano: 2018, type: "Contrapartida", value: 1017666.00 },
    { ano: 2019, type: "Subvenção", value: 1956579.00 },
    { ano: 2019, type: "Contrapartida", value: 119023.00 },
    { ano: 2020, type: "Subvenção", value: 4567246.00 },
    { ano: 2020, type: "Contrapartida", value: 293987.00 },
    { ano: 2021, type: "Subvenção", value: 11984357.00 },
    { ano: 2021, type: "Contrapartida", value: 475173.00 },
    { ano: 2022, type: "Subvenção", value: 0 },
    { ano: 2022, type: "Contrapartida", value: 0 },
  ]);

  const [filterData, setFilterData] = useState([
    { type: "Subvenção", value: 80508105 },
    { type: "Contrapartida", value: 17284254 },
  ]);

  function updateFilterData(minYear, maxYear) {
    let array = [...filterData]
    array[0].value = 0
    array[1].value = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].ano >= minYear && data[i].ano <= maxYear) {
        if (data[i].type === "Subvenção") {
          array[0].value += data[i].value
        }
        if (data[i].type === "Contrapartida") {
          array[1].value += data[i].value
        }
      }
    }
    setFilterData(array)
  }
  const handleChangeMinYear = (event) => {
    var min;
    var max;

    if (event.target.value > maxYear) {
      min = maxYear;
      max = event.target.value;
      setMinYear(maxYear);
      setMaxYear(event.target.value);
      updateFilterData(min, max);
    } else {
      min = event.target.value;
      max = maxYear;
      setMinYear(event.target.value);
      updateFilterData(min, max);
    }
  };

  const handleChangeMaxYear = (event) => {
    var min;
    var max;

    if (event.target.value < minYear) {
      min = event.target.value;
      max = minYear;
      setMaxYear(minYear);
      setMinYear(event.target.value);
      updateFilterData(min, max);
    } else {
      min = minYear;
      max = event.target.value;
      setMaxYear(event.target.value);
      updateFilterData(min, max);
    }
  };

  function getIndicadorNumber(indi) {
    var data = [
      { ano: 2008, ind: "Bolsistas", valor: 0 },
      { ano: 2008, ind: "Editais", valor: 1 },
      { ano: 2008, ind: "Projetos contratados", valor:22 },
      { ano: 2008, ind: "Propostas recebidas", valor: 22 },
      { ano: 2009, ind: "Bolsistas", valor: 0 },
      { ano: 2009, ind: "Editais", valor: 1 },
      { ano: 2009, ind: "Projetos contratados", valor: 20 },
      { ano: 2009, ind: "Propostas recebidas", valor: 84 },
      { ano: 2010, ind: "Bolsistas", valor: 0 },
      { ano: 2010, ind: "Editais", valor: 3 },
      { ano: 2010, ind: "Projetos contratados", valor: 43 },
      { ano: 2010, ind: "Propostas recebidas", valor: 118 },
      { ano: 2011, ind: "Bolsistas", valor: 0 },
      { ano: 2011, ind: "Editais", valor: 2 },
      { ano: 2011, ind: "Projetos contratados", valor: 30 },
      { ano: 2011, ind: "Propostas recebidas", valor: 153 },
      { ano: 2012, ind: "Bolsistas", valor: 0 },
      { ano: 2012, ind: "Editais", valor: 0 },
      { ano: 2012, ind: "Projetos contratados", valor: 0 },
      { ano: 2012, ind: "Propostas recebidas", valor: 0 },
      { ano: 2013, ind: "Bolsistas", valor: 0 },
      { ano: 2013, ind: "Editais", valor: 2 },
      { ano: 2013, ind: "Projetos contratados", valor: 24 },
      { ano: 2013, ind: "Propostas recebidas", valor: 152 },
      { ano: 2014, ind: "Bolsistas", valor: 0 },
      { ano: 2014, ind: "Editais", valor: 0 },
      { ano: 2014, ind: "Projetos contratados", valor: 0 },
      { ano: 2014, ind: "Propostas recebidas", valor: 0 },
      { ano: 2015, ind: "Bolsistas", valor: 0 },
      { ano: 2015, ind: "Editais", valor: 1 },
      { ano: 2015, ind: "Projetos contratados", valor: 37 },
      { ano: 2015, ind: "Propostas recebidas", valor: 143 },
      { ano: 2016, ind: "Bolsistas", valor: 0 },
      { ano: 2016, ind: "Editais", valor: 2 },
      { ano: 2016, ind: "Projetos contratados", valor: 34 },
      { ano: 2016, ind: "Propostas recebidas", valor: 224 },
      { ano: 2017, ind: "Bolsistas", valor: 0 },
      { ano: 2017, ind: "Editais", valor: 1 },
      { ano: 2017, ind: "Projetos contratados", valor: 31 },
      { ano: 2017, ind: "Propostas recebidas", valor: 123 },
      { ano: 2018, ind: "Bolsistas", valor: 0 },
      { ano: 2018, ind: "Editais", valor: 4 },
      { ano: 2018, ind: "Projetos contratados", valor: 46 },
      { ano: 2018, ind: "Propostas recebidas", valor: 157 },
      { ano: 2019, ind: "Bolsistas", valor: 0 },
      { ano: 2019, ind: "Editais", valor: 1 },
      { ano: 2019, ind: "Projetos contratados", valor: 26 },
      { ano: 2019, ind: "Propostas recebidas", valor: 26 },
      { ano: 2020, ind: "Bolsistas", valor: 0 },
      { ano: 2020, ind: "Editais", valor: 1 },
      { ano: 2020, ind: "Projetos contratados", valor: 17 },
      { ano: 2020, ind: "Propostas recebidas", valor: 68 },
      { ano: 2021, ind: "Bolsistas", valor: 0 },
      { ano: 2021, ind: "Editais", valor: 4 },
      { ano: 2021, ind: "Projetos contratados", valor: 37 },
      { ano: 2021, ind: "Propostas recebidas", valor: 102 },
      { ano: 2022, ind: "Bolsistas", valor: 101 },
      { ano: 2022, ind: "Editais", valor: 1 },
      { ano: 2022, ind: "Projetos contratados", valor: 0 },
      { ano: 2022, ind: "Propostas recebidas", valor: 443 }
    ]

    var count = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].ano >= minYear && data[i].ano <= maxYear) {
        if (data[i].ind === indi) {
          count += data[i].valor
        }
      }
    }

    return count
  }

  return (
    <div className="contentInovacaoEmpresarialSobre">
      {open
        ?
        <SimpleModal open={open} setOpen={() => setOpen(!open)}></SimpleModal>
        :
        <></>
      }
      <div className="painelInovacaoEmpresarialSobre">
        <div className="textPainelCienciaInovacaoEmpresarialSobre">
          INVESTIMENTOS EM INOVAÇÃO EMPRESARIAL
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <div className="text2PainelInovacaoEmpresarialSobre">
            Investimentos realizados de
          </div>
          <div className="divDropdownPeriodoInovacaoEmpresarialSobre">
            <FormControl className="dropdownPeriodoInovacaoEmpresarialSobre">
              <Select
                value={minYear}
                onChange={handleChangeMinYear}
                displayEmpty
                disableUnderline
                classes={{ root: classes.selectRoot }}
                className={classes.select}
                MenuProps={{ disablePortal: true }}
                inputProps={{
                  "aria-label": "Without label",
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                {anos.map((ano) => (
                  <MenuItem value={ano}>{ano}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="text2PainelInovacaoEmpresarialSobre">a</div>
          <div className="divDropdownPeriodoInovacaoEmpresarialSobre">
            <FormControl className="dropdownPeriodoInovacaoEmpresarialSobre">
              <Select
                value={maxYear}
                onChange={handleChangeMaxYear}
                displayEmpty
                disableUnderline
                classes={{ root: classes.selectRoot }}
                className={classes.select}
                MenuProps={{ disablePortal: true }}
                inputProps={{
                  "aria-label": "Without label",
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                {anos.map((ano) => (
                  <MenuItem value={ano}>{ano}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          style={{
            marginBottom: "32px",
            paddingBottom: "32px",
            paddingTop: "42px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="donutInovacaoEmpresarialSobre">
            <Donut data={filterData} cor={"verde"}></Donut>
          </div>
          <div className="divMiniIndicadoresInovacaoEmpresarial">
            <div className="divIconesInovacaoEmpresarial">
              <div className="divIconeInovacaoEmpresarial">
                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                  <Bolsistas />
                </SvgIcon>
                <p className="iconeTituloInovacaoEmpresarial"><b className="iconeNumeroInovacaoEmpresarial"><Animacao data={getIndicadorNumber("Bolsistas")} /></b><br></br>Bolsistas<br></br></p>
              </div>
              <div className="divIconeInovacaoEmpresarialEnd">
                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                  <PropostasRecebidas />
                </SvgIcon>
                <p className="iconeTituloInovacaoEmpresarial"><b className="iconeNumeroInovacaoEmpresarial"><Animacao data={getIndicadorNumber("Propostas recebidas")} /></b><br></br>Propostas Recebidas<br></br></p>
              </div>
            </div>
            <div className="divIconesInovacaoEmpresarial" >
              <div className="divIconeInovacaoEmpresarial">
                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                  <Editais />
                </SvgIcon>
                <p className="iconeTituloInovacaoEmpresarial"><b className="iconeNumeroInovacaoEmpresarial"><Animacao data={getIndicadorNumber("Editais")} /></b><br></br>Editais<br></br></p>
              </div>
              <div className="divIconeInovacaoEmpresarialEnd">
                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                  <PropostasAprovadas />
                </SvgIcon>
                <p className="iconeTituloInovacaoEmpresarial"><b className="iconeNumeroInovacaoEmpresarial"><Animacao data={getIndicadorNumber("Projetos contratados")} /></b><br></br>Projetos contratados<br></br></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlogsSection page={"Inovação Empresarial"} />
    </div>
  );
}
