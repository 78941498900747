import React, { useEffect, useState } from 'react';
import SimpleModal1 from "../../../../../pages/blog/ModalRealizacao";
import Portas from "../../../../../portas";
import "./slider.css";

const SlideShow = () => {

  const [slideIndex, setSlideIndex] = useState(1);
  const [content, setContent] = useState([]);
  const [selectedRealizacao, setSelectedRealizacao] = useState("");
  const [open1, setOpen1] = useState(false);

  //getContent() = obtem todas as realizacoes
  const getContent = async () => {

    try {

      const response = await fetch(Portas().serverHost + "/realizacoes?image=true&area=true&projeto=true&anexo=true&pagina=0&tamanho=5&paginacao=false&autor=false",
        {
          method: "GET",
        }
      );

      const resJSON = await response.json();

      let arr = resJSON;

      console.log("teste slider")
      console.log(arr.sort(function (a, b) { return a.createdAt > b.createdAt ? -1 : 1; }))

      //ordena as realizações por data de criação
      setContent(arr.sort(function (a, b) { return a.createdAt > b.createdAt ? -1 : 1; }))

    }
    catch (err) {
      console.log(err)
    }
  }

  const showSlides = (index) => {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("dot");

    if (index > slides.length) {
      setSlideIndex(1);
    }

    if (index < 1) {
      setSlideIndex(slides.length);
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }

    slides[index - 1].style.display = "block";
    dots[index - 1].className += " active";
  };

  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;
      if (newIndex > 2) {
        // Volta para o primeiro slide quando chegar ao último
        return 0;
      } else if (newIndex < 0) {
        // Avança para o último slide quando voltar do primeiro
        return 2;
      }
      return newIndex;
    });
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  function handleClickRealizacao(id) {
    setSelectedRealizacao(id);
    setOpen1(true);
  }

  const getDisplay = (index, slideIndex) => {
    if (index === slideIndex) {
      return "block"
    }
    return "none"
  }

  const getClassName = (index, slideIndex) => {
    if (index === slideIndex) {
      return "selectedDot"
    }
    return "dot"
  }

  useEffect(() => {
    const interval = setInterval(() => {
      plusSlides(1);
    }, 8000);

    return () => clearInterval(interval);
  }, [slideIndex]);

  useEffect(() => {
    getContent();
    //showSlides(slideIndex); 
  }, [slideIndex]);

  return (
    <div>
      {content.length > 0
        ?
        <div className="slideshow-container">
          {open1 ? (
            <SimpleModal1
              open={open1}
              setOpen={() => setOpen1(!open1)}
              id={selectedRealizacao}
            ></SimpleModal1>
          ) : (
            <></>
          )}
          {content.map((realizacao, index) => (
            <div className="mySlides" style={{ display: getDisplay(index, slideIndex) }}>
              <div className="numbertext">
                <p className="slideText1">Últimas realizações</p>
                <p className="slideText2">{realizacao.titulo}</p>
              </div>
              <div style={{ backgroundImage: `url(${realizacao.capa})` }} className="Frmhimg"></div>
              <div className="homeBannerBntP2" style={{cursor: "pointer"}} onClick={() => handleClickRealizacao(realizacao.id)}>Ler mais &#10095;</div>
            </div>
          ))}
          <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
          <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
          <div className="dotsDiv">
            <span className={getClassName(0, slideIndex)} onClick={() => currentSlide(0)}></span>
            <span className={getClassName(1, slideIndex)} onClick={() => currentSlide(1)}></span>
            <span className={getClassName(2, slideIndex)} onClick={() => currentSlide(2)}></span>
          </div>
        </div>
        :
        <></>
      }
    </div>
  );
};
export default SlideShow;