import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import BtnCompartilhar from "../../components/btnCompartilhar/BtnCompartilhar";
import Portas from "../../portas";
import SimpleModal from "./ModalBlogVejaMais";
import "./blogRealizacao.css";
import { ReactComponent as Artigo } from "./icons/artigo.svg";
import VejaMais from "./vejaMais/VejaMais";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#008240",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  btnCompartilhar: {
    borderRadius: "4px",
    width: "173px",
    height: "36px",
    color: "#405965",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

function App({ setOpen, id }) {
  const classes = useStyles();

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }

  function clicarNoLink(link) {
    window.open(link, "_blank");
  }
  const [selectedBlogVejaMais, setSelectedBlogVejaMais] = useState("");
  const [open2, setOpen2] = useState(false);
  const [realizacao, setRealizacao] = useState()

  function handleClick(titulo) {
    setSelectedBlogVejaMais(titulo);
    setOpen2(true);
  }

  const getRealizacao = async () => {
    try {
      const response = await fetch(Portas().serverHost + "/realizacoes/" + id + "?area=true&image=true&anexo=true&projeto=true&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON = await response.json();
      setRealizacao(resJSON)
    }
    catch (err) {
      
    }
  }

  useEffect(() => {
    getRealizacao();
  }, []);

  return (
    <div className="blogRealizacaol">
      {typeof realizacao !== "undefined" ?
        <div>
          {open2 ? (
            <SimpleModal
              open={open2}
              setOpen={() => setOpen2(!open2)}
              selectedBlogVejaMais={selectedBlogVejaMais}
            ></SimpleModal>
          ) : (
            <></>
          )}
          <div className="blogCabecalhoRealizacoesl">
            <div className="divVoltarCompartilharRealizacao">
              <Button
                className={classes.btnVoltar}
                variant="contained"
                startIcon={<ArrowBackIosIcon />}
                onClick={() => setOpen(false)}
              >
                Voltar
              </Button>

              <Breadcrumbs>
                <div className="blogCabecalhoTextRealizacoesl" href="/">
                  CIENTISTA CHEFE
                </div>
                <div
                  className="blogCabecalhoTextRealizacoesl"
                  href="/getting-started/installation/"
                >
                  REALIZAÇÃO
                </div>
                <div
                  className="blogCabecalhoTextRealizacoesl"
                  color="textPrimary"
                  href="/getting-started/installation/"
                >
                  {realizacao.titulo}
                </div>
              </Breadcrumbs>
            </div>
            {<BtnCompartilhar tipo={"realizacao"} blogid={realizacao.id} blogarea={realizacao.area_realizacao.area}></BtnCompartilhar>}
          </div>

          <div className="divblogRealizacaolVejaMais">
            <div className="conteudoPrincipalRealizacao">
              <div className="blogTituloRealizacoesl">
                <div>
                  <h1>{realizacao.titulo}</h1>
                </div>
              </div>
              <div className="autorRealizacoesl">
                <div style={{ color: "#20764B" }}>
                  {realizacao.autor_realizacao.nome}
                </div>
                <p style={{ color: "#405965", marginLeft: "2px" }}>Postado em {getData(realizacao.createdAt, "dia")}/{getData(realizacao.createdAt, "mes")}/{getData(realizacao.createdAt, "ano")}</p>
              </div>

              <div className="conteudoRealizacoesl">
                <div>
                  <img src={realizacao.capa} alt="" className="imagemRealizacao"></img>
                </div>
              </div>
              <div className="conteudoRealizacoesl">
                <div>
                  {parse(realizacao.conteudo)}
                </div>
              </div>
              {realizacao.links_relacionados.length > 0 ?
                <div>
                  <h2 className="citacaoRealizacoesl">Links Úteis</h2>
                  {realizacao.links_relacionados.map((link) => (
                    <div className="linkcitacaoRealizacoesl" onClick={() => clicarNoLink(link.link)}>
                      • {link.label}
                    </div>
                  ))}
                </div>
                : <></>
              }
              {realizacao.exibir_anexo
                ?
                <div>
                  <h2 className="citacaoRealizacoesl" style={{ marginTop: "32px" }}>
                    Anexo{" "}
                  </h2>
                  <div className="anexosRealizacoesl" style={{ marginLeft: "12px" }} onClick={() => clicarNoLink(realizacao.anexo)}>
                    <SvgIcon style={{ fontSize: "16px", color: "#008240" }}>
                      <Artigo />
                    </SvgIcon>
                    <div className="artigoTituloRealizacoesl">Artigo</div>
                  </div>
                </div>
                : <></>}
            </div>

            <VejaMais id={realizacao.id} tipo="realizacao" />
          </div>
        </div>
        : <></>
      }
    </div>
  );
}

export default App;
