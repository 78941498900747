import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import "./BlogEdit.css";
import CustomViewProjeto from "./CustomViewProjeto";
import CustomViewRealizacao from "./CustomViewRealizacao";
import EditarProjeto from "./ModalEdicao";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    color: "#405965",
  },

  buttonEditar: {
    margin: theme.spacing(1),
    color: " #405965",
  },
  buttonAprovar: {
    border: "1px solid #20764B",
    borderRadius: "4px",
    color: "#20764B",
    boxSizing: "border-box",
  },
  buttonRecusar: {
    border: "1px solid #FF594E",
    borderRadius: "4px",
    color: "#FF594E",
    boxSizing: "border-box",
  },
  btnVoltar: {
    backgroundColor: "#20764B",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

export default function VisualizacaoBlogConteudo({ setOpen, tipoBlog }) {
  const classes = useStyles();


  const handleClickOpen = () => {
    setOpen(true);
  };

  const [visibilidadeModEdicao, setVisibilidadeModEdicao] = useState(false);

  function handleClickEdit(tipoBlog) {
    setVisibilidadeModEdicao(!visibilidadeModEdicao)
  }

  return (
    <div className="blogEdit">
      <div className="cabecalhoEdit">
        <div className="blogCabecalhoFormatEdit">
          <Button
            className={classes.btnVoltar}
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </div>

        <div>

          <Button
            variant="outlined"
            className={classes.buttonEditar}
            startIcon={<EditIcon />}
            onClick={() => handleClickEdit(tipoBlog)}
          >
            Editar
          </Button>


          {visibilidadeModEdicao ? (
            <EditarProjeto open={visibilidadeModEdicao} setOpen={() => setVisibilidadeModEdicao(!visibilidadeModEdicao)} tipoBlog={tipoBlog}
            ></EditarProjeto>
          ) : (
            <></>
          )}
        </div>
      </div>

      {tipoBlog.type === "projeto" || tipoBlog.content.data_inicio !== undefined
        ?
        <CustomViewProjeto setOpen={true} projeto={tipoBlog.content}></CustomViewProjeto>
        :
        <CustomViewRealizacao setOpen={true} realizacao={tipoBlog.content}></CustomViewRealizacao>
      }
    
    </div>
  );
}
