import { Pie } from '@ant-design/charts';
import React from 'react';
import fixLongName from "../../../funcoes/fixLongName";
import toBRL from '../../../funcoes/toBRL';
import "./donut.css";
const d3 = require("d3");

export default function AntPie(props) {

  console.log(props)

  //ordena o dado e remove valores zerados
  function clearData(props) {

    var data = [];
    for (var i = 0; i < props.data.length; i++) {
      if (props.data[i].value > 0) {
        data.push(props.data[i]);
      }
    }
    data.sort(function (a, b) {
      if (a.value < b.value) {
        return -1
      }
      else {
        return 1;
      }
    })
    return data
  }

  var data = clearData(props);

  function getColor(value) {
    var verdes = d3.schemeGreens[9];

    var menor = props.data[0].value;
    var maior = props.data[0].value;

    for (var i = 0; i < props.data.length; i++) {
      if (props.data[i].value > maior) {
        maior = props.data[i].value;
      }
      if (props.data[i].value < menor) {
        menor = props.data[i].value;
      }
    }

    if (menor > 1) {
      menor = Math.log2(menor)
    }
    if (maior > 1) {
      maior = Math.log2(maior)
    }

    var colorScale = d3.scaleQuantize().domain([menor, maior]).range(verdes);
    
    if(value > 0){
      return colorScale(Math.log2(value));
    }
    return colorScale(value);

  }

  function findValue(type) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].type === type) {
        return data[i].value;
      }
    }
  }

  var config = {
    data: data,
    angleField: 'value',
    colorField: 'type', // or seriesField in some cases
    color: ({type}) => {
      return getColor(findValue(type));
    },
    innerRadius: 0.7,
    radius: 1,
    label: {
      type: 'inner',
      autoHide: true,
      offset: '-50%',
      content: '',
    },
    legend: false,
    tooltip: {
      formatter: data => {
        return { name: fixLongName(data.type, 10), value: props.tipo === "reais"? toBRL(data.value): data.value };
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'auto',
          textOverflow: 'ellipsis',
        },
        content: '',
      },
    },
  };
  return (
    <div style={{minHeight: "400px", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
      <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
        {props.data.slice(0,  Math.ceil(props.data.length / 2)).map((valor) => (
          <div style={{ textAlign: "right", marginBottom: "8px" }} >
            <div className="nomeRegiao">{fixLongName(valor.type)}</div>
            <div className="valorRegiao">{props.tipo === "reais"? toBRL(valor.value) : valor.value}</div>
          </div>
        ))}
      </div>
      <div style={{width:"258px", height:"258px", marginLeft: "63.34px", marginRight: "63.34px",}}>
        <Pie {...config} />
      </div>
      <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
        {props.data.slice(Math.ceil(props.data.length / 2)).map((valor) => (
          <div style={{ textAlign: "left", marginBottom: "8px" }} >
            <div className="nomeRegiao" style={{ textAlign: "left" }}>{fixLongName(valor.type)}</div>
            <div className="valorRegiao" style={{ textAlign: "left" }}>{props.tipo === "reais"? toBRL(valor.value): valor.value}</div>
          </div>
        ))}
      </div>
    </div>)
    ;
};
