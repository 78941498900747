import { Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { CKEditor } from "ckeditor4-react";
import React, { useContext, useEffect, useState } from "react";
import validator from 'validator';
import StoreContext from "../../../../components/Store/Context";
import { ReactComponent as Agua } from "../../../../components/icons/cadastro//agua.svg";
import { ReactComponent as Animais } from "../../../../components/icons/cadastro//animais.svg";
import { ReactComponent as Cidade } from "../../../../components/icons/cadastro//cidade.svg";
import { ReactComponent as Fome } from "../../../../components/icons/cadastro//comida.svg";
import { ReactComponent as Cubos } from "../../../../components/icons/cadastro//cubos.svg";
import { ReactComponent as Educacao } from "../../../../components/icons/cadastro//educacao.svg";
import { ReactComponent as Energia } from "../../../../components/icons/cadastro//energia.svg";
import { ReactComponent as Familia } from "../../../../components/icons/cadastro//familia.svg";
import { ReactComponent as Grafico } from "../../../../components/icons/cadastro//grafico.svg";
import { ReactComponent as Infinito } from "../../../../components/icons/cadastro//infinito.svg";
import { ReactComponent as MeioAmbiente } from "../../../../components/icons/cadastro//meioambiente.svg";
import { ReactComponent as Mundo } from "../../../../components/icons/cadastro//mundo.svg";
import { ReactComponent as Orientacao } from "../../../../components/icons/cadastro//orientacao.svg";
import { ReactComponent as Pesca } from "../../../../components/icons/cadastro//pesca.svg";
import { ReactComponent as Quimica } from "../../../../components/icons/cadastro//quimica.svg";
import { ReactComponent as Saude } from "../../../../components/icons/cadastro//saude.svg";
import { ReactComponent as Sexo } from "../../../../components/icons/cadastro//sexo.svg";
import { ReactComponent as Upload } from "../../../../components/icons/cadastro//upload.svg";
import { ReactComponent as AddLink } from "../../../../components/icons/cadastro/addLink.svg";
import { ReactComponent as Icone1 } from "../../../../components/icons/cadastro/bolsistas.svg";
import { ReactComponent as Icone3 } from "../../../../components/icons/cadastro/editais.svg";
import { ReactComponent as Icone4 } from "../../../../components/icons/cadastro/empresasFomentadas.svg";
import { ReactComponent as Icone2, ReactComponent as Icone5 } from "../../../../components/icons/cadastro/projetos.svg";
import { ReactComponent as Icone6 } from "../../../../components/icons/cadastro/setoresAtingidos.svg";
import Portas from "../../../../portas";
import "./cadastroUsuario.css";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {},
  },
  button: {
    margin: theme.spacing(1),
  },
  btnCadastrar: {
    backgroundColor: "#20764B",
    borderRadius: "10px",
    width: "140px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  formWidth: {
    width: "300px",
  },
  formWidthText: {
    width: "100%",
  },
  divFormRedesSociais: {
    margin: "24px",
  },
  formControl: {
    minWidth: 680,
  },
  formIcone: {
    marginRight: "16px",
    minWidth: 84,
  },
  importImage: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 965,
    height: "54px",
    left: "1px",
    top: "1px",
    margin: "25px",
    borderRadius: "3.5px",
    marginTop: "52px",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const indicadoresTitulo = [
  {
    value: "Pesquisadores",
    label: "Pesquisadores",
  },
  {
    value: "Projetos",
    label: "Projetos",
  },
  {
    value: "Instituiçoes atendidas",
    label: "Instituiçoes atendidas",
  },
  {
    value: "Cidades atendidas",
    label: "Cidades atendidas",
  },
];

function App({ setOpen }) {

  
  const classes = useStyles();

  const { setToken } = useContext(StoreContext);
  const { token } = useContext(StoreContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [funcaoPerfil, setFuncaoPerfil] = useState("");
  const [nomeCompleto, setNomeCompleto] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confsenha, setConfSenha] = useState("");
  const [orgaoBeneficiado, setOrgaoBeneficiado] = useState("");
  const [lattesPessoal, setLattesPessoal] = useState("");
  const [linkedinPessoal, setLinkedindPessoal] = useState("");
  const [gitHubPessoal, setGitHubPessoal] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [ppa, setPpa] = useState([]);
  const [projetos, setProjetos] = useState([]);
  const [conteudoInclusao, setConteudoInclusao] = useState("");
  const [conteudoInclusaoProjetos, setConteudoInclusaoProjetos] = useState("");
  const [editorData, setEditorData] = useState("");
  const [area, setArea] = React.useState("");
  const [areas, setAreas] = useState([]);
  const [perfis, setPerfis] = useState([]);
  const [indicadores, setIndicadores] = useState([
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
    {
      indicador: "",
      icone: 1,
      valor: 0,
    },
  ]);

  const [ods, setOds] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [odsSelecionados, setOdsSelecionados] = useState([])

  const handleChange = (item, index) => {
    let aux = [...indicadores];
    aux[index].indicador = item;
    setIndicadores(aux);
  };

  const handleChangeIcone = (item, index) => {
    let aux = [...indicadores];
    aux[index].icone = item;
    setIndicadores(aux);
  };

  const handleChangeValor = (item, index) => {
    let aux = [...indicadores];
    aux[index].valor = item;
    setIndicadores(aux);
  };

  const handleChangeColor = (index) => {

    //atualiza ods
    let aux = [...ods];
    aux[index] = !aux[index];
    setOds(aux);

    //atualiza index dos selecionados
    var auxods = []
    for (var i = 0; i < aux.length; i++) {
      if (aux[i] === true) {
        auxods.push(i);
      }
    }
    
    setOdsSelecionados(auxods);
  };

  const getIcone = (index) => {
    if (index === 0) {
      return <Icone1 />;
    } else if (index === 1) {
      return <Icone2 />;
    } else if (index === 2) {
      return <Icone3 />;
    } else if (index === 3) {
      return <Icone4 />;
    } else if (index === 4) {
      return <Icone5 />;
    } else if (index === 5) {
      return <Icone6 />;
    }
  };

  const addInputPpa = (event) => {
    setPpa([...ppa, conteudoInclusao]);
    setConteudoInclusao("");
  };

  const addInputProjetos = (event) => {
    setProjetos([...projetos, conteudoInclusaoProjetos]);
    setConteudoInclusaoProjetos("");
  };

  const handleRemoveInputPpa = (position) => {
    var auxPpa = [];
    for (var i = 0; i < ppa.length; i++) {
      if (i !== position) {
        auxPpa.push(ppa[i]);
      }
    }
    setPpa(auxPpa);
  };

  const handleRemoveInputProjetos = (position) => {
    var auxProjetos = [];
    for (var i = 0; i < projetos.length; i++) {
      if (i !== position) {
        auxProjetos.push(projetos[i]);
      }
    }
    setProjetos(auxProjetos);
  };

  function handleChangePpa(value, index) {
    var auxPpa = [];
    for (var i = 0; i < ppa.length; i++) {
      if (i === index) {
        auxPpa.push(value);
      } else {
        auxPpa.push(ppa[i]);
      }
    }
    setPpa(auxPpa);
  }

  function handleChangeProjetos(value, index) {
    var auxProjetos = [];
    for (var i = 0; i < projetos.length; i++) {
      if (i === index) {
        auxProjetos.push(value);
      } else {
        auxProjetos.push(projetos[i]);
      }
    }
    setProjetos(auxProjetos);
  }


  const criarPerfil = async () => {
    if (token !== null) {
      try {
        var perfil = "";

        try {
          const response = await fetch(Portas().serverHost + "/perfis",
            {
              method: "GET",
              headers: {
                'Authorization': "Bearer " + token,
              }
            }
          );

          const resJSON1 = await response.json();
          if (resJSON1.length > 0) {
            for (var i = 0; i < resJSON1.length; i++) {
              if (resJSON1[i].acesso === funcaoPerfil) {
                perfil = resJSON1[i].id
              }
            }
          }
        }
        catch (err) {
          
        }

        //VALIDAÇÕES
        if (nomeCompleto.length < 1) {
          alert("Insira um nome!")
          return;
        }
        if (validator.isEmail(email) === false) {
          alert("Insira um email válido!")
          return;
        }
        if (perfil === "") {
          alert("Insira um tipo de perfil")
          return;
        }
        if (senha.length < 6) {
          alert("A senha precisa ter pelo menos 6 caracteres")
          return;
        }
        if (senha !== confsenha) {
          alert("Senhas não conferem!")
          return;
        }
        if (senha !== confsenha) {
          alert("Senhas não conferem!")
          return;
        }
        if (selectedImage === null) {
          alert("Insira uma foto")
          return;
        }


        const formData = new FormData()
        formData.append('nome_completo', nomeCompleto)
        formData.append('email', email)
        formData.append('perfil_acesso', perfil)
        formData.append('senha', senha)
        formData.append('confirmar_senha', senha)
        formData.append('imagem_perfil', selectedImage)

        var cientista = {};

        if (funcaoPerfil === "Cientista-Chefe") {

          //validações

          var ppaAux = [];
          for (var i = 0; i < ppa.length; i++) {
            ppaAux.push({ "titulo": ppa[i] })
          }

          if (area.length < 1) {
            alert("Selecione uma área!")
            return;
          }

          var areaID = "";
          for (var i = 0; i < areas.length; i++) {
            if (areas[i].label === area)
              areaID = areas[i].id
          }

          var indicadoresAux = []
          for (var i = 0; i < indicadores.length; i++) {
            if (indicadores[i].indicador.length > 0 && indicadores[i].valor > 0) {
              indicadoresAux.push(indicadores[i])
            }
          }

          if (indicadoresAux.length < 1) {
            alert("Cadastre pelo menos 1 indicador")
            return;
          }

          cientista = {
            "descricao": editorData,
            "orgao_beneficiado": orgaoBeneficiado,
            //"area": "ade2b54a-04d4-4b13-9585-de4d7e1d338c",
            "area": areaID,
            "lattes": lattesPessoal,
            "ppa": ppaAux,
            "objetivos": odsSelecionados,
            "indicadores": indicadoresAux,
            "projetos": projetos
          }

          if (linkedinPessoal.length > 0) {
            cientista["linkedin"] = linkedinPessoal
          };

          if (gitHubPessoal.length > 0) {
            cientista["github"] = gitHubPessoal
          };

          if (facebook.length > 0) {
            cientista["facebook"] = facebook
          };

          if (instagram.length > 0) {
            cientista["instagram"] = instagram
          };

          if (youtube.length > 0) {
            cientista["youtube"] = youtube
          };

          formData.append('cientista', JSON.stringify(cientista))
        }

        
        
        
        
        
        
        

        const response = await fetch(Portas().serverHost + "/usuarios",
          {
            method: "POST",
            headers: {
              'Authorization': "Bearer " + token
            },
            body: formData
          }
        );

        const resJSON = await response.json();
        
        if (typeof resJSON.id !== "undefined") {
          alert("Perfil criado!")
          return;
        }
        else if (typeof resJSON.message !== "undefined") {
          alert(resJSON.message)
          return;
        }
        else {
          alert("Um erro ocorreu, tente novamente...")
        }

      }
      catch (err) {
        alert(err)
        
      }
    }
  }

  const CriarDropdowns = async () => {
    var areas = [];
    var perfis = [];

    try {
      const response = await fetch(Portas().serverHost + "/areas",
        {
          method: "GET",
          headers: {
            'Authorization': "Bearer " + token,
          }
        }
      );

      const resJSON1 = await response.json();
      if (resJSON1.length > 0) {
        for (var i = 0; i < resJSON1.length; i++) {
          areas.push({ "value": resJSON1[i].area, "label": resJSON1[i].area, "id": resJSON1[i].id })
        }
        setAreas(areas)
      }
    }
    catch (err) {
      
    }

    try {
      const response = await fetch(Portas().serverHost + "/perfis",
        {
          method: "GET",
          headers: {
            'Authorization': "Bearer " + token,
          }
        }
      );

      const resJSON1 = await response.json();
      if (resJSON1.length > 0) {
        for (var i = 0; i < resJSON1.length; i++) {
          perfis.push({ "value": resJSON1[i].acesso, "label": resJSON1[i].acesso, "id": resJSON1[i].id })
        }
        setPerfis(perfis)
      }
    }
    catch (err) {
      
    }

  }

  useEffect(() => {
    CriarDropdowns()
  }, []);

  return (
    <div className="pageCadastroUsuario">
      <div className="tituloPage">Cadastro de Usuário</div>
      <div>
        <div className="dadosIniciais">
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              value={nomeCompleto}
              onChange={(event) => setNomeCompleto(event.target.value)}
              required
              label="Nome Completo"
              className={classes.formWidth}
            />
          </form>
          <div className="perfilAcesso">
            <FormControl className={classes.formWidth}>
              <InputLabel>Perfil de Acesso</InputLabel>
              <Select
                value={funcaoPerfil}
                onChange={(event) => setFuncaoPerfil(event.target.value)}
              >
                {perfis.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {funcaoPerfil === "Curador" || funcaoPerfil === "Administrador" ? (
            <div>
              <div className="areaConhecimento">
                <FormControl className={classes.formWidth} disabled>
                  <InputLabel>Área do Conhecimento</InputLabel>
                  <Select
                    value={area}
                    onChange={(event) => setArea(event.target.value)}
                  >
                    {areas.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : (
            <div>
              <div className="areaConhecimento">
                <FormControl className={classes.formWidth}>
                  <InputLabel>Área do Conhecimento</InputLabel>
                  <Select
                    value={area}
                    onChange={(event) => setArea(event.target.value)}
                  >
                    {areas.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
        </div>

        <div className="dadosIniciais">
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className={classes.formWidth}
              required
              label="Email"
            />
          </form>

          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              value={senha}
              onChange={(event) => setSenha(event.target.value)}
              className={classes.formWidth}
              required
              label="Criar/Alterar Senha"
              type="password"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              value={confsenha}
              onChange={(event) => setConfSenha(event.target.value)}
              className={classes.formWidth}
              required
              label="Confirmar Senha"
              type="password"
            />
          </form>
        </div>
        {funcaoPerfil === "Cientista-Chefe" ? (
          <div>
            <div className="subtitulos">Descrição do Perfil</div>

            <div style={{ backgroundColor: "white", textAlign: "center" }}>
              <CKEditor
              data={editorData}
              id="editor_home"
              name="editor_home"
              onChange={(event) => setEditorData(event.editor.getData())}
              config={{
                language: "pt-br",
                uiColor: "#FFFFFF",
                toolbarCanCollapse: true,
                toolbarGroups: [
                  { name: "clipboard", groups: ["clipboard", "undo"] },
                  // { name: "styles", groups: ["styles"] },
                  { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                  {
                    name: "paragraph",
                    groups: [
                      "align",
                      "list",
                      "indent",
                      "blocks",
                      "bidi",
                      "paragraph",
                    ],
                  },
                  {
                    name: "editing",
                    groups: ["find", "selection", "spellchecker", "editing"],
                  },
                  { name: "links", groups: ["links"] },
                  { name: "insert", groups: ["insert"] },
                  { name: "forms", groups: ["forms"] },
                  {
                    name: "document",
                    groups: ["mode", "document", "doctools"],
                  },
                  { name: "others", groups: ["others"] },
                  // { name: "colors", groups: ["colors"] },
                  { name: "about", groups: ["about"] },
                  { name: "tools", groups: ["tools"] },
                ],
                extraPlugins: "justify, font",
                removeButtons:
                  "FontSize,Subscript,Superscript,Scayt,PasteText,PasteFromWord,Anchor,Strike,RemoveFormat,Source,About,Styles, Font, Link",
              }}
              onInstanceReady={() => {

              }}
            />
            </div>
            <div className={classes.divFormRedesSociais}>
              <form
                className={classes.formWidthText}
                noValidate
                autoComplete="off"
              >
                <TextField
                  value={orgaoBeneficiado}
                  onChange={(event) => setOrgaoBeneficiado(event.target.value)}
                  label="Orgão Beneficiado"
                  style={{ marginTop: "48px" }}
                  fullWidth
                />
              </form>
            </div>
            {selectedImage === null
              ?
              <div>
                <Paper className={classes.importImage} variant="outlined">
                  <InputBase
                    fullWidth
                    disabled
                    placeholder="Imagem de perfil"
                  />
                  <label htmlFor={'upload-button'}>
                    <Upload />
                  </label>
                  <form encType="multipart/form-data" action="">
                    <input
                      type="file"
                      accept="image/*"
                      name="upload-button"
                      id="upload-button"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        
                        setSelectedImage(event.target.files[0]);
                      }}
                    />
                  </form>
                </Paper>
              </div>
              :
              <div>
                <Paper className={classes.importImage} variant="outlined">
                  <InputBase
                    fullWidth
                    value={selectedImage.name}
                    disabled
                    placeholder="Imagem de perfil"
                  />
                  <label htmlFor={'upload-button1'}>
                    <div>
                      <Upload />
                    </div>
                  </label>
                  <form encType="multipart/form-data" action="">
                    <input
                      type="file"
                      accept="image/*"
                      name="upload-button1"
                      id="upload-button1"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        
                        setSelectedImage(event.target.files[0]);
                      }}
                    />
                  </form>
                </Paper>
              </div>
            }
            <div>
              <div className="subtitulos">Redes Sociais</div>
              <div className={classes.divFormRedesSociais}>
                <div>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Lattes Pessoal</InputLabel>
                    <Input
                      value={lattesPessoal}
                      onChange={(event) => setLattesPessoal(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <AddLink />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>

                <div>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>LinkedIn Pessoal</InputLabel>
                    <Input
                      value={linkedinPessoal}
                      onChange={(event) =>
                        setLinkedindPessoal(event.target.value)
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <AddLink />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Github Pessoal</InputLabel>
                    <Input
                      value={gitHubPessoal}
                      onChange={(event) => setGitHubPessoal(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <AddLink />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>

                <div>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Facebook de divulgação</InputLabel>
                    <Input
                      value={facebook}
                      onChange={(event) => setFacebook(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <AddLink />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Instagram de divulgação</InputLabel>
                    <Input
                      value={instagram}
                      onChange={(event) => setInstagram(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <AddLink />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Youtube de divulgação</InputLabel>
                    <Input
                      value={youtube}
                      onChange={(event) => setYoutube(event.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <AddLink />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <div>
              <div className="subtitulos">
                Projetos
              </div>
              {projetos.map((projeto, index) => (
                <div key={index} className={classes.divFormRedesSociais}>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Título do Projeto</InputLabel>
                    <Input
                      value={projetos[index]}
                      onChange={(event) => {
                        handleChangeProjetos(event.target.value, index);
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          type="submit"
                          onClick={() => {
                            handleRemoveInputProjetos(index);
                          }}
                        >
                          <DeleteIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormHelperText>
                    Digite o título do projeto
                  </FormHelperText>
                </div>
              ))}
              <div className={classes.divFormRedesSociais}>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Título do Projeto</InputLabel>
                  <Input
                    value={conteudoInclusaoProjetos}
                    onChange={(event) => {
                      setConteudoInclusaoProjetos(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        type="submit"
                        onClick={addInputProjetos}
                      >
                        <AddIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormHelperText>
                  Digite o título do projeto
                </FormHelperText>
              </div>
            </div>
            <div>
              <div className="subtitulos">
                Programas do Plano Plurianual(PPA) beneficiados
              </div>
              {ppa.map((ppas, index) => (
                <div key={index} className={classes.divFormRedesSociais}>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Título do Programa</InputLabel>
                    <Input
                      value={ppa[index]}
                      onChange={(event) => {
                        handleChangePpa(event.target.value, index);
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          type="submit"
                          onClick={() => {
                            handleRemoveInputPpa(index);
                          }}
                        >
                          <DeleteIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormHelperText>
                    Digite o título de um PPA que julgue estar relacionado a
                    seus projetos
                  </FormHelperText>
                </div>
              ))}
              <div className={classes.divFormRedesSociais}>
                <FormControl className={classes.formWidthText}>
                  <InputLabel>Título do Programa</InputLabel>
                  <Input
                    value={conteudoInclusao}
                    onChange={(event) => {
                      setConteudoInclusao(event.target.value);
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        type="submit"
                        onClick={addInputPpa}
                      >
                        <AddIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormHelperText>
                  Digite o título de um PPA que julgue estar relacionado a seus
                  projetos
                </FormHelperText>
              </div>
            </div>

            <div>
              <div className="subtitulos">
                Objetivos de Desenvolvimento Sustentável Atingidos
              </div>

              <div className="divOds">
                {ods[0] === false ? (
                  <div
                    className="divIconesOds1"
                    onClick={() => handleChangeColor(0)}
                  >
                    <LightTooltip
                      title="Erradicação da probreza"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Familia />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                ) : (
                  <div
                    className="divIconesOds1Clicked"
                    onClick={() => handleChangeColor(0)}
                  >
                    <LightTooltip
                      title="Erradicação da probreza"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Familia />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                )}
                {ods[1] === false ? (
                  <div
                    className="divIconesOds2"
                    onClick={() => handleChangeColor(1)}
                  >
                    <LightTooltip title="Fome Zero" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Fome />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                ) : (
                  <div
                    className="divIconesOds2Clicked"
                    onClick={() => handleChangeColor(1)}
                  >
                    <LightTooltip title="Fome Zero" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Fome />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                )}
                {ods[2] === false
                  ?
                  <div className="divIconesOds3"
                    onClick={() => handleChangeColor(2)}>
                    <LightTooltip title="Saúde e bem-estar" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Saude />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds3Clicked"
                    onClick={() => handleChangeColor(2)}>
                    <LightTooltip title="Saúde e bem-estar" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Saude />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }

                {ods[3] === false
                  ?
                  <div className="divIconesOds4"
                    onClick={() => handleChangeColor(3)}>
                    <LightTooltip
                      title="Educação de qualidade"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Educacao />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds4Clicked"
                    onClick={() => handleChangeColor(3)}>
                    <LightTooltip
                      title="Educação de qualidade"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Educacao />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[4] === false
                  ?

                  <div className="divIconesOds5"
                    onClick={() => handleChangeColor(4)}>
                    <LightTooltip title="Igualdade de genêro" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Sexo />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :

                  <div className="divIconesOds5Clicked"
                    onClick={() => handleChangeColor(4)}>
                    <LightTooltip title="Igualdade de genêro" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Sexo />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }

                {ods[5] === false
                  ?
                  <div className="divIconesOds6"
                    onClick={() => handleChangeColor(5)}>
                    <LightTooltip
                      title="Água limpa e saneamento"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Agua />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds6Clicked"
                    onClick={() => handleChangeColor(5)}>
                    <LightTooltip
                      title="Água limpa e saneamento"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Agua />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[6] === false
                  ?
                  <div className="divIconesOds7"
                    onClick={() => handleChangeColor(6)}>
                    <LightTooltip
                      title="Energia limpa e acessível"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Energia />
                      </SvgIcon>
                    </LightTooltip>
                  </div>

                  :
                  <div className="divIconesOds7Clicked"
                    onClick={() => handleChangeColor(6)}>
                    <LightTooltip
                      title="Energia limpa e acessível"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Energia />
                      </SvgIcon>
                    </LightTooltip>
                  </div>

                }
                {ods[7] === false
                  ?
                  <div className="divIconesOds8"
                    onClick={() => handleChangeColor(7)}>
                    <LightTooltip
                      title="Trabalho decente e crescimento econômico"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Grafico />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds8Clicked"
                    onClick={() => handleChangeColor(7)}>
                    <LightTooltip
                      title="Trabalho de decente e crescimento econômico"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Grafico />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[8] === false
                  ?
                  <div className="divIconesOds9"
                    onClick={() => handleChangeColor(8)}>
                    <LightTooltip
                      title="Inovação infraestrutura"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Cubos />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds9Clicked"
                    onClick={() => handleChangeColor(8)}>
                    <LightTooltip
                      title="Inovação infraestrutura"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Cubos />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[9] === false
                  ?
                  <div className="divIconesOds10"
                    onClick={() => handleChangeColor(9)}>
                    <LightTooltip
                      title="Redução das desigualdades"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Orientacao />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds10Clicked"
                    onClick={() => handleChangeColor(9)}>
                    <LightTooltip
                      title="Redução das desigualdades"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Orientacao />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[10] === false
                  ?
                  <div className="divIconesOds11"
                    onClick={() => handleChangeColor(10)}>
                    <LightTooltip
                      title="Cidades e comunidades sustentáveis"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Cidade />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds11Clicked"
                    onClick={() => handleChangeColor(10)}>
                    <LightTooltip
                      title="Cidades e comunidades sustentáveis"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Cidade />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[11] === false
                  ?
                  <div className="divIconesOds12"
                    onClick={() => handleChangeColor(11)}>
                    <LightTooltip
                      title="Consumo e produção responsáveis"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Infinito />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds12Clicked"
                    onClick={() => handleChangeColor(11)}>
                    <LightTooltip
                      title="Consumo e produção responsáveis"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Infinito />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[12] === false
                  ?
                  <div className="divIconesOds13"
                    onClick={() => handleChangeColor(12)}>
                    <LightTooltip
                      title="Combate às alterações climáticas"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Mundo />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds13Clicked"
                    onClick={() => handleChangeColor(12)}>
                    <LightTooltip
                      title="Combate às alterações climáticas"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Mundo />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[13] === false
                  ?
                  <div className="divIconesOds14"
                    onClick={() => handleChangeColor(13)}>
                    <LightTooltip title="Vida debaixo d'água" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Pesca />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds14Clicked"
                    onClick={() => handleChangeColor(13)}>
                    <LightTooltip title="Vida debaixo d'água" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Pesca />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[14] === false
                  ?
                  <div className="divIconesOds15"
                    onClick={() => handleChangeColor(14)}>
                    <LightTooltip title="Vida terrestre" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <MeioAmbiente />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds15Clicked"
                    onClick={() => handleChangeColor(14)}>
                    <LightTooltip title="Vida terrestre" placement="top-end">
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <MeioAmbiente />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[15] === false
                  ?
                  <div className="divIconesOds16"
                    onClick={() => handleChangeColor(15)}>
                    <LightTooltip
                      title="Paz, justiça e instituições fortes"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Animais />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds16Clicked"
                    onClick={() => handleChangeColor(15)}>
                    <LightTooltip
                      title="Paz, justiça e instituições fortes"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Animais />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
                {ods[16] === false
                  ?
                  <div className="divIconesOds17"
                    onClick={() => handleChangeColor(16)}>
                    <LightTooltip
                      title="Parcerias em prol das metas"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Quimica />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                  :
                  <div className="divIconesOds17Clicked"
                    onClick={() => handleChangeColor(16)}>
                    <LightTooltip
                      title="Parcerias em prol das metas"
                      placement="top-end"
                    >
                      <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                        <Quimica />
                      </SvgIcon>
                    </LightTooltip>
                  </div>
                }
              </div>
            </div>

            <div>
              <div className="subtitulos"> Indicadores </div>

              {indicadores.map((item, index) => (
                <div className="indicadoresCadastroUsuario">
                  <div className="divItem">{index + 1 + "º"}</div>
                  <FormControl className={classes.formControl} style={{marginTop: "16px"}}>
                    <TextField
                      value={item.indicador}
                      onChange={(event) =>
                        handleChange(event.target.value, index)
                      }
                    ></TextField>
                    <FormHelperText>Descreva um indicador</FormHelperText>
                  </FormControl>
                  <FormControl style={{ marginLeft: "16px" }}>
                    <InputLabel className={classes.formIcone}></InputLabel>
                    <Select
                      value={item.icone}
                      onChange={(event) =>
                        handleChangeIcone(event.target.value, index)
                      }
                    >
                      {indicadores.map((item, index) => (
                        <MenuItem key={index} value={index}>
                          {
                            <SvgIcon
                              style={{ fontSize: "20px", color: "#FFFFFF" }}
                            >
                              {getIcone(index)}
                            </SvgIcon>
                          }
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Icone</FormHelperText>
                  </FormControl>

                  <FormControl
                    style={{ marginTop: "16px", marginLeft: "16px" }}
                  >
                    <TextField
                      id="quantHoras"
                      type="number"
                      defaultValue={0}
                      value={item.valor}
                      onChange={(event) =>
                        handleChangeValor(event.target.value, index)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 4);
                      }}
                    />
                    <FormHelperText>Valor Agregado</FormHelperText>
                  </FormControl>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {funcaoPerfil === "Curador" || funcaoPerfil === "Administrador" ? (
          <div>
            {selectedImage === null
              ?
              <div>
                <Paper className={classes.importImage} variant="outlined">
                  <InputBase
                    fullWidth
                    disabled
                    placeholder="Imagem de perfil"
                  />
                  <label htmlFor={'upload-button'}>
                    <Upload />
                  </label>
                  <form encType="multipart/form-data" action="">
                    <input
                      type="file"
                      name="upload-button"
                      id="upload-button"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        
                        setSelectedImage(event.target.files[0]);
                      }}
                    />
                  </form>
                </Paper>
              </div>
              :
              <div>
                <Paper className={classes.importImage} variant="outlined">
                  <InputBase
                    fullWidth
                    value={selectedImage.name}
                    disabled
                    placeholder="Imagem de perfil"
                  />
                  <label htmlFor={'upload-button1'}>
                    <div>
                      <Upload />
                    </div>
                  </label>
                  <form encType="multipart/form-data" action="">
                    <input
                      type="file"
                      name="upload-button1"
                      id="upload-button1"
                      style={{ display: 'none' }}
                      onChange={(event) => {
                        
                        setSelectedImage(event.target.files[0]);
                      }}
                    />
                  </form>
                </Paper>
              </div>
            }
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="botaoCadastro">
        <Button
          className={classes.btnCadastrar}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => criarPerfil()}
        >
          Cadastrar
        </Button>
      </div>
    </div>
  );
}

export default App;
