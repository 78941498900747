import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import Blog from "./BlogProjeto";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide timeout={1000} direction="up" ref={ref} {...props} />;
});

export default function SimpleModal({ open, setOpen, id }) {
  const handleClose = () => {
    setOpen(false);
  };


  return (
    
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div>
        <Modal
          TransitionComponent={Transition}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ overflowY: "scroll" }}
        >
          <Slide direction="down" in={open} mountOnEnter unmountOnExit timeout={2000} >
            <div >
          <Blog id={id} setOpen={() => setOpen(false)}></Blog>
          </div>
          </Slide>
        </Modal>
      </div>
    </Slide>
  );
}
