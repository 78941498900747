import React, { useState } from "react";
import Indicadores from "./Indicadores";
import Sobre from "./Sobre";
import "./empresarial.css";

export default function Ciencia() {
    const [page, setPage] = useState("sobre");

    return (
        <div>
            {page === "sobre" ?
                <div>
                    <div className="backgroundPainelInovacaoEmpresarial">
                        <div className="divSelectContentInovacaoEmpresarial">
                            <div className="selectedContentInovacaoEmpresarial" onClick={() => setPage("sobre")}>
                                <div className="textMainInovacaoEmpresarial" style={{ marginRight: "16px" }}>Sobre</div>
                            </div>
                            <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                                <div className="textOffMainInovacaoEmpresarial">Indicadores</div>
                            </div>
                        </div>
                        <div className="textDescricaoInovacaoEmpresarial">
                            <div>Dados gerais e resultados das ações de apoio a projetos de produção e difusão do conhecimento científico e tecnológico.</div>
                        </div>
                    </div>
                    <Sobre />
                </div>
                : ""}
            {page === "indicadores" ?
                <div className="backgroundPainelInovacaoEmpresarial">
                    <div className="divSelectContentInovacaoEmpresarial">
                        <div className="selectedContentInovacaoEmpresarial" onClick={() => setPage("sobre")}>
                            <div className="textOffMainInovacaoEmpresarial" style={{ marginRight: "16px" }}>Sobre</div>
                        </div>
                        <div onClick={() => setPage("indicadores")} style={{ marginLeft: "16px" }}>
                            <div className="textMainInovacaoEmpresarial">Indicadores</div>
                        </div>
                    </div>
                    <div className="textDescricaoInovacaoEmpresarial">
                        <div>Seleção de indicadores pertinentes às ações de apoio a projetos empresariais para o desenvolvimento de produtos (bens ou serviços) e/ou processos inovadores.</div>
                    </div>
                    <Indicadores />
                </div>
                : ""}
        </div>
    )
}