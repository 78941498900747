import React, { useState } from "react";
import "./indicadores.css";
import InvestimentoApoioColaboracao from "./indicadores/indTeste/Indicador";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Area1 } from '../../../components/icons/areas/area1.svg';
import { ReactComponent as Area2 } from '../../../components/icons/areas/area2.svg';
import { ReactComponent as Area3 } from '../../../components/icons/areas/area3.svg';
import { ReactComponent as Area4 } from '../../../components/icons/areas/area4.svg';
import { ReactComponent as Area5 } from '../../../components/icons/areas/area5.svg';
import { ReactComponent as Area6 } from '../../../components/icons/areas/area6.svg';
import { ReactComponent as Area7 } from '../../../components/icons/areas/area7.svg';
import { ReactComponent as Area8 } from '../../../components/icons/areas/area8.svg';


export default function Indicadores() {

    const [area1, setArea1] = useState(false);
    const [area2, setArea2] = useState(false);
    const [area3, setArea3] = useState(false);
    const [area4, setArea4] = useState(false);
    const [area5, setArea5] = useState(false);
    const [area6, setArea6] = useState(false);
    const [area7, setArea7] = useState(false);
    const [area8, setArea8] = useState(false);

    function getClassname(value) {
        if (value) {
            return "roundSelectedCienciaInternacionalizacao"
        }
        else return "roundCienciaInternacionalizacao"
    }

    function getTextClassname(value) {
        if (value) {
            return "areaSelectedTitleCienciaInternacionalizacao"
        }
        else return "areaTitleCienciaInternacionalizacao"
    }

    return (
        <div>
            <div className="filtroAreasCienciaInternacionalizacao">
                <div className="divTextFiltroAreasCienciaInternacionalizacao">
                    <div className="nomeFiltroAreasCienciaInternacionalizacao">BUSCAR E MESCLAR DADOS</div>
                    <div className="legendaFiltroAreasCienciaInternacionalizacao">Pesquise por um termo ou filtre e(ou) mescle os dados, marcando os campos de interesse abaixo</div>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", paddingTop: "32px" }}>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area1)} onClick={() => setArea1(!area1)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area1 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area1)}>Ciências exatas e da terra</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area2)} onClick={() => setArea2(!area2)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area2 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area2)}>Ciências Biológicas</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area3)} onClick={() => setArea3(!area3)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area3 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area3)}>Engenharias</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area4)} onClick={() => setArea4(!area4)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area4 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area4)}>Ciências da Saúde</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area5)} onClick={() => setArea5(!area5)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area5 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area5)}>Ciências Agrárias</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area6)} onClick={() => setArea6(!area6)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area6 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area6)}>Ciências Sociais Aplicadas</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area7)} onClick={() => setArea7(!area7)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area7 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area7)}>Ciências Humanas</div>
                    </div>
                    <div className="divBorderCienciaInternacionalizacao">
                        <div className={getClassname(area8)} onClick={() => setArea8(!area8)}>
                            <SvgIcon style={{ fontSize: "50px", backgroundColor: "#FDCC09", color: "white" }}>
                                <Area8 />
                            </SvgIcon>
                        </div>
                        <div className={getTextClassname(area8)}>Linguística, Letras e Artes</div>
                    </div>
                </div>
            </div>
            <InvestimentoApoioColaboracao />
        </div>
    )
}