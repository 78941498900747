export default function toBRL(data, espChar = true) {

    data = parseFloat(data);

    if (espChar === false) {
        return data.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    }

    data = data.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    
    return data;
}