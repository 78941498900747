import React, { useContext } from "react";

//auth
import StoreContext from "../../components/Store/Context";

export default function AuthTeste() {

    const { setToken } = useContext(StoreContext);
    const { token } = useContext(StoreContext);

    function logar() {
        setToken("2hv4v13HV31vhvh3241hvhbhv");
    }

    function sair() {
        setToken(null);
    }


    return (
        <div>
            <div>Meu token: {token}</div>
            <div style={{ display: "flex" }}>
                <button onClick={logar} style={{ backgroundColor: "green", marginTop: "20px", cursor: "pointer" }}>Log in</button>
                <button onClick={sair} style={{ backgroundColor: "red", marginTop: "20px", marginLeft: "10px" ,cursor: "pointer" }}>Log out</button>
            </div>
        </div>

    )
}