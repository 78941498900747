import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import Blog from "./VisualizacaoBlog";

export default function SimpleModal({ open, setOpen, tipoBlog }) {

    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{ overflowY: "scroll" }}
          >
            <Slide direction="down" in={open} mountOnEnter unmountOnExit timeout={1500}>
              <div >
                <Blog setOpen={() => setOpen(false)} tipoBlog={tipoBlog}></Blog>
              </div>
            </Slide>
          </Modal>
        </div>
      </Slide>
  
    );
  }