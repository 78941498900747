import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import React from "react";
import { ReactComponent as Artigo } from "../conteudos/edicaoBlog/icons/artigo.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#008240",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  btnCompartilhar: {
    borderRadius: "4px",
    width: "173px",
    height: "36px",
    color: "#405965",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

function App({ setOpen, realizacao }) {

  
  const classes = useStyles();

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }

  function clicarNoLink(link) {
    window.open(link, "_blank");
  }

  return (
    <div className="blogRealizacaol">
      {typeof realizacao !== "undefined" ?
        <div>
          <div className="divblogRealizacaolVejaMais">
            <div className="conteudoPrincipalRealizacao">
              <div className="blogTituloRealizacoesl">
                <div>
                  <h1>{realizacao.titulo}</h1>
                </div>
              </div>
              <div className="autorRealizacoesl">
                <div style={{ color: "#20764B" }}>
                  {/*realizacao.autor_realizacao.nome*/}
                </div>
                <p style={{ color: "#405965", marginLeft: "2px" }}>Postado em {getData(realizacao.createdAt, "dia")}/{getData(realizacao.createdAt, "mes")}/{getData(realizacao.createdAt, "ano")}</p>
              </div>

              <div className="conteudoRealizacoesl">
                <div>
                  <img src={realizacao.capa} alt="" className="imagemRealizacao"></img>
                </div>
              </div>
              <div className="conteudoRealizacoesl">
                <div>
                  {parse(realizacao.conteudo)}
                </div>
              </div>
              {realizacao.links_relacionados.length > 0 ?
                <div>
                  <h2 className="citacaoRealizacoesl">Citação na Mídia</h2>
                  {realizacao.links_relacionados.map((link) => (
                    <div className="linkcitacaoRealizacoesl" onClick={() => clicarNoLink(link.link)}>
                      • {link.label}
                    </div>
                  ))}
                </div>
                : <></>
              }
              {realizacao.exibir_anexo
                ?
                <div>
                  <h2 className="citacaoRealizacoesl" style={{ marginTop: "32px" }}>
                    Anexo{" "}
                  </h2>
                  <div className="anexosRealizacoesl" style={{ marginLeft: "12px" }} onClick={() => clicarNoLink(realizacao.anexo)}>
                    <SvgIcon style={{ fontSize: "16px", color: "#008240" }}>
                      <Artigo />
                    </SvgIcon>
                    <div className="artigoTituloRealizacoesl">Artigo</div>
                  </div>
                </div>
                : <></>}
            </div>
          </div>
        </div>
        : <></>
      }
    </div>
  );
}

export default App;
