import { TabPanelDisabled, AntTabsDisabled, AntTabDisabled } from "../DisabledTabs";
import React from "react";
import Loading from "../icons/loading.gif";
import Paper from "@material-ui/core/Paper";
import "./loading.css";


export default function LoadingPainel(props) {

    const value = 0;

    return (
        <div style={{marginLeft: "5vw", marginRight: "5vw", paddingTop: "50px"}}>
            <div style={{ display: "flex" }}>
                <p style={{ color: "#FDCC09", fontWeight: "bold", marginRight: "1vw" }}>{props.title}</p>
            </div>
            <AntTabsDisabled aria-label="ant example">
                <AntTabDisabled label="Mapa de calor" />
                <AntTabDisabled label="Gráfico circular" />
                <AntTabDisabled label="Gráfico de barras" />
                <AntTabDisabled label="Tabela de dados" />
            </AntTabsDisabled>
            <Paper elevation={1} style={{ backgroundColor: "transparent" }}>
                <TabPanelDisabled value={value} index={0}>
                    <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                </TabPanelDisabled>
                <TabPanelDisabled value={value} index={1}>
                    <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                </TabPanelDisabled>
                <TabPanelDisabled value={value} index={2}>
                    <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                </TabPanelDisabled>
                <TabPanelDisabled value={value} index={3}>
                    <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                </TabPanelDisabled>
            </Paper>
        </div >
    )
}