import DateFnsUtils from '@date-io/date-fns';
import Button from "@material-ui/core/Button";
import MuiDialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from '@material-ui/core/Grid';
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { CKEditor } from "ckeditor4-react";
import 'date-fns';
import ptLocale from "date-fns/locale/pt";
import React, { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../components/Store/Context";
import { ReactComponent as AddLink } from "../../../../components/icons/cadastro/addLink.svg";
import { ReactComponent as Upload } from "../../../../components/icons/cadastro/upload.svg";
import Portas from "../../../../portas";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {},
  },
  button: {
    margin: theme.spacing(1),
  },
  btnCadastrar: {
    backgroundColor: "#008240",
    borderRadius: "10px",
    width: "140px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  formWidth: {
    width: "300px",
  },
  formWidthText: {
    width: "100%",
  },

  divFormCitacaoMidia: {
    margin: "24px",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  },
  divFormRedesSociais: {
    marginLeft: "24px",
    marginRight: "24px",
  },
  formControl: {
    minWidth: 680,
  },
  formIcone: {
    marginRight: "16px",
    minWidth: 84,
  },
  importImage: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 965,
    height: "54px",
    left: "1px",
    top: "1px",
    margin: "25px",
    borderRadius: "3.5px",
    marginTop: "52px",
  },
  importAnexo: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "54px",
    left: "1px",
    top: "1px",
    marginLeft: "25px",
    marginRight: "25px",
    borderRadius: "3.5px",
  },
  margin: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#20764B",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    marginRight: "24px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

function App({ setOpen, projeto }) {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [areas, setAreas] = useState([]);
  const [areaRelacionada, setAreaRelacionada] = React.useState(projeto.area_projeto.area);
  const [citacaoMidia, setCitacaoMidia] = React.useState(projeto.citacoes);
  const [conteudoMidia, setConteudoMidia] = React.useState("");
  const [tituloProjeto, setTituloProjeto] = useState(projeto.titulo);
  const [pretituloProjeto, setPreTituloProjeto] = useState(projeto.titulo);
  const [dataInicioProjeto, setDataInicioProjeto] = useState(new Date(projeto.data_inicio));
  const [dataConclusaoProjeto, setDataConclusaoProjeto] = useState(new Date(projeto.data_fim));
  const [editorData, setEditorData] = useState(projeto.conteudo);
  const [selectedImageCapaProjeto, setSelectedImageCapaProjeto] = useState(null);
  const [selectedAnexoProjeto, setSelectedAnexoProjeto] = useState(null);
  const [equipeEnvolvida, setEquipeEnvolvida] = useState(projeto.equipe);
  const [nomeBolsista, setNomeBolsista] = useState("")
  const [lattesBolsista, setLattesBolsista] = useState("")
  const [linksRelacionados, setLinksRelacionados] = useState(projeto.citacoes);
  const [linkUrl, setLinkUrl] = useState("")
  const [tituloLink, setTituloLink] = useState("")
  const [emptyValue, SetEmptyValue] = useState(false);

  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const addInputLink = (event) => {
    setLinksRelacionados([...linksRelacionados, { link: linkUrl, label: tituloLink }]);
    setLinkUrl("");
    setTituloLink("");
  };

  const handleRemoveInputLink = (position) => {
    var auxLinks = [];
    for (var i = 0; i < linksRelacionados.length; i++) {
      if (i !== position) {
        auxLinks.push(linksRelacionados[i]);
      }
    }
    setLinksRelacionados(auxLinks);
  };

  function handleChangeTituloLinks(value, index) {
    let aux = [...linksRelacionados];
    aux[index].label = value;
    setLinksRelacionados(aux);
  }

  function handleChangeLinkUrl(value, index) {
    let aux = [...linksRelacionados];
    aux[index].link = value;
    setLinksRelacionados(aux);
  }

  const handleDateInicioChange = (date) => {
    setDataInicioProjeto(date);
  };

  const handleDateConclusaoChange = (date) => {
    setDataConclusaoProjeto(date);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const addInputCitacaoMidia = (event) => {
    setCitacaoMidia([...citacaoMidia, conteudoMidia]);
    setConteudoMidia("");
  };

  const handleRemoveInputCitacaoMidia = (position) => {
    var auxCitacaoMidia = [];
    for (var i = 0; i < citacaoMidia.length; i++) {
      if (i !== position) {
        auxCitacaoMidia.push(citacaoMidia[i]);
      }
    }
    setCitacaoMidia(auxCitacaoMidia);
  };

  function handleChangeCitacaoMidia(value, index) {
    var auxCitacaoMidia = [];
    for (var i = 0; i < citacaoMidia.length; i++) {
      if (i === index) {
        auxCitacaoMidia.push(value);
      } else {
        auxCitacaoMidia.push(citacaoMidia[i]);
      }
    }
    setCitacaoMidia(auxCitacaoMidia);
  }

  const [value, setValue] = useState("teste");

  const addInputEquipeEnvolvida = (event) => {
    setEquipeEnvolvida([...equipeEnvolvida, { nome: nomeBolsista, lattes: lattesBolsista }]);
    setNomeBolsista("");
    setLattesBolsista("");
  };

  const handleRemoveInputEquipeEnvolvida = (position) => {
    var auxEquipeEnvolvida = [];
    for (var i = 0; i < equipeEnvolvida.length; i++) {
      if (i !== position) {
        auxEquipeEnvolvida.push(equipeEnvolvida[i]);
      }
    }
    setEquipeEnvolvida(auxEquipeEnvolvida);
  };

  function handleChangeNomeEquipeEnvolvida(value, index) {
    let aux = [...equipeEnvolvida];
    aux[index].nome = value;
    setEquipeEnvolvida(aux);
  }

  function handleChangeLattesEquipeEnvolvida(value, index) {
    let aux = [...equipeEnvolvida];
    aux[index].lattes = value;
    setEquipeEnvolvida(aux);
  }

  const CriarDropdowns = async () => {
    var areas = [];

    try {
      const response = await fetch(Portas().serverHost + "/areas",
        {
          method: "GET",
          headers: {
            'Authorization': "Bearer " + token,
          }
        }
      );

      const resJSON1 = await response.json();
      if (resJSON1.length > 0) {
        for (var i = 0; i < resJSON1.length; i++) {
          areas.push({ "value": resJSON1[i].area, "label": resJSON1[i].area, "id": resJSON1[i].id })
        }
        setAreas(areas)
      }
    }
    catch (err) {

    }

  }

  const editar = async () => {

    //validacoes

    if (tituloProjeto.length < 1) {
      alert("Insira um titulo!")
      SetEmptyValue(true);
      return;
    }

    //date format fix
    let yeIni = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dataInicioProjeto);
    let moIni = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(dataInicioProjeto);
    let daIni = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(dataInicioProjeto);
    let yeFim = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(dataConclusaoProjeto);
    let moFim = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(dataConclusaoProjeto);
    let daFim = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(dataConclusaoProjeto);

    if (moIni < 10) {
      moIni = "0" + moIni
    }

    if (moFim < 10) {
      moFim = "0" + moFim
    }

    if (daIni < 10) {
      daIni = "0" + daIni
    }

    if (daFim < 10) {
      daFim = "0" + daFim
    }

    var dataIni = yeIni + "-" + moIni + "-" + daIni
    var dataFim = yeFim + "-" + moFim + "-" + daFim

    if (editorData.length < 1) {
      alert("Insira um conteudo!")
      SetEmptyValue(true);
      return;
    }


    if (equipeEnvolvida.length < 1) {
      alert("Insira pelo menos um membro da equipe")
      SetEmptyValue(true);
      return;
    }

    var idArea = "";

    for (var i = 0; i < areas.length; i++) {
      if (areaRelacionada === areas[i].value) {
        idArea = areas[i].id;
      }
    }

    //const formData = new FormData()
    //formData.append('titulo', tituloProjeto)
    //formData.append('data_inicio', dataIni)
    //formData.append('data_fim', dataFim)
    //formData.append('capa', selectedImageCapaProjeto)
    //formData.append('conteudo', editorData)
    //formData.append('equipe', JSON.stringify(equipeEnvolvida))
    //formData.append('capital', 0)
    //formData.append('citacoes', citacaoMidia)
    //formData.append('anexo', selectedAnexoProjeto)
    //formData.append('id_area', idArea)
    //formData.append('exibir_anexo', values.showPassword)

    var retJson = {}

    if (pretituloProjeto !== tituloProjeto) {
      retJson["titulo"] = tituloProjeto
    }

    retJson["data_inicio"] = dataIni
    retJson["data_fim"] = dataFim
    retJson["equipe"] = equipeEnvolvida
    retJson["citacoes"] = linksRelacionados
    retJson["exibir_anexo"] = values.showPassword + ""
    retJson["conteudo"] = editorData


    try {
      const response = await fetch(Portas().serverHost + "/projetos/" + projeto.id,
        {
          method: "PUT",
          headers: {
            'Authorization': "Bearer " + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(retJson)
        }
      );
      const resJSON = await response.json();

      if (selectedImageCapaProjeto !== null) {

        const formData = new FormData()
        formData.append('imagem_capa', selectedImageCapaProjeto)

        const response1 = await fetch(Portas().serverHost + "/projetos/capa/" + projeto.id,
          {
            method: "PATCH",
            headers: {
              'Authorization': "Bearer " + token
            },
            body: formData
          }
        );

        const resJSON1 = await response1.json();

      }

      if (selectedAnexoProjeto !== null) {

        const formData = new FormData()
        formData.append('anexo', selectedAnexoProjeto)

        const response1 = await fetch(Portas().serverHost + "/projetos/anexo/" + projeto.id,
          {
            method: "PATCH",
            headers: {
              'Authorization': "Bearer " + token
            },
            body: formData
          }
        );

        const resJSON1 = await response1.json();
      }

      if (typeof resJSON.id !== "undefined") {
        const response2 = await fetch(Portas().serverHost + "/revisoes/projeto/resubmit/" + projeto.id,
          {
            method: "PUT",
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            }
          }
        );

        const resJSON2 = await response2.json();
        // alert(resJSON2.message)
        alert("Projeto submetido!")
        window.location.reload(false)
        return;
      }
      else if (typeof resJSON.message !== "undefined") {
        alert(resJSON.message)
        return;
      }
      else {
        alert("Um erro ocorreu, tente novamente...")
      }

    }
    catch (err) {

    }

  }

  useEffect(() => {
    CriarDropdowns()
  }, []);


  return (
    <DialogContent className="pageCadastroRealizacao">
      <div style={{ display: "flex", justifyItems: "center", alignItems: "center", justifyContent: "space-between" }}>
        <div className="tituloPageRealizacao">Editar Projeto</div>
        <Button
          className={classes.btnVoltar}
          variant="contained"
          startIcon={<ArrowBackIosIcon />}
          onClick={() => setOpen(false)}
        >
          Voltar
        </Button>
      </div>
      <div>
        <div className={classes.divFormRedesSociais} style={{ marginBottom: "15px" }}>
          <FormControl className={classes.formWidthText}>
            <InputLabel required>Área relacionada</InputLabel>
            {areas.length > 0 ?
              <Select
                disabled
                value={areaRelacionada}
                onChange={(event) => setAreaRelacionada(event.target.value)}
              >
                {areas.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              : <></>}
            <FormHelperText>
              Selecione em que área o post deverá aparecer e ser vinculado{" "}
            </FormHelperText>
          </FormControl>

          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              value={tituloProjeto}
              onChange={(event) => setTituloProjeto(event.target.value)}
              required
              label="Título do Projeto"
              fullWidth
            />
            <FormHelperText>
              O título acima será vinculado a todos os blogs relacionados ao
              projeto
            </FormHelperText>
            {emptyValue && !tituloProjeto ? 
            <span style={{ color: "red", fontSize:"12px" }}>Campo obrigatório</span>: ""}
          
          </form>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            justifyItems: "Center",
            alignContent: "center",
            alignItems: "center",
            marginBottom: "32px",
          }}
        >
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  fullWidth
                  label="Data de inicio do projeto"
                  format="dd/MM/yyyy"
                  value={dataInicioProjeto}
                  onChange={handleDateInicioChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <FormHelperText>
              Não relacionado a data de postagem na plataforma
            </FormHelperText>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  fullWidth
                  label="Data de conclusão do projeto"
                  format="dd/MM/yyyy"
                  value={dataConclusaoProjeto}
                  onChange={handleDateConclusaoChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <FormHelperText>
              Peencha o campo futuramente quando concluir o projeto
            </FormHelperText>
          </div>
        </div>
        {selectedImageCapaProjeto === null ? (
          <div>
            <Paper className={classes.importImage} variant="outlined">
              <InputBase
                fullWidth
                disabled
                placeholder="Imagem da Capa"
              />
              <label htmlFor={"upload-button"}>
                <Upload />
              </label>
              <form encType="multipart/form-data" action="">
                <input
                  type="file"
                  accept="image/*"
                  name="upload-button"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={(event) => {

                    setSelectedImageCapaProjeto(event.target.files[0]);
                  }}
                />
              </form>
            </Paper>
          </div>
        ) : (
          <div>
            <Paper className={classes.importImage} variant="outlined">
              <InputBase
                fullWidth
                value={selectedImageCapaProjeto.name}
                disabled
                placeholder="Imagem da Capa"
              />
              <label htmlFor={"upload-button1"}>
                <div>
                  <Upload />
                </div>
              </label>
              <form encType="multipart/form-data" action="">
                <input
                  type="file"
                  accept="image/*"
                  name="upload-button1"
                  id="upload-button1"
                  style={{ display: "none" }}
                  onChange={(event) => {

                    setSelectedImageCapaProjeto(event.target.files[0]);
                  }}
                />
              </form>
            </Paper>
          </div>
        )}

        <div
          className="subtitulosCadastroProjeto"
          style={{ marginTop: "49px", marginBottom: "16px" }}
        >
          Conteúdo
        </div>
        
          <CKEditor
            initData={editorData}
            onChange={(event) => setEditorData(event.editor.getData())}
            config={{
              language: "pt-br",
              uiColor: "#FFFFFF",
              toolbarCanCollapse: true,
              toolbarGroups: [
                { name: "clipboard", groups: ["clipboard", "undo"] },
                // { name: "styles", groups: ["styles"] },
                { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                {
                  name: "paragraph",
                  groups: [
                    "align",
                    "list",
                    "indent",
                    "blocks",
                    "bidi",
                    "paragraph",
                  ],
                },
                {
                  name: "editing",
                  groups: ["find", "selection", "spellchecker", "editing"],
                },
                { name: "links", groups: ["links"] },
                { name: "insert", groups: ["insert"] },
                { name: "forms", groups: ["forms"] },
                {
                  name: "document",
                  groups: ["mode", "document", "doctools"],
                },
                { name: "others", groups: ["others"] },
                // { name: "colors", groups: ["colors"] },
                { name: "about", groups: ["about"] },
                { name: "tools", groups: ["tools"] },
              ],
              extraPlugins: "justify, font",
              removeButtons:
                "FontSize,Subscript,Superscript,Scayt,PasteText,PasteFromWord,Anchor,Strike,RemoveFormat,Source,About,Styles, Font, Link",
            }}
            onInstanceReady={() => { }}
          />
           {emptyValue && !editorData ? 
            <span style={{ color: "red", fontSize:"12px" }}>Campo obrigatório</span>: ""}
       

        <div
          className="subtitulosCadastroProjeto"
          style={{ marginTop: "32px" }}
        >
          Relacionados
        </div>
        <div>
          {linksRelacionados.map((link, index) => (
            <div className="divequipeEnvolvida">
              <div style={{ width: "50%", marginRight: "32px" }}>
              <form noValidate autoComplete="off">
                  <TextField
                    label="Titulo"
                    value={link.label}
                    onChange={(event) => {
                      handleChangeTituloLinks(event.target.value, index)
                    }}
                  fullWidth/>
                  <FormHelperText>Preencha este campo com um título</FormHelperText>
                </form>
              </div>
              <div className="divequipeEnvolvidaLinkCurriculoLattes">
                <FormControl fullWidth>
                <InputLabel>Link: Citação na mídia</InputLabel>
                  <Input
                    value={link.link}
                    onChange={(event) => {
                      handleChangeLinkUrl(event.target.value, index)
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        type="submit">
                        <AddLink />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText>Preencha este campo com uma URL</FormHelperText>
                </FormControl>
                <IconButton
                  onClick={() => {
                    handleRemoveInputLink(index);
                  }}
                  type="submit"
                  className={classes.iconButton}
                  style={{ fontSize: "17px", color: "#405965" }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </div>

        <div className="divequipeEnvolvida">
          <div style={{ width: "50%", marginRight: "32px" }}>
            <form noValidate autoComplete="off">
                  <TextField
                    label="Título"
                    value={tituloLink}
                    onChange={(event) => {
                      setTituloLink(event.target.value);
                    }}
                    fullWidth/>
              <FormHelperText>Preencha este campo com um título</FormHelperText>
            </form>
          </div>
          <div className="divequipeEnvolvidaLinkCurriculoLattes">
            <FormControl fullWidth>
              <InputLabel>Link: Citação na mídia</InputLabel>
              <Input
                value={linkUrl}
                onChange={(event) => {
                  setLinkUrl(event.target.value);
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    type="submit">
                    <AddLink />
                  </InputAdornment>
                }
              />
              <FormHelperText>Preencha este campo com uma URL</FormHelperText>
            </FormControl>
             
            <IconButton
              onClick={addInputLink}
              type="submit"
              className={classes.iconButton}
              style={{ fontSize: "17px", color: "#405965" }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>

        <div
          className="subtitulosCadastroProjeto"
          style={{ marginTop: "32px" }}
        >
          Equipe Envolvida
        </div>

        {equipeEnvolvida.map((membro, index) => (
          <div className="divequipeEnvolvida">
            <div style={{ width: "50%", marginRight: "32px" }}>
              <form noValidate autoComplete="off">
                <TextField
                  value={equipeEnvolvida[index].nome}
                  label="Nome da Bolsista"
                  onChange={(event) => {
                    handleChangeNomeEquipeEnvolvida(event.target.value, index);
                  }}
                  fullWidth />
              </form>
            </div>
            <div className="divequipeEnvolvidaLinkCurriculoLattes">
              <FormControl fullWidth>
                <InputLabel>Link: Currículo Lattes</InputLabel>
                <Input
                  value={equipeEnvolvida[index].lattes}
                  onChange={(event) => {
                    handleChangeLattesEquipeEnvolvida(event.target.value, index);
                  }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      type="submit">
                      <AddLink />
                    </InputAdornment>
                  }
                />
                <FormHelperText>Preencha este campo com uma URL</FormHelperText>
              </FormControl>
              <IconButton
                onClick={() => {
                  handleRemoveInputEquipeEnvolvida(index);
                }}
                type="submit"
                className={classes.iconButton}
                style={{ fontSize: "17px", color: "#405965" }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}

        <div className="divequipeEnvolvida">
          <div style={{ width: "50%", marginRight: "32px" }}>
            <form noValidate autoComplete="off">
              <TextField
                value={nomeBolsista}
                onChange={(event) => {
                  setNomeBolsista(event.target.value);
                }}
                label="Nome da Bolsista"
                fullWidth />
            </form>
            {emptyValue && !nomeBolsista ? 
            <span style={{ color: "red", fontSize:"12px" }}>Campo obrigatório</span>: ""}
          </div>
          <div className="divequipeEnvolvidaLinkCurriculoLattes">
            <FormControl fullWidth>
              <InputLabel>Link: Currículo Lattes</InputLabel>
              <Input
                value={lattesBolsista}
                onChange={(event) => {
                  setLattesBolsista(event.target.value);
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    type="submit">
                    <AddLink />
                  </InputAdornment>
                }
              />
              <FormHelperText>Preencha este campo com uma URL</FormHelperText>
            </FormControl>
            <IconButton
              onClick={addInputEquipeEnvolvida}
              type="submit"
              className={classes.iconButton}
              style={{ fontSize: "17px", color: "#405965" }}
            >
              <AddIcon />
            </IconButton>
          </div>
         
        </div>
        {selectedAnexoProjeto === null ?
          <div style={{ marginTop: "32px" }}>
            <div className="divAnexoProjeto">
              <Paper className={classes.importAnexo} variant="outlined" style={{ width: "90%" }}>
                <InputBase
                  fullWidth
                  disabled
                  placeholder="Anexo"
                />
                <label htmlFor={"upload-button2"}>
                  <Upload />
                </label>
                <form encType="multipart/form-data" action="">
                  <input
                    type="file"
                    name="upload-button2"
                    id="upload-button2"
                    style={{ display: "none" }}
                    onChange={(event) => {

                      setSelectedAnexoProjeto(event.target.files[0]);
                    }}
                  />
                </form>
              </Paper>
              <IconButton
                style={{ fontSize: "17px", color: "#405965" }}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <IconButton
                type="submit"
                className={classes.iconButton}
                style={{ fontSize: "17px", color: "#405965" }}
                onClick={() => setSelectedAnexoProjeto(null)}
              >
                <DeleteIcon />
              </IconButton>

            </div>
            <FormHelperText style={{ marginLeft: "25px" }}>
              PDF, PNG ou JPEG{" "}
            </FormHelperText>
          </div>
          :
          <div style={{ marginTop: "32px" }}>
            <div className="divAnexoProjeto">
              <Paper className={classes.importAnexo} variant="outlined" style={{ width: "90%" }}>
                <InputBase
                  fullWidth
                  value={selectedAnexoProjeto.name}
                  disabled
                  placeholder="Anexo"
                />
                <label htmlFor={"upload-button3"}>
                  <div>
                    <Upload />
                  </div>
                </label>
                <form encType="multipart/form-data" action="">
                  <input
                    type="file"
                    name="upload-button3"
                    id="upload-button3"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setSelectedAnexoProjeto(event.target.files[0]);
                    }}
                  />
                </form>
              </Paper>
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ fontSize: "17px", color: "#405965" }}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <IconButton
                type="submit"
                className={classes.iconButton}
                style={{ fontSize: "17px", color: "#405965" }}
                onClick={() => setSelectedAnexoProjeto(null)}
              >
                <DeleteIcon />
              </IconButton>

            </div>
            <FormHelperText style={{ marginLeft: "25px" }}>
              PDF, PNG ou JPEG{" "}
            </FormHelperText>
          </div>
        }
      </div>

      <div className="botaoCadastroCooperacao">
        <Button
          className={classes.btnCadastrar}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => editar()}
        >
          Submeter
        </Button>
      </div>
    </DialogContent>
  );
}

export default App;
