import SvgIcon from "@material-ui/core/SvgIcon";
import React, { useContext, useEffect, useState } from "react";
import SimpleModal from "../edicao/ModalEdicaoUsuario";
import "./lista.css";

//table
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { createStyles, lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Portas from "../../../../portas";

//auth
import StoreContext from "../../../../components/Store/Context";

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import substringColor from "../../../../components/funcoes/substringColor";
import { ReactComponent as Balao } from "../../../../components/icons/gerenciamento/balao.svg";
import { ReactComponent as Caneta } from "../../../../components/icons/gerenciamento/caneta.svg";
import { ReactComponent as Filtro } from "../../../../components/icons/gerenciamento/filtro.svg";
import { ReactComponent as FiltroPreenchido } from "../../../../components/icons/gerenciamento/filtroPreenchido.svg";

const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965"
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#405965',
        },
        '&:hover .MuiInput-underline:before': {
            borderBottomColor: '#20764B',
        },
        '& .Mui-focused:after': {
            borderBottomColor: '#20764B',
        },
    },
})(TextField);

const GreenSwitch = withStyles({
    switchBase: {
        color: "gray",
        '&$checked': {
            color: "#20764B",
        },
        '&$checked + $track': {
            backgroundColor: "gray",
        },
    },
    checked: {},
    track: {},
})(Switch);


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'area', numeric: true, disablePadding: false, label: 'Área' },
    { id: 'perfil', numeric: true, disablePadding: false, label: 'Perfil' },
    { id: 'editar', numeric: true, disablePadding: false, label: 'Editar' },
    { id: 'desativar', numeric: true, disablePadding: false, label: 'Ativar' }
];

const StyledTableSortLabel = withStyles(() =>
    createStyles({
        root: {
            color: '#20764B',
            "&:hover": {
                color: '#20764B',
            },
            '&$active': {
                color: '#20764B',
            },
        },
        active: {},
        icon: {
            color: "#20764B !important"
        }
    })
)(TableSortLabel);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}


function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow >
                <TableCell padding="checkbox" >
                    <Checkbox
                        style={{ color: "#20764B" }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ color: "#20764B" }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id == "name"
                            ?
                            <StyledTableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </StyledTableSortLabel>
                            :
                            <div>
                                {headCell.label}
                            </div>

                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    return (
        <div></div>
    )

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: "32px",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
}));


export default function Lista() {

    const { token } = useContext(StoreContext);
    const [rows, setRows] = useState([]);
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [open, setOpen] = useState(false);
    const [selectedUsuario, setSelectedUsuario] = useState("");
    const [textOpened, setTextOpened] = useState(false);
    const [searchField, setSearchField] = useState("");
    const [openFilter, setOpenFilter] = useState(false);
    const [selectFilter, setSelectFilter] = useState(false);
    const [filtro, setFiltro] = useState([]);
    const [fullData, setFullData] = useState([]);
   
    const handleClose = () => {
        setSelectFilter(false)
    }
    
    const handleOpen = () => {
        setSelectFilter(true)
    }   

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    function handleClickEdit(row) {
        setOpen(true)
        setSelectedUsuario(row)
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


    const handleChange = (event) => {

        let newRows = [...rows]

        for (var i = 0; i < newRows.length; i++) {
            if (newRows[i].nome === event.target.name) {
                gerenciarUsuarios(newRows[i].id, !newRows[i].status)
                newRows[i].status = !newRows[i].status;
                setRows(newRows);
            }
        }
    };


    const getStyle = (status) => {
        if (status === false) {
            return ({ opacity: 0.5 });
        }

        return {};
    }

    const gerenciarUsuarios = async (userid = "", operacao = true) => {
        if (token !== null) {
            if (operacao === true) {
                try {
                    const response = await fetch(Portas().serverHost + "/usuarios/ativar/" + userid,
                        {
                            method: "POST",
                            headers: {
                                'Authorization': "Bearer " + token
                            }
                        }
                    );

                    const resJSON = await response.json();
                    


                }
                catch (err) {
                    
                }
            }
            else {
                try {
                    const response = await fetch(Portas().serverHost + "/usuarios/desativar/" + userid,
                        {
                            method: "POST",
                            headers: {
                                'Authorization': "Bearer " + token
                            }
                        }
                    );

                    const resJSON = await response.json();
                    

                }
                catch (err) {
                    
                }
            }

        }
    }

    const getUsers = async () => {
        if (token !== null) {
            try {
                const response = await fetch(Portas().serverHost + "/usuarios?cientista=true&acesso=true&pagina=0&tamanho=5&image=true&paginacao=false",
                    {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + token
                        }
                    }
                );

                const resJSON = await response.json();
                
                setRows(resJSON)
                setFullData(resJSON)

            }
            catch (err) {
                
            }

        }
    }

    const handleSearch = async (text) => {
        if (token !== null) {
            try {
                const response = await fetch(Portas().serverHost + "/usuarios?cientista=true&acesso=true&pagina=0&tamanho=5&image=true&paginacao=false",
                    {
                        method: "GET",
                        headers: {
                            'Authorization': "Bearer " + token
                        }
                    }
                );

                const resJSON = await response.json();

                let arr = []
                for (var i = 0; i < resJSON.length; i++) {
                    if (resJSON[i].nome.toLowerCase().includes(text.toLowerCase())) {
                        arr.push(resJSON[i])
                    }
                }
                
                setRows(arr);
                setSearchField(text);

            }
            catch (err) {
                
            }

        }
    }

    const handleChangeFilter = (event) => {
        setFiltro(event.target.value);
        let filtered = [];
        var filtros = event.target.value;

        if (filtros.length < 1) {
            setRows(fullData)
            return
        }

        for (var i = 0; i < fullData.length; i++ ) {            
            for( var j = 0; j < filtros.length; j++) {
                if (fullData[i].perfil_acesso.acesso == 'Administrador' && filtros[j] == 'Administrador') {
                    filtered.push(fullData[i]);
                    break
                }
                if (fullData[i].perfil_acesso.acesso == 'Curador' && filtros[j] == 'Curador') {
                    filtered.push(fullData[i]);
                    break
                }
                if (fullData[i].perfil_acesso.acesso == 'Cientista-Chefe' && filtros[j] == 'Cientista-Chefe') {
                    filtered.push(fullData[i]);
                    break
                }
                
            }

        }
       
        setRows(filtered)
      };
     

    const names  = [
        {
            value:'Cientista-Chefe',
            label: 'Cientista-Chefe'
        },
        {
            value:'Curador',
            label: 'Curador'
        },
        {
            value:'Administrador',
            label: 'Administrador'   
        },
    ];

    const handleSelectFilter = () => {
         setOpenFilter(true)
         setSelectFilter(true)
    }


    useEffect(() => {
        getUsers()
    }, []);

    return (
        <div className="divConteudoGerenciamentoUsuariosLista">
            {open && selectedUsuario !== ""
                ?
                <SimpleModal user={selectedUsuario} open={open} setOpen={() => setOpen(!open)}></SimpleModal>
                :
                <></>
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="tituloGerenciamentoUsuariosLista">Lista</div>
                <div style={{ display: "flex" }}>
                {textOpened
                        ?
                        <CssTextField
                            id='searchfield'
                            style={{ width: "300px" }}
                            placeholder={"Buscar usuário"}
                            onChange={(event) => handleSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        :
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => setTextOpened(true)}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <Balao />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Buscar</div>
                        </div>
                    }
                     {openFilter? 
                      <FormControl style={{width:"200px", marginLeft:"20px"}}>
                        <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={filtro}
                        open={selectFilter}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onChange={handleChangeFilter}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        >
                        {names.map((name) => (
                            <MenuItem key={name.value} value={name.value}>
                                {filtro.includes(name.value) ?
                                <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                    <FiltroPreenchido />
                                </SvgIcon>
                                :
                                <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                    <Filtro />
                                </SvgIcon>
                                }
                                <ListItemText primary={name.label} style={{marginLeft:"10px"}}/>
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    :
                    <div style={{ display: "flex", cursor: "pointer", marginLeft:"20px" }} onClick={handleSelectFilter}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <Filtro />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Filtrar</div>
                        </div>
                    }
                </div>
            </div>
            <div style={{ marginTop: "32px" }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.nome}
                                            selected={isItemSelected}
                                            style={getStyle(row.desativar)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.name)}
                                                    style={{ color: "#20764B" }}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                            {substringColor(row.nome.toUpperCase(), searchField.toUpperCase(), 1)}
                                                {/* {row.nome} */}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.perfil_acesso.acesso === "Cientista-Chefe"
                                                    ? row.cientista.area_cientista.area
                                                    : "N/A"
                                                }
                                            </TableCell>
                                            <TableCell align="right">{row.perfil_acesso.acesso}</TableCell>
                                            <TableCell align="right">
                                                <SvgIcon style={{ fontSize: "15px", color: "#20764B" }}  onClick={() => handleClickEdit(row)}>
                                                    <Caneta />
                                                </SvgIcon>
                                            </TableCell>
                                            <TableCell align="right">
                                                <GreenSwitch checked={row.status} onChange={(event) => handleChange(event)} name={row.nome} status={row.status}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="textQuantidadeConteudoGerenciamentoUsuariosLista">{selected.length + " Usuários"}</div>
                    <TablePagination
                        style={{ color: "#20764B" }}
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={rows.length}
                        labelRowsPerPage="Linhas por página"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>

            </div>
        </div>
    )
}