import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { useState } from "react";
import BtnCompartilhar from "../../components/btnCompartilhar/BtnCompartilhar";
import SimpleModal from "./ModalBlogVejaMais";
import "./blogRealizacao.css";
import { ReactComponent as Artigo } from "./icons/artigo.svg";
import Img1 from "./img/img1.png";
import Img2 from "./img/img2.png";
import Img3 from "./img/img3.png";
import Img4 from "./img/img4.png";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#008240",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  btnCompartilhar: {
    borderRadius: "4px",
    width: "173px",
    height: "36px",
    color: "#405965",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

function App({ setOpen }) {
  const classes = useStyles();

  ;

  function clicarNoLink() {
    //window.location="http://www.google.com.br"
    window.open("http://www.google.com.br", "_blank");
  }
  const [selectedBlogVejaMais, setSelectedBlogVejaMais] = useState("");
  const [open2, setOpen2] = useState(false)

  function handleClick(titulo){
    setSelectedBlogVejaMais(titulo)
    setOpen2(true)
  }


  return (
    <div className="blogRealizacaol">
       {open2 ? (
        <SimpleModal
          open={open2}
          setOpen={() => setOpen2(!open2)}
          selectedBlogVejaMais={selectedBlogVejaMais}></SimpleModal>
      ) : (
        <></>
      )}
      <div className="blogCabecalhoRealizacoesl">
        <div className="divVoltarCompartilharRealizacao">
          <Button
            className={classes.btnVoltar}
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>

          <Breadcrumbs>
            <Link className="blogCabecalhoTextRealizacoesl" href="/">
              CIENTISTA CHEFE DE INOVAÇÃO
            </Link>
            <Link
              className="blogCabecalhoTextRealizacoesl"
              href="/getting-started/installation/"
            >
              TECNOLOGIA
            </Link>
            <Link
              className="blogCabecalhoTextRealizacoesl"
              color="textPrimary"
              href="/getting-started/installation/"
            >
              INOVACAO EM TECNOLOGIA
            </Link>
          </Breadcrumbs>
        </div>
        <BtnCompartilhar></BtnCompartilhar>
      </div>

      <div className="divblogRealizacaolVejaMais">
        <div>
          <div className="blogTituloRealizacoesl">
            <div>
              <h1>INOVAÇÃO EM TECNOLOGIA</h1>{" "}
            </div>
          </div>
          <div className="autorRealizacoesl">
            <div style={{ color: "#008240" }}>
              Claudivan Feitosa de Lacerda{" "}
            </div>
            <p>- postado em: 05 outubro de 2020</p>
          </div>

          <div className="conteudoRealizacoesl">
            <div>
              <img 
              className="imgBlogRealizacao"
              src="https://telemedicinamorsch.com.br/wp-content/uploads/2019/03/inovacao-area-da-saude-9-novas-tecnologias-tendencias.jpg" alt=""></img>
            </div>
            <div>
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed neque, morbi turpis mauris tempus, a fringilla non. Eu id eu nisl lectus. Proin ullamcorper sed eleifend ac volutpat mi semper ac eu. Ac tellus ac habitasse in urna ut elementum nunc tempor. Vel et id nulla urna tincidunt sit id. Non massa accumsan lectus tempor lectus dui. Donec eget aliquam in molestie justo. Sed ut viverra feugiat nibh urna, eget pharetra, varius.  Ornare dolor scelerisque in volutpat ut at sed. Morbi suspendisse placerat ut consectetur sit viverra senectus. Ultrices suspendisse vitae porttitor curabitur
              </p>
            </div>
          </div>
          <div style={{ textAlign: "justify" }}>
            <p>
            Ornare dolor scelerisque in volutpat ut at sed. Morbi suspendisse placerat ut consectetur sit viverra senectus. Ultrices suspendisse vitae porttitor curabitur vitae pellentesque erat tellus phasellus. Quis auctor et id curabitur odio sed. Sapien aliquet malesuada feugiat massa elementum. A adipiscing volutpat ut egestas integer at massa tellus nibh. Elementum tincidunt ut vitae iaculis id tortor risus. Viverra aliquet magna curabitur adipiscing gravida. In tortor morbi ornare mi urna.

            Nulla aliquam elementum arcu tortor condimentum amet. Imperdiet sit viverra id arcu, elementum nullam non amet tellus. Vulputate dictumst velit scelerisque vel porta vivamus aenean dignissim. Condimentum ipsum scelerisque id augue lectus suspendisse commodo augue. Dolor risus posuere scelerisque id eleifend praesent vitae quisque. Sit nisi, in praesent rhoncus enim sit. Scelerisque nulla eu lectus consequat nec nec enim. Lacus, scelerisque tristique in sit mi mattis egestas maecenas. Quis nulla fames sapien vel elementum vulputate tempor lectus molestie. Eu neque dignissim elit augue netus. Aliquam non orci, ultricies enim quisque convallis elit, eu commodo. A mattis sollicitudin hac massa ornare auctor facilisis imperdiet. Tempor, porttitor odio aenean nullam sit. Turpis odio amet porta sem vulputate.

            Id eget pharetra sed habitasse. In tempus metus convallis tortor felis. Amet velit augue vitae, pellentesque magna aliquet eget id sed. Rhoncus volutpat tortor sit velit nisl tortor. Quisque non ultricies bibendum pellentesque massa vel gravida cras diam. Integer bibendum parturient sodales sed augue dignissim. Aenean morbi leo enim neque nunc, at velit. Mi arcu, aliquet libero, iaculis id leo, ac. Amet, et et sagittis, scelerisque. Proin tellus tortor nam a eu purus lorem amet. 
            </p>
          </div>

          <div>
            <h2 className="citacaoRealizacoesl">Citação na Mídia </h2>

            <div className="linkcitacaoRealizacoesl" onClick={clicarNoLink}>
              • http://jornal.com
            </div>
          </div>
          <div>
            <h2 className="citacaoRealizacoesl" style={{ marginTop: "32px" }}>
              Anexo{" "}
            </h2>
            <div className="anexosRealizacoesl" style={{ marginLeft: "12px" }}>
              <SvgIcon style={{ fontSize: "16px", color: "#008240" }}>
                <Artigo />
              </SvgIcon>
              <div className="artigoTituloRealizacoesl">Artigo</div>
            </div>
          </div>
        </div>

        <div className="divVejaMaisRealizacoes">
          <div className="vejaMaisRealizacoesl">Veja Mais</div>
          <div
            className="postvermaisRealizacoesl"
            onClick={() => handleClick(1)}
          >
            <div className="divimgRealizacoes">
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img1 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                <div>Produtividade da água </div>
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>

          <div className="postvermaisRealizacoesl">
            <div className="divimgRealizacoes" onClick={() => handleClick(2)}>
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img2 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                Produtividade da água e inovação tecnológica no setor
                agropecuário do Estado do Ceará
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>

          <div className="postvermaisRealizacoesl">
            <div className="divimgRealizacoes">
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img3 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                Ações inovadoras para agregar valor à agricultura cearense
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>

          <div className="postvermaisRealizacoesl">
            <div className="divimgRealizacoes">
              <div
                className="imgPostRealizacao"
                style={{
                  backgroundImage: "url(" + Img4 + ")",
                }}
              ></div>
              <div className="postVerMaisTítuloRealizacoes">
                Eficiência do uso da água na agricultura
              </div>
            </div>
            <div className="postVerMaisLegendaRealizacoes">REALIZAÇÃO</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
