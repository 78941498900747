import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Cabecalho from "../../components/cabecalho/Cabecalho";
import Rodape from "../../components/rodape/Rodape";
import Empresarial from "./empresarial/Empresarial";
import "./inovacao.css";
import Publica from "./publica/Publica";


export default function Inovacao() {

    //pre-menu selecionado
    const location = useLocation().pathname;
    
    //rotas alternativas
    var route1 = "empresarial";
    var route2 = "publica";
    var route3 = "publica/ind";

    var selectedRoute = "empresarial"; //default
  
    if(location.includes(route1)){
        selectedRoute = route1;
    }
    else if(location.includes(route2)){
        selectedRoute = route2;
    }

    const [currentPage, setCurrentPage] = useState(selectedRoute);

    function changeCurrentPage(value) {
        setCurrentPage(value)
    }

    return (
        <div>
            <Cabecalho name="inovacao"></Cabecalho>
            <div className="root">
                <div className="navInovacao">
                    {currentPage === "empresarial"
                        ?
                        <div className="divGreenMainInovacao" style={{ marginRight: "8px" }}>
                            <div className="textMainInovacao">Empresarial</div>
                        </div>
                        :
                        <div className="divGreenOffMainInovacao" onClick={() => changeCurrentPage("empresarial")} style={{ marginRight: "8px" }}>
                            <div className="textOffMainInovacao">Empresarial</div>
                        </div>
                    }
                    {currentPage === "publica"
                        ?
                        <div className="divYellowMainInovacao">
                            <div className="textMainInovacao">Pública</div>
                        </div>
                        :
                        <div className="divYellowOffMainInovacao" onClick={() => changeCurrentPage("publica")}>
                            <div className="textOffMainInovacao">Pública</div>
                        </div>
                    }
                </div>
                {currentPage === "empresarial"
                    ?
                    <Empresarial />
                    :
                    ""
                }
                {currentPage === "publica"
                    ?
                    <Publica ind={location.includes(route3)}/>
                    :
                    ""
                }
                <Rodape></Rodape>
            </div>
        </div>
    )
}