import { useEffect, useState } from "react";
import IndicadorComponente from "../../../../components/indicadorComp/Indicador";

export default function IndicadorData(props) {

    const [filter, setFilter] = useState([]); // eslint-disable-line react/no-unused-state

    function filterData(data) {

        var filterJSON = [];
        var data = data;

        for (var i = 0; i < data.length; i++) {
            if (data[i].programa !== null) {
                if (data[i].programa.includes("BSOCIAL")) {
                    filterJSON.push(data[i]);
                }
            }
        }

        setFilter(filterJSON);
    }

    var datasetExcel = [
        { titulo: "Região", campo: "regiao", tamanho: "12", tipo: "texto", wpx: 160 },
        { titulo: "Área", campo: "dominio", tamanho: "12", tipo: "texto" , wpx: 200 },
        { titulo: "Ano", campo: "ano", tamanho: "12", tipo: "numero", wpx: 100 },
        { titulo: "Valor (R$)", campo: "investimento", tamanho: "12", tipo: "reais", wpx: 100 }
    ];

    var datasetPrint = [
        { titulo: "Região", campo: "regiao", tamanho: "12", tipo: "texto" },
        { titulo: "Área", campo: "dominio", tamanho: "12", tipo: "texto" },
        { titulo: "Ano", campo: "ano", tamanho: "12", tipo: "numero" },
        { titulo: "Valor (R$)", campo: "investimento", tamanho: "12", tipo: "reais"}
    ]

    useEffect(() => {
        filterData(props.data);
    }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <div>
            {
                filter.length > 0 ?

                    <IndicadorComponente datasetPrint={datasetPrint} datasetExcel={datasetExcel} found={props.found} titulo={"Investimentos realizados no Programa de Inclusão Social – BSocial"} legenda={false} inst={true} data={filter} pivo={"investimento"} pivo_inst={"empresa"} tipo={"reais"} tipo_legenda={""} cor={'verde'} />
                    :
                    <></>
            }
        </div>
    )

}