import { G2, Pie } from '@ant-design/charts';
import React, { useState } from 'react';
import cores from '../../cores/cores';
import toBRL from '../../funcoes/toBRL';
import "./donut.css";


export default function AntPie(props) {

    const [data, setData] = useState(props.data);
    var cor = props.cor;
    var orient = props.orient;


    function getPercentage(partialValue, data) {

        var totalValue = 0;

        for (var i = 0; i < data.length; i++) {
            totalValue += data[i].value;
        }
        
        if (totalValue !== 0) {
            return ((100 * partialValue) / totalValue).toFixed(1) + '%';
        }
        return totalValue +'%';
    }

    function getPercentageWidth(partialValue, data) {

        var totalValue = 0;

        for (var i = 0; i < data.length; i++) {
            totalValue += data[i].value;
        }

        return ((100 * partialValue) / totalValue).toFixed(1) + '%';
    }



    const G = G2.getEngine('canvas');

    var arrayCores = cores().verdes.slice(6);
    if (cor === "amarelo") {
        arrayCores = cores().amarelos.slice(6);
    }

    var config = {
        data: data,
        angleField: 'value',
        colorField: 'type',
        innerRadius: 0.7,
        radius: 1,
        label: false,
        legend: false,
        color: arrayCores,
        tooltip: false,
        interactions: [{ type: 'element-active' }],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'auto',
                    textOverflow: 'ellipsis',
                },
                content: '',
            },
        },

    };

    const half = Math.ceil(data.length / 2);
    const firstHalf = data.slice(0, half)
    const secondHalf = data.slice(half)

    if (orient === 'vert2') {

        return (
            <div className='divContainerDonutPainelFrontal'>
                <div>
                    {firstHalf.map((item, index) => (
                        <div className='divLegendDonutPainelFrontal'>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
                                    <div className='divLegendTitleDonutPainelFrontal' >{item.type}</div>
                                    <div className='divLegendPercentDonutPainelFrontal' style={{ color: arrayCores[index] }}>{getPercentage(item.value, data)}</div>
                                </div>
                                <div className='divLineDonutPainelFrontal' style={{ backgroundColor: arrayCores[index], width: getPercentageWidth(item.value, data) }}></div>
                                <div className='divLegendValueDonutPainelFrontal' style={{ color: arrayCores[index] }}>{toBRL(item.value)}</div>
                            </div>
                        </div>))}
                </div>
                <div className='divDonutPainelFrontal'>
                    <Pie {...config} width={"100%"} height={"100%"} />
                </div>
                <div>
                    {secondHalf.map((item, index) => (
                        <div className='divLegendDonutPainelFrontal2'>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                                    <div className='divLegendPercentDonutPainelFrontal2' style={{ color: arrayCores[index + firstHalf.length - 1] }}>{getPercentage(item.value, data)}</div>
                                    <div className='divLegendTitleDonutPainelFrontal2' >{item.type}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                                    <div className='divLineDonutPainelFrontal2' style={{ backgroundColor: arrayCores[index + firstHalf.length - 1], width: getPercentageWidth(item.value, data) }}></div>
                                </div>
                                <div style={{ display: "flex", flexDirection: 'row-reverse' }}>
                                <div className='divLegendValueDonutPainelFrontal2' style={{ color: arrayCores[index + firstHalf.length - 1] }}>{toBRL(item.value)}</div>
                                </div>
                            </div>
                        </div>))}
                </div>
            </div>
        );

    }
    else if (orient === undefined) {

        return (
            <div className='divContainerDonutPainelFrontal'>
                <div>
                    {data.map((item, index) => (
                        <div className='divLegendDonutPainelFrontal'>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
                                    <div className='divLegendTitleDonutPainelFrontal' >{item.type}</div>
                                    <div className='divLegendPercentDonutPainelFrontal' style={{ color: arrayCores[index] }}>{getPercentage(item.value, data)}</div>
                                </div>
                                <div className='divLineDonutPainelFrontal' style={{ backgroundColor: arrayCores[index], width: getPercentageWidth(item.value, data) }}></div>
                                <div className='divLegendValueDonutPainelFrontal' style={{ color: arrayCores[index] }}>{toBRL(item.value)}</div>
                            </div>
                        </div>))}
                </div>
                <div className='divDonutPainelFrontal'>
                    <Pie {...config} width={"100%"} height={"100%"} />
                </div>
            </div>
        );

    }
};
