import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import EditarUsuario from "./EdicaoUsuario";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit timeout={1500} />;
});

export default function SimpleModal({ open, setOpen, user }) {

    const handleClose = () => {
      setOpen(false);
    };
    
return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          scroll={'paper'}       
          fullScreen
          style={{margin:"20px 0px 0px 0px"}}
          PaperProps={{
            style: {borderTopLeftRadius:10, borderTopRightRadius:10}}}
          TransitionComponent={Transition}
        >
         
              <EditarUsuario user={user} setOpen={() => setOpen(false)}></EditarUsuario>
            
        </Dialog>
      </div>
    </Slide>

  );
}