import React, { useState } from "react";
import Cabecalho from "../../components/cabecalho/Cabecalho";
import Rodape from "../../components/rodape/Rodape";
import Duvidas from "./duvidas/Duvidas";
import "./home.css";
import PaginaInicial from "./paginaInicial/PaginaInicial";
import TodosIndicadores from "./todosIndicadores/TodosIndicadores";

export default function Home() {

    const [currentPage, setCurrentPage] = useState("inicio");
    const [subItem, setSubItem] = useState(1);

    function changeCurrentPage(value, subValue = 1) {
        setSubItem(subValue)
        setCurrentPage(value)
    }

    return (
        <div>
            <Cabecalho name="inicio"></Cabecalho>
            <div className="root">
                <div className="navHome">
                    {currentPage === "inicio"
                        ?
                        <div className="divGreenMainHome" style={{ marginRight: "8px" }}>
                            <div className="textMainHome">Destaques</div>
                        </div>
                        :
                        <div className="divGreenOffMainHome" onClick={() => changeCurrentPage("inicio")} style={{ marginRight: "8px" }}>
                            <div className="textOffMainHome">Destaques</div>
                        </div>
                        
                    }

                    {currentPage === "indicadores"
                        ?
                        <div className="divGreenMainHome" style={{ marginRight: "8px" }}>
                            <div className="textMainHome">Indicadores</div>
                        </div>
                        :
                        <div className="divGreenOffMainHome" onClick={() => changeCurrentPage("indicadores")} style={{ marginRight: "8px" }}>
                            <div className="textOffMainHome" >Indicadores</div>
                        </div>
                    }
                    {currentPage === "duvidas"
                        ?
                        <div className="divGreenMainHome" style={{ marginRight: "8px",  width: "232px" }}>
                            <div className="textMainHome">Dúvidas frequentes</div>
                        </div>
                        :
                        <div className="divGreenOffMainHome" onClick={() => changeCurrentPage("duvidas")} style={{ marginRight: "8px",  width: "232px" }}>
                            <div className="textOffMainHome" >Dúvidas frequentes</div>
                        </div>
                    }

                </div>

                {currentPage === "inicio"
                    ?
                    <PaginaInicial changeCurrentPage={changeCurrentPage} />
                    :
                    ""
                }
                {currentPage === "indicadores"
                    ?
                    <TodosIndicadores subItem={subItem} />
                    :
                    ""
                }
                {currentPage === "duvidas"
                    ?
                    <Duvidas subItem={subItem} />
                    :
                    ""
                }
            </div>
            <Rodape></Rodape>
        </div>
    )
}