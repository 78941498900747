import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { CKEditor } from "ckeditor4-react";
import React, { useContext, useEffect, useState } from "react";
import StoreContext from "../../../../components/Store/Context";
import { ReactComponent as Upload } from "../../../../components/icons/cadastro/upload.svg";
import Portas from "../../../../portas";
import "./cadastroRealizacao.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {},
  },
  button: {
    margin: theme.spacing(1),
  },
  btnCadastrar: {
    backgroundColor: "#20764B",
    borderRadius: "10px",
    width: "140px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  formWidth: {
    width: "300px",
  },
  formWidthText: {
    width: "100%",

  },
  divFormRedesSociais: {
    margin: "24px",
  },
  formControl: {
    minWidth: 680,
  },
  formIcone: {
    marginRight: "16px",
    minWidth: 84,
  },
  importImage: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 965,
    height: "54px",
    left: "1px",
    top: "1px",
    marginLeft: "25px",
    marginRight: "25px",
    borderRadius: "3.5px",
  },
  importAnexo: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "54px",
    left: "1px",
    top: "1px",
    marginLeft: "25px",
    marginRight: "25px",
    borderRadius: "3.5px",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function CadastroRealizacao() {
  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [usuario, setUsuario] = useState([]);
  const [areaComProjeto, setAreaComProjeto] = useState(true);
  const [titulo, setTitulo] = useState("");
  const [areas, setAreas] = useState([]);
  const [areaSelecionada, setAreaSelecionada] = useState("");
  const [projetosRelacionados, setProjetosRelacionados] = useState([]);
  const [projetoSelecionado, setProjetoRelacionado] = useState("");
  const [selectedImageCapa, setSelectedImageCapa] = useState(null);
  const [selectedAnexo, setSelectedAnexo] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [citacaoMidiaRealizacao, setCitacaoMidiaRealizacao] = useState([]);
  const [conteudoMidiaRealizacao, setConteudoMidiaRealizacao] = React.useState("");
  const [linksRelacionados, setLinksRelacionados] = useState([]);
  const [linkUrl, setLinkUrl] = useState("")
  const [tituloLink, setTituloLink] = useState("")

  console.log(token)

  const addInputLink = (event) => {
    setLinksRelacionados([...linksRelacionados, { link: linkUrl, label: tituloLink }]);
    setLinkUrl("");
    setTituloLink("");
  };

  const handleRemoveInputLink = (position) => {
    var auxLinks = [];
    for (var i = 0; i < linksRelacionados.length; i++) {
      if (i !== position) {
        auxLinks.push(linksRelacionados[i]);
      }
    }
    setLinksRelacionados(auxLinks);
  };

  function handleChangeTituloLinks(value, index) {
    let aux = [...linksRelacionados];
    aux[index].label = value;
    setLinksRelacionados(aux);
  }

  function handleChangeLinkUrl(value, index) {
    let aux = [...linksRelacionados];
    aux[index].link = value;
    setLinksRelacionados(aux);
  }

  const addInputCitacaoMidiaRealizacao = (event) => {
    setCitacaoMidiaRealizacao([
      ...citacaoMidiaRealizacao,
      conteudoMidiaRealizacao,
    ]);
    setConteudoMidiaRealizacao("");
  };

  const handleRemoveInputCitacaoMidiaRealizacao = (position) => {
    var auxCitacaoMidia = [];
    for (var i = 0; i < citacaoMidiaRealizacao.length; i++) {
      if (i !== position) {
        auxCitacaoMidia.push(citacaoMidiaRealizacao[i]);
      }
    }
    setCitacaoMidiaRealizacao(auxCitacaoMidia);
  };

  function handleChangeCitacaoMidiaRealizacao(value, index) {
    var auxCitacaoMidia = [];
    for (var i = 0; i < citacaoMidiaRealizacao.length; i++) {
      if (i === index) {
        auxCitacaoMidia.push(value);
      } else {
        auxCitacaoMidia.push(citacaoMidiaRealizacao[i]);
      }
    }
    setCitacaoMidiaRealizacao(auxCitacaoMidia);
  }

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const CriarDropdowns = async (usuario) => {
    var areas = [];

    try {
      const response = await fetch(Portas().serverHost + "/areas?image=true&vitrine=true", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const resJSON = await response.json();

      if (resJSON.length > 0 && usuario.perfil_acesso.acesso !== 'Cientista-Chefe') {
        for (var i = 0; i < resJSON.length; i++) {
          areas.push({ value: resJSON[i].area, label: resJSON[i].area, id: resJSON[i].id })
        }
        setAreas(areas)
      }
      else {
        areas.push({ "value": usuario.cientista.area_cientista.area, "label": usuario.cientista.area_cientista.area, "id": usuario.cientista.area_cientista.id })
        setAreas(areas)
      }

    } catch (err) {

    }
  };

  const getProjetos = async (areaSelecionada) => {

    try {

      var projetos = [];
      var idAreaSelecionada = ''

      for (var i = 0; i < areas.length; i++) {
        if (areas[i].label === areaSelecionada) {
          idAreaSelecionada = areas[i].id
          break
        }
      }

      const response = await fetch(Portas().serverHost + '/projetos/area/' + idAreaSelecionada + '?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true', {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const resJSON1 = await response.json();

      if (resJSON1.length > 0) {
        for (var i = 0; i < resJSON1.length; i++) {
          projetos.push({
            value: resJSON1[i].titulo,
            label: resJSON1[i].titulo,
            id_area: resJSON1[i].id_area,
            id_projeto: resJSON1[i].id,
          });
        }
        setProjetosRelacionados(projetos);
      }

    } catch (err) {

    }

  }

  const getUser = async () => {

    try {
      const response = await fetch(Portas().serverHost + "/usuarios/me?cientista=true&acesso=true&image=true",
        {
          method: "GET",
          headers: {
            'Authorization': "Bearer " + token,
          }
        }
      );

      const resJSON1 = await response.json();
      setUsuario(resJSON1)
      CriarDropdowns(resJSON1)

    }
    catch (err) {

    }

  }

  const publicar = async () => {
    //validacoes

    if (areaSelecionada.length < 1) {
      alert("Insira uma área!");
      return;
    }

    if (areaComProjeto) {
      if (projetoSelecionado.length < 1) {
        alert("Selecione um projeto!");
        return;
      }
    }


    if (titulo.length < 1) {
      alert("Insira um titulo!");
      return;
    }

    if (selectedImageCapa === null) {
      alert("Insira uma capa!");
      return;
    }

    if (editorData.length < 1) {
      alert("Insira um conteudo!");
      return;
    }

    var idArea = "";
    var idProjeto = "";


    for (var i = 0; i < areas.length; i++) {
      if (areaSelecionada === areas[i].value) {
        console.log(areas[i])
        idArea = areas[i].id;
      }
    }

    for (var i = 0; i < projetosRelacionados.length; i++) {
      if (projetoSelecionado === projetosRelacionados[i].value) {
        idProjeto = projetosRelacionados[i].id_projeto;
      }
    }

    const formData = new FormData();
    formData.append("titulo", titulo);
    formData.append("capa", selectedImageCapa);
    formData.append("conteudo", editorData);
    formData.append("links_relacionados", JSON.stringify(linksRelacionados));
    if (selectedAnexo !== null) {
      formData.append("anexo", selectedAnexo);
    }
    formData.append("id_area", idArea);
    if (areaSelecionada !== "Dúvidas frequentes" && areaSelecionada !== "Sobre*" && areaSelecionada !== "Tutorial" && areaComProjeto) {
      formData.append("id_projeto", idProjeto);
    }
    if (selectedAnexo !== null) {
      formData.append("exibir_anexo", values.showPassword);
    }

    try {
      const response = await fetch(Portas().serverHost + "/realizacoes", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });
      const resJSON = await response.json();
      if (typeof resJSON.id !== "undefined") {
        alert("Realização criada!");
        return;
      } else if (typeof resJSON.message !== "undefined") {
        alert(resJSON.message);
        return;
      } else {
        alert("Um erro ocorreu, tente novamente...");
      }
    } catch (err) {

    }
  };

  function handleChangeArea(value) {
    setAreaSelecionada(value)
    if (value === 'Inovação Empresarial' || value === 'Ciência (Internacionalização)' || value === 'Ciência (Interiorização)' || value === 'Ciência (Formação de RH)' || value === 'Ciência (Auxílio a Projetos)' || value === 'Dúvidas frequentes' || value === 'Sobre*' || value === 'Tutorial') {
      setProjetoRelacionado('')
      setAreaComProjeto(false)
    }
    else {
      setProjetoRelacionado('')
      setAreaComProjeto(true)
    }
    getProjetos(value)
  }

  useEffect(() => {
    getUser()
  }, []);

  return (
    <div className="pageCadastroRealizacao">
      <div className="tituloPageRealizacao">Criar Realização</div>
      <div>
        <div className={classes.divFormRedesSociais}>
          {areas.length > 0 ? (
            <FormControl className={classes.formWidthText}>
              <InputLabel required>Relacionado a(ao)</InputLabel>
              <Select
                value={areaSelecionada}
                onChange={(event) => handleChangeArea(event.target.value)}
              >
                {areas.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Selecione em que área o post deverá aparecer e ser vinculado{" "}
              </FormHelperText>
            </FormControl>
          ) : (
            <></>
          )}
        </div>

        <div className={classes.divFormRedesSociais}>
          {areaComProjeto ? (
            <FormControl required className={classes.formWidthText}>
              <InputLabel required>Projeto Relacionado</InputLabel>
              <Select
                value={projetoSelecionado}
                onChange={(event) => setProjetoRelacionado(event.target.value)}
              >
                {projetosRelacionados.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}
        </div>
        <div className={classes.divFormRedesSociais}>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              value={titulo}
              onChange={(event) => setTitulo(event.target.value)}
              style={{ marginBottom: "51px" }}
              label="Título"
              fullWidth
            />
          </form>
        </div>
        {selectedImageCapa === null ? (
          <div>
            <Paper className={classes.importImage} variant="outlined">
              <InputBase fullWidth disabled placeholder="Imagem da Capa" />
              <label htmlFor={"upload-button"}>
                <Upload />
              </label>
              <form encType="multipart/form-data" action="">
                <input
                  type="file"
                  accept="image/*"
                  name="upload-button"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setSelectedImageCapa(event.target.files[0]);
                  }}
                />
              </form>
            </Paper>
          </div>
        ) : (
          <div>
            <Paper className={classes.importImage} variant="outlined">
              <InputBase
                fullWidth
                value={selectedImageCapa.name}
                disabled
                placeholder="Imagem da Capa"
              />
              <label htmlFor={"upload-button1"}>
                <div>
                  <Upload />
                </div>
              </label>
              <form encType="multipart/form-data" action="">
                <input
                  type="file"
                  accept="image/*"
                  name="upload-button1"
                  id="upload-button1"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    setSelectedImageCapa(event.target.files[0]);
                  }}
                />
              </form>
            </Paper>
          </div>
        )}
        <div className="subtitulosCadastroRealizacao">Conteúdo</div>
        <CKEditor
          data={editorData}
          id="editor_home"
          name="editor_home"
          onChange={(event) => setEditorData(event.editor.getData())}
          config={{
            language: "pt-br",
            uiColor: "#FFFFFF",
            toolbarCanCollapse: true,
            toolbarGroups: [
              { name: "clipboard", groups: ["clipboard", "undo"] },
              // { name: "styles", groups: ["styles"] },
              { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
              {
                name: "paragraph",
                groups: [
                  "align",
                  "list",
                  "indent",
                  "blocks",
                  "bidi",
                  "paragraph",
                ],
              },
              {
                name: "editing",
                groups: ["find", "selection", "spellchecker", "editing"],
              },
              { name: "links", groups: ["links"] },
              { name: "insert", groups: ["insert"] },
              { name: "forms", groups: ["forms"] },
              {
                name: "document",
                groups: ["mode", "document", "doctools"],
              },
              { name: "others", groups: ["others"] },
              // { name: "colors", groups: ["colors"] },
              { name: "about", groups: ["about"] },
              { name: "tools", groups: ["tools"] },
            ],
            extraPlugins: "justify, font",
            removeButtons:
              "FontSize,Subscript,Superscript,Scayt,PasteText,PasteFromWord,Anchor,Strike,RemoveFormat,Source,About,Styles, Font, Link",
          }}
          onInstanceReady={() => { }}
        />
        <div className="subtitulosCadastroRealizacao">Relacionados</div>
        <div>
          <div>
            {linksRelacionados.map((link, index) => (
              <div className="divequipeEnvolvida">
                <div className="divequipeEnvolvidaLinkCurriculoLattes" style={{ marginRight: "32px" }}>
                  <FormControl className={classes.formWidthText}>
                    <InputLabel>Link: Citação na mídia</InputLabel>
                    <Input
                      value={link.link}
                      onChange={(event) => {
                        handleChangeLinkUrl(event.target.value, index)
                      }}
                    />
                    <FormHelperText>Preencha este campo com uma URL</FormHelperText>
                  </FormControl>
                </div>
                <div className="divequipeEnvolvidaLinkCurriculoLattes">
                  <FormControl fullWidth>
                    <InputLabel>Título</InputLabel>
                    <Input
                      value={link.label}
                      onChange={(event) => {
                        handleChangeTituloLinks(event.target.value, index)
                      }}
                    />
                    <FormHelperText>Preencha este campo com uma título</FormHelperText>
                  </FormControl>
                  <IconButton
                    onClick={() => {
                      handleRemoveInputLink(index);
                    }}
                    type="submit"
                    className={classes.iconButton}
                    style={{ fontSize: "17px", color: "#405965" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}

          </div>

          <div className="divequipeEnvolvida">
            <div className="divequipeEnvolvidaLinkCurriculoLattes" style={{ marginRight: "32px" }}>
              <FormControl className={classes.formWidthText}>
                <InputLabel>Link: Citação na mídia</InputLabel>
                <Input
                  value={linkUrl}
                  onChange={(event) => {
                    setLinkUrl(event.target.value);
                  }}
                />
                <FormHelperText>Preencha este campo com uma URL</FormHelperText>
              </FormControl>
            </div>
            <div className="divequipeEnvolvidaLinkCurriculoLattes">
              <FormControl fullWidth>
                <InputLabel>Título</InputLabel>
                <Input
                  value={tituloLink}
                  onChange={(event) => {
                    setTituloLink(event.target.value);
                  }}
                />
                <FormHelperText>Preencha este campo com uma título</FormHelperText>
              </FormControl>
              <IconButton
                onClick={addInputLink}
                type="submit"
                className={classes.iconButton}
                style={{ fontSize: "17px", color: "#405965" }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>

        </div>

        <div className="subtitulosCadastroRealizacao">Anexos</div>
        {selectedAnexo === null ? (
          <div style={{ marginTop: "32px" }}>
            <div className="divAnexo">
              <Paper
                className={classes.importAnexo}
                variant="outlined"
                style={{ width: "90%" }}
              >
                <InputBase fullWidth disabled placeholder="Anexo" />
                <label htmlFor={"upload-button4"}>
                  <Upload />
                </label>
                <form encType="multipart/form-data" action="">
                  <input
                    type="file"
                    name="upload-button4"
                    id="upload-button4"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setSelectedAnexo(event.target.files[0]);
                    }}
                  />
                </form>
              </Paper>
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ fontSize: "17px", color: "#405965" }}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <IconButton
                type="submit"
                className={classes.iconButton}
                style={{ fontSize: "17px", color: "#405965" }}
                onClick={() => setSelectedAnexo(null)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <FormHelperText style={{ marginLeft: "25px" }}>
              PDF, PNG ou JPEG{" "}
            </FormHelperText>
          </div>
        ) : (
          <div style={{ marginTop: "32px" }}>
            <div className="divAnexo">
              <Paper
                className={classes.importAnexo}
                variant="outlined"
                style={{ width: "90%" }}
              >
                <InputBase
                  fullWidth
                  value={selectedAnexo.name}
                  disabled
                  placeholder="Anexo"
                />
                <label htmlFor={"upload-button5"}>
                  <div>
                    <Upload />
                  </div>
                </label>
                <form encType="multipart/form-data" action="">
                  <input
                    type="file"
                    name="upload-button5"
                    id="upload-button5"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setSelectedAnexo(event.target.files[0]);
                    }}
                  />
                </form>
              </Paper>
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                style={{ fontSize: "17px", color: "#405965" }}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
              <IconButton
                type="submit"
                className={classes.iconButton}
                style={{ fontSize: "17px", color: "#405965" }}
                onClick={() => setSelectedAnexo(null)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <FormHelperText style={{ marginLeft: "25px" }}>
              PDF, PNG ou JPEG{" "}
            </FormHelperText>
          </div>
        )}
      </div>

      <div className="botaoCadastroCooperacao">
        <Button
          className={classes.btnCadastrar}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => publicar()}
        >
          Publicar
        </Button>
      </div>
    </div>
  );
}
