import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import substringColor from "../../components/funcoes/substringColor";
import SimpleModal from "../../pages/blog/ModalProjeto";
import SimpleModal1 from "../../pages/blog/ModalRealizacao";
import Portas from "../../portas";


const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B",
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965",
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green",
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: "#405965",
        },
        "&:hover .MuiInput-underline:before": {
            borderBottomColor: "#20764B",
        },
        "& .Mui-focused:after": {
            borderBottomColor: "#20764B",
        },
    },
})(TextField);

const useStyles = makeStyles({
    selectRoot: {
        "&:focus": {
            backgroundColor: "inherit",
        },
    },
    select: {
        "&:before": {
            borderColor: "#20764B",
        },
        "&:after": {
            borderColor: "#20764B",
        },
        color: "#405965",
        fontWeight: "bold",
    },
    icon: {
        fill: "#20764B",
    },
});


export default function BlogsSection(props) {

    const [content, setContent] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [page, setPage] = useState(0);
    const [selectedProjeto, setSelectedProjeto] = useState("");
    const [selectedRealizacao, setSelectedRealizacao] = useState("");
    const [tipos, setTipos] = useState("");
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [blogParam, setBlogParam] = useState(window.location.href.substring(window.location.href.lastIndexOf("/") + 1))

    var allBlogs = [
        {
            tipo: 1,
        }
    ];

    function getData(timestamp, tipo) {
        var data = new Date(timestamp)
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()

        if (tipo === "dia") {
            return dia
        }

        if (tipo === "mes") {
            return mes
        }

        if (tipo === "ano") {
            return ano
        }
    }

    function filterContent(content) {

        let arr = [];

        for (var i = 0; i < content.length; i++) {
            if (content[i].content.titulo.toUpperCase().includes(searchField.toUpperCase())) {
                arr.push(content[i])
            }
        }

        return arr;

    }

    function filterContentList(content) {

        let arr = [];

        for (var i = 0; i < content.length; i++) {
            if (i >= 5 * (page) && i < 5 * (page + 1)) {
                arr.push(content[i])
            }
        }

        return arr;

    }


    const handleChange = (event) => {
        setTipos(event.target.value);

        if (event.target.value === "") {
            setBlogs(allBlogs);
        } else {
            var filtro = [];
            for (var i = 0; i < allBlogs.length; i++) {
                if (allBlogs[i].tipo === event.target.value) {
                    filtro.push(allBlogs[i]);
                }
            }
            if (event.target.value === 1 || event.target.value === 2)
                setBlogs(filtro);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function handleClickProjeto(id) {
        setSelectedProjeto(id);
        setOpen2(true);
    }

    function handleClickRealizacao(id) {
        setSelectedRealizacao(id);
        setOpen1(true);
    }

    function submit(e) {
        e.preventDefault();
        setSearchField(document.getElementById("searchfield").value);
    }

    //getContent() = obtem todos os projetos e realizacoes
    const getContent = async () => {

        try {

            const response = await fetch(Portas().serverHost + "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();

            const response2 = await fetch(Portas().serverHost + "/realizacoes?image=true&area=true&projeto=true&anexo=true&pagina=0&tamanho=5&paginacao=false&autor=false",
                {
                    method: "GET",
                }
            );

            const resJSON2 = await response2.json();

            let arr = []

            for (var i = 0; i < resJSON.length; i++) {

                if (resJSON[i].id === blogParam) {
                    handleClickProjeto(blogParam)
                }

                if (resJSON[i].area_projeto.area === "Tutorial") {
                    arr.push(
                        {
                            type: "projeto",
                            content: resJSON[i]
                        }
                    )
                }

            }

            for (var i = 0; i < resJSON2.length; i++) {

                if (resJSON2[i].id === blogParam) {
                    handleClickRealizacao(blogParam)
                }

                if (resJSON2[i].area_realizacao.area === "Tutorial") {
                    arr.push(
                        {
                            type: "realização",
                            content: resJSON2[i]
                        }
                    )
                }


            }

            setContent(arr.sort(function (a, b) { return a.content.createdAt > b.content.createdAt ? -1 : 1; }))

        }
        catch (err) {
            
        }
    }

    useEffect(() => {
        getContent();
    }, []);

    return (
        <div>
            {content.length > 0 ?
                <div className="backgroundPainelHome">
                    {open1 ? (
                        <SimpleModal1
                            open={open1}
                            setOpen={() => setOpen1(!open1)}
                            id={selectedRealizacao}
                        ></SimpleModal1>
                    ) : (
                        <></>
                    )}
                    {open2 ? (
                        <SimpleModal
                            open={open2}
                            setOpen={() => setOpen2(!open2)}
                            id={selectedProjeto}
                        ></SimpleModal>
                    ) : (
                        <></>
                    )}

                    <div
                        className="blogsHome"
                        style={{ marginTop: "32px", marginBottom: "32px" }}
                    >
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center",
                                }}
                            >
                                {props.page === "home" ?
                                    <form
                                        onSubmit={submit}
                                        style={{

                                            paddingLeft: "32px",
                                            paddingRight: "32px",
                                        }}
                                    >
                                        <CssTextField
                                            id="searchfield"
                                            style={{ width: "970px" }}
                                            placeholder={"Buscar Postagem"}
                                            onBlur={(event) => setSearchField(event.target.value)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </form>
                                    : <></>
                                }
                                
                            </div>
                            {content.length > 0 ?
                                <div style={{marginTop: "20px"}}>
                                    {filterContentList(filterContent(content)).map((item) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginBottom: "30px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginLeft: "32px",
                                                    marginRight: "32px",
                                                }}
                                            >
                                                <div className="divContentBlogRealizacaoHome" onClick={() => handleClickRealizacao(item.content.id)}>
                                                    <img
                                                        className="imgBlogHome"
                                                        alt=""
                                                        src={item.content.capa}
                                                    />
                                                    <div className="divTextBottomImgBlogTipoRealizacaoHome">
                                                        <div className="textBottomImgBlogTipoRealizacaoHome">
                                                            {item.type.toUpperCase()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="divTextBlogHome">
                                                    <div className="divTextTituloBlogTipoRealizacaoHome">
                                                        {substringColor(item.content.titulo.toUpperCase(), searchField.toUpperCase(), 1)}
                                                    </div>
                                                    <div className="divTextDataBlogTipoRealizacaoHome">
                                                        <div>Postado em {getData(item.content.createdAt, "dia")}/{getData(item.content.createdAt, "mes")}/{getData(item.content.createdAt, "ano")}</div>
                                                    </div>
                                                    <div className="divTextResumoBlogHome">
                                                        <div>
                                                            {parse(item.content.conteudo)}
                                                        </div>
                                                    </div>
                                                    <div className="divButtonLerMaisHome">
                                                        <button
                                                            className="buttonLerMaisRealizacaoHome"
                                                            onClick={() => handleClickProjeto(item.content.id)}
                                                        >
                                                            Ler Mais
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                : <></>
                            }
                            {/*
                            {filterContent(content).length > 0 ?
                                <TablePagination
                                    component="div"
                                    count={filterContent(content).length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={5}
                                    rowsPerPageOptions={[]}
                                />
                                : <></>
                            }
                        */}
                        </div>
                    </div>
                </div>
                : <></>
            }
        </div>
    )
}