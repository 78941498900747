import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';
import React from 'react';

const ColorButton = withStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: "#20764B",
        '&:hover': {
            backgroundColor: "rgb(253, 204, 9, 0.25)",
            color: "#20764B"
        },
    },
}))(Button);

function DownloadButton(props) {

    return (
        <ColorButton variant="contained" color="primary" onClick={props.onClick} startIcon={<CloudDownloadIcon />}>
            Baixar
        </ColorButton>
    );
}

function DownloadButton1(props) {

    return (
        <ColorButton variant="contained" color="primary" onClick={props.onClick} startIcon={<CloudDownloadIcon />}>
            Baixar para Excel
        </ColorButton>
    );
}

function PrintButton() {

    return (
        <ColorButton variant="contained" color="primary" startIcon={<PrintIcon />}>
            Imprimir
        </ColorButton>
    );
}

export { DownloadButton, DownloadButton1, PrintButton };

