export default function portas(){

    var clientHost = process.env.REACT_APP_CLIENT_HOST;
    var serverHost = process.env.REACT_APP_SERVER_HOST;

    //var clientHost = "http://177.153.59.47";
    //var serverHost = "http://177.153.59.47:3333";

    //PROD - Hostgator

    //var serverHost = "https://vitrinetecnologica.funcap.ce.gov.br";
    //var clientHost = "https://vitrinefuncap.com";

    //DEV - Funcap
	//var serverHost = "http://200.129.23.1:4444";
    //var clientHost = "http://200.129.23.1:3000";
    
    //DEV - LocalHost
    //var serverHost = "http://200.129.23.1:4444";
    //var clientHost = "http://localhost:3000";

    var testHost = "http://f8836f.vps-kinghost.net:3000";

    
 
    return {serverHost, clientHost, testHost};

}