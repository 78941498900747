import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import BtnCompartilhar from "../../components/btnCompartilhar/BtnCompartilhar";
import "./blog.css";
import { ReactComponent as Artigo } from "./icons/artigo.svg";
import Img1 from "./img/img1.png";
import Img2 from "./img/img2.png";
import Img3 from "./img/img3.png";
import Img4 from "./img/img4.png";
import Produtividade from "./img/produtividadeAgua.png";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#008240",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

function App({ setOpen }) {
  const classes = useStyles();

  function clicarNoLink() {
    //window.location="http://www.google.com.br"
    window.open("http://www.google.com.br", "_blank");
  }

  return (
    <div className="blogRealizacao">
      <div className="blogCabecalhoRealizacoes">
        <div className="divVoltarCompartilharProjeto">
          <Button
            className={classes.btnVoltar}
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>

          <div className="blogCabecalhoTextRealizacoes">
            CIENTISTA CHEFE / TECNOLOGIA / INOVAÇÃO EM TECNOLOGIA
          </div>
        </div>
        <BtnCompartilhar
            link={"https://vitrinefuncap.com/inovacao/1"}
          ></BtnCompartilhar>

      </div>

      <div className="divblogRealizacaoVejaMais">
        <div className="divblogRealizacaoConteudo">
          <div className="blogTituloRealizacoes">
            <div>
              <h1>Produtividade da água</h1>{" "}
            </div>
            <div>
              <p>(Início em 06/2020)</p>
            </div>
          </div>
          <div className="autorRealizacoes">
            <p>Samuel Facanha - postado em: 05/10/2020</p>
          </div>

          <div className="conteudoRealizacoes">
            <div>
              <img src={Produtividade} alt=""></img>
            </div>
            <div>
              <p>
                O clima exerce papel fundamental no desenvolvimento do setor
                agropecuário, sendo que a disponibilidade hídrica é o fator que
                mais impacta a produção vegetal e animal, afetando a economia e
                a geração de emprego e renda no Estado do Ceará. Nesse sentido,
                estratégias para aumentar a eficiência do uso e o nível
                tecnológico dos sistemas de produção são essenciais para a
                prática de uma agricultura sustentável. Nesse documento é
                apresentada a proposta de atuação do Cientista-Chefe em
                Agricultura na SEDET e as ações de pesquisa sob sua
              </p>
            </div>
          </div>
          <div style={{ textAlign: "justify" }}>
            <p>
              responsabilidade. A posposta de trabalho do Cientista􀃦Chefe é
              baseada em princípios norteadores e tem os seguintes objetivos: 1.
              Aumentar a eficiência do uso e a produtividade da água na produção
              agrícola; 2. Possibilitar o melhor aproveitamento e o incremento
              da oferta de água para o setor agropecuário; 3. Melhorar o nível
              tecnológico dos sistemas de produção; 4. Melhorar as condições de
              manejo e uso do solo, em sistemas agrícolas. A partir dos
              objetivos acima são propostas as seguintes metas: 1. Realizar uma
              análise dos projetos dé pesquisa em andamento na SEDET; 2.
              Discutir e elaborar uma proposta de acompanhamento de novos
              projetos, focados nos objetivos da presente proposta; 3. Executar
              ações de pesquisa em parceria com outros grupos de pesquisa com
              foco no aumento da oferta e na racionalização dos recursos
              hídricos (Irrigação suplementar, irrigação deficitária, reuso de
              água e agricultura biossalina), as quais estão descritas neste
              documento; 4. Avaliar e emitir parecer sobre novos projetos de
              pesquisa submetidos à SEDET, principalmente nos seguintes temas:
              Eficiência e produtividade da água: Inovação tecnológica e
              potencial de geração de renda, Coleta e armazenamento de água na
              propriedade, Sistemas de cultivas inovadores com potencial de
              estimular o crescimento do setor agrícola, Manejo e conservação do
              solo e da água; 5, Definir estratégias de irrigação suplementar
              para ampliar a produça9 agrícola e reduzir as perdas associadas
              aos riscos climáticos no semiárido do Estado do Ceará; 6. Difundir
              na sociedade (mediante palestras, textos em jornais, participação
              em fóruns de discussão, etc.) a importância do setor agropecuário
              para o desenvolvimento do estado e a necessidade do uso eficiente
              da água nas atividades agrícolas; 7. Formar recursos humanos
              (graduação e pós-graduação) com foco nos objetivos do projeto; 8.
              Produzir artigos técnicos e científicos relacionados aos temas
              pesquisados. Espera-se que a atuação do Cientista-Chefe em
              Agricultura permita maior aproximação do conhecimento científico
              das ações do governo no setor agropecuário.
            </p>
          </div>

          <div>
            <h2 className="citacaoRealizacoes">Citação na mídia </h2>

            <div className="linkcitacaoRealizacoes" onClick={clicarNoLink}>
              • http://jornal.com
            </div>
          </div>
          <div>
            <h2 className="citacaoRealizacoes" style={{ marginTop: "32px" }}>
              Anexo{" "}
            </h2>
            <div className="anexosRealizacoes" style={{ marginLeft: "12px" }}>
              <SvgIcon style={{ fontSize: "16px", color: "#008240" }}>
                <Artigo />
              </SvgIcon>
              <div className="artigoTituloRealizacoes">Artigo</div>
            </div>
          </div>
        </div>

        <div className="vejaMaisRealizacoes">
      
          <div>Veja Mais</div>
          <div
            className="postvermaisRealizacoes"
            style={{
              backgroundImage: "url(" + Img1 + ")",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="postVerMaisTituloRealizacoes">
              INOVAÇÃO EM TECNOLOGIA
            </div>
          </div>
          <div
            className="postvermaisRealizacoes"
            style={{
              backgroundImage: "url(" + Img2 + ")",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="postVerMaisTituloRealizacoes">
              Produtividade da água
            </div>
          </div>
          <div
            className="postvermaisRealizacoes"
            style={{
              backgroundImage: "url(" + Img3 + ")",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="postVerMaisTituloRealizacoes">
              agregando valor à agricultura cearense
            </div>
          </div>
          <div
            className="postvermaisRealizacoes"
            style={{
              backgroundImage: "url(" + Img4 + ")",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="postVerMaisTituloRealizacoes">
              Eficiência do uso da água na agricultura
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
