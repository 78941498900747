import { useEffect, useState } from 'react';
//search component
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Portas from '../../../portas';

//indicadores ciencia
import Indrh1 from "../../ciencia/bolsas/indicadores/Indicador1";
import Indrh2 from "../../ciencia/bolsas/indicadores/Indicador2";
import Indrh3 from "../../ciencia/bolsas/indicadores/Indicador3";
import Indrh4 from "../../ciencia/bolsas/indicadores/Indicador4";

import Indaux1 from "../../ciencia/auxilio/indicadores/Indicador1";
import Indaux2 from "../../ciencia/auxilio/indicadores/Indicador2";
import Indaux3 from "../../ciencia/auxilio/indicadores/Indicador3";
import Indaux4 from "../../ciencia/auxilio/indicadores/Indicador4";
import Indaux5 from "../../ciencia/auxilio/indicadores/Indicador5";
import Indaux6 from "../../ciencia/auxilio/indicadores/Indicador6";

import Indinterior1 from "../../ciencia/interiorizacao/indicadores/Indicador1";
import Indinterior2 from "../../ciencia/interiorizacao/indicadores/Indicador2";

import IndEmm1 from "../../inovacao/empresarial/indicadores/Indicador1";
import IndEmp2 from "../../inovacao/empresarial/indicadores/Indicador2";

import LoadingPainel from '../../../components/painelndicador/loadingIndicador/LoadingPainel';

const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965"
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#405965',
        },
        '&:hover .MuiInput-underline:before': {
            borderBottomColor: '#20764B',
        },
        '& .Mui-focused:after': {
            borderBottomColor: '#20764B',
        },
    },
})(TextField);

export default function Apoio() {

    const [searchField, setSearchField] = useState("");
    const [data, setData] = useState([])
    const [dataEmp, setDataEmp] = useState([])

    function submit(e) {
        e.preventDefault();
        setSearchField(document.getElementById("searchfield").value);
    }

    async function getData() {

        try {
            const response = await fetch(Portas().serverHost + "/fatos/cientifica",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();
            setData(resJSON)

        }
        catch (err) {

        }

        try {
            const response = await fetch(Portas().serverHost + "/fatos/empresarial",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();
            setDataEmp(resJSON)

        }
        catch (err) {

        }

    }

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div style={{ backgroundColor: "white" }}>
                <div style={{ paddingTop: "35px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                    <form onSubmit={submit}>
                        <CssTextField
                            id='searchfield'
                            style={{ width: "1272px" }}
                            placeholder={"Buscar indicador"}
                            onBlur={(event) => setSearchField(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                    {data.length > 0 && dataEmp.length > 0
                        ?
                        <div style={{ width: "1366px" }}>
                            {"Investimentos em Bolsas de Iniciação Científica e Tecnológica – BICT".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indrh1 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos em Bolsas de Mestrado e Doutorado".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indrh2 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos em Bolsas do Programa Universitário Avance".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indrh3 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos realizados no Programa de Inclusão Social – BSocial".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indrh4 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Auxilio para apoio a projetos de grupos de pesquisas".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indaux1 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos realizados no Programa de Núcleos de Excelência – PRONEX".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indaux2 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos realizados no Programa de Núcleos Emergentes – PRONEM".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indaux3 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos realizados no Programa de Desenvolvimento Científico e Tecnológico Regional – PDCTR".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indaux4 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos realizados no Programa de Apoio a Projetos de Doutorado Interinstitucional – DINTER".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indaux5 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos realizados no Programa de Pós-Doutorado para Jovens Doutores".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indaux6 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos em Bolsas de Produtividade em Pesquisa, Estímulo à Interiorização e à Inovação Tecnológica – BPI".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indinterior1 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos em Auxílio de Produtividade em Pesquisa, Estímulo à Interiorização e à Inovação Tecnológica – BPI".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Indinterior2 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos nos Programas de Apoio à Inovação Empresarial (Valores Contratados)".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <IndEmm1 data={dataEmp} found={searchField} />
                                :
                                <></>
                            }
                            {"Investimentos nos Programas de Apoio à Inovação Empresarial (Valores Submetidos)".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <IndEmp2 data={dataEmp} found={searchField} />
                                :
                                <></>
                            }

                        </div>
                        :
                        <LoadingPainel />
                    }
                </div>

            </div>
        </div>
    )
}