import toBRL from "../funcoes/toBRL";

export default function getDataset(allData, dataset) {

    var auxData = [];

    for (var i = 0; i < allData.length; i++) {

        var file = []
        var value = ""

        for (var j = 0; j < dataset.length; j++) {
            
            if (allData[i][dataset[j].campo] !== null && allData[i][dataset[j].campo] !== undefined) {
                
                if (dataset[j].tipo === "reais") {
                    value = toBRL(allData[i][dataset[j].campo])
                }
                else {
                    value = allData[i][dataset[j].campo]
                }

                file.push({ value: value, style: { font: { sz: dataset[j].tamanho } } })
            }
            else {
                file.push({ value: "-", style: { font: { sz: dataset[j].tamanho } } })
            }

           
        } 
        
        auxData.push(file)

    }

    var colunas = [];

    for(var i = 0; i < dataset.length; i++){
        colunas.push({title: dataset[i].titulo, width: { wpx: dataset[i].wpx }})
    }

    const multiDataSet = [
        {
            columns: colunas,
            data: auxData

        }
    ];

    return multiDataSet;
}