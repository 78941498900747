export default function getRegioes(){
	var regioes = [
            {
                type: 'Grande Fortaleza',
                value: 0,
            },
            {
                type: 'Serra da Ibiapaba',
                value: 0,
            },
            {
                type: 'Sertão de Canindé',
                value: 0,
            },
            {
                type: 'Sertão de Sobral',
                value: 0,
            },
            {
                type: 'Maciço de Baturité',
                value: 0,
            },
            {
                type: 'Sertão Central',
                value: 0,
            },
            {
                type: 'Cariri',
                value: 0,
            },
            {
                type: 'Vale do Jaguaribe',
                value: 0,
            },
            {
                type: 'Sertão dos Crateús',
                value: 0,
            },
            {
                type: 'Litoral Norte',
                value: 0,
            },
            {
                type: 'Litoral Oeste / Vale do Curu',
                value: 0,
            },
            {
                type: 'Sertão dos Inhamuns',
                value: 0,
            },
            {
                type: 'Centro Sul',
                value: 0,
            },
            {
                type: 'Litoral Leste',
                value: 0,
            },
        ];
 
    return regioes;
}