import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Animacao from "../../../components/animacoes/IncrementNumbers";
import Donut from "../../../components/graficos/donutPaineis/Donut";
import "./sobre.css";

import SvgIcon from "@material-ui/core/SvgIcon";
import BlogsSection from "../../../components/blogsSection/BlogsSection";
import { ReactComponent as Projetos } from "../../../components/icons/paineis/caneta.svg";
import { ReactComponent as InstituicoesParceiras } from "../../../components/icons/paineis/casinha.svg";
import { ReactComponent as PaisesAlcancados } from "../../../components/icons/paineis/mala.svg";
import { ReactComponent as Bolsistas } from "../../../components/icons/paineis/pessoas.svg";

const useStyles = makeStyles({
  selectRoot: {
    "&:focus": {
      backgroundColor: "inherit",
    },
  },
  select: {
    "&:before": {
      borderColor: "#20764B",
    },
    "&:after": {
      borderColor: "#20764B",
    },
    color: "#405965",
    fontWeight: "bold",
  },
  icon: {
    fill: "#20764B",
  },
});

export default function Sobre() {
  var blogs = [
    {
      tipo: 1,
    },
    {
      tipo: 1,
    },
  ];
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [anos, setAnos] = useState([
    2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
  ]);
  const [dados, setDados] = useState([
    { ano: 2011, type: "Funcap/INRIA", value: 93524.00 },
    { ano: 2012, type: "Funcap/INRIA/CNRS", value: 78960.00 },
    { ano: 2012, type: "Funcap/CAPES", value: 519388.00 },
    { ano: 2013, type: "Funcap/CAPES", value: 420000.00 },
    { ano: 2014, type: "Funcap/INRIA/CNRS", value: 149500.00 },
    { ano: 2014, type: "Funcap/CAPES", value: 920000.00 },
    { ano: 2016, type: "Funcap/INRIA/CNRS", value: 80990.00 },
    { ano: 2018, type: "Funcap/INRIA/CNRS", value: 138990.00 },
    { ano: 2018, type: "Funcap/JPI", value: 207740.00 },
    { ano: 2018, type: "Funcap/CNPq/ERC", value: 48560.00 },
    { ano: 2018, type: "Funcap/British Council", value: 23275.00 },
    { ano: 2018, type: "Funcap/CNPq/ICMBio", value: 50135.00 },
    { ano: 2018, type: "Funcap/FCT", value: 734310.00 },
    { ano: 2019, type: "Funcap/Bill e Melinda Gate/CNPq", value: 108456.00 },
    { ano: 2019, type: "Funcap/MRC/FUNDO Newton", value: 100200.00 },
    { ano: 2019, type: "Funcap/CNPq/FUNDO Newton/Uk Academies", value: 70328.00 },
    { ano: 2020, type: "Funcap/CNPq/TheUkAcademies", value: 12300.00 },
    { ano: 2021, type: "Funcap/CNPq/MCTI/PELD", value: 198000.00 },
  ]);
  const [minYear, setMinYear] = useState(2021);
  const [maxYear, setMaxYear] = useState(2021);

  const [filterData, setFilterData] = useState([
    { type: "Funcap/INRIA", value: 93524.00 },
    { type: "Funcap/INRIA/CNRS", value: 448440.00 },
    { type: "Funcap/CAPES", value: 1859388.00 },
    { type: "Funcap/JPI", value: 207740.00 },
    { type: "Funcap/CNPq/ERC", value: 48560.00 },
    { type: "Funcap/British Council", value: 23275.00 },
    { type: "Funcap/CNPq/ICMBio", value: 50135.00 },
    { type: "Funcap/FCT", value: 734310.00 },
    { type: "Funcap/Bill e Melinda Gate/CNPq", value: 108456.00 },
    { type: "Funcap/MRC/FUNDO Newton", value: 100200.00 },
    { type: "Funcap/CNPq/FUNDO Newton/Uk Academies", value: 70328.00 },
    { type: "Funcap/CNPq/TheUkAcademies", value: 12300.00 },
    { type: "Funcap/CNPq/MCTI/PELD", value: 198000.00 }
  ]);

  function updateFilterData(minYear, maxYear) {
    let array = [...filterData]
    for (var o = 0; o < array.length; o++) {
      array[o].value = 0
    }
    
    for (var i = 0; i < dados.length; i++) {
      if (dados[i].ano >= minYear && dados[i].ano <= maxYear) {
        if (dados[i].type === "Funcap/INRIA") {
          array[0].value += dados[i].value
        }
        if (dados[i].type === "Funcap/INRIA/CNRS") {
          array[1].value += dados[i].value
        }
        if (dados[i].type === "Funcap/CAPES") {
          array[2].value += dados[i].value
        }
        if (dados[i].type === "Funcap/JPI") {
          array[3].value += dados[i].value
        }
        if (dados[i].type === "Funcap/CNPq/ERC") {
          array[4].value += dados[i].value
        }
        if (dados[i].type === "Funcap/British Council") {
          array[5].value += dados[i].value
        }
        if (dados[i].type === "Funcap/CNPq/ICMBio") {
          array[6].value += dados[i].value
        }
        if (dados[i].type === "Funcap/FCT") {
          array[7].value += dados[i].value
        }
        if (dados[i].type === "Funcap/Bill e Melinda Gate/CNPq") {
          array[8].value += dados[i].value
        }
        if (dados[i].type === "Funcap/MRC/FUNDO Newton") {
          array[9].value += dados[i].value
        }
        if (dados[i].type === "Funcap/CNPq/FUNDO Newton/Uk Academies") {
          array[10].value += dados[i].value
        }
        if (dados[i].type === "Funcap/CNPq/TheUkAcademies") {
          array[11].value += dados[i].value
        }
        if (dados[i].type === "Funcap/CNPq/MCTI/PELD") {
          array[12].value += dados[i].value
        }
      }
    }
    setFilterData(array)
  }

  const handleChangeMinYear = (event) => {
    var min;
    var max;

    if (event.target.value > maxYear) {
      min = maxYear;
      max = event.target.value;
      setMinYear(maxYear);
      setMaxYear(event.target.value);
      updateFilterData(min, max);
    } else {
      min = event.target.value;
      max = maxYear;
      setMinYear(event.target.value);
      updateFilterData(min, max);
    }
  };

  const handleChangeMaxYear = (event) => {
    var min;
    var max;

    if (event.target.value < minYear) {
      min = event.target.value;
      max = minYear;
      setMaxYear(minYear);
      setMinYear(event.target.value);
      updateFilterData(min, max);
    } else {
      min = minYear;
      max = event.target.value;
      setMaxYear(event.target.value);
      updateFilterData(min, max);
    }
  };

  const [data, setData] = useState([
    { ano: 2008, type: "Alemanha", area: "agronegocio", value: 101579.4 },
    { ano: 2008, type: "Argentina", area: "agronegocio", value: 37600 },
    { ano: 2008, type: "Austrália", area: "agronegocio", value: 37590 + 19806 },
    { ano: 2008, type: "Bélgica", area: "agronegocio", value: 30000 },
    {
      ano: 2008,
      type: "Colômbia",
      area: "agronegocio",
      value: 160000 + 33330 + 16500,
    },
    { ano: 2008, type: "Dinamarca", area: "agronegocio", value: 35200 },
    { ano: 2008, type: "Escócia", area: "agronegocio", value: 9800 },
    {
      ano: 2008,
      type: "Estados Unidos da América",
      area: "agronegocio",
      value: 633430.84,
    },
    { ano: 2008, type: "Finlândia", area: "agronegocio", value: 207740 },
    { ano: 2008, type: "França", area: "agronegocio", value: 775139.7 },
    {
      ano: 2008,
      type: "Reino Unido",
      area: "agronegocio",
      value: 259003.96 + 12300,
    },
    { ano: 2008, type: "Israel", area: "agronegocio", value: 39392 },
    {
      ano: 2008,
      type: "Itália",
      area: "agronegocio",
      value: 20000 + 36845 + 39999 + 192000,
    },
    { ano: 2008, type: "México", area: "agronegocio", value: 26000 },
    { ano: 2008, type: "Portugal", area: "agronegocio", value: 877910 },
    { ano: 2008, type: "Tchéquia", area: "agronegocio", value: 198000 },
  ]);


  function getIndicadorNumber(indi) {
    var data = [
      { ano: 2011, ind: "Bolsistas", valor: 2 },
      { ano: 2011, ind: "Projetos", valor: 2 },
      { ano: 2011, ind: "Países incluídos", valor: 1 },
      { ano: 2011, ind: "Cooperações", valor: 1 },
      { ano: 2012, ind: "Bolsistas", valor: 23 },
      { ano: 2012, ind: "Projetos", valor: 23 },
      { ano: 2012, ind: "Países incluídos", valor: 9 },
      { ano: 2012, ind: "Cooperações", valor: 2 },
      { ano: 2013, ind: "Bolsistas", valor: 19 },
      { ano: 2013, ind: "Projetos", valor: 19 },
      { ano: 2013, ind: "Países incluídos", valor: 4 },
      { ano: 2013, ind: "Cooperações", valor: 1 },
      { ano: 2014, ind: "Bolsistas", valor: 15 },
      { ano: 2014, ind: "Projetos", valor: 15 },
      { ano: 2014, ind: "Países incluídos", valor: 3 },
      { ano: 2014, ind: "Cooperações", valor: 2 },
      { ano: 2015, ind: "Bolsistas", valor: 0 },
      { ano: 2015, ind: "Projetos", valor: 0 },
      { ano: 2015, ind: "Países incluídos", valor: 0 },
      { ano: 2015, ind: "Cooperações", valor: 0 },
      { ano: 2016, ind: "Bolsistas", valor: 1 },
      { ano: 2016, ind: "Projetos", valor: 1 },
      { ano: 2016, ind: "Países incluídos", valor: 0 },
      { ano: 2016, ind: "Cooperações", valor: 1 },
      { ano: 2017, ind: "Bolsistas", valor: 0 },
      { ano: 2017, ind: "Projetos", valor: 0 },
      { ano: 2017, ind: "Países incluídos", valor: 0 },
      { ano: 2017, ind: "Cooperações", valor: 0 },
      { ano: 2018, ind: "Bolsistas", valor: 17 },
      { ano: 2018, ind: "Projetos", valor: 17 },
      { ano: 2018, ind: "Países incluídos", valor: 1 },
      { ano: 2018, ind: "Cooperações", valor: 6 },
      { ano: 2019, ind: "Bolsistas", valor: 6 },
      { ano: 2019, ind: "Projetos", valor: 6 },
      { ano: 2019, ind: "Países incluídos", valor: 1 },
      { ano: 2019, ind: "Cooperações", valor: 3 },
      { ano: 2020, ind: "Bolsistas", valor: 1 },
      { ano: 2020, ind: "Projetos", valor: 1 },
      { ano: 2020, ind: "Países incluídos", valor: 1 },
      { ano: 2020, ind: "Cooperações", valor: 1 },
      { ano: 2021, ind: "Bolsistas", valor: 1 },
      { ano: 2021, ind: "Projetos", valor: 1 },
      { ano: 2021, ind: "Países incluídos", valor: 1 },
      { ano: 2021, ind: "Cooperações", valor: 1 }
    ]

    var count = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].ano >= minYear && data[i].ano <= maxYear) {
        if (data[i].ind === indi) {
          count += data[i].valor
        }
      }
    }

    return count
  }


  return (
    <div className="contentCienciaInternacionalizacaoSobre">
      {/*
            <div className="painelWorldMapCienciaInternacionalizacaoSobre"  >
                <div className="titleWorldMapCienciaInternacionalizacaoSobre">CONHEÇA NOSSAS PARTICIPAÇÕES MUNDO A FORA</div>
                <div className="subtitleWorldMapCienciaInternacionalizacaoSobre">Clique sobre os balões e Instituições para saber mais</div>
                <div className="divWorldMapCienciaInternacionalizacaoSobre">
                    <WorldMap data={data}/>
                </div>

            </div>
            */}
      <div className="painelCienciaInternacionalizacaoSobre">
        <div className="textPainelCienciaInternacionalizacaoSobre">
          INVESTIMENTOS EM INTERNACIONALIZAÇÃO
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <div className="text2PainelCienciaInternacionalizacaoSobre">
            Investimentos realizados de
          </div>
          <div className="divDropdownPeriodoCienciaInternacionalizacaoSobre">
            <FormControl className="dropdownPeriodoCienciaInternacionalizacaoSobre">
              <Select
                value={minYear}
                onChange={handleChangeMinYear}
                displayEmpty
                disableUnderline
                classes={{ root: classes.selectRoot }}
                className={classes.select}
                MenuProps={{ disablePortal: true }}
                inputProps={{
                  "aria-label": "Without label",
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                {anos.map((ano) => (
                  <MenuItem value={ano}>{ano}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="text2PainelCienciaInternacionalizacaoSobre">a</div>
          <div className="divDropdownPeriodoCienciaInternacionalizacaoSobre">
            <FormControl className="dropdownPeriodoCienciaInternacionalizacaoSobre">
              <Select
                value={maxYear}
                onChange={handleChangeMaxYear}
                displayEmpty
                disableUnderline
                classes={{ root: classes.selectRoot }}
                className={classes.select}
                MenuProps={{ disablePortal: true }}
                inputProps={{
                  "aria-label": "Without label",
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                {anos.map((ano) => (
                  <MenuItem value={ano}>{ano}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div
          style={{
            marginBottom: "32px",
            paddingBottom: "32px",
            paddingTop: "42px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="donutCienciaInternacionalizacaoSobre">
            <Donut data={filterData} cor={"amarelo"} orient={"vert2"}></Donut>
          </div>
          <div className="divMiniIndicadoresCienciaInternacionalizacao">
            <div className="divIconesCienciaInternacionalizacao">
              <div className="divIconeCienciaInternacionalizacao">
                <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                  <Bolsistas />
                </SvgIcon>
                <p className="iconeTituloCienciaInternacionalizacao">
                  <b className="iconeNumeroCienciaInternacionalizacao">
                    <Animacao data={getIndicadorNumber("Bolsistas")} />
                  </b>
                  <br></br>Bolsistas<br></br>
                </p>
              </div>
              <div className="divIconeCienciaInternacionalizacaoEnd">
                <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                  <PaisesAlcancados />
                </SvgIcon>
                <p className="iconeTituloCienciaInternacionalizacao">
                  <b className="iconeNumeroCienciaInternacionalizacao">
                    <Animacao data={getIndicadorNumber("Países incluídos")} />
                  </b>
                  <br></br>Países incluídos<br></br>
                </p>
              </div>
            </div>
            <div className="divIconesCienciaInternacionalizacao">
              <div className="divIconeCienciaInternacionalizacao">
                <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                  <Projetos />
                </SvgIcon>
                <p className="iconeTituloCienciaInternacionalizacao">
                  <b className="iconeNumeroCienciaInternacionalizacao">
                    <Animacao data={getIndicadorNumber("Projetos")} />
                  </b>
                  <br></br>Projetos<br></br>
                </p>
              </div>
              <div className="divIconeCienciaInternacionalizacaoEnd">
                <SvgIcon style={{ fontSize: "50px", color: "#FDCC09" }}>
                  <InstituicoesParceiras />
                </SvgIcon>
                <p className="iconeTituloCienciaInternacionalizacao">
                  <b className="iconeNumeroCienciaInternacionalizacao">
                    <Animacao data={getIndicadorNumber("Cooperações")} />
                  </b>
                  <br></br>Cooperações<br></br>
                </p>
              </div>
            </div>
            <div className="divIconesCienciaInternacionalizacaoEnd">
              <div className="divIconeCienciaInternacionalizacao">
                <SvgIcon
                  style={{ fontSize: "50px", color: "transparent" }}
                ></SvgIcon>
                <p
                  className="iconeTituloCienciaInternacionalizacao"
                  style={{ color: "transparent" }}
                >
                  <b
                    className="iconeNumeroCienciaInternacionalizacao"
                    style={{ color: "transparent" }}
                  ></b>
                  <br></br>
                  <br></br>
                </p>
              </div>
              <div className="divIconeCienciaInternacionalizacaoEnd">
                <SvgIcon
                  style={{ fontSize: "50px", color: "transparent" }}
                ></SvgIcon>
                <p
                  className="iconeTituloCienciaInternacionalizacao"
                  style={{ color: "transparent" }}
                >
                  <b
                    className="iconeNumeroCienciaInternacionalizacao"
                    style={{ color: "transparent" }}
                  ></b>
                  <br></br>
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlogsSection page={"Ciência (Internacionalização)"} />
    </div>
  );
}
