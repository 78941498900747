import React, { useState } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
} from "react-simple-maps";
import geoMap from "../../../../../../../components/worldMap/World";
import { zoom } from "d3";
import cores from "../../../../../../../components/cores/cores";

const d3 = require("d3");

const MapChart = ({ setTooltipContent, data }) => {

    function getValor(geo){
        const { NAME_PTBR } = geo.properties;

        for (var i = 0; i < data.length; i++) {
            if (`${NAME_PTBR}` === data[i].type) {
                return data[i].value
            }
        }

        return 0
    }

    function getColor(geo) {

        var value = getValor(geo);

        var verdes = cores().amarelos;

        var menor = data[0].value;
        var maior = data[0].value;

        for (var i = 0; i < data.length; i++) {
            if (data[i].value > maior) {
                maior = data[i].value;
            }
            if (data[i].value < menor) {
                menor = data[i].value;
            }
        }

        if (menor > 1) {
            menor = Math.log2(menor)
        }
        if (maior > 1) {
            maior = Math.log2(maior)
        }

        var colorScale = d3.scaleQuantize().domain([menor, maior]).range(verdes);

        if (value > 0) {
            return colorScale(Math.log2(value));
        }
        return  "#D5D790";

    }

    return (
        <div className="componentWorldMapCienciaInternacionalizacaoSobre">
            {zoom}
            <ComposableMap width={900} height={446} projectionConfig={{ rotate: [-10, 0, 0], scale: 147 }}>
                <Geographies geography={geoMap}>
                    {({ geographies }) =>
                        geographies
                            .filter(d => d.properties.REGION_UN !== "Antarctica")
                            .map((geo) => {
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        onMouseEnter={() => {
                                            const { NAME_PTBR } = geo.properties;
                                            setTooltipContent([`${NAME_PTBR}`, getValor(geo)]);
                                        }}
                                        onMouseLeave={() => {
                                            setTooltipContent("");
                                        }}
                                        style={{
                                            default: {
                                                fill: getColor(geo),
                                                outline: "none",
                                                stroke: "none"
                                            },
                                            hover: {
                                                fill: "#FDCC09",
                                                outline: "none",
                                                stroke: "none"
                                            },
                                            pressed: {
                                                fill: "#FDCC09",
                                                outline: "none",
                                                stroke: "none"
                                            }
                                        }}
                                    />
                                );
                            })
                    }
                </Geographies>
            </ComposableMap>
        </div >

    );
};

export default MapChart;
