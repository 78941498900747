import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles((theme) => ({
    SVGMaplocation: {
        fill: (props) => props.fill,
        cursor: "pointer",
        '&:hover': {
            fill: "rgb(253, 204, 9)",
            opacity: "25%",
            outline: 0,
        },
    },
}));

export default UseStyles;
