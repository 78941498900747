import React from 'react';
import Modal from '@material-ui/core/Modal';
import BlogPerfil from "./BlogPerfil";
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';

export default function SimpleModal({ open, setOpen, selectedCientista }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ overflowY: "scroll" }}
        >
          <Slide direction="down" in={open} mountOnEnter unmountOnExit timeout={2000} >
            <div >
              <BlogPerfil setOpen={() => setOpen(false)} selectedCientista={selectedCientista}></BlogPerfil>
            </div>
          </Slide>
        </Modal>
      </div>
    </Slide>

  );
}
