// verifica se um ano já foi adicionado ao intervalo
function verifyDuplicates(ano, listaAnos) {

    if (listaAnos.length < 1) {
        return false;
    }

    for (var j = 0; j < listaAnos.length; j++) {
        if (parseInt(ano) === parseInt(listaAnos[j])) {
            return true;
        }
    }

    return false;

}

//verifica se um ano pertence a um intervalo
function isInInterval(ano, maiorAno, menorAno) {

    if (ano >= menorAno && ano <= maiorAno) {
        return true;
    }

    return false;
}


export default function getIntervalProps(data) {

    var maiorAno = parseInt(data[0].ano);
    var menorAno = parseInt(data[0].ano);
    var intervaloExistente = [];

    //verifica qual o maior e o menor ano da lista
    for (var i = 0; i < data.length; i++) {

        if (parseInt(data[i].ano) > maiorAno) {
            maiorAno = parseInt(data[i].ano);
        }
        else if (parseInt(data[i].ano) < menorAno)
            menorAno = parseInt(data[i].ano);
    }


    //trata erros e controi o intervalo
    for (var k = 0; k < data.length; k++) {
        if (isInInterval(parseInt(data[k].ano), maiorAno, menorAno) === true) {
            if (verifyDuplicates(parseInt(data[k].ano), intervaloExistente) === false) {
                intervaloExistente.push(parseInt(data[k].ano))
            }
        }
    }

    //ordena array

    for (var l = 0; l < intervaloExistente.length; l++) {
        for (var m = l + 1; m < intervaloExistente.length; m++) {
            if (intervaloExistente[m] < intervaloExistente[l]) {
                var aux = intervaloExistente[m];
                intervaloExistente[m] = intervaloExistente[l];
                intervaloExistente[l] = aux;
            }

        }
    }

    return (
        {
            intervaloExistente,
            maiorAno,
            menorAno
        }
    )
}