import Backdrop from '@material-ui/core/Backdrop';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import SvgIcon from "@material-ui/core/SvgIcon";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from "react";
import Tooltip from 'react-tooltip-lite';
import StoreContext from "../../../components/Store/Context";
import substringColor from "../../../components/funcoes/substringColor";
import { ReactComponent as Balao } from "../../../components/icons/gerenciamento/balao.svg";
import { ReactComponent as DeleteSvg } from "../../../components/icons/gerenciamento/delete.svg";
import { ReactComponent as Exibir } from "../../../components/icons/gerenciamento/exibirBlog.svg";
import { ReactComponent as Filtro } from "../../../components/icons/gerenciamento/filtro.svg";
import { ReactComponent as FiltroPreenchido } from "../../../components/icons/gerenciamento/filtroPreenchido.svg";
import { ReactComponent as RevisaoRecusado } from "../../../components/icons/gerenciamento/revisaoRecusado.svg";
import { ReactComponent as RevisaoAprovado } from "../../../components/icons/gerenciamento/revisaoSelected.svg";
import Error from "../../../components/icons/loading/falha_estatico.png";
import Loading from "../../../components/icons/loading/greenLoading.gif";
import Portas from "../../../portas";
import "./conteudosFuncap.css";
import SimpleModal from "./edicaoBlog/ModalVisualizacao";

const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965"
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#405965',
        },
        '&:hover .MuiInput-underline:before': {
            borderBottomColor: '#20764B',
        },
        '& .Mui-focused:after': {
            borderBottomColor: '#20764B',
        },
    },
})(TextField);

function createData(name, data, autor, tipo, revisao) {
    return { name, data, autor, tipo, revisao };
}

const rows = [
    createData('Com apoio da Funcap, Museu de Paleontologia...', "16/03/2022", "Giovanni Rafael", "Realização", true),
    createData('Artigo com participação de pesquisadores a...', "25/11/2021", "Giovanni Rafael", "Realização", true),
    createData('Cientistas do Ceará publicam artigo que pode...', "11/01/2021", "Paulo Silva", "Realização", true),
    createData('Mudanças na dieta de ovinos podem reduzir...', "04/12/2020", "Giovanni Rafael", "Realização", true),
    createData('Estudantes da UNILAB publicam artigo em revista...', "13/11/2020", "Giovanni Rafael", "Realização", true),
    createData('Com o apoio da Funcap, pesquisadores da Uece...', "24/08/2021", "Giovanni Rafael", "Realização", true),
    createData('British Concil', "29/06/2021", "Paulo Silva", "Cooperação", true),
    createData('Inovação Científica Internacional', "29/06/2020", "Paulo Silva", "Projeto", true),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Postagens' },
    { id: 'data', numeric: true, disablePadding: false, label: 'Data de postagem' },
    { id: 'autor', numeric: true, disablePadding: false, label: 'Autor' },
    { id: 'tipo', numeric: true, disablePadding: false, label: 'Tipo' },
    { id: 'revisao', numeric: true, disablePadding: false, label: 'Revisão' },
    { id: 'exibir', numeric: true, disablePadding: false, label: 'Exibir' }
];

const StyledTableSortLabel = withStyles(() =>
    createStyles({
        root: {
            color: '#20764B',
            "&:hover": {
                color: '#20764B',
            },
            '&$active': {
                color: '#20764B',
            },
        },
        active: {},
        icon: {
            color: "#20764B !important"
        }
    })
)(TableSortLabel);


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper1: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    paper: {
        width: '100%',
        marginTop: "32px",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ConteudosFuncap() {

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [content, setContent] = useState([])
    const [open, setOpen] = useState(false);
    const [tipoBlog, setTipoBlog] = useState("");
    const { token, setToken } = useContext(StoreContext);
    const [user, setUser] = useState();
    const [selecionados, setSelecionados] = useState([])
    const [textOpened, setTextOpened] = useState(false)
    const [searchField, setSearchField] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [openPopupMult, setOpenPopupMult] = useState(false);
    const [textPopup, setTextPopup] = useState(false);
    const [statusExclusao, setStatusExclusao] = useState("Confirmar");
    const [tipoExclusao, setTipoExclusao] = useState("unica");
    const [resultadoExclusao, setResultadoExclusao] = useState(false)
    const [listaExcluir, setListaExcluir] = useState([]);
    const [listaExcluidos, setListaExcluidos] = useState([]);
    const [openFilter, setOpenFilter] = useState(false);
    const [selectFilter, setSelectFilter] = useState(false);
    const [filtro, setFiltro] = useState([]);
    const [fullData, setFullData] = useState([]);
   
    const handleClose = () => {
        setSelectFilter(false)
    }
    
    const handleOpen = () => {
        setSelectFilter(true)
    }    

    content.sort((a, b) => (new Date(a.content.createdAt) > new Date(b.content.createdAt) ? -1 : 1))

    const getContent = async () => {
        try {

            const response3 = await fetch(Portas().serverHost + "/usuarios/me?cientista=true&acesso=true&image=true",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': "Bearer " + token,
                    }
                }
            );

            const resJSON3 = await response3.json();
            setUser(resJSON3);
            
            var selecionados = [];

            if (resJSON3.perfil_acesso.acesso === "Cientista-Chefe") {
                const responseRevisao = await fetch(Portas().serverHost + "/revisoes/cientista",
                {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                });

                const resJSONRevisao = await responseRevisao.json();
                let arrRevisao = []

                for(var i=0; i < resJSONRevisao.length; i++) {
                    arrRevisao.push(
                        {
                            content: resJSONRevisao[i],
                            selected: false
                        }
                    )
                }

                for ( var i= 0; i < arrRevisao.length; i++) {
                    selecionados.push(false)
                }
                setContent(arrRevisao)
                setFullData(arrRevisao)
                setSelecionados(selecionados)
            }
            else {
                const response = await fetch(Portas().serverHost + "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
                {
                    method: "GET",
                });

                const resJSON = await response.json();
                console.log(resJSON)

                const response2 = await fetch(Portas().serverHost + "/realizacoes?image=true&area=true&projeto=true&anexo=true&pagina=0&tamanho=5&paginacao=false&autor=true",
                    {
                        method: "GET",
                    }
                );

                const resJSON2 = await response2.json();

                let arr = []

                for (var i = 0; i < resJSON.length; i++) {
                    arr.push(
                        {
                            type: "projeto",
                            content: resJSON[i],
                            selected: false
                        }
                    )
                }


                for (var i = 0; i < resJSON2.length; i++) {
                    arr.push(
                        {
                            type: "realização",
                            content: resJSON2[i],
                            selected: false
                        }
                    )
                }

                for (var i = 0; i < arr.length; i++) {
                    selecionados.push(false)
                }
                setContent(arr)
                setFullData(arr)
                setSelecionados(selecionados)
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    function handleClickVisualizacao(blog) {
        setTipoBlog(blog)
        setOpen(true)
    }

    function getState(content) {
        var cont = 0
        for (var i = 0; i < content.length; i++) {
            if (content[i].selected === true) {
                cont++
            }
        }
        if (cont === content.length) {
            return true
        }
        return false
    }

    function EnhancedTableHead(props) {
        const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow >
                    <TableCell padding="checkbox">
                        <Checkbox
                            //indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={getState(content)}
                            onChange={handleSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            style={{ color: "#20764B" }}
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >

                            <div>
                                {headCell.label}
                            </div>

                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = () => {
        var auxSelecionados = []
        if (!getState(content)) {
            for (var i = 0; i < content.length; i++) {
                auxSelecionados.push(content[i]);
                auxSelecionados[i].selected = true
            }
        }
        else {
            for (var i = 0; i < selecionados.length; i++) {
                auxSelecionados.push(content[i]);
                auxSelecionados[i].selected = false
            }
        }
        setContent(auxSelecionados)
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    function isSelected(index) {

        var auxSelecionados = []
        for (var i = 0; i < content.length; i++) {
            auxSelecionados.push(content[i])
            if (i === index) {
                auxSelecionados[i].selected = !content[i].selected
            }
        }

        setContent(auxSelecionados)

    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function getData(timestamp, tipo) {
        var data = new Date(timestamp)
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()

        if (tipo === "dia") {
            return ("00" + dia).slice(-2)
        }

        if (tipo === "mes") {
            return ("00" + mes).slice(-2)
        }

        if (tipo === "ano") {
            return ("0000" + ano).slice(-2)
        }
    }

    const deleteContent = async () => {

        setStatusExclusao("Excluindo")
        setListaExcluidos([])
        setListaExcluidos([])

        for (var i = 0; i < content.length; i++) {

            if (content[i].selected) {

                try {

                    var rota = ""
                    if (content[i].type === 'projeto'|| content[i].content.data_inicio !== undefined ) {
                        rota = '/projetos/' + content[i].content.id
                    }
                    else {
                        rota = '/realizacoes/' + content[i].content.id
                    }

                    //adiciona elemento a ser excluido
                    var lexcluir = []
                    lexcluir.push(content[i].content.titulo)
                    setListaExcluir(lexcluir)

                    const response = await fetch(Portas().serverHost + rota,
                        {
                            method: "DELETE",
                            headers: {
                                'Authorization': "Bearer " + token
                            }
                        }
                    );

                    //Realização deletado com sucesso.
                    //Projeto deletado com sucesso.

                    const resJSON = await response.json();

                    //var resJSON = { message: "Realização deletado com sucesso." }

                    //armazena na lista de excluidos, se houve sucesso.
                    if (resJSON.message === "Realização deletado com sucesso." || resJSON.message === "Projeto deletado com sucesso.") {
                        var lexcluidos = listaExcluidos
                        lexcluidos.push(content[i].content.titulo)
                        setListaExcluidos(lexcluidos)
                        setListaExcluir([])

                        setResultadoExclusao(true)
                        setTextPopup(resJSON.message)
                        setStatusExclusao("Concluida")

                    }
                    else {
                        setResultadoExclusao(false)
                        setTextPopup(resJSON.message)
                        setStatusExclusao("Concluida")
                        break;
                    }
                }
                catch (err) {
                    
                }
            }
        }
        getContent()
    }

    function anySelected(content) {
        for (var i = 0; i < content.length; i++) {
            if (content[i].selected) {
                return true
            }
        }
        return false
    }

    const handleSearch = async (text) => {
        try {

            const response = await fetch(Portas().serverHost + "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();

            let dataProjeto= []
            for (var j=0; j<  resJSON.length;j++)
            {
                dataProjeto.push(
                    getData(resJSON[j].createdAt, "dia") + "/" +
                    getData(resJSON[j].createdAt, "mes") + "/" +
                    getData(resJSON[j].createdAt, "ano")
                )
               
            }
            console.log(dataProjeto)
            
            const response2 = await fetch(Portas().serverHost + "/realizacoes?image=true&area=true&projeto=true&anexo=true&pagina=0&tamanho=5&paginacao=false&autor=true",
                {
                    method: "GET",
                }
            );

            const resJSON2 = await response2.json();

            let dataRealizacao= []
            for (var k=0; k<resJSON2.length; k++)
            {
                dataRealizacao.push(
                    getData(resJSON2[k].createdAt, "dia") + "/" +
                    getData(resJSON2[k].createdAt, "mes") + "/" +
                    getData(resJSON2[k].createdAt, "ano")
                )
               
            }

            let arr = []

            for (var i = 0; i < resJSON.length; i++) {
                if (resJSON[i].titulo.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(
                        {
                            type: "projeto",
                            content: resJSON[i],
                            selected: false
                        }
                    )
                } else 
                if (resJSON[i].autor_projeto.nome.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(
                        {
                            type: "projeto",
                            content: resJSON[i],
                            selected: false
                        }
                    )
                } else 
                if (dataProjeto[i].toLowerCase().includes(text.toLowerCase())){
                    console.log(dataProjeto[i])
                    arr.push(
                        {
                            type: "projeto",
                            content: resJSON[i],
                            selected: false
                        }
                    )
                } 
            }


            for (var i = 0; i < resJSON2.length; i++) {
                if (resJSON2[i].titulo.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(
                        {
                            type: "realização",
                            content: resJSON2[i],
                            selected: false
                        }
                    )
                } else 
                if (resJSON2[i].autor_realizacao.nome.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(
                        {
                            type: "realização",
                            content: resJSON2[i],
                            selected: false
                        }
                    )
                }else 
                if (dataRealizacao[i].toLowerCase().includes(text.toLowerCase())){
                    console.log(dataRealizacao[i])
                    arr.push(
                        {
                            type: "realização",
                            content: resJSON2[i],
                            selected: false
                        }
                    )
                } 
            }

            setSearchField(text)

            const response3 = await fetch(Portas().serverHost + "/usuarios/me?cientista=true&acesso=true&image=true",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': "Bearer " + token,
                    }
                }
            );

            const resJSON3 = await response3.json();
            setUser(resJSON3);

            var auxConteudos = [];
            var selecionados = [];

            if (resJSON3.perfil_acesso.acesso === "Cientista-Chefe") {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].content.id_area === resJSON3.cientista.id_area) {
                        auxConteudos.push(arr[i])
                        selecionados.push(false)
                    }
                }
                setContent(auxConteudos)
                setSelecionados(selecionados)
            }
            else {
                for (var i = 0; i < arr.length; i++) {
                    selecionados.push(false)
                }
                setContent(arr)
                setSelecionados(selecionados)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    function handleExclusao() {

        setStatusExclusao("Confirmar")
        var quantSelecionados = 0
        var listaExc = []

        for (var i = 0; i < content.length; i++) {
            if (content[i].selected) {
                quantSelecionados++;
                listaExc.push(content[i].content.titulo)
            }
        }

        setListaExcluir(listaExc)

        if (quantSelecionados > 1) {
            setTipoExclusao("multipla")
            setOpenPopupMult(true)
        }

        else {
            setTipoExclusao("unica")
            setOpenPopup(true)
        }
    }

    const handleChange = (event) => {
        setFiltro(event.target.value);
        let filtered = [];
        var filtros = event.target.value;

        if (filtros.length < 1) {
            setContent(fullData)
            return
        }

        for (var i = 0; i < fullData.length; i++ ) {            
            for( var j = 0; j < filtros.length; j++) {
                if (fullData[i].content.data_inicio !== undefined && filtros[j] == 'Projeto') {
                    filtered.push(fullData[i]);
                    break
                }
                if (fullData[i].content.data_inicio == undefined && filtros[j] == 'Realização') {
                    filtered.push(fullData[i]);
                    break
                }
                
            }

        }
       
        setContent(filtered)
      };
     

    const names  = [    
        // {
        //     value:'Cooperação',
        //     label: 'Cooperação'
        // },
        {
            value:'Projeto',
            label: 'Projeto'
        },
        {
            value:'Realização',
            label: 'Realização'
        }
    ];

    const handleSelectFilter = () => {
         setOpenFilter(true)
         setSelectFilter(true)
    }

    useEffect(() => {
        getContent();
        //getRealizacoes();
    }, []);


    return (
        <div className="divConteudoGerenciamentoConteudo">
            {open
                ?
                <SimpleModal open={open} setOpen={() => setOpen(!open)} tipoBlog={tipoBlog} ></SimpleModal>
                :
                <></>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <div className={classes.paper1}>
                    {statusExclusao === 'Confirmar'
                        ?
                        <div>
                            <div className="titlePopupConteudo">Excluir conteúdo?</div>
                            <div className="legendPopupConteudo">Tem certeza? Uma vez excluído, o conteúdo não poderá ser recuperado.</div>
                            <div className="botoesAvaliacao">
                                <Button
                                    onClick={() => deleteContent()}
                                    style={{ color: "#20764B" }}
                                >
                                    CONFIRMAR
                                </Button>
                                <Button
                                    onClick={() => setOpenPopup(false)}
                                    style={{ color: "#FF594E" }}
                                >
                                    CANCELAR
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {statusExclusao === 'Excluindo'
                        ?
                        <div>
                            <div className="titlePopupConteudo">Excluindo</div>
                            <div className="loadingDivConteudo"><img className="loadingImgConteudo" src={Loading} alt="loading..." /></div>
                        </div>
                        :
                        <></>
                    }
                    {statusExclusao === "Concluida"
                        ?
                        <div>
                            <div className="titlePopupConteudo">{resultadoExclusao ? "Exclusão realizada!" : "Exclusão recusada"}</div>
                            {!resultadoExclusao
                                ?
                                <div className="loadingDivConteudo"><img className="loadingImgConteudo" src={Error} alt="error..." /></div>
                                :
                                <></>
                            }
                            {!resultadoExclusao
                                ?
                                <div className="legendPopupConteudo">{textPopup}</div>
                                :
                                <></>
                            }
                            <div className="botoesAvaliacao">
                                <Button
                                    onClick={() => setOpenPopup(false)}
                                    style={{ color: "#FF594E" }}
                                >
                                    SAIR
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </Modal >
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openPopupMult}
                onClose={() => setOpenPopupMult(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >

                <div className={classes.paper1}>
                    {statusExclusao === 'Confirmar'
                        ?
                        <div>
                            <div className="titlePopupConteudo" style={{ marginBottom: "16px" }}>Excluir vários conteúdos?</div>
                            {listaExcluir.length > 0
                                ?
                                <div>
                                    <div className="legendPopupConteudo2">Você selecionou {listaExcluir.length} conteúdos:</div>
                                    {listaExcluir.map((item, index) => (
                                        <div className="legendPopupConteudo2" style={{ margin: 0 }}>
                                            {" • " + item}
                                        </div>
                                    ))}
                                </div>
                                :
                                <></>
                            }
                            <div className="legendPopupConteudo2">Tem certeza? Uma vez excluído, os conteúdos não poderão ser recuperados. Caso algum conteúdo não permita a exclusão, os demais permitidos ainda serão excluídos.</div>
                            <div className="botoesAvaliacao">
                                <Button
                                    onClick={() => deleteContent()}
                                    style={{ color: "#20764B" }}
                                >
                                    CONFIRMAR
                                </Button>
                                <Button
                                    onClick={() => setOpenPopupMult(false)}
                                    style={{ color: "#FF594E" }}
                                >
                                    CANCELAR
                                </Button>
                            </div>

                        </div>
                        :
                        <></>
                    }
                    {statusExclusao === 'Excluindo'
                        ?
                        <div>
                            <div className="titlePopupConteudo">Excluindo conteúdos...</div>
                            {listaExcluidos.length > 0
                                ?
                                <div>
                                    {listaExcluidos.map((item, index) => (
                                        <div className="legendPopupConteudo2" style={{ margin: 0 }}>
                                            {"Excluido: " + item}
                                        </div>
                                    ))}
                                </div>
                                :
                                <></>
                            }
                            {listaExcluir.length > 0
                                ?
                                <div>
                                    {listaExcluir.map((item, index) => (
                                        <div className="legendPopupConteudo2" style={{ margin: 0 }}>
                                            {"Excluindo: " + item}
                                        </div>
                                    ))}
                                </div>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    {statusExclusao === "Concluida"
                        ?
                        <div>
                            <div className="titlePopupConteudo" style={{marginBottom: "16px"}}>{resultadoExclusao ? "Exclusão realizada!" : "Exclusão recusada"}</div>
                            {listaExcluidos.length > 0
                                ?
                                <div>
                                    {listaExcluidos.map((item, index) => (
                                        <div className="legendPopupConteudo2" style={{ margin: 0 }}>
                                            {"Excluido: " + item}
                                        </div>
                                    ))}
                                </div>
                                :
                                <></>
                            }
                            {listaExcluir.length > 0
                                ?
                                <div>
                                    {listaExcluir.map((item, index) => (
                                        <div className="legendPopupConteudo2" style={{ margin: 0 }}>
                                            {"Excluindo: " + item}
                                        </div>
                                    ))}
                                </div>
                                :
                                <></>
                            }
                            {!resultadoExclusao
                                ?
                                <div className="loadingDivConteudo"><img className="loadingImgConteudo" src={Error} alt="error..." /></div>
                                :
                                <></>
                            }
                            {!resultadoExclusao
                                ?
                                <div className="legendPopupConteudo">{textPopup}</div>
                                :
                                <></>
                            }
                            <div className="botoesAvaliacao">
                                <Button
                                    onClick={() => setOpenPopupMult(false)}
                                    style={{ color: "#FF594E" }}
                                >
                                    SAIR
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>


            </Modal >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="tituloGerenciamentoConteudo">Conteúdos Funcap</div>
                <div style={{ display: "flex" }}>
                    {textOpened
                        ?
                        <CssTextField
                            id='searchfield'
                            style={{ width: "300px" }}
                            placeholder={"Buscar por: Título|Data|Autor"}
                            onChange={(event) => handleSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        :
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => setTextOpened(true)}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <Balao />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Buscar</div>
                        </div>
                    }
                    {openFilter? 
                      <FormControl style={{width:"200px", marginLeft:"20px"}}>
                        <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={filtro}
                        open={selectFilter}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onChange={handleChange}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        >
                        {names.map((name) => (
                            <MenuItem key={name.value} value={name.value}>
                                {filtro.includes(name.value) ?
                                <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                    <FiltroPreenchido />
                                </SvgIcon>
                                :
                                <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                    <Filtro />
                                </SvgIcon>
                                }
                                <ListItemText primary={name.label} style={{marginLeft:"10px"}}/>
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    :
                    <div style={{ display: "flex", cursor: "pointer", marginLeft:"20px" }} onClick={handleSelectFilter}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <Filtro />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Filtrar</div>
                        </div>
                    }
                    {anySelected(content)
                        ?
                        <div style={{ display: "flex", marginLeft: "10px", cursor: "pointer" }} onClick={() => handleExclusao()}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <DeleteSvg />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Deletar</div>
                        </div>
                        :
                        <div style={{ display: "flex", marginLeft: "10px" }}>
                            <SvgIcon style={{ opacity: "50%", fontSize: "18.33px", color: "#20764B" }} >
                                <DeleteSvg />
                            </SvgIcon>
                            <div className="textIconGerConteudo" style={{ opacity: "50%" }}>Deletar</div>
                        </div>
                    }
                    {/*}
                    <div>
                        <SvgIcon style={{ fontSize: "18.33px", color: "#20764B", marginRight: "34.5px" }} >
                            <PublicarSvg />
                        </SvgIcon>
                        <div className="textIconGerConteudo">Publicar</div>
                    </div>
        */}
                </div>
            </div>
            <div style={{ marginTop: "32px" }}>
                {content.length > 0
                    ?
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={content.length}
                            />
                            <TableBody>
                                {content
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        //const isItemSelected = isSelected(index);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                //onClick={(event) => handleClick(event, row.content.id)}
                                                tabIndex={-1}
                                                key={row.content.id}
                                            //selected={isItemSelected}
                                            >

                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onChange={() => isSelected(page * rowsPerPage + index)}
                                                        checked={row.selected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" id={row.content.id} scope="row" padding="none">
                                                {substringColor(row.content.titulo.toUpperCase(), searchField.toUpperCase(), 1)}
                                                    {/* {row.content.titulo} */}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div>
                                                    {substringColor(getData(row.content.createdAt, "dia") + "/" + getData(row.content.createdAt, "mes") + "/" + getData(row.content.createdAt, "ano"), searchField.toUpperCase(), 1)} 
                                                    </div>
                                                </TableCell>
                                                {row.type === "projeto" ||  row.content.data_inicio !== undefined ?
                                                    <TableCell align="right">
                                                        {/* {row.content.autor_projeto.nome.toUpperCase()} */}
                                                        {substringColor(row.content.autor_projeto.nome.toUpperCase(), searchField.toUpperCase(), 1)}
                                                    </TableCell>
                                                    :
                                                    <TableCell align="right">
                                                        {/* {row.content.autor_realizacao.nome.toUpperCase()} */}
                                                        {substringColor(row.content.autor_realizacao.nome.toUpperCase(), searchField.toUpperCase(), 1)}
                                                    </TableCell>
                                                }
                                                {row.type !== undefined ? 
                                                    <TableCell align="right">{row.type.toUpperCase()}</TableCell>
                                                :
                                                row.content.data_inicio !== undefined ?
                                                    <TableCell align="right">PROJETO</TableCell>
                                                    :
                                                    <TableCell align="right">REALIZAÇÃO</TableCell>
                
                                                }
                                                <TableCell align="right">
                                                    {row.content.avaliado
                                                        ?
                                                        <Tooltip
                                                            content={
                                                                <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
                                                                    <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                                                                        <div style={{ display: "flex", opacity: "0.6" }}>
                                                                            <div style={{ color: "#20764B", marginRight: "5px" }}>{"Aprovado:"}</div>
                                                                            <div style={{ color: "#405965" }}>{row.titulo}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            direction="left"
                                                            tagName="span"
                                                            className="target"
                                                        >
                                                            <SvgIcon style={{ fontSize: "20px", color: "#20764B" }} >
                                                                <RevisaoAprovado />
                                                            </SvgIcon>
                                                        </Tooltip>

                                                        :
                                                        ""
                                                    }
                                                    {!row.content.avaliado && row.content.avaliado != null?
                                                                <Tooltip
                                                                    style={{ borderColor: "transparent" }}
                                                                    content={
                                                                        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
                                                                            <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                                                                                <div style={{ display: "flex", opacity: "0.6" }}>
                                                                                    <div style={{ color: "#FF594E", marginRight: "5px" }}>{"Recusado:"}</div>
                                                                                    {/* <div style={{ color: "#405965", marginRight: "5px" }}>{row.titulo + ","}</div> */}
                                                                                    <div style={{ color: "#405965", marginRight: "5px"  }}>
                                                                                        {row.content.revisao.comentario !== null ?
                                                                                            <div>{row.content.revisao.comentario}</div>
                                                                                            : <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    direction="left"
                                                                    tagName="span"
                                                                    className="target"
                                                                >
                                                                    <SvgIcon style={{ fontSize: "20px", color: "#FF594E" }} >
                                                                        <RevisaoRecusado />
                                                                    </SvgIcon>
                                                                </Tooltip>
                                                                :
                                                                ""
                                                            }
                                                    {/*
                                                        
                                                        <SvgIcon style={{ fontSize: "20px", color: "#20764B" }} >
                                                            <RevisaoSelected />
                                                        </SvgIcon>
                                                        
                                                        <SvgIcon style={{ fontSize: "20px", color: "#405965" }} >
                                                            <RevisaoUnselected />
                                                        </SvgIcon>
                                                        */
                                                    }
                                                </TableCell>
                                                <TableCell align="right" >
                                                    <SvgIcon style={{ cursor: "pointer", fontSize: "20px", color: "#20764B" }} onClick={() => handleClickVisualizacao(row)}>
                                                        <Exibir />
                                                    </SvgIcon>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
                }
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="textQuantidadeConteudoGerenciamentoConteudo">{selected.length + " Conteúdos"}</div>
                    <TablePagination
                        style={{ color: "#20764B" }}
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={content.length}
                        labelRowsPerPage="Linhas por página"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>

            </div>
        </div >
    )
}