import { Column } from '@ant-design/charts';
import React from 'react';
import fixLongName from "../../../funcoes/fixLongName";
import toBRL from '../../../funcoes/toBRL';
const d3 = require("d3");

export default function BarChart(props) {

  function adapt(data) {
    var newProp = [];
    var json = {};
    for (var i = 0; i < data.length; i++) {
      json = {
        type: fixLongName(data[i].type),
        value: data[i].value
      }
      newProp.push(json)
    }
    return newProp;
  }

  function getColor(value) {
    var verdes = d3.schemeGreens[9];

    var menor = props.data[0].value;
    var maior = props.data[0].value;

    for (var i = 0; i < props.data.length; i++) {
      if (props.data[i].value > maior) {
        maior = props.data[i].value;
      }
      if (props.data[i].value < menor) {
        menor = props.data[i].value;
      }
    }

    if (menor > 1) {
      menor = Math.log2(menor)
    }
    if (maior > 1) {
      maior = Math.log2(maior)
    }

    var colorScale = d3.scaleQuantize().domain([menor, maior]).range(verdes);

    if (value > 0) {
      return colorScale(Math.log2(value));
    }
    return colorScale(value);

  }

  function findValue(type) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].type === type) {
        return data[i].value;
      }
    }
  }

  function getType(){
    if(props.type === "regiao"){
      return true
    }

    return false
  }

  const data = adapt(props.data);
  const config = {
    data,
    xField: 'type',
    yField: 'value',
    label: {
      position: 'top',
      // 'top', 'bottom', 'middle',
      style: {
        fill: 'black',
        opacity: 0,
      },
    },
    color: ({ type }) => {
      return getColor(findValue(type));
    },
    xAxis: {
      label: {
        autoHide: getType(),
        autoRotate: getType(),
      },
    },
    yAxis: {
      label: {
        formatter: (v) => props.tipo === "reais"? toBRL(v) : v
      }
    },
    tooltip: {
      formatter: data => {
        return { name: fixLongName(data.type), value: props.tipo === "reais"? toBRL(data.value): data.value };
      },
    },

  };
  return (
    <div style={{ minHeight: "400px" }}>
      <Column {...config} />
    </div>
  );
};
