import Backdrop from '@material-ui/core/Backdrop';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import EdicaoCooperacao from "../criarCooperacao/cadastroCooperacao";
import EdicaoProjeto from "./cadastroProjeto";
import EdicaoRealizacao from "./cadastroRealizacao";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} mountOnEnter unmountOnExit timeout={1500} />;
});

export default function SimpleModal({ open, setOpen, tipoBlog}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Slide direction="down" in={open} mountOnEnter unmountOnExit>
      <div>
        <Dialog
          TransitionComponent={Transition}
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          scroll={'paper'}       
          fullScreen
          style={{margin:"20px 0px 0px 0px"}}
          PaperProps={{
            style: {borderTopLeftRadius:10, borderTopRightRadius:10}}}
          >
                     
              {tipoBlog.type === "projeto" || tipoBlog.content.data_inicio !== undefined
                ?
                
                <EdicaoProjeto setOpen={() => setOpen(false)} projeto={tipoBlog.content} ></EdicaoProjeto>
                :
                ""
                }

                
              {tipoBlog.type === "realização"
                ?
                <EdicaoRealizacao setOpen={() => setOpen(false)} realizacao={tipoBlog.content}></EdicaoRealizacao>
                :
                ""
                }
                
              {tipoBlog.type === "Cooperação"
                ?
                <EdicaoCooperacao setOpen={() => setOpen(false)} cooperacao={tipoBlog.content}></EdicaoCooperacao>
                :
                ""
                }
        </Dialog>
      </div>
    </Slide>

  );
}
