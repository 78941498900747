import Paper from "@material-ui/core/Paper";
import React, { useState } from "react";
import Loading from "../../icons/loading/greenLoading.gif";
import { AntTabDisabled, AntTabsDisabled, TabPanelDisabled } from "../DisabledTabs";
import "./loading.css";


export default function LoadingPainel(props) {

    const [value, setValue] = useState(0);

    return (
        <div style={{width: "1366px"}}>
            <div style={{ marginLeft: "5vw", marginRight: "5vw", marginTop: "75px" }}>
                <div style={{ display: "flex" }}>
                    <p style={{ color: "#20764B", fontWeight: "bold", marginRight: "1vw" }}>{"Carregando Indicadores..."}</p>
                </div>
                <AntTabsDisabled aria-label="ant example">
                    <AntTabDisabled label="Mapa de calor" />
                    <AntTabDisabled label="Gráfico circular" />
                    <AntTabDisabled label="Gráfico de barras" />
                    <AntTabDisabled label="Tabela de dados" />
                </AntTabsDisabled>
                <Paper elevation={1} style={{ backgroundColor: "transparent" }}>
                    <TabPanelDisabled value={value} index={0}>
                        <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                    </TabPanelDisabled>
                    <TabPanelDisabled value={value} index={1}>
                        <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                    </TabPanelDisabled>
                    <TabPanelDisabled value={value} index={2}>
                        <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                    </TabPanelDisabled>
                    <TabPanelDisabled value={value} index={3}>
                        <div className="loadingDiv"><img className="loadingImg" src={Loading} alt="loading..." /></div>
                    </TabPanelDisabled>
                </Paper>
            </div >
        </div>
    )
}