import React from "react";
import BlogsSection from "../../../components/blogsSection/BlogsSectionDuvidas";
import "./duvidas.css";

export default function Duvidas({ }) {

    return (
        <div className="root">
            <div>
                <div className="backgroundDuvidasHome" >
                    <div className="navLinkHome">
                        <div className="navLinkPathHome">Inicio/</div>
                        <div className="navLinkSelectedPathHome" style={{ marginLeft: "5px" }}>Dúvidas frequentes</div>
                    </div>
                    <div className="divContentAllRow" style={{marginBottom: "32px", paddingBottom: "8px", borderBottom: "1px solid #D9D9D9"}}>
                        <div className="titleSubPageHome">Dúvidas frequentes</div>
                    </div>
                    <BlogsSection page={"home"} />
                </div>
            </div>
        </div>
    )
}