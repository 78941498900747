import { Tooltip } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Agua } from "../../../../../../components/icons/cadastro/agua.svg";
import { ReactComponent as Animais } from "../../../../../../components/icons/cadastro/animais.svg";
import { ReactComponent as Cidade } from "../../../../../../components/icons/cadastro/cidade.svg";
import { ReactComponent as Fome } from "../../../../../../components/icons/cadastro/comida.svg";
import { ReactComponent as Cubos } from "../../../../../../components/icons/cadastro/cubos.svg";
import { ReactComponent as Educacao } from "../../../../../../components/icons/cadastro/educacao.svg";
import { ReactComponent as Energia } from "../../../../../../components/icons/cadastro/energia.svg";
import { ReactComponent as Familia } from "../../../../../../components/icons/cadastro/familia.svg";
import { ReactComponent as Grafico } from "../../../../../../components/icons/cadastro/grafico.svg";
import { ReactComponent as Infinito } from "../../../../../../components/icons/cadastro/infinito.svg";
import { ReactComponent as MeioAmbiente } from "../../../../../../components/icons/cadastro/meioambiente.svg";
import { ReactComponent as Mundo } from "../../../../../../components/icons/cadastro/mundo.svg";
import { ReactComponent as Orientacao } from "../../../../../../components/icons/cadastro/orientacao.svg";
import { ReactComponent as Pesca } from "../../../../../../components/icons/cadastro/pesca.svg";
import { ReactComponent as Quimica } from "../../../../../../components/icons/cadastro/quimica.svg";
import { ReactComponent as Saude } from "../../../../../../components/icons/cadastro/saude.svg";
import { ReactComponent as Sexo } from "../../../../../../components/icons/cadastro/sexo.svg";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export default function Ods(props) {
    return (
        <div>
            <div className="divOdsBlog">
                {props.ods.includes(0) ?
                    <div className="divFlexOds">
                    <div
                        className="divIconesOds1Clicked"

                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Familia />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Erradicação da probreza</div>
                    </div>
                    : <></>
                }
                {props.ods.includes(1)? 
                    <div className="divFlexOds">
                    <div
                        className="divIconesOds2Clicked"

                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Fome />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Fome zero</div>
                    </div>
                    
                :<></>}
                {props.ods.includes(2)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds3Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Saude />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Saúde e bem-estar</div>
                    </div>
                    :<></>
                }

                {props.ods.includes(3)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds4Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Educacao />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Educação de qualidade</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(4)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds5Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Sexo />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Igualdade de genêro</div>
                    </div>
                     :<></>
                }

                {props.ods.includes(5)
                    ?

                    <div className="divFlexOds">
                    <div className="divIconesOds6Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Agua />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Água limpa e saneamento</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(6)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds7Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Energia />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Energia limpa e acessível</div>
                    </div>
                    :<></>

                }
                {props.ods.includes(7)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds8Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Grafico />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Trabalho decente e crescimento econômico</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(8)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds9Clicked"
                    >
                        
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Cubos />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Inovação infraestrutura</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(9)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds10Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Orientacao />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Redução das desigualdades</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(10)
                    ?
                    <div className="divFlexOds">                   
                    <div className="divIconesOds11Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Cidade />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Cidades e comunidades sustentáveis</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(11)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds12Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Infinito />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Consumo e produção responsáveis</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(12)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds13Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Mundo />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Combate às alterações climáticas</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(13)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds14Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Pesca />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Vida debaixo d'água</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(14)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds15Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <MeioAmbiente />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Vida terrestre</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(15)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds16Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Animais />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Paz, justiça e instituições fortes</div>
                    </div>
                    :<></>
                }
                {props.ods.includes(16)
                    ?
                    <div className="divFlexOds">
                    <div className="divIconesOds17Clicked"
                    >
                            <SvgIcon style={{ fontSize: "36px", color: "#FFFFFF" }}>
                                <Quimica />
                            </SvgIcon>
                    </div>
                    <div className="titleOds">Parcerias em prol das metas</div>
                    </div>
                    :<></>
                }
            </div>
        </div>
    )
}