import { useEffect, useState } from 'react';
//search component
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import LoadingPainel from '../../../components/painelndicador/loadingIndicador/LoadingPainel';
import Portas from '../../../portas';

//indicadores ciencia
import Ind2 from "./indicadores/Indicador2";
import Ind3 from "./indicadores/Indicador3";
import Ind5 from "./indicadores/Indicador5";
import Ind6 from "./indicadores/Indicador6";

const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965"
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#405965',
        },
        '&:hover .MuiInput-underline:before': {
            borderBottomColor: '#20764B',
        },
        '& .Mui-focused:after': {
            borderBottomColor: '#20764B',
        },
    },
})(TextField);

export default function Apoio() {

    const [searchField, setSearchField] = useState("");
    const [data, setData] = useState([])

    function submit(e) {
        e.preventDefault();
        setSearchField(document.getElementById("searchfield").value);
    }

    async function getData() {

        try {
            const response = await fetch(Portas().serverHost + "/fatos/cti",
                {
                    method: "GET",
                }
            );

            const resJSON = await response.json();
            setData(resJSON)

        }
        catch (err) {
            
        }

    }

    useEffect(() => {
        getData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div style={{ backgroundColor: "white" }}>
                <div style={{ paddingTop: "35px", display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                    <form onSubmit={submit}>
                        <CssTextField
                            id='searchfield'
                            style={{ width: "1272px" }}
                            placeholder={"Buscar indicador"}
                            onBlur={(event) => setSearchField(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                    {searchField.toLocaleLowerCase()}
                    {data.length > 0
                        ?
                        <div style={{ width: "1366px" }}>
                            {/*"Quantidade de cursos de graduação".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Ind1 data={data} found={searchField} />
                                :
                                <></>
                    */}
                            {"Quantidade de titulados em graduação".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Ind2 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Quantidade de matriculados em graduação".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Ind3 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {/*"Quantidade de programas de pós-graduação".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Ind4 data={data} found={searchField} />
                                :
                                <></>
                        */}
                            {"Quantidade de titulados de pós-graduação".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Ind5 data={data} found={searchField} />
                                :
                                <></>
                            }
                            {"Quantidade de matriculados de pós-graduação".toLocaleLowerCase().includes(searchField.toLocaleLowerCase()) ?
                                <Ind6 data={data} found={searchField} />
                                :
                                <></>
                            }

                        </div>
                        :
                        <LoadingPainel></LoadingPainel>
                    }
                </div>

            </div>
        </div>
    )
}