import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { useContext, useState } from "react";
import StoreContext from "../../../components/Store/Context";
import "./BlogEdit.css";

import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import EditIcon from "@material-ui/icons/Edit";
import Portas from "../../../portas";
import CustomViewProjeto from "./CustomViewProjetoCuradoria";
import CustomViewRealizacao from "./CustomViewRealizacaoCuradoria";
import EditarProjeto from "./ModalEdicao";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    color: "#405965",
  },

  buttonEditar: {
    margin: theme.spacing(1),
    color: " #405965",
  },
  buttonAprovar: {
    border: "1px solid #20764B",
    borderRadius: "4px",
    color: "#20764B",
    boxSizing: "border-box",
  },
  buttonRecusar: {
    border: "1px solid #FF594E",
    borderRadius: "4px",
    color: "#FF594E",
    boxSizing: "border-box",
  },
  btnVoltar: {
    backgroundColor: "#20764B",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

export default function VisualizacaoBlogCuradoria({ setOpen, openning, updateRow }) {
  
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [value, setValue] = React.useState('');
  const [statusBotao, setStatusBotao] = useState(openning.avaliado);
  const [revisao, setRevisao] = useState(openning.revisao);
  const [visibilidadeModApr, setVisibilidadeModApr] = useState(false);
  const [visibilidadeModCmt, setVisibilidadeModCmt] = useState(false);
  const [visibilidadeModEdicao, setVisibilidadeModEdicao] = useState(false);
  const [statusAvaliacao, setStatusAvaliacao] = useState(false);
  const { token } = useContext(StoreContext);

  function aprovar() {
    setVisibilidadeModApr(false);
    setVisibilidadeModCmt(true);
    setStatusAvaliacao(true);
    setStatusBotao(true);
  }

  function recusar() {
    setVisibilidadeModApr(false);
    setVisibilidadeModCmt(true);
    setStatusAvaliacao(false);
    setStatusBotao(false);
  }

  async function enviar() {

    setVisibilidadeModCmt(false);
    try {
      
      var retJson = {}
      if (value.length > 0) {
        retJson["comentario"] = value
      }

      var rota = ""
      if (openning.data_inicio !== undefined) {
        retJson["id_projeto"] = openning.id
        rota += "/revisoes/projeto"
      }
      else {
        retJson["id_realizacao"] = openning.id
        rota += "/revisoes/realizacao"
      }
      retJson["avaliado"] = statusAvaliacao
    
      if (openning.avaliado == null) {
        const response = await fetch(Portas().serverHost + rota,
          {
            method: "POST",
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(retJson)
          }
        );

        const resJSON = await response.json();
        

        if (typeof resJSON.id !== "undefined") {
          if (openning.data_inicio !== null) {
            alert("Projeto revisado!")
            window.location.reload(false)
            return;
          }
          else {
            alert("Realização revisada!")
            window.location.reload(false)
            return;
          }
        }
        if (typeof resJSON.message !== "undefined") {
          alert(resJSON.message)
          window.location.reload(false)
          return;
        }
        else {
          alert("Um erro ocorreu, tente novamente...")
          window.location.reload(false)
        }
      } 
      else {
        var retJsonUpdate = {}

        if (value.length > 0) {
          retJsonUpdate["comentario"] = value
        }

        retJsonUpdate["avaliado"] = statusAvaliacao

        var rotaUpdate = ""
        if (openning.data_inicio !== undefined) {
          rotaUpdate += "/revisoes/projeto/"
        }
        else 
        rotaUpdate += "/revisoes/realizacao/"

        const response = await fetch(Portas().serverHost + rotaUpdate + openning.revisao.id,
          {
            method: "PUT",
            headers: {
              'Authorization': "Bearer " + token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(retJsonUpdate)
          }
        );

        const resJSON = await response.json();
        

        if (typeof resJSON.id !== "undefined") {
          if (openning.data_inicio !== null) {
            alert("Projeto revisado!")
            window.location.reload(false)
            return;
          }
          else {
            alert("Realização revisada!")
            window.location.reload(false)
            return;
          }
        }
        if (typeof resJSON.message !== "undefined") {
          alert(resJSON.message)
          return;
        }
        else {
          alert("Um erro ocorreu, tente novamente...")
        }
      }
    }
    catch (err) {
      
    }

  }


  return (
    <div className="blogEdit">
      <div className="cabecalhoEdit">
        <div className="blogCabecalhoFormatEdit">
          <Button
            className={classes.btnVoltar}
            variant="contained"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>

          <div className="blogCabecalhoEdit">
            INÍCIO/<b>FUNCAP</b>
          </div>
        </div>

        <div>
          <Button
            variant="outlined"
            className={classes.buttonEditar}
            startIcon={<EditIcon />}
            onClick={() => setVisibilidadeModEdicao(!visibilidadeModEdicao)}
          >
            Editar
          </Button>

          {visibilidadeModEdicao ? (
            <EditarProjeto
              open={visibilidadeModEdicao}
              setOpen={() => setVisibilidadeModEdicao(!visibilidadeModEdicao)}
              openning={openning}
            ></EditarProjeto>
          ) : (
            <></>
          )}

          {statusBotao ? (
            <Button
              variant="outlined"
              className={classes.buttonAprovar}
              onClick={() => setVisibilidadeModApr(!visibilidadeModApr)}
              startIcon={<CheckCircleRoundedIcon />}
            >
              Aprovado
            </Button>
          ) : (
            ""
          )}
          {!statusBotao && statusBotao != null ? (
            <Button
              variant="outlined"
              className={classes.buttonRecusar}
              startIcon={<CancelIcon />}
              onClick={() => setVisibilidadeModApr(!visibilidadeModApr)}
            >
              Recusado
            </Button>
          ) : (
            ""
          )}

          {statusBotao === null ? (
            <Button
              variant="outlined"
              className={classes.buttonEditar}
              onClick={() => setVisibilidadeModApr(!visibilidadeModApr)}
            >
              Avaliar Publicação
            </Button>
          ) : (
            ""
          )}

          {visibilidadeModApr ? (
            <div className="caixaDialogo">
              <div className="títuloDialogo">Alterar Revisão deste blog?</div>

              <div className="contentDialogo">
                Após aprovado, tornará o blog visível na plataforma para os
                visitantes. Ao recusar, o blog continua oculto e o criador é
                notificado à efetuar correções para nova revisão.
              </div>
              <div className="botoesAvaliacao">
                <Button
                  onClick={aprovar}
                  style={{ color: "#20764B" }}
                  className={classes.buttonEditar}
                >
                  Aprovar
                </Button>

                <Button
                  onClick={recusar}
                  style={{ color: "#FF594E" }}
                  className={classes.buttonEditar}
                >
                  Recusar
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}

          {visibilidadeModCmt ? (
            <div className="caixaDialogoComentario">
              <div className="títuloDialogo">Comentar Revisão?</div>

              <div className="contentDialogo">
                <TextField
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                  fullWidth
                  variant="outlined"
                  autoFocus
                  margin="dense"
                  id="name"
                  multiline
                  rows={4}
                  defaultValue="Digite aqui..."
                />
              </div>
              <div className="botoesAvaliacao">
                <Button onClick={enviar} className={classes.button}>
                  Enviar
                </Button>

                <Button onClick={enviar} className={classes.button}>
                  Pular
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {openning.data_inicio != null ?
        <CustomViewProjeto setOpen={true} projeto={openning}></CustomViewProjeto>
        :
        <CustomViewRealizacao setOpen={true} realizacao={openning}></CustomViewRealizacao>
      }
    </div>
  );
}
