import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from "@material-ui/core/SvgIcon";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useContext, useEffect, useState } from "react";
import Tooltip from 'react-tooltip-lite';
import StoreContext from "../../../components/Store/Context";
import substringColor from "../../../components/funcoes/substringColor";
import { ReactComponent as Balao } from "../../../components/icons/gerenciamento/balao.svg";
import { ReactComponent as Exibir } from "../../../components/icons/gerenciamento/exibirBlog.svg";
import { ReactComponent as Filtro } from "../../../components/icons/gerenciamento/filtro.svg";
import { ReactComponent as FiltroPreenchido } from "../../../components/icons/gerenciamento/filtroPreenchido.svg";
import { ReactComponent as RevisaoRecusado } from "../../../components/icons/gerenciamento/revisaoRecusado.svg";
import { ReactComponent as RevisaoAprovado } from "../../../components/icons/gerenciamento/revisaoSelected.svg";
import { ReactComponent as RevisaoAvaliar } from "../../../components/icons/gerenciamento/revisaoUnselected.svg";
import Portas from "../../../portas";
import SimpleModal from "./ModalVisualizacaoBlogCuradoria";
import "./curadoria.css";

const CssTextField = withStyles({
    root: {
        "&:hover .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        "& .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#405965"
        },
        "& .Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root": {
            color: "#20764B"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#405965',
        },
        '&:hover .MuiInput-underline:before': {
            borderBottomColor: '#20764B',
        },
        '& .Mui-focused:after': {
            borderBottomColor: '#20764B',
        },
    },
})(TextField);

const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Recentes' },
    { id: 'data', numeric: true, disablePadding: false, label: 'Data de postagem' },
    { id: 'autor', numeric: true, disablePadding: false, label: 'Autor' },
    { id: 'tipo', numeric: true, disablePadding: false, label: 'Tipo' },
    { id: 'revisao', numeric: true, disablePadding: false, label: 'Revisão' },
    { id: 'exibir', numeric: true, disablePadding: false, label: 'Exibir' },
];

const StyledTableSortLabel = withStyles(() =>
    createStyles({
        root: {
            color: '#20764B',
            "&:hover": {
                color: '#20764B',
            },
            '&$active': {
                color: '#20764B',
            },
        },
        active: {},
        icon: {
            color: "#20764B !important"
        }
    })
)(TableSortLabel);


function EnhancedTableHead(props) {

    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow >
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ color: "#20764B" }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.id == "name"
                            ?
                            <StyledTableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </StyledTableSortLabel>
                            :
                            <div>
                                {headCell.label}
                            </div>

                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginTop: "32px",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function Curadoria() {

    const [content, setContent] = useState([])
    const { token } = useContext(StoreContext);
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [open, setOpen] = useState(false);
    const [openning, setOpenning] = useState("");
    const [autor, setAutor] = useState([]);
    const [textOpened, setTextOpened] = useState(false);
    const [searchField, setSearchField] = useState("");
    const [openFilter, setOpenFilter] = useState(false);
    const [selectFilter, setSelectFilter] = useState(false);
    const [filtro, setFiltro] = useState([]);
    const [fullData, setFullData] = useState([]);
   
    const handleClose = () => {
        setSelectFilter(false)
    }
    
    const handleOpen = () => {
        setSelectFilter(true)
    }    

    content.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1 ))

    const getContent = async () => {
        try {

            const response = await fetch(Portas().serverHost + "/revisoes",
                {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                }
            );

            const resJSON = await response.json();
           
            let arr =[]
            for (var i=0; i< resJSON.length; i++){
                if(resJSON[i].hasOwnProperty("titulo")) {
                    arr.push(resJSON[i])
                }
            }
            setContent(arr)
            setFullData(arr)

            
            const response1 = await fetch(Portas().serverHost + "/usuarios?cientista=true&acesso=true&image=true",
                {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                }
            );

            const resJSON1 = await response1.json();
            setAutor(resJSON1);
            

        }
        catch (err) {
            
        }
    }

    const handleSearch = async (text) => {
        try {

            const response = await fetch(Portas().serverHost + "/revisoes",
                {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                }
            );

            const resJSON = await response.json();

            let arrSearch =[]
            for (var i=0; i< resJSON.length; i++){
                if(resJSON[i].hasOwnProperty("titulo")) {
                    arrSearch.push(resJSON[i])
                }
            }

            let dataRevisao= []
            for (var j=0; j<  arrSearch.length;j++)
            {
                dataRevisao.push(
                    getData(arrSearch[j].createdAt, "dia") + "/" +
                    getData(arrSearch[j].createdAt, "mes") + "/" +
                    getData(arrSearch[j].createdAt, "ano")
                )
               
            }
            
            let autor= []
            for (var k=0; k<arrSearch.length;k++)
            {
                autor.push(
                    getAutor(arrSearch[k].id_autor)
                )
               
            }
        
            let arr = []

            for (var i = 0; i < arrSearch.length; i++) {
                if (arrSearch[i]?.titulo?.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(arrSearch[i])
                } else 
                if(dataRevisao[i]?.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(arrSearch[i])
                } else 
                if(autor[i]?.toLowerCase().includes(text.toLowerCase())) {
                    arr.push(arrSearch[i])
                } 
            }
          
            setSearchField(text)
            setContent(arr)
            
            const response1 = await fetch(Portas().serverHost + "/usuarios?cientista=true&acesso=true&image=true",
                {
                    method: "GET",
                    headers: {
                        'Authorization': "Bearer " + token,
                    }
                }
            );

            const resJSON1 = await response1.json();
            setAutor(resJSON1);
            

        }
        catch (err) {
            
        }
    }

    function getAutor(idAutor) {

        for (var i = 0; i < autor.length; i++) {
            if (autor[i].id === idAutor) {
                return autor[i].nome
            }
        }
        return ""
    }

    function handleClickVisualizacao(blog) {
        setOpenning(blog)
        setOpen(true)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = content.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function getData(timestamp, tipo) {
        var data = new Date(timestamp)
        var dia = data.getDate()
        var mes = data.getMonth() + 1
        var ano = data.getFullYear()

        if (tipo === "dia") {

            return ("00" + dia).slice(-2)
        }

        if (tipo === "mes") {
            return ("00" + mes).slice(-2)
        }

        if (tipo === "ano") {
            return ("0000" + ano).slice(-2)
        }
    }


    function updateRows(open){
        getContent()
        setOpen(open)
    }

    const handleChange = (event) => {
        setFiltro(event.target.value);
        let filtered = [];
        var filtros = event.target.value;

        if (filtros.length < 1) {
            setContent(fullData)
            return
        }

        for (var i = 0; i < fullData.length; i++ ) {            
            for( var j = 0; j < filtros.length; j++) {
                if (fullData[i].avaliado === true && filtros[j] == 'Aprovados') {
                    if(filtros.includes('Projeto') && fullData[i].data_inicio !== undefined) {
                        filtered.push(fullData[i]);
                        break
                    }
                    if(filtros.includes('Realização') && fullData[i].data_inicio == undefined) {
                        filtered.push(fullData[i]);
                        break
                    }
                    if (!filtros.includes('Projeto') && !filtros.includes('Realização')) {
                        filtered.push(fullData[i]);
                        break
                    }
                }
                if (fullData[i].avaliado === false && filtros[j] == 'Recusados') {
                    if(filtros.includes('Projeto') && fullData[i].data_inicio !== undefined) {
                        filtered.push(fullData[i]);
                        break
                    }
                    if(filtros.includes('Realização') && fullData[i].data_inicio == undefined) {
                        filtered.push(fullData[i]);
                        break
                    }
                    if (!filtros.includes('Projeto') && !filtros.includes('Realização')) {
                        filtered.push(fullData[i]);
                        break
                    }
                }
                if (fullData[i].avaliado == null && filtros[j] == 'Avaliação pendente') {
                    if(filtros.includes('Projeto') && fullData[i].data_inicio !== undefined) {
                        filtered.push(fullData[i]);
                        break
                    }
                    if(filtros.includes('Realização') && fullData[i].data_inicio == undefined) {
                        filtered.push(fullData[i]);
                        break
                    }
                    if (!filtros.includes('Projeto') && !filtros.includes('Realização')) {
                        filtered.push(fullData[i]);
                        break
                    }
                }
                if (fullData[i].data_inicio !== undefined && filtros[j] == 'Projeto' && !filtros.includes('Aprovados') && !filtros.includes('Recusados') && !filtros.includes('Avaliação pendente')) {
                    filtered.push(fullData[i]);
                    break
                }
                if (fullData[i].data_inicio == undefined && filtros[j] == 'Realização' && !filtros.includes('Aprovados') && !filtros.includes('Recusados') && !filtros.includes('Avaliação pendente')) {
                    filtered.push(fullData[i]);
                    break
                }
                
            }

        }
       
        setContent(filtered)
      };
     

    const names  = [
        {
            value:'Aprovados',
            label: 'Aprovados'
        },
        {
            value:'Recusados',
            label: 'Recusados'
        },
        {
            value:'Avaliação pendente',
            label: 'Avaliação pendente'   
        },
        // {
        //     value:'Leitura pendente',
        //     label: 'Leitura pendente'
        // },
        // {
        //     value:'Cooperação',
        //     label: 'Cooperação'
        // },
        {
            value:'Projeto',
            label: 'Projeto'
        },
        {
            value:'Realização',
            label: 'Realização'
        }
    ];

    const handleSelectFilter = () => {
         setOpenFilter(true)
         setSelectFilter(true)
    }

    useEffect(() => {
        getContent();
    }, []);

    return (
        <div className="divConteudoGerenciamentoCuradoria">
            {open
                ?
                <SimpleModal open={open} setOpen={() => updateRows(!open)} openning={openning}></SimpleModal>
                :
                <></>
            }
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="tituloGerenciamentoCuradoria">Curadoria</div>
                <div style={{ display: "flex" }}>
                    {textOpened
                        ?
                        <CssTextField
                            id='searchfield'
                            style={{ width: "300px" }}
                            placeholder={"Buscar por: Título|Data|Autor"}
                            onChange={(event) => handleSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        :
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => setTextOpened(true)}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <Balao />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Buscar</div>
                        </div>
                    }
                    {openFilter? 
                      <FormControl style={{width:"200px", marginLeft:"20px"}}>
                        <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={filtro}
                        open={selectFilter}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onChange={handleChange}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        >
                        {names.map((name) => (
                            <MenuItem key={name.value} value={name.value}>
                                {filtro.includes(name.value) ?
                                <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                    <FiltroPreenchido />
                                </SvgIcon>
                                :
                                <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                    <Filtro />
                                </SvgIcon>
                                }
                                <ListItemText primary={name.label} style={{marginLeft:"10px"}}/>
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    :
                    <div style={{ display: "flex", cursor: "pointer", marginLeft:"20px" }} onClick={handleSelectFilter}>
                            <SvgIcon style={{ fontSize: "18.33px", color: "#20764B" }} >
                                <Filtro />
                            </SvgIcon>
                            <div className="textIconGerConteudo">Filtrar</div>
                        </div>
                    }
                </div>
            </div>
            <div style={{ marginTop: "32px" }}>
                {content.length > 0
                    ?
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={content.length}
                            />
                            <TableBody>
                                {content
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.id)}
                                                tabIndex={-1}
                                                key={row.id}

                                            >
                                                <TableCell component="th" id={row.id} scope="row" padding="none">
                                                {substringColor(row.titulo.toUpperCase(), searchField.toUpperCase(), 1)}
                                                    {/* {row.titulo} */}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div>
                                                    {substringColor(getData(row.createdAt, "dia") + "/" + getData(row.createdAt, "mes") + "/" + getData(row.createdAt, "ano"), searchField.toUpperCase(), 1)} 
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {/* {getAutor(row.id_autor).toUpperCase()} */}
                                                    <div>
                                                    {substringColor(getAutor(row.id_autor).toUpperCase(), searchField.toUpperCase(), 1)} 
                                                    </div>
                                                    
                                                </TableCell>
                                                {row.data_inicio !== undefined ?
                                                    <TableCell align="right">PROJETO</TableCell>
                                                    :
                                                    <TableCell align="right">REALIZAÇÃO</TableCell>
                                                }

                                                <TableCell align="right">
                                                    {row.revisao !== null
                                                        ?
                                                        <div>
                                                            {row.avaliado ?
                                                                <Tooltip
                                                                    content={
                                                                        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
                                                                            <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                                                                                <div style={{ display: "flex", opacity: "0.6" }}>
                                                                                    <div style={{ color: "#20764B", marginRight: "5px" }}>{"Aprovado:"}</div>
                                                                                    <div style={{ color: "#405965" }}>
                                                                                        {/*row.avaliado !== null ?
                                                                                            <div>
                                                                                                {row.revisao.comentario !== null ?
                                                                                                    <div>{row.revisao.comentario}</div>
                                                                                                    : <></>
                                                                                                }
                                                                                            </div>
                                                                                            : <></>
                                                                                            */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    direction="left"
                                                                    tagName="span"
                                                                    className="target"
                                                                >
                                                                    <SvgIcon style={{ fontSize: "20px", color: "#20764B" }} >
                                                                        <RevisaoAprovado />
                                                                    </SvgIcon>
                                                                </Tooltip>
                                                                :
                                                                ""
                                                            }
                                                            {!row.avaliado && row.avaliado != null?
                                                                <Tooltip
                                                                    style={{ borderColor: "transparent" }}
                                                                    content={
                                                                        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
                                                                            <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                                                                                <div style={{ display: "flex", opacity: "0.6" }}>
                                                                                    <div style={{ color: "#FF594E", marginRight: "5px" }}>{"Recusado:"}</div>
                                                                                    {/* <div style={{ color: "#405965", marginRight: "5px" }}>{row.titulo + ","}</div> */}
                                                                                    <div style={{ color: "#405965", marginRight: "5px"  }}>
                                                                                        {row.revisao.comentario !== null ?
                                                                                            <div>{row.revisao.comentario}</div>
                                                                                            : <></>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    direction="left"
                                                                    tagName="span"
                                                                    className="target"
                                                                >
                                                                    <SvgIcon style={{ fontSize: "20px", color: "#FF594E" }} >
                                                                        <RevisaoRecusado />
                                                                    </SvgIcon>
                                                                </Tooltip>
                                                                :
                                                                ""
                                                            }
                                                            {row.avaliado == null ?
                                                                <Tooltip
                                                                    style={{ borderColor: "transparent" }}
                                                                    content={
                                                                        <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
                                                                            <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                                                                                <div style={{ display: "flex", opacity: "0.6" }}>
                                                                                    {/*<div style={{ color: "#FF594E", marginRight: "5px" }}>{"Recusado:"}</div>*/}
                                                                                    <div style={{ color: "#405965", marginRight: "5px" }}>{"Avaliação Pendente"}</div>
                                                                                    <div style={{ color: "#405965" }}>
                                                                                        {/*row.revisao.comentario !== null ?
                                                                                            <div>{row.revisao.comentario}</div>
                                                                                            : <></>
                                                                    */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    direction="left"
                                                                    tagName="span"
                                                                    className="target"
                                                                >
                                                                    <SvgIcon style={{ fontSize: "20px", color: "#405965" }} >
                                                                        <RevisaoAvaliar />
                                                                    </SvgIcon>
                                                                </Tooltip>
                                                                :
                                                                ""
                                                            }

                                                        </div>
                                                        :
                                                        <Tooltip
                                                            content={
                                                                <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
                                                                    <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
                                                                        <div style={{ display: "flex", opacity: "0.6" }}>
                                                                            <div style={{ color: "#405965" }}>{"Revisão Pendente"}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            direction="left"
                                                            tagName="span"
                                                            className="target"
                                                        >
                                                            <SvgIcon style={{ fontSize: "20px", color: "#405965" }} >
                                                                <RevisaoAvaliar />
                                                            </SvgIcon>
                                                        </Tooltip>
                                                    }

                                                </TableCell>
                                                <TableCell align="right">
                                                    <SvgIcon style={{ fontSize: "20px", color: "#20764B" }} onClick={() => handleClickVisualizacao(row)}>
                                                        <Exibir />
                                                    </SvgIcon>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <></>
                }
                 <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="textQuantidadeConteudoGerenciamentoConteudo">{selected.length + " Conteúdos"}</div>
                    <TablePagination
                        style={{ color: "#20764B" }}
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={content.length}
                        labelRowsPerPage="Linhas por página"
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </div>
    )
}