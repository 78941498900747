import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanelDisabled(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} style={{backgroundColor: "white"}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const AntTabsDisabled = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
        display: "flex",
        width: "100%",
    },
    indicator: {
        backgroundColor: '#FDCC09',
    },
})(Tabs);

const AntTabDisabled = withStyles((theme) => ({
    root: {
        minWidth: "25%",
        textTransform: 'none',
        borderRadius: "10px 10px 0px 0px",
        fontWeight: "bold",
        backgroundColor: "#FDCC09",
        color: "white",
        borderColor: "black",
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: 'white',
            opacity: 1,
        },
        '&$selected': {
            color: '#FDCC09',
            backgroundColor: "white",
        },
        '&:focus': {
            color: '#FDCC09',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} disabled/>);


export {TabPanelDisabled, AntTabDisabled, AntTabsDisabled};