import React, { useState, useEffect, useRef } from "react";
import { TabPanel, AntTabs, AntTab } from "../components/Tabs";
import { PrintButton, DownloadButton } from "../components/Buttons";
import Slider from '@material-ui/core/Slider';
import Portas from "../../../../../portas";
import Regioes from "../components/grafico_heatmap/regioes";
import substringColor from "../../../../../components/funcoes/substringColor";
import Donut from "../components/grafico_donut/Donut";
import BarChart from "../components/grafico_barras/BarChart";
import Heatmap from "../components/grafico_heatmap/HeatmapSVG";
import Tabela from "../components/tabela/Tabela";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Paper from "@material-ui/core/Paper";
import LoadingPainel from "../components/loadingIndicador/LoadingPainel";
import ReactToPrint from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#FDCC09"
            },
            track: {
                color: "#FDCC09"
            },
            rail: {
                color: "#FDCC09"
            },
            markLabel: {
                color: "transparent"
            },
            markLabelActive: {
                color: "transparent"
            }
        }
    }
});

export default function IPANE(props) {
    const [value, setValue] = useState(0);
    const [sliderValue, setSliderValue] = useState();
    const [minYear, setMinYear] = useState();
    const [maxYear, setMaxYear] = useState();
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [marks, setMarks] = useState([]);

    const componentRef = useRef();
    const pageStyle = `{ margin: 25mm 25mm 25mm 25mm; }`;

    function getTotal(allData) {
        var auxData = Regioes();

        for (var i = 0; i < allData.length; i++) {
            for (var j = 0; j < auxData.length; j++) {
                if (auxData[j].type === allData[i].regiao) {
                    auxData[j].value = parseFloat(auxData[j].value) + parseFloat(allData[i].valor);
                }
            }
        }
        return auxData;
    }

    const getAllData = async () => {

        try {
            /*
            const response = await fetch(Portas().serverHost + "/funcap/vitrines/api/v1/investimentoempresarial",
                {
                    method: "GET",
                }
            );

            var resJSON = await response.json();
            */
            var resJSON = [
                { ano: 2008, type: "Alemanha", area: "agronegocio", value: 101579.40 },
                { ano: 2008, type: "Argentina", area: "agronegocio", value: 37600 },
                { ano: 2008, type: "Austrália", area: "agronegocio", value: 37590 + 19806 },
                { ano: 2008, type: "Bélgica", area: "agronegocio", value: 30000 },
                { ano: 2008, type: "Colômbia", area: "agronegocio", value: 160000 + 33330 + 16500 },
                { ano: 2008, type: "Dinamarca", area: "agronegocio", value: 35200 },
                { ano: 2008, type: "Escócia", area: "agronegocio", value: 9800 },
                { ano: 2008, type: "Estados Unidos da América", area: "agronegocio", value: 633430.84 },
                { ano: 2008, type: "Finlândia", area: "agronegocio", value: 207740 },
                { ano: 2008, type: "França", area: "agronegocio", value: 775139.7 },
                { ano: 2008, type: "Reino Unido", area: "agronegocio", value: 259003.96+ 12300 },
                { ano: 2008, type: "Israel", area: "agronegocio", value: 39392 },
                { ano: 2008, type: "Itália", area: "agronegocio", value: 20000 + 36845 + 39999 + 192000 },
                { ano: 2008, type: "México", area: "agronegocio", value: 26000 },
                { ano: 2008, type: "Portugal", area: "agronegocio", value: 877910 },
                { ano: 2008, type: "Tchéquia", area: "agronegocio", value: 198000 }
    
            ]
            setAllData(resJSON);
            setData(resJSON);

        } catch (err) {
            alert(err)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getAllData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {((data.length > 0) === true) ?
                <div style={{ marginLeft: "5vw", marginRight: "5vw", paddingTop: "50px" }}>
                    <div style={{ display: "flex" }}>
                        <p style={{ color: "#FDCC09", fontWeight: "bold", marginRight: "1vw" }}>{substringColor("Investimento realizado para o apoio à Colaboração Internacional", props.found, 2)}</p>
                    </div>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Mapa de calor" />
                        <AntTab label="Gráfico circular" />
                        <AntTab label="Gráfico de barras" />
                        <AntTab label="Tabela de dados" />
                    </AntTabs>
                    <Paper elevation={1} style={{ backgroundColor: "transparent" }}>
                        <TabPanel value={value} index={0}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <Heatmap data={data}></Heatmap>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <Donut data={data}></Donut>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <BarChart data={data}></BarChart>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <Tabela data={data}></Tabela>
                                </div>
                            </div>
                        </TabPanel>
                    </Paper>
                    <div style={{ display: "flex", marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                        <div style={{ marginLeft: "0.5vw" }}>
                            <DownloadButton></DownloadButton>
                        </div>
                        <div style={{ marginLeft: "0.5vw" }}>
                            <ReactToPrint
                                pageStyle={pageStyle}
                                trigger={() => <div><PrintButton></PrintButton></div>}
                                content={() => componentRef.current}
                            />
                            <div style={{ display: "none" }}>
                                <ComponentToPrint data={allData} ref={componentRef} />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <LoadingPainel title="Investimento realizado para o apoio à Colaboração Internacional"></LoadingPainel>
            }
        </div>
    )
}