import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';
import React, { useContext, useState } from "react";
import StoreContext from "../../../components/Store/Context";
import Portas from "../../../portas";
import { ReactComponent as Artigo } from "../conteudos/edicaoBlog/icons/artigo.svg";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  btnVoltar: {
    backgroundColor: "#FDCC09",
    borderRadius: "4px",
    width: "109.06px",
    height: "36px",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",
    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
  btnCompartilhar: {
    borderRadius: "4px",
    width: "173px",
    height: "36px",
    color: "#405965",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    marginRight: "16px",

    "&:hover": {
      background: "#FDCC09",
      color: "black",
    },
  },
}));

export default function App({ setOpen, projeto }) {
  

  const classes = useStyles();
  const { token } = useContext(StoreContext);
  const [autor, setAutor] = useState([]);
  

  function clicarNoLink(link) {
    window.open(link, "_blank");
  }

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }

  function getData(timestamp, tipo) {
    var data = new Date(timestamp)
    var dia = data.getDate()
    var mes = data.getMonth() + 1
    var ano = data.getFullYear()

    if (tipo === "dia") {
      return dia
    }

    if (tipo === "mes") {
      return mes
    }

    if (tipo === "ano") {
      return ano
    }
  }
  const getInfo = async () => {
    try {
      const response1 = await fetch(Portas().serverHost + "/usuarios?cientista=true&acesso=true&image=true",
        {
          method: "GET",
          headers: {
            'Authorization': "Bearer " + token,
          }
        }
      );

      const resJSON1 = await response1.json();
      setAutor(resJSON1);

    }
    catch (err) {
      
    }
  }

  function getAutor(idAutor) {
    for (var i = 0; i < autor.length; i++) {
      if (autor[i].id === idAutor) {
        return autor[i].nome
      }
    }
  }

  return (
    <div className="blogProjeto">
      {typeof projeto !== "undefined" ?
        <div className="divBlogProjetoConteudo">
          <div className="conteudoRealizacao">
            <div className="conteudoPrincipalProjeto">
              <div className="blogTituloProjeto">
                <h1>
                  {projeto.titulo}
                </h1>
              </div>
              <div className="autorProjeto">
                <div className="nomeAutorProjeto">
                  {getAutor(projeto.id_autor, autor)}
                </div>
                <div style={{ color: "#405965" }}> - De: {getData(projeto.data_inicio, "mes")}/{getData(projeto.data_inicio, "ano")} até {getData(projeto.data_fim, "mes")}/{getData(projeto.data_fim, "ano")}</div>
              </div>

              <div className="conteudoProjeto">

                <img src={projeto.capa} alt="" className="imagemProjeto"></img>

              </div>
              <div className="conteudoProjeto">
                <div>
                  {parse(projeto.conteudo)}
                </div>
              </div>
              {projeto.equipe.length > 0 ?
                <div className="divTopicos">
                  <h2
                    className="divTopicoBlogProjeto"
                    style={{ marginTop: "32px" }}
                  >
                    Equipe Envolvida{" "}
                  </h2>

                  <div className="equipeProjeto">
                    {projeto.equipe.map((membro) => (
                      <div className="linkcitacaoProjetoNoticias" onClick={() => clicarNoLink(membro.lattes)}>
                        • {membro.nome}
                        </div>
                    ))}
                  </div>
                </div>
                : <></>
              }
              {projeto.citacoes.length > 0 ?
                <div className="divTopicos">
                  <h2 className="divTopicoBlogProjeto">Citação na Mídia </h2>
                  <div className="linkcitacaoProjeto">
                    {projeto.citacoes.map((citacao) => (
                      <div className="linkcitacaoProjetoNoticias" onClick={() => clicarNoLink(citacao.link)}>
                        • {citacao.label}
                      </div>
                    ))}
                  </div>
                </div>
                : <></>
              }
              {projeto.exibir_anexo
                ?
                <div className="divTopicos">
                  <h2 className="citacao">Anexo</h2>
                  <div className="anexosProjeto" style={{ marginLeft: "12px" }} onClick={() => clicarNoLink(projeto.anexo)}>
                    <SvgIcon style={{ fontSize: "16px", color: "#008240" }} >
                      <Artigo />
                    </SvgIcon>
                    <div className="artigoTituloProjeto">Artigo</div>
                  </div>
                </div>
                : <></>}
            </div>
          </div>
        </div>
        : <></>
      }
    </div>
  );
}
