import React, { useState } from "react";
import MicroRegioes from "../../../../../../components/heatmap/microregioes.json";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import "./heatmapSVG.css";
import UseStyles from "./HeatmapStyle";
import MouseTooltip from 'react-sticky-mouse-tooltip';
import toBRL from '../../../../../../components/funcoes/toBRL';
import MapChart from "./worldMap/MapChart";
import "./worldMap/worldMap.css";
import ReactCountryFlag from "react-country-flag"

export default function HeatmapSVG(props) {

  var data = props.data;

  const [content, setContent] = useState([]);

  const isMouseTooltipVisible = () => {
    if (content.length !== 0) {
      return true
    }
    return false;
  }

  return (
    <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
      <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
        {data.slice(0, 7).map((valor) => (
          <div style={{ textAlign: "right", marginBottom: "8px" }} >
            <div className="nomeRegiaoEmpresarial">{valor.type}</div>
            <div className="valorRegiaoEmpresarial">{toBRL(valor.value)}</div>
          </div>
        ))}
      </div>
      <div>
        <div className="worldMapInternacionalizacao2">
          <MapChart setTooltipContent={setContent} data={data}></MapChart>
        </div>
        <MouseTooltip
          visible={isMouseTooltipVisible()}
          offsetX={-14}
          offsetY={-70}
          style={{ pointerEvents: "none" }}
        >
          <div style={{ filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))", pointerEvents: "none" }}>
            <div style={{ backgroundColor: "white", padding: "11px", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignContent: "flex-start", justifyItems: "flex-start", alignItems: "flex-start" }}>
              <div className="nomeRegiao" >{content[0]}</div>
              <div className="valorRegiao" style={{ left: 0 }}>{toBRL(content[1])}</div>
            </div>
            <div style={{ marginTop: "-1px", marginLeft: "5px", width: 0, height: 0, borderLeft: "10px solid transparent", borderRight: "10px solid transparent", borderTop: "10px solid white" }}></div>
          </div>
        </MouseTooltip>
      </div>
      <div style={{ paddingTop: "57px", paddingBottom: "57px" }}>
        {data.slice(7, 14).map((valor) => (
          <div style={{ textAlign: "left", marginBottom: "8px" }} >
            <div className="nomeRegiaoEmpresarial" style={{ textAlign: "left" }}>{valor.type}</div>
            <div className="valorRegiaoEmpresarial" style={{ textAlign: "left" }}>{toBRL(valor.value)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}