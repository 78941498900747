import React, { useEffect, useState } from "react";
import Portas from "../../../portas";
import SimpleModal from "../../blog/ModalProjeto";
import SimpleModalRealizacao from "../ModalRealizacao";

export default function VejaMais(props) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedVejaMais, setSelectedVejaMais] = useState("");
  const [projetos, setProjetos] = useState([]);
  const [realizacoes, setRealizacoes] = useState([]);
  const [page, setPage] = useState(0);

  function handleClickProjeto(id) {
    setSelectedVejaMais(id);
    setOpen(true);
  }

  function handleClickRealizacao(id) {
    setSelectedVejaMais(id);
    setOpen2(true);
  }
  window.addEventListener("pageshow", function (event) {
    var historyTraversal =
      event.persisted ||
      (typeof window.performance != "undefined" &&
        window.performance.navigation.type === 2);
    if (historyTraversal) {
      window.location.reload();
    }
  });

  const getVejaMais = async () => {
    try {
      const response = await fetch(
        Portas().serverHost +
          "/projetos?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON = await response.json();
      setProjetos(resJSON);

      const responseRealizacao = await fetch(
        Portas().serverHost +
          "/realizacoes?area=true&image=true&pagina=0&tamanho=5&anexo=true&paginacao=false&autor=true",
        {
          method: "GET",
        }
      );

      const resJSON1 = await responseRealizacao.json();
      setRealizacoes(resJSON1);

    } catch (err) {
      
    }
  };

  function filterProjetos() {
    let arr = [];
    for (var i = 0; i < projetos.length; i++) {
      if (i >= 5 * page && i < 5 * (page + 1)) {
        if (projetos[i].id !== props.id) arr.push(projetos[i]);
      }
    }

    return arr;
  }
  function filterRealizacoes() {
    let arr = [];
    for (var i = 0; i < realizacoes.length; i++) {
      if (i >= 5 * page && i < 5 * (page + 1)) {
        if (realizacoes[i].id !== props.id) arr.push(realizacoes[i]);
      }
    }

    return arr;
  }
  useEffect(() => {
    getVejaMais();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {open ? (
        <SimpleModal
          open={open}
          setOpen={() => setOpen(!open)}
          id={selectedVejaMais}
        ></SimpleModal>
      ) : (
        <></>
      )}
      {open2 ? (
        <SimpleModalRealizacao
            open={open2}
            setOpen={() => setOpen2(!open2)}
            id={selectedVejaMais}
        ></SimpleModalRealizacao>
      ) : (
        <></>
      )}

      {props.tipo === "projeto" ? (
        <div>
          {projetos.length > 1 ? (
            <div style={{ paddingLeft: "68.17px" }}>
              <div className="vejaMaisProjeto">Veja Mais</div>
              {filterProjetos(projetos).map((item) => (
                <div
                  className="postvermaisProjeto"
                  onClick={() => handleClickProjeto(item.id)}
                >
                  <div className="divimg">
                    <div
                      className="imgPostProjeto"
                      style={{
                        backgroundImage: `url('${item.capa}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>

                    <div className="postVerMaisTítuloProjeto">
                      <div>{item.titulo}</div>
                    </div>
                  </div>
                  <div className="postVerMaisLegendaProjeto">projeto</div>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>
            {realizacoes.length > 1 ? (
                <div style={{ paddingLeft: "68.17px" }}>
                <div className="vejaMaisRealizacoesl">Veja Mais</div>
                {filterRealizacoes(realizacoes).map((item) => (
                <div
                  className="postvermaisRealizacoesl"
                  onClick={() => handleClickRealizacao(item.id)}
                >
                  <div className="divimgRealizacoes">
                    <div
                      className="imgPostRealizacao"
                      style={{
                        backgroundImage: `url('${item.capa}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className="postVerMaisTítuloRealizacoes">
                      <div> {item.titulo}</div>
                    </div>
                  </div>
                  <div className="postVerMaisLegendaRealizacoes">realização</div>
                </div>
                ))}
              </div>
            ) : (
                <></>
            )}
          
        </div>
      )}
    </div>
  );
}
