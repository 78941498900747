import Popover from '@material-ui/core/Popover';
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { ReactComponent as BackButton } from "../../components/icons/cabecalho/backButton.svg";
import { ReactComponent as Logo } from "../../components/icons/cabecalho/logoFuncap.svg";
import { ReactComponent as Notificacao } from "../../components/icons/cabecalho/notificacao.svg";
import Ondas from "../../components/icons/cabecalho/ondas.svg";
import { ReactComponent as OpenPerfil } from "../../components/icons/cabecalho/openPerfil.svg";
import { ReactComponent as Ajuda } from "../../components/icons/gerenciamento/ajuda.svg";
import { ReactComponent as ClosedArrow } from "../../components/icons/gerenciamento/closedArrow.svg";
import { ReactComponent as CuradoriaIcon } from "../../components/icons/gerenciamento/curadoria.svg";
import { ReactComponent as OpenedArrow } from "../../components/icons/gerenciamento/openedArrow.svg";
import { ReactComponent as Papel } from "../../components/icons/gerenciamento/papel.svg";
import { ReactComponent as Pasta } from "../../components/icons/gerenciamento/pasta.svg";
import { ReactComponent as File } from "../../components/icons/gerenciamento/unselectedFile.svg";
import { ReactComponent as Usuarios } from "../../components/icons/gerenciamento/usuarios.svg";
import { ReactComponent as Sair } from "../../components/icons/login/sair.svg";
import Portas from "../../portas";
import AjudaPage from "./ajuda/Ajuda";
import ConteudosFuncap from "./conteudos/ConteudosFuncap";
import CriarArea from "./conteudos/criarArea/cadastroArea";
import CriarCooperacao from "./conteudos/criarCooperacao/cadastroCooperacao";
import CadastroProjeto from "./conteudos/criarProjeto/cadastroProjeto";
import CriarRealizacao from "./conteudos/criarRealizacao/cadastroRealizacao";
import Curadoria from "./curadoria/Curadoria";
import "./gerenciamento.css";
import CadastroUsuario from "./usuarios/cadastro/CadastroUsuario";
import UsuariosLista from "./usuarios/lista/Lista";

//auth
import StoreContext from "../../components/Store/Context";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function Tab() {

  //pre-menu selecionado
  const location = useLocation().pathname;

  //rotas alternativas
  var route1 = "1";
  var route2 = "2";
  var route3 = "3";
  var route4 = "4";

  var selectedRoute = "curadoria"; //default
  var selectedSonRoute = ""; //default

  if (location.includes(route1)) {
    selectedRoute = "curadoria";
  }
  else if (location.includes(route2)) {
    selectedRoute = "conteudo";
    selectedSonRoute = "conteudo/conteudosfuncap";
  }
  else if (location.includes(route3)) {
    selectedRoute = "usuarios";
    selectedSonRoute = "usuarios/lista";
  }
  else if (location.includes(route4)) {
    selectedRoute = "ajuda";
  }

  const classes = useStyles();
  const [tabOpened, setTabOpened] = useState(false);
  const [notificacoes, setNotificacoes] = useState([{ notificacao: "Não há novas notificações", type: "Pendente" }])
  const [tabMotherOpened, setTabMotherOpened] = useState(selectedRoute);
  const [tabSonOpened, setTabSonOpened] = useState(selectedSonRoute);
  const [conteudoOpened, setConteudoOpened] = useState(false);
  const [usuariosOpened, setUsuariosOpened] = useState(false);
  const [quantRevPendentes, setQuantRevPendentes] = useState(0);
  const [ajudaOpened, setAjudaOpened] = useState(false);
  const [user, setUser] = useState();
  const { token, setToken } = useContext(StoreContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [time, setTime] = useState(new Date());

  async function markReadNotifi(notificacoes) {
    if (notificacoes[0].type === "Notificação") {
      try {
        const response = await fetch(Portas().serverHost + "notificacoes/" + notificacoes[0].id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': "Bearer " + token,
            }
          }
        );

        const resJSON = await response.json();
      }
      catch (err) {
        console.log(err)
      }
    }
  }

  const handleClickNotificacao = (event) => {
    setAnchorEl(event.currentTarget);
    markReadNotifi(notificacoes);
  };

  const handleCloseNotificacao = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  function handleTabChange(tabName) {
    if (tabName === "conteudo") {
      if (tabSonOpened.indexOf("conteudo") > -1) {
        setTabMotherOpened(tabName);
      }

      setConteudoOpened(!conteudoOpened);
      setUsuariosOpened(false)
      setAjudaOpened(false)

    } else if (tabName === "usuarios") {
      if (tabSonOpened.indexOf("usuarios") > -1) {
        setTabMotherOpened(tabName);
      }

      setUsuariosOpened(!usuariosOpened);
      setConteudoOpened(false)
      setAjudaOpened(false)

    } else if (tabName === "ajuda") {
      setTabMotherOpened(tabName);
      setTabSonOpened("");
    } else if (tabName === "curadoria") {
      setTabMotherOpened(tabName);
      setTabSonOpened("");
    }
  }

  function handleSubTabChange(tabName) {
    if (tabName.indexOf("conteudo") > -1) {
      setTabMotherOpened("conteudo");
    } else if (tabName.indexOf("usuarios") > -1) {
      setTabMotherOpened("usuarios");
    } else if (tabName.indexOf("ajuda") > -1) {
      setTabMotherOpened("ajuda");
    }

    setTabSonOpened(tabName);
  }

  function getSonClassName(className) {
    if (className === tabSonOpened) {
      return "subItemDivGerenciamentoSelected";
    } else {
      return "subItemDivGerenciamento";
    }
  }

  function clickOnNotOpenedTab(tabName) {
    setTabOpened(!tabOpened);
    handleTabChange(tabName);
  }

  const getNotificacao = async () => {
    if (token !== null) {

      //getNotificacao
      try {
        const response = await fetch(Portas().serverHost + "/notificacoes",
          {
            method: "GET",
            headers: {
              'Authorization': "Bearer " + token,
            }
          }
        );
        const resJSON2 = await response.json();
        console.log(resJSON2)

        if (resJSON2.length > 0) {
          setNotificacoes(resJSON2)
        }
        else {
          setNotificacoes([{ notificacao: "Não há novas notificações", type: "Pendente" }])
        }
      }
      catch (err) {
        console.log(err);
        setNotificacoes([{ notificacao: "Não há novas notificações", type: "Pendente" }])
      }

    }
  }

  const getUser = async () => {
    if (token !== null) {
      try {
        const response = await fetch(Portas().serverHost + "/usuarios/me?cientista=true&acesso=true&image=true",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              'Authorization': "Bearer " + token,
            }
          }
        );

        const resJSON1 = await response.json();
        setUser(resJSON1);

        if (resJSON1.perfil_acesso.acesso === "Administrador" || resJSON1.perfil_acesso.acesso === "Curador") {
          try {
            const response = await fetch(Portas().serverHost + "/revisoes",
              {
                method: "GET",
                headers: {
                  'Authorization': "Bearer " + token,
                }
              }
            );
            const resJSON = await response.json();
            var quant = 0;
            for (var i = 0; i < resJSON.length; i++) {
              if (resJSON[i].avaliado === null) {
                quant++
              }
            }
            setQuantRevPendentes(quant)
          }
          catch (err) {
            console.log(err)
          }
        }

      }
      catch (err) {

      }

    }
  }


  useEffect(() => {
    // Update the document title using the browser API
    getUser();
    const interval = setInterval(() => {
      getNotificacao()
    }, 5000);
    return () => clearInterval(interval);

  }, []);

  if (typeof user === "undefined") {
    return (
      <div></div>
    )
  }

  return (
    <div className="root">
      <div
        className="cabecalhoGerenciamento"
        style={{
          zIndex: 100000,
          backgroundColor: "white",
          backgroundImage: `url(${Ondas})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "300px 100px",
        }}
      >
        <div style={{ position: "absolute", paddingLeft: "60px", paddingTop: "15px" }}>
          {notificacoes.length > 0 ?
            <SvgIcon onClick={handleClickNotificacao} style={{ cursor: "pointer", fontSize: "24px", color: "#FFFFFF", marginLeft: "16px" }}>
              <Notificacao />
            </SvgIcon>
            :
            <></>
          }
          {notificacoes.length > 0 ?
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseNotificacao}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography className={classes.typography}>{notificacoes.map((notificacao) => <>{notificacao.notificacao}<br /></>)}</Typography>
            </Popover>
            :
            <></>
          }
        </div>
        <div
          className="divGerenciamento"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {!tabOpened ? (
            <div>
              <div
                className="divIconeGerenciamento"
                style={{ opacity: 1 }}
                onClick={() => setTabOpened(!tabOpened)}
              >
                <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                  <OpenPerfil />
                </SvgIcon>
              </div>
              {
                user.perfil_acesso.acesso === "Administrador" || user.perfil_acesso.acesso === "Curador" ?
                  <div>
                    {tabMotherOpened === "curadoria" ? (
                      <div
                        className="divIconeGerenciamentoSelected"
                        style={{ marginTop: "43px" }}
                        onClick={() => clickOnNotOpenedTab("curadoria")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <CuradoriaIcon />
                        </SvgIcon>
                      </div>
                    ) : (
                      <div
                        className="divIconeGerenciamento"
                        style={{ marginTop: "43px" }}
                        onClick={() => clickOnNotOpenedTab("curadoria")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <CuradoriaIcon />
                        </SvgIcon>
                      </div>
                    )}
                  </div>
                  : <></>
              }
              {tabMotherOpened === "conteudo" ? (
                <div
                  className="divIconeGerenciamentoSelected"
                  onClick={() => clickOnNotOpenedTab("conteudo")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Papel />
                  </SvgIcon>
                </div>
              ) : (
                <div
                  className="divIconeGerenciamento"
                  onClick={() => clickOnNotOpenedTab("conteudo")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Papel />
                  </SvgIcon>
                </div>
              )}
              {
                user.perfil_acesso.acesso === "Administrador" ?
                  <div>
                    {tabMotherOpened === "usuarios" ? (
                      <div
                        className="divIconeGerenciamentoSelected"
                        onClick={() => clickOnNotOpenedTab("usuarios")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <Usuarios />
                        </SvgIcon>
                      </div>
                    ) : (
                      <div
                        className="divIconeGerenciamento"
                        onClick={() => clickOnNotOpenedTab("usuarios")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <Usuarios />
                        </SvgIcon>
                      </div>
                    )}
                  </div>
                  : <></>
              }
              {tabMotherOpened === "ajuda" ? (
                <div
                  className="divIconeGerenciamentoSelected"
                  onClick={() => clickOnNotOpenedTab("ajuda")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Ajuda />
                  </SvgIcon>
                </div>
              ) : (
                <div
                  className="divIconeGerenciamento"
                  onClick={() => clickOnNotOpenedTab("ajuda")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Ajuda />
                  </SvgIcon>
                </div>
              )}
              <div
                className="divIconeGerenciamento"
                style={{ position: "absolute", bottom: 0, left: 0 }}
                onClick={() => setToken(null)}
              >
                <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                  <Sair />
                </SvgIcon>
              </div>
            </div>
          ) : (
            <div>
              <div className="divPerfilGerenciamento">
                <div>
                  <img
                    className="usuarioImgGerenciamento"
                    src={user.foto}
                    alt=""
                  ></img>
                </div>
                <div className="divUsuarioTextGerenciamento">
                  <div className="usuarioNomeGerenciamento">{user.nome}</div>
                  <div className="usuarioInfoGerenciamento">{user.perfil_acesso.acesso}</div>
                </div>
                <div
                  className="cursorPointerGerenciamento"
                  onClick={() => setTabOpened(!tabOpened)}
                >
                  <SvgIcon
                    style={{
                      fontSize: "24px",
                      color: "#FFFFFF",
                      marginRight: "16px",
                    }}
                  >
                    <BackButton />
                  </SvgIcon>
                </div>
              </div>
              <div className="textGerenciamento">GERENCIAMENTO</div>
              {
                user.perfil_acesso.acesso === "Administrador" || user.perfil_acesso.acesso === "Curador" ?
                  <div>
                    {tabMotherOpened === "curadoria" ? (
                      <div
                        className="divIconeGerenciamentoLargeSelected"
                        onClick={() => handleTabChange("curadoria")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <CuradoriaIcon />
                        </SvgIcon>
                        <div className="divTextGerenciamento">Curadoria</div>
                        <div className="divIconNotificacaoGerenciamento" style={{ marginRight: "10px" }}>{quantRevPendentes}</div>
                      </div>
                    ) : (
                      <div
                        className="divIconeGerenciamentoLarge"
                        onClick={() => handleTabChange("curadoria")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <CuradoriaIcon />
                        </SvgIcon>
                        <div className="divTextGerenciamento">Curadoria</div>
                        <div className="divIconNotificacaoGerenciamento" style={{ marginRight: "10px" }}>{quantRevPendentes}</div>
                      </div>
                    )}
                  </div>
                  : <></>
              }
              {tabMotherOpened === "conteudo" ? (
                <div
                  className="divIconeGerenciamentoLargeSelected"
                  onClick={() => handleTabChange("conteudo")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Papel />
                  </SvgIcon>
                  <div className="divTextGerenciamento">Conteúdo</div>
                  <div className="divBackIconTextGerenciamento">
                    <SvgIcon style={{ fontSize: "12px", color: "#FFFFFF" }}>
                      {conteudoOpened ? <OpenedArrow /> : <ClosedArrow />}
                    </SvgIcon>
                  </div>
                </div>
              ) : (
                <div
                  className="divIconeGerenciamentoLarge"
                  onClick={() => handleTabChange("conteudo")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Papel />
                  </SvgIcon>
                  <div className="divTextGerenciamento">Conteúdo</div>
                  <div className="divBackIconTextGerenciamento">
                    <SvgIcon style={{ fontSize: "12px", color: "#FFFFFF" }}>
                      {conteudoOpened ? <OpenedArrow /> : <ClosedArrow />}
                    </SvgIcon>
                  </div>
                </div>
              )}
              {conteudoOpened ? (
                <div>
                  <div
                    className={getSonClassName("conteudo/conteudosfuncap")}
                    onClick={() =>
                      handleSubTabChange("conteudo/conteudosfuncap")
                    }
                  >
                    <div className="subItemBorderGerenciamento" style={{ borderRadius: "25px 25px 0px 0px" }}></div>
                    <div className="subItemIconGerenciamento">
                      <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                        <Pasta />
                      </SvgIcon>
                    </div>
                    <div className="subItemTextGerenciamento">
                      Conteúdos Funcap
                    </div>
                  </div>
                  {user.perfil_acesso.acesso === "Administrador" || user.perfil_acesso.acesso === "Curador" ?
                    <div
                      className={getSonClassName("conteudo/criarcooperacao")}
                      onClick={() =>
                        handleSubTabChange("conteudo/criarcooperacao")
                      }
                    >
                      <div className="subItemBorderGerenciamento"></div>
                      <div className="subItemIconGerenciamento">
                        <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                          <File />
                        </SvgIcon>
                      </div>
                      <div className="subItemTextGerenciamento">
                        Criar Cooperação
                      </div>
                    </div>
                    :
                    <></>}
                  <div
                    className={getSonClassName("conteudo/criararea")}
                    onClick={() => handleSubTabChange("conteudo/criararea")}
                  >
                    <div className="subItemBorderGerenciamento"></div>
                    <div className="subItemIconGerenciamento">
                      <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                        <File />
                      </SvgIcon>
                    </div>
                    <div className="subItemTextGerenciamento">Criar Area</div>
                  </div>
                  <div
                    className={getSonClassName("conteudo/criarprojeto")}
                    onClick={() => handleSubTabChange("conteudo/criarprojeto")}
                  >
                    <div className="subItemBorderGerenciamento"></div>
                    <div className="subItemIconGerenciamento">
                      <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                        <File />
                      </SvgIcon>
                    </div>
                    <div className="subItemTextGerenciamento">
                      Criar Projeto
                    </div>
                  </div>
                  <div
                    className={getSonClassName("conteudo/criarrealizacao")}
                    onClick={() =>
                      handleSubTabChange("conteudo/criarrealizacao")
                    }
                  >
                    <div className="subItemBorderGerenciamento" style={{ borderRadius: "0px 0px 25px 25px" }}></div>
                    <div className="subItemIconGerenciamento">
                      <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                        <File />
                      </SvgIcon>
                    </div>
                    <div className="subItemTextGerenciamento">
                      Criar Realização
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {
                user.perfil_acesso.acesso === "Administrador" ?
                  <div>
                    {tabMotherOpened === "usuarios" ? (
                      <div
                        className="divIconeGerenciamentoLargeSelected"
                        onClick={() => handleTabChange("usuarios")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <Usuarios />
                        </SvgIcon>
                        <div className="divTextGerenciamento">Usuários</div>
                        <div className="divBackIconTextGerenciamento">
                          <SvgIcon style={{ fontSize: "12px", color: "#FFFFFF" }}>
                            {usuariosOpened ? <OpenedArrow /> : <ClosedArrow />}
                          </SvgIcon>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="divIconeGerenciamentoLarge"
                        onClick={() => handleTabChange("usuarios")}
                      >
                        <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                          <Usuarios />
                        </SvgIcon>
                        <div className="divTextGerenciamento">Usuários</div>
                        <div className="divBackIconTextGerenciamento">
                          <SvgIcon style={{ fontSize: "12px", color: "#FFFFFF" }}>
                            {usuariosOpened ? <OpenedArrow /> : <ClosedArrow />}
                          </SvgIcon>
                        </div>
                      </div>
                    )}
                  </div>
                  : <></>
              }
              {usuariosOpened ? (
                <div>
                  <div
                    className={getSonClassName("usuarios/lista")}
                    onClick={() => handleSubTabChange("usuarios/lista")}
                  >
                    <div className="subItemBorderGerenciamento" style={{ borderRadius: "25px 25px 0px 0px" }}></div>
                    <div className="subItemIconGerenciamento">
                      <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                        <Pasta />
                      </SvgIcon>
                    </div>
                    <div className="subItemTextGerenciamento">Lista</div>
                  </div>
                  <div
                    className={getSonClassName("usuarios/cadastro")}
                    onClick={() => handleSubTabChange("usuarios/cadastro")}
                  >
                    <div className="subItemBorderGerenciamento" style={{ borderRadius: "0px 0px 25px 25px" }}></div>
                    <div className="subItemIconGerenciamento">
                      <SvgIcon style={{ fontSize: "17px", color: "white" }}>
                        <File />
                      </SvgIcon>
                    </div>
                    <div className="subItemTextGerenciamento">Cadastro</div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {tabMotherOpened === "ajuda" ? (
                <div
                  className="divIconeGerenciamentoLargeSelected"
                  onClick={() => handleTabChange("ajuda")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Ajuda />
                  </SvgIcon>
                  <div className="divTextGerenciamento">Ajuda</div>
                  {/*
                  <div className="divBackIconTextGerenciamento">
                    <SvgIcon style={{ fontSize: "12px", color: "#FFFFFF" }}>
                      {ajudaOpened ? <OpenedArrow /> : <ClosedArrow />}
                    </SvgIcon>
                  </div>
              */}
                </div>
              ) : (
                <div
                  className="divIconeGerenciamentoLarge"
                  onClick={() => handleTabChange("ajuda")}
                >
                  <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                    <Ajuda />
                  </SvgIcon>
                  <div className="divTextGerenciamento">Ajuda</div>
                  {/*
                  <div className="divBackIconTextGerenciamento">
                    <SvgIcon style={{ fontSize: "12px", color: "#FFFFFF" }}>
                      {ajudaOpened ? <OpenedArrow /> : <ClosedArrow />}
                    </SvgIcon>
                  </div>
              */}
                </div>
              )}
              <div
                className="divIconeGerenciamentoLarge"
                onClick={() => setToken(null)}
                style={{ position: "absolute", bottom: 0, left: 0 }}
              >
                <SvgIcon style={{ fontSize: "18px", color: "white" }}>
                  <Sair />
                </SvgIcon>
                <div className="divTextGerenciamento">Sair</div>
                <div className="divBackIconTextGerenciamento"></div>
              </div>
            </div>
          )}
        </div>
        {!tabOpened ?
          <div
            className="divFuncapGerenciamentoOpen"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div className="divTituloGerenciamento">Vitrine FUNCAP</div>
            <div style={{ marginLeft: "4px" }}>
              <SvgIcon style={{ fontSize: "64px", color: "#20764B" }}>
                <Logo />
              </SvgIcon>
            </div>
          </div>
          :
          <div
            className="divFuncapGerenciamentoClosed"
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div className="divTituloGerenciamento">Vitrine FUNCAP</div>
            <div style={{ marginLeft: "4px" }}>
              <SvgIcon style={{ fontSize: "64px", color: "#20764B" }}>
                <Logo />
              </SvgIcon>
            </div>
          </div>
        }

        <div
          className="divBackButtonGerenciamento"
          onClick={() => {
            window.location = "/";
          }}
          style={{
            cursor: "pointer",
            userSelect: "none",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <div>{"< Voltar à vitrine"}</div>
        </div>
      </div>
      {!tabOpened ? (
        <div
          style={{
            zIndex: 1,
            minHeight: "400px",
            marginLeft: "94px",
            marginTop: "32px",
            marginRight: "32px",
            marginBottom: "32px",
            backgroundColor: "white",
          }}
        >
          {tabMotherOpened === "curadoria" ? <Curadoria /> : <div />}
          {tabMotherOpened === "ajuda" ? <AjudaPage /> : <div />}
          {tabMotherOpened === "conteudo" ? (
            <div>
              {tabSonOpened === "conteudo/conteudosfuncap" ? (
                <ConteudosFuncap />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criarcooperacao" ? (
                <CriarCooperacao />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criararea" ? (
                <CriarArea />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criarrealizacao" ? (
                <CriarRealizacao />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criarprojeto" ? (
                <CadastroProjeto />
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div />
          )}
          {tabMotherOpened === "usuarios" ? (
            <div>
              {tabSonOpened === "usuarios/lista" ? (
                <UsuariosLista />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "usuarios/cadastro" ? (
                <CadastroUsuario />
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div
          style={{
            zIndex: 1,
            minHeight: "400px",
            marginLeft: "315px",
            marginTop: "32px",
            marginRight: "32px",
            marginBottom: "32px",
            backgroundColor: "white",
          }}
        >
          {tabMotherOpened === "curadoria" ? <Curadoria /> : <div />}
          {tabMotherOpened === "ajuda" ? <AjudaPage /> : <div />}
          {tabMotherOpened === "conteudo" ? (
            <div>
              {tabSonOpened === "conteudo/conteudosfuncap" ? (
                <ConteudosFuncap />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criarcooperacao" ? (
                <CriarCooperacao />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criararea" ? (
                <CriarArea />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criarrealizacao" ? (
                <CriarRealizacao />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "conteudo/criarprojeto" ? (
                <CadastroProjeto />
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div />
          )}
          {tabMotherOpened === "usuarios" ? (
            <div>
              {tabSonOpened === "usuarios/lista" ? (
                <UsuariosLista />
              ) : (
                <div></div>
              )}
              {tabSonOpened === "usuarios/cadastro" ? (
                <CadastroUsuario />
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
}
