import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Cabecalho from "../../components/cabecalho/Cabecalho";
import Rodape from "../../components/rodape/Rodape";
import Auxilio from "./auxilio/Auxilio";
import Bolsas from "./bolsas/Bolsas";
import "./ciencia.css";
import Interiorizacao from "./interiorizacao/Interiorizacao";
import Internacionalizacao from "./internacionalizacao/internacionalizacao";



export default function Ciencia() {

    //pre-menu selecionado
    const location = useLocation().pathname;

    //rotas alternativas
    var route1 = "internacionalizacao";
    var route2 = "interiorizacao";
    var route3 = "formacao";
    var route4 = "auxilio";

    var selectedRoute = "internacionalizacao"; //default

    if (location.includes(route1)) {
        selectedRoute = route1;
    }
    else if (location.includes(route2)) {
        selectedRoute = route2;
    }
    else if (location.includes(route3)) {
        selectedRoute = route3;
    }
    else if (location.includes(route4)) {
        selectedRoute = route4;
    }

    const [currentPage, setCurrentPage] = useState(selectedRoute);

    function changeCurrentPage(value) {
        setCurrentPage(value)
    }

    return (
        <div>
            <Cabecalho name="ciencia"></Cabecalho>
            <div className="root">
                <div className="navCiencia">
                    {currentPage === "internacionalizacao"
                        ?
                        <div className="divYellowMainCiencia" style={{ marginRight: "8px"}}>
                            <div className="textMainCiencia">Programas de Internacionalização</div>
                        </div>
                        :
                        <div className="divYellowOffMainCiencia" onClick={() => changeCurrentPage("internacionalizacao")} style={{ marginRight: "8px"}}>
                            <div className="textOffMainCiencia" >Programas de Internacionalização</div>
                        </div>
                    }
                    {currentPage === "interiorizacao"
                        ?
                        <div className="divGreenMainCiencia" style={{ marginRight: "8px" }}>
                            <div className="textMainCiencia">Programas de <br></br>Interiorização</div>
                        </div>
                        :
                        <div className="divGreenOffMainCiencia" onClick={() => changeCurrentPage("interiorizacao")} style={{ marginRight: "8px"}}>
                            <div className="textOffMainCiencia" >Programas de <br></br> Interiorização</div>
                        </div>
                    }
                    {currentPage === "auxilio"
                        ?
                        <div className="divGreenMainCiencia" style={{ marginRight: "8px"}}>
                            <div className="textMainCiencia">Auxílio a <br></br> Projetos Científicos</div>
                        </div>
                        :
                        <div className="divGreenOffMainCiencia" onClick={() => changeCurrentPage("auxilio")} style={{ marginRight: "8px" }}>
                            <div className="textOffMainCiencia">Auxílio a <br></br> Projetos Científicos</div>
                        </div>
                    }
                    {currentPage === "formacao"
                        ?
                        <div className="divGreenMainCiencia" style={{ marginRight: "8px" }}>
                            <div className="textMainCiencia">Formação de <br></br> Recursos Humanos</div>
                        </div>
                        :
                        <div className="divGreenOffMainCiencia" onClick={() => changeCurrentPage("formacao")} style={{ marginRight: "8px" }}>
                            <div className="textOffMainCiencia" >Formação de <br></br> Recursos Humanos</div>
                        </div>
                    }
                </div>
                {currentPage === "auxilio"
                    ?
                    <Auxilio></Auxilio>
                    :
                    ""
                }
                {currentPage === "formacao"
                    ?
                    <Bolsas></Bolsas>
                    :
                    ""
                }
                {currentPage === "interiorizacao"
                    ?
                    <Interiorizacao></Interiorizacao>
                    :
                    ""
                }
                {currentPage === "internacionalizacao"
                    ?
                    <Internacionalizacao></Internacionalizacao>
                    :
                    ""
                }
                <Rodape></Rodape>
            </div>
        </div>
    )
}