import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import ReactExport from 'react-data-export';
import ReactToPrint from 'react-to-print';
import getIntervalProps from "../funcoes/getIntervalProps";
import substringColor from "../funcoes/substringColor";
import { ComponentToPrint } from './ComponentToPrint';
import getDataset from "./componentToDownload";
import { DownloadButton, PrintButton } from "./components/Buttons";
import { AntTab, AntTabs, TabPanel } from "./components/Tabs";
import BarChart from "./components/grafico_barras/BarChart";
import Donut from "./components/grafico_donut/Donut";
import Heatmap from "./components/grafico_heatmap/HeatmapSVG";
import getRegioes from "./components/grafico_heatmap/regioes";
import Tabela from "./components/tabela/Tabela";
import "./styles.css";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStyles = makeStyles({
    selectRoot: {
        "&:focus": {
            backgroundColor: "white"
        }
    },
    select: {
        "&:before": {
            borderColor: "black"
        },
        "&:after": {
            borderColor: "black",
        },
        color: "black"
    },
    icon: {
        fill: '#405965'
    },
    root: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '21px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fill: '#405965',
        color: '#405965'
    }
});

export default function Indicador(props) {

    //PROPRIEDADES DO INDICADOR
    //titulo - String - titulo do indicador
    //tipo - Se o resultado exibido é em reais ou quantidade unitária
    //tipo_indicativo - string que explique o valor, ex: 10 (país(es))
    //found - Sring - Substring de busca do titulo
    //filtro - Array - Tuplas contendo coluna e valor
    //colunas -  Array - Tupla com nomes das colunas e titulos
    //inst - Se deve exibir a opção de instituição
    //legenda - Se deve exibir legenda no heatmap
    //pivo - Coluna que indica de onde virá o valor
    //cor - Tom a ser utilizado pelo indicador

    const [coluna, setColuna] = useState('Região')
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [anos, setAnos] = useState([]);
    const [minYear, setMinYear] = useState(0);
    const [maxYear, setMaxYear] = useState(10);
    const [data, setData] = useState([]);
    const [dataInst, setDataInst] = useState([]);
    const [intervalData, setIntervalData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [dataRef, setDataRef] = useState('regiao');

    const componentRef = useRef();
    const pageStyle = `{ margin: 25mm 25mm 25mm 25mm; }`;

    const getData = (allData, minYear, maxYear) => {

        var auxData = getRegioes();
        var interval = []

        for (var i = 0; i < allData.length; i++) {
            if (parseInt(allData[i].ano) >= minYear && parseInt(allData[i].ano) <= maxYear) {
                if (allData[i][props.pivo] !== null && !Number.isNaN(allData[i][props.pivo])) {
                    interval.push(allData[i])
                    for (var j = 0; j < auxData.length; j++) {
                        if (auxData[j].type === allData[i].regiao) {
                            auxData[j].value = parseFloat(auxData[j].value) + parseFloat(allData[i][props.pivo]);
                        }
                    }
                }
            }
        }

        setIntervalData(interval);
        setData(auxData);
    }

    const getDataInst = (allData, minYear, maxYear) => {

        var auxData = [];
        var quantInst = 9;
        var uniqueData = [];

        //Cria um array sem repetição de instituição
        for (var i = 0; i < allData.length; i++) {
            if (allData[i][props.pivo_inst] !== null) {
                if (parseInt(allData[i].ano) >= minYear && parseInt(allData[i].ano) <= maxYear) {
                    if (uniqueData.length === 0) {
                        if (allData[i][props.pivo] !== null && !Number.isNaN(allData[i][props.pivo])) {
                            allData[i][props.pivo] = parseFloat(allData[i][props.pivo])
                            uniqueData.push(allData[i])
                        }
                    }
                    else {
                        if (allData[i][props.pivo] !== null && !Number.isNaN(allData[i][props.pivo])) {
                            var adicionado = false;
                            for (var j = 0; j < uniqueData.length; j++) {
                                if (uniqueData[j][props.pivo_inst] === allData[i][props.pivo_inst]) {
                                    adicionado = true;
                                    uniqueData[j][props.pivo] = parseFloat(uniqueData[j][props.pivo]) + parseFloat(allData[i][props.pivo])
                                }
                            }
                            if (!adicionado) {
                                uniqueData.push(allData[i])
                            }
                        }
                    }
                }
            }
        }

        //ordena os valores
        var auxOrder;
        for (var i = 0; i < uniqueData.length; i++) {
            for (var j = i + 1; j < uniqueData; j++) {
                if (uniqueData[i][props.pivo] < uniqueData[j][props.pivo]) {
                    auxOrder = uniqueData[i];
                    uniqueData[i] = uniqueData[j];
                    uniqueData[j] = auxOrder;
                }
            }
        }

        //seleciona os N maiores valores
        var outros = { type: "Outros", value: 0.0 }
        for (var i = 0; i < uniqueData.length; i++) {
            var type = ""
            if (uniqueData[i].hasOwnProperty('sigla')) {
                if (uniqueData[i].sigla !== null && uniqueData[i].sigla !== undefined) {
                    type = uniqueData[i].sigla
                }
            }
            else {
                type = uniqueData[i][props.pivo_inst]
            }
            if (quantInst > 0) {
                auxData.push({ type: type, value: parseFloat(uniqueData[i][props.pivo]) })
            }
            else {
                outros = { type: 'OUTROS', value: parseFloat(outros.value) + parseFloat(uniqueData[i][props.pivo]) }
            }
            quantInst--;
        }

        if (auxData.length > 0) {
            auxData.push(outros)
            setDataInst(auxData);
        }

    }

    const getAllData = async (data) => {
        try {

            var filterJSON = data;

            if (filterJSON.length > 0) {

                setAllData(filterJSON);
                setAnos(getIntervalProps(filterJSON).intervaloExistente);
                setMinYear(getIntervalProps(filterJSON).menorAno);
                setMaxYear(getIntervalProps(filterJSON).maiorAno);
                getData(filterJSON, getIntervalProps(filterJSON).menorAno, getIntervalProps(filterJSON).maiorAno);

                if (props.inst) {
                    getDataInst(filterJSON, getIntervalProps(filterJSON).menorAno, getIntervalProps(filterJSON).maiorAno);
                }

            }

            else {

                setData([])

            }

        } catch (err) {

        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleChangeMinYear = (event) => {

        var min;
        var max;

        if (event.target.value > maxYear) {
            min = maxYear;
            max = event.target.value;
            setMinYear(maxYear);
            setMaxYear(event.target.value)
        }
        else {
            min = event.target.value;
            max = maxYear;
            setMinYear(event.target.value);
        }

        getData(allData, min, max);

    }

    const handleChangeMaxYear = (event) => {

        var min;
        var max;

        if (event.target.value < minYear) {
            min = event.target.value;
            max = minYear;
            setMaxYear(minYear);
            setMinYear(event.target.value)
        }
        else {
            min = minYear;
            max = event.target.value;
            setMaxYear(event.target.value);
        }

        getData(allData, min, max);
    }

    useEffect(() => {
        getAllData(props.data);
    }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            {(((data.length > 0) === true) && allData.length > 0 && ((anos.length > 0) === true)) ?
                <div style={{ marginLeft: "5vw", marginRight: "5vw", marginTop: "75px" }}>
                    <div style={{ display: "flex" }}>
                        <p style={{ color: "#20764B", fontWeight: "bold", marginRight: "1vw" }}>{substringColor(props.titulo, props.found, 1)}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", marginTop: "14px", justifyItems: "center", alignItems: "center" }}>
                                <div className="periodoInicialIndicador">Filtrar do período de:</div>
                                <FormControl className="dropdownPeriodoIndicador">
                                    <Select
                                        value={minYear}
                                        onChange={handleChangeMinYear}
                                        displayEmpty
                                        disableUnderline
                                        classes={{ root: classes.selectRoot }}
                                        className={classes.select}
                                        MenuProps={{ disablePortal: true }}
                                        inputProps={{
                                            'aria-label': 'Without label', classes: {
                                                icon: classes.icon,
                                                root: classes.root,
                                            }
                                        }}
                                    >
                                        {anos.map(ano => <MenuItem value={ano}>{ano}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <div className="periodoFinalIndicador">até:</div>
                                <FormControl className="dropdownPeriodoIndicador">
                                    <Select
                                        value={maxYear}
                                        onChange={(event) => handleChangeMaxYear(event)}
                                        displayEmpty
                                        disableUnderline
                                        classes={{ root: classes.selectRoot }}
                                        className={classes.select}
                                        MenuProps={{ disablePortal: true }}
                                        inputProps={{
                                            'aria-label': 'Without label', classes: {
                                                icon: classes.icon,
                                                root: classes.root,
                                            }
                                        }}
                                    >
                                        {anos.map(ano => <MenuItem value={ano}>{ano}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                    </div>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Mapa de calor" />
                        <AntTab label="Gráfico circular" />
                        <AntTab label="Gráfico de barras" />
                        <AntTab label="Tabela de dados" />
                    </AntTabs>
                    <Paper elevation={1} style={{ backgroundColor: "transparent" }}>
                        <TabPanel value={value} index={0}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <Heatmap data={data} legenda={props.legenda} cor={props.cor} tipo={props.tipo} tipo_legenda={props.tipo_legenda}></Heatmap>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                {props.inst && dataInst.length > 0
                                    ?
                                    <div style={{ display: "flex", marginTop: "14px", justifyItems: "center", alignItems: "center" }}>
                                        <div className="periodoInicialIndicador" style={{ marginRight: "12px" }}>{"Visualizar por:"}</div>
                                        {dataRef === "regiao" ?
                                            <div>
                                                <div className="backgroundPainelIndicadorComp" >
                                                    <div className="divSelectContentIndicadorComp" style={{ userSelect: "none" }}>
                                                        <div className="selectedContentIndicadorComp" onClick={() => setDataRef("regiao")}>
                                                            <div className="textMainIndicadorComp" style={{ marginRight: "16px" }}>Região</div>
                                                        </div>
                                                        <div onClick={() => setDataRef("instituicao")} style={{ marginLeft: "16px" }}>
                                                            <div className="textOffMainIndicadorComp">Instituição</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }
                                        {dataRef === "instituicao" ?
                                            <div>
                                                <div className="backgroundPainelIndicadorComp" >
                                                    <div className="divSelectContentIndicadorComp" style={{ userSelect: "none" }}>
                                                        <div className="selectedContentIndicadorComp" onClick={() => setDataRef("regiao")}>
                                                            <div className="textOffMainIndicadorComp" style={{ marginRight: "16px" }}>Região</div>
                                                        </div>
                                                        <div onClick={() => setDataRef("instituicao")} style={{ marginLeft: "16px" }}>
                                                            <div className="textMainIndicadorComp">Instituição</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <Donut data={dataRef === "regiao" ? data : dataInst} tipo={props.tipo} tipo_legenda={props.tipo_legenda}></Donut>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ display: "flex", marginTop: "14px", justifyItems: "center", alignItems: "center" }}>
                                    <div className="periodoInicialIndicador" style={{ marginRight: "12px" }}>{"Visualizar por:"}</div>
                                    {dataRef === "regiao" ?
                                        <div>
                                            <div className="backgroundPainelIndicadorComp" >
                                                <div className="divSelectContentIndicadorComp" style={{ userSelect: "none" }}>
                                                    <div className="selectedContentIndicadorComp" onClick={() => setDataRef("regiao")}>
                                                        <div className="textMainIndicadorComp" style={{ marginRight: "16px" }}>Região</div>
                                                    </div>
                                                    <div onClick={() => setDataRef("instituicao")} style={{ marginLeft: "16px" }}>
                                                        <div className="textOffMainIndicadorComp">Instituição</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                    {dataRef === "instituicao" ?
                                        <div>
                                            <div className="backgroundPainelIndicadorComp" >
                                                <div className="divSelectContentIndicadorComp" style={{ userSelect: "none" }}>
                                                    <div className="selectedContentIndicadorComp" onClick={() => setDataRef("regiao")}>
                                                        <div className="textOffMainIndicadorComp" style={{ marginRight: "16px" }}>Região</div>
                                                    </div>
                                                    <div onClick={() => setDataRef("instituicao")} style={{ marginLeft: "16px" }}>
                                                        <div className="textMainIndicadorComp">Instituição</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    <BarChart data={dataRef === "regiao" ? data : dataInst} type={dataRef === "regiao" ? "regiao" : "instituicao"} tipo={props.tipo} tipo_legenda={props.tipo_legenda}></BarChart>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", justifyItems: "center", alignItems: "center" }}>
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    justifyItems: "center",
                                }}>
                                    <Tabela data={intervalData}></Tabela>
                                </div>
                            </div>
                        </TabPanel>
                    </Paper>
                    <div style={{ display: "flex", marginTop: "0.5vw", marginBottom: "0.5vw" }}>
                        {props.datasetExcel !== null && props.datasetExcel !== undefined
                            ?
                            <div style={{ marginLeft: "0.5vw" }}>
                                <ExcelFile filename="dadosFuncap" element={<DownloadButton></DownloadButton>}>
                                    <ExcelSheet dataSet={getDataset(allData, props.datasetExcel)} name="dadosFuncap" />
                                </ExcelFile>
                            </div>
                            :
                            <></>
                        }
                        {props.datasetPrint !== null && props.datasetPrint !== undefined
                            ?
                            <div style={{ marginLeft: "0.5vw" }}>
                                <ReactToPrint
                                    pageStyle={pageStyle}
                                    trigger={() => <div><PrintButton></PrintButton></div>}
                                    content={() => componentRef.current}
                                />
                                <div style={{ display: "none" }}>
                                    <ComponentToPrint data={allData} dataset={props.datasetPrint} ref={componentRef} />
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>

                </div>
                :
                <div></div>
            }
        </div >
    )
}