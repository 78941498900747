import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from "react";
import Animacao from "../../../components/animacoes/IncrementNumbers";
import Donut from "../../../components/graficos/donutPaineis/Donut";
import "./sobre.css";

import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SvgIcon from "@material-ui/core/SvgIcon";
import BlogsSection from "../../../components/blogsSection/BlogsSection";
import { ReactComponent as Editais } from '../../../components/icons/paineis/caneta.svg';
import { ReactComponent as InstituicoesAtendidas } from '../../../components/icons/paineis/casinha.svg';
import { ReactComponent as Bolsistas } from '../../../components/icons/paineis/pessoas.svg';
import { ReactComponent as ProgramasPos } from '../../../components/icons/paineis/quadrados.svg';

const useStyles = makeStyles({
    selectRoot: {
      "&:focus": {
        backgroundColor: "inherit",
      },
    },
    select: {
      "&:before": {
        borderColor: "#20764B",
      },
      "&:after": {
        borderColor: "#20764B",
      },
      color: "#405965",
      fontWeight: "bold",
    },
    icon: {
      fill: "#20764B",
    },
  });

export default function Sobre() {

    var blogs = [
        {
            tipo: 1
        },
        {
            tipo: 1
        },
    ];

    const [data, setData] = useState([
        { ano: 2021, type: "Auxílio", value: 516000.00 },
        { ano: 2021, type: "Bolsa", value: 1387600.00 },
        { ano: 2022, type: "Auxílio", value: 446344.00 },
        { ano: 2022, type: "Bolsa", value: 658400.00 },
            // {
            //     type: 'Bolsa Pesquisador',
            //     value: 774000,
            // },
            // {
            //     type: 'Bolsa Iniciação',
            //     value: 613600,
            // }
        ]
    )

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [anos, setAnos] = useState([2021,2022]);
    const [minYear, setMinYear] = useState(2022);
    const [maxYear, setMaxYear] = useState(2022);
    const [titulo, setTitulo] = useState("Investimentos em Interiorização");

    const [filterData, setFilterData] = useState([
        { type: "Auxílio", value: 446344.00 },
        { type: "Bolsa", value: 658400.00 },
      ]);

    const handleChangeMinYear = (event) => {
        var min;
        var max;
    
        if (event.target.value > maxYear) {
          min = maxYear;
          max = event.target.value;
          setMinYear(maxYear);
          setMaxYear(event.target.value);
          updateFilterData(min, max);
        } else {
          min = event.target.value;
          max = maxYear;
          setMinYear(event.target.value);
          updateFilterData(min, max);
        }
      };
    
      const handleChangeMaxYear = (event) => {
        var min;
        var max;
    
        if (event.target.value < minYear) {
          min = event.target.value;
          max = minYear;
          setMaxYear(minYear);
          setMinYear(event.target.value);
          updateFilterData(min, max);
        } else {
          min = minYear;
          max = event.target.value;
          setMaxYear(event.target.value);
          updateFilterData(min, max);
        }
      };

      function updateFilterData(minYear, maxYear) {
        let array = [...filterData]
        array[0].value = 0
        array[1].value = 0
        for (var i = 0; i < data.length; i++) {
          if (data[i].ano >= minYear && data[i].ano <= maxYear) {
            if (data[i].type === "Auxílio") {
              array[0].value += data[i].value
            }
            if (data[i].type === "Bolsa") {
              array[1].value += data[i].value
            }
          }
        }
        setFilterData(array)
      }

      function getIndicadorNumber(indi) {
        var data = [
          { ano: 2021, ind: "Cidades Contempladas", valor: 12},
          { ano: 2021, ind: "Pesquisadores", valor: 87 },
          { ano: 2021, ind: "Instituições Atendidas", valor: 11 },
          { ano: 2021, ind: "Bolsistas de IC", valor: 256},
          { ano: 2022, ind: "Cidades Contempladas", valor: 9},
          { ano: 2022, ind: "Pesquisadores", valor: 81 },
          { ano: 2022, ind: "Instituições Atendidas", valor: 13 },
          { ano: 2022, ind: "Bolsistas de IC", valor: 220},
        ]
    
        var count = 0
        for (var i = 0; i < data.length; i++) {
          if (data[i].ano >= minYear && data[i].ano <= maxYear) {
            if (data[i].ind === indi) {
              count += data[i].valor
            }
          }
        }
    
        return count
      }
    

    return (
        <div className="contentCienciaAuxilioSobre">
            <div className="painelCienciaAuxilioSobre">
            <div className="textTituloPainelSobre">{titulo}</div>
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    }}
                >
                    <div className="text2TituloPainelSobre">
                    Investimentos realizados de
                    </div>
                    <div className="divDropdownPeriodoTituloBanner">
                    <FormControl className="dropdownPeriodoTituloBanner">
                        <Select
                        value={minYear}
                        onChange={handleChangeMinYear}
                        displayEmpty
                        disableUnderline
                        classes={{ root: classes.selectRoot }}
                        className={classes.select}
                        MenuProps={{ disablePortal: true }}
                        inputProps={{
                            "aria-label": "Without label",
                            classes: {
                            icon: classes.icon,
                            root: classes.root,
                            },
                        }}
                        >
                        {anos.map((ano) => (
                            <MenuItem value={ano}>{ano}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </div>
                    <div className="text2TituloPainelSobre">a</div>
                    <div className="divDropdownPeriodoTituloBanner">
                    <FormControl className="dropdownPeriodoTituloBanner">
                        <Select
                        value={maxYear}
                        onChange={handleChangeMaxYear}
                        displayEmpty
                        disableUnderline
                        classes={{ root: classes.selectRoot }}
                        className={classes.select}
                        MenuProps={{ disablePortal: true }}
                        inputProps={{
                            "aria-label": "Without label",
                            classes: {
                            icon: classes.icon,
                            root: classes.root,
                            },
                        }}
                        >
                        {anos.map((ano) => (
                            <MenuItem value={ano}>{ano}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: "32px",
                        paddingBottom: "32px",
                        paddingTop: "42px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <div className="donutCienciaAuxilioSobre">
                        <Donut data={filterData} cor={"verde"}></Donut>
                    </div>
                    <div className="divMiniIndicadoresCienciaAuxilio">
                        <div className="divIconesCienciaAuxilio">
                            <div className="divIconeCienciaAuxilio">
                                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                    <Editais />
                                </SvgIcon>
                                <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Cidades Contempladas")}  /></b><br></br>Cidades Contempladas<br></br></p>
                            </div>
                            <div className="divIconeCienciaAuxilioEnd">
                                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                    <InstituicoesAtendidas />
                                </SvgIcon>
                                <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Instituições Atendidas")}  /></b><br></br>Instituições Atendidas<br></br></p>
                            </div>
                        </div>
                        <div className="divIconesCienciaAuxilio" >
                            <div className="divIconeCienciaAuxilio">
                                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                    <ProgramasPos />
                                </SvgIcon>
                                <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio"><Animacao data={getIndicadorNumber("Pesquisadores")}  /></b><br></br>Pesquisadores<br></br></p>
                            </div>
                            <div className="divIconeCienciaAuxilioEnd" style={{ userSelect: "none" }}>
                                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>
                                    <Bolsistas />
                                </SvgIcon>
                                <p className="iconeTituloCienciaAuxilio"><b className="iconeNumeroCienciaAuxilio" ><Animacao data={getIndicadorNumber("Bolsistas de IC")}  /></b><br></br>Bolsistas de IC <br></br></p>
                            </div>
                        </div>
                        <div className="divIconesCienciaAuxilioEnd">
                            <div className="divIconeCienciaAuxilio">
                                <SvgIcon style={{ fontSize: "50px", color: "#20764B" }}>

                                </SvgIcon>
                                <p className="iconeTituloCienciaAuxilio" style={{ color: "transparent" }}><b className="iconeNumeroCienciaAuxilio" style={{ color: "transparent" }}><Animacao data={11} /></b><br></br><br></br></p>
                            </div>
                            <div className="divIconeCienciaAuxilioEnd" style={{ userSelect: "none" }}>
                                <SvgIcon style={{ fontSize: "50px", color: "transparent" }}>
                                </SvgIcon>
                                <p className="iconeTituloCienciaAuxilio" style={{ color: "transparent" }}><b className="iconeNumeroCienciaAuxilio" style={{ color: "transparent" }}></b><br></br><br></br></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BlogsSection page={"Ciência (Interiorização)"} title={"Realizações"}/>
        </div>
    );
}
